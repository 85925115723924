import React from "react";
import {Button, Typography} from "@mui/material";
import strings from "globals/constants/strings.json";
import Modal from "react-responsive-modal";
import {AppColors} from "globals/appcolors";

interface IProps {
  open: boolean;
  onClick: () => void;
}

export const ScreenShareDenied: React.FC<IProps> = ({open, onClick}) => {
  return (
    <Modal
      open={open}
      onClose={() => {}}
      center
      showCloseIcon={false}
      styles={{
        modal: {maxWidth: "50vw", borderRadius: 10, padding: "2rem"},
      }}
    >
      <div>
        <Typography fontWeight={"700"} fontSize={20}>
          {strings.screenShareDenied.title}
        </Typography>
        <ul className="list-disc">
          <li>
            <Typography sx={{marginTop: 0.8}} fontSize={14} fontWeight={"500"}>
              {strings.screenShareDenied.description}
            </Typography>
          </li>
          <li>
            <Typography sx={{marginTop: 0.8}} fontSize={14} fontWeight={"500"}>
              {strings.screenShareDenied.additionalInfo}
            </Typography>
          </li>
        </ul>

        <div className="flex justify-end">
          <Button
            variant="contained"
            sx={{
              marginTop: 2,
              background: AppColors.PRIMARYCOLOR,
              ":hover": {background: AppColors.PRIMARYCOLOR},
            }}
            onClick={onClick}
            href={""}
          >
            <Typography fontWeight={700} letterSpacing={0.2}>
              {strings.reshare}
            </Typography>
          </Button>
        </div>
      </div>
    </Modal>
  );
};
