import {useQuery, useQueryClient, UseQueryResult} from "@tanstack/react-query";
import axios from "axios";
import cookies from "react-cookies";

import {COOKIESKEYS} from "globals/constants/cookiesKeys";
import {APIEndPoints} from "globals/constants/endPoints";
import {JWTList} from "globals/types/APIResponses";

export function useJWTList(
  assessmentId: string
): UseQueryResult<JWTList, unknown> {
  const headers = {
    Authorization: `Bearer ${cookies.load(COOKIESKEYS.CANDIDATE_TOKEN)}`,
    Accept: "application/json",
  };
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ["jwtList"],
    queryFn: async () => {
      return axios
        .get<JWTList>(APIEndPoints.GET_JWTS(assessmentId), {headers})
        .then((res) => {
          if (res.data.success === true && res.data.code === 200) {
            queryClient.invalidateQueries(["jwtList"]);
            return res.data;
          }
        })
        .catch((e) => console.log("eee", e));
    },
    enabled: false,
    onError: (e) => e,
  });
}
