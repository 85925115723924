import {useMutation, UseMutationResult} from "@tanstack/react-query";
import {candidateInstance} from "globals/axiosConstants";
import {APIEndPoints} from "globals/constants/endPoints";

interface Variables {
  option_id: string;
}

interface Request {
  questionId: number;
  payload: Variables;
}

export function useUpdateBehaviourResponse(
  assessmentId: string
): UseMutationResult<unknown, unknown, Request> {
  return useMutation({
    mutationKey: ["updateAnswer"],
    mutationFn: async (data: Request) => {
      return candidateInstance.patch<unknown, unknown, Variables>(
        `${APIEndPoints.UPDATE_BEHAVIOUR_QUESTION_RESPONSE(
          assessmentId,
          data.questionId.toString()
        )}`,
        data.payload
      );
    },
    onError: (e) => e,
  });
}
