import {Images} from "../../assets/Image";
import ContactForm from "../ContactForm";
import styles from "./style.module.css";

const JoinJourney = () => {
  return (
    <div className={styles.main_container1} id={"join-journey"}>
      <img className={styles.background} src={Images.JoinJourneyBg} />
      <div className=" flex justify-start w-full ">
        <div className={styles.wrapper}>
          <div className={styles.content_wrapper}>
            <button className={styles.btn}>Launching Soon</button>
            <h2 className={styles.heading}>
              <span>Join us Today</span>
            </h2>
            <p className={styles.desc}>
              Join our extensive waitlist today to spark connection and get
              notified when we launch!
            </p>
          </div>
          <ContactForm otherStyles="mt-[-80px] 1750:mt-[-160px]" />
        </div>
      </div>
    </div>

    // <div className={styles.main_container}>

    //   <div className={styles.bg_wrapper}>
    //     <img className={styles.bg} src={Images.JoinJourneyBg} />
    //   </div>
    //   <div className={styles.content_wrapper}>
    //     <div className={styles.section1}>

    //     </div>
    //   </div>
    //  </div>
  );
};

export default JoinJourney;
