import React from "react";
import Modal from "react-responsive-modal";
import {Row} from "components/Row";
import {
  CandidateDetailsScreenshots,
  CustomModalBaseProps,
} from "globals/types/globalTypes";

interface IProps extends CustomModalBaseProps {
  screenshots: CandidateDetailsScreenshots[];
}

export const AllScreenshots: React.FC<IProps> = ({
  open,
  onClose,
  screenshots,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      showCloseIcon={false}
      styles={{
        modal: {
          padding: "2rem",
          borderRadius: "1rem",
          maxHeight: "80vh",
          overflowY: "scroll",
        },
      }}
    >
      <Row classNames="flex-wrap justify-between">
        {screenshots.map((screenshot) => (
          <div
            className="w-1/2 mb-3 rounded-lg pb-2"
            style={{borderBottom: "1px dashed black", height: 210}}
          >
            <img
              src={screenshot.path}
              alt=""
              key={screenshot.path}
              style={{
                width: "95%",
                height: "100%",
                borderRadius: 7,
              }}
            />
          </div>
        ))}
      </Row>
    </Modal>
  );
};
