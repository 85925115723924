import {Images} from "../../assets/Image";
import InfiniteLooper from "../InfiniteLooper";
import styles from "./style.module.css";

const cards = [
  {image: Images.hcl, alt: "HCL"},
  {image: Images.persistent, alt: "persistent"},
  {image: Images.swiggy, alt: "swiggy"},
  {image: Images.zomato, alt: "zomato"},
];

const CompanyTicker = () => {
  return (
    <div className={styles.main_container}>
      <InfiniteLooper direction={"left"} speed={50}>
        <div className="flex items-center gap-4 xxl:gap-5">
          {cards.map((ele, i) => {
            return (
              <div key={i.toString()}>
                <img
                  src={ele.image}
                  className=" w-[150px] h-[60px] xxl:w-[177px] xxl:h-[88.29px] 1750:w-[197px] 1750:h-[88.29px]"
                  alt={ele.alt}
                />
              </div>
            );
          })}
        </div>
      </InfiniteLooper>
    </div>
  );
};

export default CompanyTicker;
