import React from "react";
import styles from "./AppBody.module.scss";

interface Props {
  children: React.ReactChild;
}

const AppBody = ({children}: Props) => {
  return <div className={styles.app_body}>{children}</div>;
};

export default AppBody;
