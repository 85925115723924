import {SwipeableDrawer} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {makeStyles} from "@mui/styles";
import React from "react";

const useStyles = makeStyles({
  drawerContainer: {
    widthnde: 99999,
  },
});

export interface DrawerViewProps {
  onClose: React.ReactEventHandler;
  onOpen?: React.ReactEventHandler;
  className?: string;
  children: React.ReactNode;
  direction?: "bottom" | "left" | "right" | "top";
  HeaderComponent?: () => void;
  FooterComopnent?: () => void;
  variant?: "permanent" | "persistent" | "temporary";
  hasBackdrop?: boolean;
}

export const DrawerView: React.FC<DrawerViewProps> = (props) => {
  const {
    FooterComopnent,
    HeaderComponent,
    direction = "right",
    className = "",
    onClose,
    onOpen,
    children,
    hasBackdrop = true,
    variant,
  } = props;

  const isMobile = useMediaQuery("(max-width:600px)");
  const classes = useStyles();

  return (
    //@ts-ignore
    <SwipeableDrawer
      anchor={direction}
      open={true}
      onClose={onClose}
      onOpen={() => onOpen}
      // className={`${className} ${classes.drawerContainer}`}
      classes={{paper: `${className} ${classes.drawerContainer}`}}
      ModalProps={{BackdropProps: {invisible: isMobile ? true : !hasBackdrop}}}
      variant={isMobile ? "permanent" : variant}
      transitionDuration={0}
    >
      {HeaderComponent && HeaderComponent()}
      {children}
      {FooterComopnent && FooterComopnent()}
    </SwipeableDrawer>
  );
};

// export default DrawerView;
