import React from "react";
import strings from "globals/constants/strings.json";
import {Link} from "react-router-dom";
import {QuestionLinks, RouteLinks} from "globals/constants/routeLinks";

interface TopTabsProps {
  selectedTab: Number;
}

const TopTabs: React.FC<TopTabsProps> = (props) => {
  const role: string = localStorage.getItem("Role")!;

  return (
    <div className="flex mt-8">
      <Tab
        selectedTab={props.selectedTab === 0}
        routeTo={RouteLinks.AllQuestion}
        label={strings.question.all_question}
      />
      {role == "Super Admin" ? (
        <>
          <Tab
            selectedTab={props.selectedTab === 1}
            routeTo={QuestionLinks.Create_Question}
            label={strings.question.create_question}
          />
          <Tab
            selectedTab={props.selectedTab === 2}
            routeTo={QuestionLinks.Csv_Questions}
            label={strings.question.csv_questions}
          />
        </>
      ) : null}
    </div>
  );
};

interface TabProps {
  label: string;
  routeTo: string;
  selectedTab: boolean;
}

const Tab: React.FC<TabProps> = (props) => (
  <Link
    to={`/${props.routeTo}`}
    className={`w-44 justify-center flex pb-2 border-b ${
      props.selectedTab ? "border-primary" : "border-monoGrey-400"
    } mr-8`}
  >
    <span
      className={`font-semibold text-xs ${
        props.selectedTab ? "text-primary" : "text-monoGrey-400"
      } `}
    >
      {props.label}
    </span>
  </Link>
);

export default TopTabs;
