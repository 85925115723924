import {Backdrop} from "@mui/material";
import {AppColors} from "globals/appcolors";
import {COOKIESKEYS} from "globals/constants/cookiesKeys";
import {APIEndPoints} from "globals/constants/endPoints";
import {RouteLinks} from "globals/constants/routeLinks";
import {decodeEncodedAPIResponse} from "globals/helpers";
import {AssessmentDataResponse} from "globals/types/globalTypes";
import axiosPostModule from "network/Post/AxiosPostModule";
import React, {useEffect} from "react";
import {Spinner} from "react-activity";
import cookie from "react-cookies";
import {useNavigate, useSearchParams} from "react-router-dom";

export const CandidateLogin: React.FC = () => {
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();

  const payload = JSON.parse(`${queryParams.get("payload")!}`);
  const {data} = decodeEncodedAPIResponse(payload, {});

  useEffect(() => {
    const {name, lastname, email, password} = data;
    axiosPostModule(`${APIEndPoints.CANDIDATE_LOGIN_NODE}?isRoundTwo=true`, {
      first_name: name.trim(),
      last_name: lastname.trim(),
      email: email.trim(),
      password: password.trim(),
    }).then(({data: {interview, Token}}: AssessmentDataResponse) => {
      localStorage.setItem("candidateToken", Token);
      cookie.save(COOKIESKEYS.CANDIDATE_TOKEN, Token, {
        path: "/",
      });
      localStorage.setItem(
        COOKIESKEYS.ROUND_TWO_QUESTIONS,
        JSON.stringify(interview![0].assessment_round_two_questions)
      );

      const assessmentId = interview![0].id;
      navigate(
        `/${RouteLinks.Interview_Preview}?assessment=${assessmentId}&token=${Token}`
      );
    });
  }, []);

  return (
    <Backdrop open>
      <Spinner size={40} color={AppColors.PRIMARYCOLOR} />
    </Backdrop>
  );
};
