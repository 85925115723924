import {CountDownTimer} from "components/Timer";
import {memo, useEffect, useState} from "react";
import styles from "./styles.module.css";

interface HeaderProps {
  title: string;
  isDarkMode?: boolean;
  showTimer?: boolean;
  initialTime?: number;
}
const InterviewHeader = ({
  title,
  isDarkMode = false,
  showTimer,
  initialTime,
}: HeaderProps) => {
  const lightLogo =
    "https://images.ctfassets.net/7oc31naqqojs/19IuRVsEWksBeu3EtMHkuL/86ca2c966980d64c0c4869aa03f1a312/Frame_1686552542.png";
  const darkLogo =
    "https://images.ctfassets.net/7oc31naqqojs/wivo2YZ5aefPWwjqcNIXs/b945c193c56ad798c273f83a3ce2af34/Frame_1686552542__1_.png";

  const [remainingTime, setRemainingTime] = useState<number | null>(null);

  useEffect(() => {
    if (initialTime) {
      setRemainingTime(initialTime);
    }
  }, [initialTime]);

  return (
    <div className={styles.main_wrapper}>
      <div>
        <img src={isDarkMode ? darkLogo : lightLogo} className={styles.logo} />
      </div>

      <p className={`${isDarkMode && "text-white-900"} ${styles.title}`}>
        {title}
      </p>

      {showTimer && initialTime ? (
        <div
          className={`py-2 px-4 rounded-[45px] text-white-900 ${
            remainingTime
              ? remainingTime > 180
                ? "bg-[#28af3a]"
                : "bg-[#f63333]"
              : "transparent"
          }`}
        >
          <CountDownTimer
            time={initialTime}
            sx={{fontWeight: "bold", color: "white"}}
          />
        </div>
      ) : null}
    </div>
  );
};

export default memo(InterviewHeader);
