import React from "react";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {Box, TextField} from "@mui/material";
import {Moment} from "moment";
import {CSS, DatePickerBox} from "globals/types/globalTypes";

interface Props {
  customInputStyle: CSS;
  customContainerStyle: DatePickerBox;
  label?: string | undefined | null;
  onChange: (newDate: Moment | null) => void;
  value?: Moment | null;
  format?: string;
  maxDate?: Moment;
  isCustomInput?: boolean;
  disabled?: boolean;
  minDate?: Moment;
}

const Datepicker: React.FC<Props> = ({
  label,
  onChange,
  value,
  format,
  maxDate,
  isCustomInput,
  customInputStyle,
  customContainerStyle,
  disabled,
  minDate,
}) => {
  return (
    <DatePicker
      label={label}
      onChange={(newDate) => onChange(newDate)}
      value={value}
      className={"h-1"}
      renderInput={
        isCustomInput
          ? ({inputRef, inputProps, InputProps}) => (
              <Box
                sx={[
                  {display: "flex", alignItems: "center"},
                  customContainerStyle,
                ]}
                component={"div"}
              >
                <input
                  ref={inputRef}
                  style={customInputStyle}
                  {...inputProps}
                />
                {InputProps?.endAdornment}
              </Box>
            )
          : (params) => <TextField {...params} />
      }
      inputFormat={format}
      maxDate={maxDate}
      disabled={disabled}
      minDate={minDate}
    />
  );
};

export default Datepicker;
