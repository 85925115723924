import React from "react";
import {decodeEncodedAPIResponse, getIPOfUser} from "globals/helpers";
import axios from "axios";
import {APIEndPoints} from "globals/constants/endPoints";
import {InterviewInstructionsResponse} from "globals/types/APIResponses";
import {useQuery, useQueryClient, UseQueryResult} from "@tanstack/react-query";

export function useFetchAssessmentDetails(
  id: string
): UseQueryResult<InterviewInstructionsResponse> {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ["assessmentRules"],
    queryFn: async () => {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("candidateToken")}`,
        Accept: "application/json",
      };
      return axios
        .get(`${APIEndPoints.GET_INTERVIEW_INSTRUCTIONS}/${id}`, {
          headers,
        })
        .then((res) => {
          if (res.data.success === true && res.data.code === 200) {
            const {data} = res.data;
            const decodedResponse: InterviewInstructionsResponse =
              decodeEncodedAPIResponse(data, res.data);
            queryClient.invalidateQueries(["assessmentRules"]);
            return decodedResponse;
          }
        });
    },
    enabled: false,
    onError: (e) => e,
    retry: false,
  });
}
