import React, {useState} from "react";
import Modal from "react-responsive-modal";
import {useNavigate} from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import styles from "./QuestionCard.module.scss";

import SizedBox from "components/SizedBox/SizedBox";
import ClickableTags from "components/ClickableTag/ClickableTags";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";

import strings from "globals/constants/strings.json";
import {QuestionLinks} from "globals/constants/routeLinks";
import {GetAllQuestionDataNode} from "globals/types/globalTypes";
import {difficultyOptions} from "globals/constants/dropdownConstants";

import {Images} from "Images";

// export interface GetAllQuestionDataNode {
//   assessment_id: number;
//   created_at: string;
//   created_by: any;
//   deletedAt: string | any;
//   id: number;
//   question: {
//     created_at: string;
//     created_by: any;
//     deletedAt: string | any;
//     id: number;
//     is_always_included: number;
//     is_csv_question: number;
//     is_paid_question: number;
//     organization_id: number;
//     question: string;
//     question_duration: number;
//     question_level: number;
//     question_points: number;
//     question_type: number;
//     shuffle_options: number
//     skill_id: number;
//     updated_at: number;
//     updated_by: number;
//     question_options: {
//       created_at: string;
//       created_by: any;
//       deletedAt: string | any;
//       id: number;
//       is_correct_option: number;
//       option: string;
//       option_points: number;
//       question_id: number;
//       updated_at: number;
//       updated_by: number;
//     }
//   }
//   question_id: number;
//   question_points_scored: number;
//   question_total_points: number;
//   skill: {
//     skill: string;
//   }
//   skill_id: number;
//   status: number;
//   updated_at: number;
//   updated_by: number;
// }

interface Props {
  data: GetAllQuestionDataNode;
  canView?: boolean;
  canEdit?: boolean;
  onDelete?: () => void;
}

const QuestionCard: React.FC<Props> = ({
  data,
  canEdit = true,
  canView = true,
  onDelete,
}) => {
  const {setquestionData} = useAppCommonDataProvider();
  const navigate = useNavigate();
  const questionLevel = (val: number) => difficultyOptions[val - 1]?.label;

  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const {questionData} = useAppCommonDataProvider();

  const navigateToCreateQuestion = () => {
    setquestionData?.(data);
    navigate(`/${QuestionLinks.Create_Question}?edit=true`);
  };

  return (
    <>
      <div className={styles.container}>
        <div className="flex justify-between items-center">
          <p
            className={styles.question__difficulty}
          >{`difficulty level- ${questionLevel(data.question_level)}`}</p>

          <div className="flex">
            {canView && (
              <>
                <img
                  className="cursor-pointer w-1/4"
                  src={Images.ViewEye}
                  onClick={onOpenModal}
                />
                <Modal
                  open={open}
                  onClose={onCloseModal}
                  styles={{
                    modal: {
                      borderRadius: 10,
                      width: "100%",
                      padding: "10px 20px",
                    },
                  }}
                  center
                >
                  <h2 className="font-bold py-2 text-xl">Question</h2>
                  {/* <p className="text-base font-semibold ">{data.question}</p> */}
                  <TextareaAutosize
                    value={data.question}
                    className={styles.question__textarea}
                  ></TextareaAutosize>

                  <SizedBox height="10px" />
                  <p className="text-base font-semibold">
                    {data.question_options &&
                      data.question_options.map((item, value) => {
                        return (
                          <div key={item.option}>
                            <div className="flex py-1">
                              <div className={styles.correct__optionContainer}>
                                {!!item.is_correct_option && (
                                  <div
                                    className={
                                      styles.correct__optionOuterCircle
                                    }
                                  >
                                    <div
                                      className={
                                        styles.correct__optionInnerCircle
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                              <TextareaAutosize
                                value={item.option.replace("\n", "")}
                                className="px-2 resize-none"
                                style={{width: "100%"}}
                                readOnly
                              ></TextareaAutosize>
                            </div>
                          </div>
                        );
                      })}
                  </p>
                  <SizedBox height="20px" />
                  <div className="flex">
                    <h1 className={styles.miscellaneous__title}>
                      Question Duration
                    </h1>
                    <p className="px-2">{data.question_duration}</p>
                  </div>
                  <SizedBox height="10px" />
                  <div className="flex">
                    <h1 className={styles.miscellaneous__title}>
                      Question Points
                    </h1>
                    <p className="px-2">{data.question_points}</p>
                  </div>
                </Modal>
              </>
            )}
            <SizedBox width="10px" />
            {canEdit && (
              <img
                className="cursor-pointer"
                src={Images.EditIcon}
                onClick={navigateToCreateQuestion}
              />
            )}
            <SizedBox width="10px" />
            {onDelete && (
              <img
                className="cursor-pointer"
                src={Images.Dustbin}
                width={"26px"}
                height={"26px"}
                onClick={onDelete}
              />
            )}
          </div>
        </div>

        <SizedBox height={"10px"} />
        <TextareaAutosize
          value={data.question}
          className="text-sm font-semibold"
        >
          {/* {data.question} */}
        </TextareaAutosize>

        <SizedBox height="10px" />
        <div className={styles.skills__container}>
          <ClickableTags
            isClickable={false}
            label={data?.skill!?.skill}
            labelStyle={styles.skill__label}
            containerStyle={styles.skill__container}
          />
          <div className="flex items-end">
            <p className={styles.created__by}>
              {`${strings.created_by}${
                data.user.email
                // {/* // ? data.created_by.email */}
                // {/* // : data.creator && data.creator.email */}
              }`}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionCard;
