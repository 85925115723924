import InterviewActionButton from "components/Button/InterviewActionButton";
import React from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import styles from "./styles.module.css";

type WarningType =
  | "secondRound"
  | "permissionsIssue"
  | "systemMismatch"
  | "screenShareStopped"
  | "tabSwitch"
  | "enterFullScreen"
  | "finishConfirmation"
  | "finishing";

interface WarningModalProps {
  isOpen: boolean;
  onClose: () => void;
  type: WarningType;
  remainingTime?: number;
  switchCount?: number;
}

const WarningModal: React.FC<WarningModalProps> = ({
  isOpen,
  onClose,
  type,
  remainingTime,
  switchCount,
}) => {
  const getModalContent = () => {
    switch (type) {
      case "secondRound":
        return {
          icon: "✅",
          heading: "Second Round Completed",
          content:
            "Great job! You've completed the second round. We'll get back to you with the next steps shortly.",
        };
      case "permissionsIssue":
        return {
          icon: "🚫",
          heading: "Permissions Issue",
          content: (
            <>
              <p className="font-semibold">Permission Denied:</p>
              <p>
                We need access to your camera and microphone to proceed. Please
                follow the instructions below to enable the necessary
                permissions:
              </p>
              <ul>
                <li>
                  <strong>Chrome</strong>: Click the lock icon next to the URL
                  and enable both camera and microphone.
                </li>
                <li>
                  <strong>Firefox</strong>: Click the permissions icon near the
                  URL and allow access.
                </li>
                <li>
                  <strong>Safari</strong>: Go to Safari Preferences &gt;
                  Websites and enable camera/microphone.
                </li>
              </ul>
              <p>Refresh the page after making changes.</p>
            </>
          ),
        };
      case "systemMismatch":
        return {
          icon: "⚠️",
          heading: "System Mismatch",
          content: (
            <>
              <p className="font-semibold">System Mismatch Detected:</p>
              <p>
                Your system does not meet the required specifications. Please
                check the following:
              </p>
              <ul>
                <li>
                  You kicked off this assessment on a different system than the
                  one you are using now.
                </li>
                <li>You used an incognito window to login to the system.</li>
                <li>
                  You are trying to access the link in an incognito window after
                  logging in from a regular tab.
                </li>
              </ul>
              <p>Try attempting from the system you started originally.</p>
            </>
          ),
        };
      case "screenShareStopped":
        return {
          icon: "🚫",
          heading: "Screen Sharing Stopped",
          content: (
            <>
              <p>
                To continue, please resume sharing your screen. Follow these
                steps:
              </p>
              <ul>
                <li>
                  <strong>Chrome/Firefox</strong>: Click on the screen share
                  icon in the tab bar.
                </li>
                <li>
                  <strong>Safari</strong>: Re-enable screen sharing in your
                  Safari Preferences &gt; Websites.
                </li>
              </ul>
            </>
          ),
        };
      case "tabSwitch":
        return {
          icon: "⚠️",
          heading: "Attention: Tab Switch Detected",
          content: `Please return to this tab to continue with your assessment otherwise your test shall be ended. You have ${
            5 - (switchCount ?? 0)
          } more ${
            5 - (switchCount ?? 0) > 1 ? "attempts" : "attempt"
          }. The interview will also be finished if you do not return to the screenn within the next ${
            45 - (remainingTime ?? 0)
          } seconds.`,
        };
      case "enterFullScreen":
        return {
          icon: "🔄",
          heading: "Please Enter Full-Screen Mode",
          content: "Click the full-screen icon for the best experience.",
        };
      case "finishConfirmation":
        return {
          icon: "✅",
          heading: "You're Almost Done!",
          content:
            "Please confirm that you're ready to submit your responses. Once submitted, no further changes can be made.",
        };
      case "finishing":
        return {
          icon: "🔄",
          heading: "Wrapping Things Up...",
          content:
            "Please wait while we finalize your submission. This will take just a moment.",
        };
      default:
        return {
          icon: "ℹ️",
          heading: "Information",
          content: "Here is some information.",
        };
    }
  };

  const {icon, heading, content} = getModalContent();

  return (
    <Modal
      open={isOpen}
      onClose={() => {}}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      center
      classNames={{
        modal: styles.modal_wrapper,
        overlay: styles.overlay,
      }}
      showCloseIcon={false}
    >
      <div className={styles.modal_content}>
        <div className={styles.head_wrapper}>
          <div className={styles.icon}>{icon}</div>
          <h2 className={styles.head}>{heading}</h2>
        </div>

        <div className={styles.content}>{content}</div>
        <div className="mt-6 ">
          <InterviewActionButton
            isDarkMode={true}
            handleClick={onClose}
            label="Got it"
          />
        </div>
      </div>
    </Modal>
  );
};

export default WarningModal;
