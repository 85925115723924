import React from "react";
import Modal from "react-responsive-modal";
import {Button, Typography} from "@mui/material";

import {halfModalContainer} from "globals/constants/styles";
import {CustomModalBaseProps} from "globals/types/globalTypes";
import strings from "globals/constants/strings.json";

interface IProps extends CustomModalBaseProps {}

export const SecondRoundCompleted: React.FC<IProps> = ({open, onClose}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      styles={{modal: halfModalContainer}}
      showCloseIcon={false}
    >
      <Typography variant="h2">{strings.uh_oh}</Typography>
      <Typography sx={{marginTop: 1, fontSize: 17}} variant="h6">
        {strings.secondRoundFinished}
      </Typography>
      <Button
        sx={{marginTop: 2, float: "right", paddingX: 5}}
        onClick={onClose}
      >
        <Typography variant="button" sx={{fontWeight: "bold"}}>
          {strings.ok}
        </Typography>
      </Button>
    </Modal>
  );
};
