import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import axios from "axios";
import cookies from "react-cookies";

import {COOKIESKEYS} from "globals/constants/cookiesKeys";
import {APIEndPoints} from "globals/constants/endPoints";

export function useUploadScreenshot(
  assessmentId: string
): UseMutationResult<unknown, unknown, FormData> {
  const headers = {
    Authorization: `Bearer ${cookies.load(COOKIESKEYS.CANDIDATE_TOKEN)}`,
    Accept: "application/json",
  };
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["uploadScreenshot"],
    mutationFn: async (data: FormData) => {
      return axios
        .post(APIEndPoints.SEND_SCREENSHOT(assessmentId), data, {
          headers,
        })
        .then((res) => {
          queryClient.invalidateQueries(["uploadScreenshot"]);
          return res;
        });
    },
    onError: (e) => e,
  });
}
