import React, {MouseEventHandler} from "react";

interface Props {
  label: string;
  isSelected?: boolean;
  showBorder?: boolean;
  labelStyle?: string;
  isClickable: boolean;
  innerDivStyle?: string;
  containerStyle?: string;
  selectedLabelStyle?: string;
  onClick?: (val: any) => void;
}

const ClickableTags: React.FC<Props> = (props) => {
  return (
    <div
      onClick={() => props.onClick && props.onClick(props.label)}
      className={`
          ${props.containerStyle || `border-monoGrey-800 rounded-lg p-1 w-max`}
          ${props.isClickable && "cursor-pointer"}
          ${props.showBorder && "border"}
        `}
    >
      <div
        className={`
          ${
            props.isClickable &&
            props.isSelected &&
            (props.innerDivStyle || `rounded-md bg-green-3c5 flex`)
          }
        `}
      >
        <p
          className={`
          ${
            props.isSelected
              ? props.selectedLabelStyle
              : props.labelStyle || `text-white-900 text-xl mx-3 my-1`
          }
          `}
        >
          {props.label}
        </p>
      </div>
    </div>
  );
};

export default ClickableTags;
