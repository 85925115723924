import React from "react";
import {Typography} from "@mui/material";
import styles from "routes/Interview/Interview.module.scss";

import {CameraAccess, Hamburger, Mic, Padlock} from "components/Icons";
import strings from "globals/constants/strings.json";
import {AppColors} from "globals/appcolors";

export const UserDeniedPermissions: React.FC = () => {
  const {error_title, user_denied_permission} =
    strings.interview.permissions_error;
  const {
    description_1,
    description_chrome,
    description_firefox,
    safari,
    others,
  } = user_denied_permission;
  const {description_chrome_1, description_chrome_2, description_chrome_3} =
    description_chrome;
  const {
    description_firefox_1,
    description_firefox_2,
    description_firefox_3,
    description_firefox_4,
    description_firefox_5,
  } = description_firefox;

  const {description_safari_1, description_safari_2} = safari;

  return (
    <div>
      <Typography variant="h4" sx={{fontWeight: 600}}>
        {error_title}
      </Typography>
      <p className={styles.user__permissionErrorSubtitle}>{description_1}</p>
      <ul className="mt-4 list-disc px-6">
        <li>
          <p className={styles.permission__error}>
            {description_chrome_1}
            <span className="mx-1">
              <Padlock color={AppColors.PRIMARYCOLOR} />
            </span>
            <span>{description_chrome_2}</span>
            <span className="mx-1">
              <CameraAccess color={AppColors.PRIMARYCOLOR} />
            </span>
            <span>{` and`}</span>
            <span className="mx-1">
              <Mic color={AppColors.PRIMARYCOLOR} />
            </span>
            <span>{description_chrome_3}</span>
          </p>
        </li>

        <li className={`my-4`}>
          <p className="text-sm font-medium">
            {`${description_firefox_1}`}
            <br />
            {description_firefox_2}
            <Hamburger
              color={AppColors.PRIMARYCOLOR}
              className="inline text-center mx-1"
              size={20}
            />
            {description_firefox_3}
            <Padlock
              color={AppColors.PRIMARYCOLOR}
              className="inline ml-2 mr-1 -translate-y-3px"
            />
            <span className="mx-1">{`and`}</span>
            {description_firefox_4}

            <CameraAccess
              color={AppColors.PRIMARYCOLOR}
              className="inline mx-1"
            />
            <span className="mx-1">{`and`}</span>

            <Mic color={AppColors.PRIMARYCOLOR} className="inline mx-1" />
            {description_firefox_5}
          </p>
        </li>

        <li className="mb-4">
          <p className="text-sm font-medium">
            {`${description_safari_1}`}
            <br />
            {description_safari_2}
          </p>
        </li>

        <li>
          <p className={styles.permission__error}>{others}</p>
        </li>
      </ul>
    </div>
  );
};
