import React from "react";

interface Props {
  onChangeText: (text: string) => void;
  value?: string;
  style?: {};
  placeholder?: string;
}

const CustomTextArea: React.FC<Props> = ({
  onChangeText,
  value,
  style,
  placeholder,
}) => {
  return (
    <textarea
      style={style}
      placeholder={placeholder}
      onChange={(e) => onChangeText(e.target.value)}
      value={value}
      className="border text-xs border-white-300 rounded-md bg-white-800 h-28 outline-none p-2 font-light w-100"
    />
  );
};

export default CustomTextArea;
