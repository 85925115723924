import React, {useEffect} from "react";
import cookie from "react-cookies";
import {Formik} from "formik";
import {Link, useNavigate} from "react-router-dom";
import * as yup from "yup";
import {toast} from "react-toastify";
import {Bounce} from "react-activity";
import {useSearchParams} from "react-router-dom";

import styles from "./LoginCardSass.module.scss";
import {Eye, EyeHidden} from "components/Icons";
import SizedBox from "components/SizedBox/SizedBox";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";

import axios from "globals/axiosConstants";
import strings from "globals/constants/strings.json";
import {
  decodeEncodedAPIResponse,
  getDeviceName,
  postGoogleAnalyticsEvent,
  postMixPanelEvent,
} from "globals/helpers";
import {LoginResponse} from "globals/types/APIResponses";
import {COOKIESKEYS} from "globals/constants/cookiesKeys";
import {RouteLinks} from "globals/constants/routeLinks";
import {APIEndPoints} from "globals/constants/endPoints";

import {Images} from "Images";
import usernameicon from "Images/usernameicon.svg";

import axiosPostModule from "network/Post/AxiosPostModule";
import {MixPanelEvents} from "globals/constants/MixPanelEvents";
import mixpanel from "mixpanel-browser";

const LoginCard: React.FC = () => {
  const navigate = useNavigate();
  const eventTracker = postGoogleAnalyticsEvent("User");
  const {loading, setLoading} = useAppCommonDataProvider();

  const [showPassword, setshowPassword] = React.useState<boolean>(false);

  useEffect(() => {
    checkIfAlreadyLogined();
  }, []);

  const checkIfAlreadyLogined = () => {
    let isUserVerified = cookie.load(COOKIESKEYS.IS_VERIFIED);
    const token = localStorage.getItem("AUTH_TOKEN");
    !!token &&
      isUserVerified !== "false" &&
      navigate(`/${RouteLinks.Dashboard}`);
  };

  let initialValues = {
    email: "",
    password: "",
    device_name: getDeviceName(),
    // role_id: 2,
  };

  const schema = yup.lazy(() =>
    yup.object().shape({
      email: yup
        .string()
        .required("Email is required")
        .email("Enter a valid email"),
      password: yup
        .string()
        .required("Password is required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&^!])[A-Za-z\d@$!%*#?&^!]{8,}$/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
    })
  );

  const handleLogin = async (data: any) => {
    cookie.remove(COOKIESKEYS.PASSWORD, {path: "/"});
    setLoading?.(true);
    await axiosPostModule(APIEndPoints.LOGIN_NODE, data, {}, {})
      .then((res: LoginResponse) => {
        {
          res.data.user.roles.forEach((item: any) => {
            if (item.role == "Super Admin")
              localStorage.setItem("Role", item.role);
          });
        }

        mixpanel.track(res.data.user.id.toString());
        localStorage.setItem("AUTH_TOKEN", res.data.accessToken);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${res.data.accessToken}`;
        // cookie.save(COOKIESKEYS.AUTH_TOKEN, res.data.accessToken, {
        //   path: "/",
        // });
        localStorage.setItem("USER_DETAILS", JSON.stringify(res.data));
        cookie.save(
          COOKIESKEYS.IS_VERIFIED,
          res.data.user.is_user_verified
            ? res.data.user.is_user_verified
            : "false",
          {path: "/"}
        );
        cookie.save(COOKIESKEYS.PASSWORD, `${data.password}`, {path: "/"});
        setTimeout(() => {
          if (!res.data.user.is_user_verified) {
            navigate(`/${RouteLinks.Password_Change}`);
          } else {
            eventTracker("Logged in", "User logged in");
            postMixPanelEvent(MixPanelEvents.LOGIN);
            navigate(`/${RouteLinks.Dashboard}`);
          }
        }, 800);
      })
      .catch((err) => {
        if (err.status === 422) {
          toast.error(err.data.errors);
        }
        if (!err) {
          toast.error(
            "We seem to be having a problem at our end. We request you to try again after some time"
          );
          return;
        }
        // const decryptedErrors = err.data;
        // if (decryptedErrors.errors) {
        //   const errors = Object.keys(decryptedErrors.errors);
        //   toast.error(decryptedErrors.errors[errors[0]][0]);
        // }
        // if (err.status === 429) {
        //   toast.error(decryptedErrors.message);
        // }
      })
      .finally(() => {
        setTimeout(() => setLoading?.(false), 1000);
      });
  };

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url(${Images.loginBg})`,
      }}
    >
      <div className={styles.logo__container}>
        <p
          className="font-bold text-24 cursor-pointer"
          onClick={() => navigate("/")}
        >
          RecruitBae
        </p>
        <p className="lg:text-16 text-10 font-medium  text-black-150">
          Don't Have Any Account?{" "}
          <span
            onClick={() =>
              window.location.assign(
                `https://docs.google.com/forms/d/e/1FAIpQLScY7iJV7Dke7XdSx2eS9x0juCEruPkA1bfoM7lLyFSb6mGV1g/viewform`
              )
            }
            className="cursor-pointer text-blue-600"
          >
            {" "}
            Contact Us
          </span>
        </p>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.card__container}>
          <SizedBox height="10px" />
          <h1 className={styles.title}>Welcome to RecruitBae</h1>
          <SizedBox height="40px" />
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(data) => {
              handleLogin(data);
            }}
            validationSchema={schema}
          >
            {({handleSubmit, handleChange, errors, touched}) => {
              return (
                <form>
                  <div className={styles.form__container}>
                    <div className={styles.input__container}>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        onChange={handleChange}
                        placeholder="Email/Username"
                      />
                      <img
                        src={usernameicon}
                        alt="clockicon"
                        className={styles.user_logo}
                      />
                    </div>
                    {errors.email && touched.email && (
                      <p className={styles.error__text}>{errors?.email}</p>
                    )}
                  </div>

                  <SizedBox height="20px" />

                  <div
                    className={styles.form__container}
                    style={{
                      marginTop: errors.email && touched.email ? "1rem" : 0,
                    }}
                  >
                    <div className={styles.input__container}>
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        onChange={handleChange}
                        placeholder="Password"
                      />
                      {!showPassword ? (
                        <div onClick={() => setshowPassword(!showPassword)}>
                          <Eye size={23} />
                        </div>
                      ) : (
                        <div onClick={() => setshowPassword(!showPassword)}>
                          <EyeHidden size={23} />
                        </div>
                      )}
                    </div>
                    {errors.password && touched.password && (
                      <p className={styles.error__text}>{errors?.password}</p>
                    )}
                  </div>
                  <SizedBox height="10px" />
                  <span className="flex justify-end">
                    <Link
                      to="/reset-password"
                      className="text-sm text-blue-600"
                    >
                      {strings.forgot_password}
                    </Link>
                  </span>
                  <SizedBox height="30px" />
                  <div className={styles.submit__buttonContainer}>
                    <button
                      className={styles.submit__button}
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                      type="submit"
                    >
                      <div>{loading ? <Bounce /> : "Login"}</div>
                    </button>
                  </div>
                </form>
              );
            }}
          </Formik>
          <SizedBox height="10px" />
        </div>
      </div>
    </div>
  );
};

export default LoginCard;
