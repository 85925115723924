import React from "react";
import Modal from "react-responsive-modal";
import styles from "routes/Interview/Interview.module.scss";

import SizedBox from "components/SizedBox/SizedBox";
import {CustomModalBaseProps} from "globals/types/globalTypes";
import strings from "globals/constants/strings.json";
import {Button, Typography} from "@mui/material";
import {Row} from "components/Row";

interface IProps extends CustomModalBaseProps {
  setUserStoppedScreenShare: React.Dispatch<React.SetStateAction<boolean>>;
  startRecording: () => void;
}

export const ScreenShareStopped: React.FC<IProps> = ({
  onClose,
  open,
  setUserStoppedScreenShare,
  startRecording,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      showCloseIcon={false}
      styles={{
        modal: {borderRadius: 10, minWidth: "35vw", maxWidth: "45vw"},
      }}
    >
      <div className={styles.finish__testModalContainer}>
        <SizedBox height="20px" />
        <Row classNames="w-11/12">
          <Typography variant="h4" fontWeight={"bold"} fontSize={30}>
            {strings.interview.screenshare_stop_title}
            {"!"}
          </Typography>
        </Row>
        <SizedBox height="15px" />
        <Row classNames="w-90">
          <Typography sx={{fontSize: 15, color: "#131313"}} variant="body2">
            {strings.interview.screenshare_stop_description}
          </Typography>
        </Row>
        <SizedBox height="20px" />
        <Row classNames="w-full justify-end">
          <Button
            //   ref={navigateAwayButtonRef}
            onClick={() => {
              setUserStoppedScreenShare(false);
              startRecording();
            }}
            style={{padding: "2%"}}
          >
            <Typography variant="button" fontWeight={"bold"}>
              Reshare
            </Typography>
          </Button>
        </Row>
        <SizedBox height="10px" />
      </div>
    </Modal>
  );
};
