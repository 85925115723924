import React from "react";
import {useNavigate} from "react-router-dom";
import styles from "./AssessmentDetail.module.scss";

import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import ViewButton from "components/Containers/Button/Button/ViewButton";
import SizedBox from "components/SizedBox/SizedBox";
import SkillsButton from "components/Containers/Button/Button/SkillsButton";

import strings from "globals/constants/strings.json";
import {CSS} from "globals/types/globalTypes";
import {deleteAssessmentTest, formatDDMMMYYYY} from "globals/helpers";
import {AssesmentTemplateLinks, RouteLinks} from "globals/constants/routeLinks";

import rightArrow from "Images/filledRightArrow.svg";
import Banner from "Images/assessment_details_banner.svg";

const AssessmentDetails: React.FC = () => {
  const navigate = useNavigate();

  const buttonStyles: CSS = {
    height: "40px",
    padding: "0 35px",
  };

  const {assessment_details, setAssessments_template_timestamp} =
    useAppCommonDataProvider();
  const {name, created_at, updated_at, skills, id} = assessment_details!;

  const deleteTest = async () => {
    await deleteAssessmentTest(id).then((res) => {
      if (res && res?.status === 200) {
        setAssessments_template_timestamp?.(null);
        navigate(`/${RouteLinks.Assessment_Test}`);
      }
    });
  };

  return (
    <div className={`${styles.show__assessment__detail__wrap}`}>
      <div className={`${styles.show__assessment__heading}`}>
        <h1 className="flex">
          {" "}
          <img
            src={rightArrow}
            alt=""
            className="pr-2 cursor-pointer"
            onClick={() => history.back()}
          />
          {name}
        </h1>
      </div>
      <div className="flex">
        <div className={`${styles.show__detail__left}`}>
          <div className={`${styles.created__date}`}>
            <h3>Created on</h3>
            <p>{formatDDMMMYYYY(created_at)}</p>
          </div>
          <div className={`${styles.modify__date}`}>
            <h3>Last Modified</h3>
            <p>{formatDDMMMYYYY(updated_at)}</p>
          </div>
          <div className={`${styles.created__by} `}>
            <h3>Created by</h3>
            <p>Ankit soni</p>
          </div>
        </div>

        <div className={`${styles.show__detail__right}`}>
          <div className="w-90">
            <SizedBox height={"4rem"} />
            <div className="flex items-center">
              <img src={Banner} alt="" />
              <div className="pl-10">
                <ViewButton
                  onClick={() =>
                    navigate(
                      `/${AssesmentTemplateLinks.View_Question_Set}?id=${id}`
                    )
                  }
                  name="View Question Set"
                  invert
                  style={{height: "40px"}}
                />
              </div>
            </div>
            <SizedBox height={"4rem"} />
            <div>
              <p className="font-semibold">{strings.skills}</p>
              <div className="flex items-center flex-wrap">
                {skills.map((skill) => (
                  <div key={skill.id} className="mr-3 mt-3">
                    <SkillsButton skill={skill} />
                  </div>
                ))}
              </div>
            </div>
            <SizedBox height={"3rem"} />
            <div className="w-full flex items-center justify-end">
              <ViewButton
                name={strings.delete}
                style={buttonStyles}
                onClick={deleteTest}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentDetails;
