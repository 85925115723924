import React, {CSSProperties, useCallback, useEffect, useState} from "react";
import moment, {Moment} from "moment";
import {Link, useNavigate} from "react-router-dom";
import {debounce} from "lodash";
import {Spinner} from "react-activity";

import {Add, Clear} from "components/Icons";
import DatePicker from "components/Pickers/DatePicker/DatePicker";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import Loader from "components/Loader";
import ViewButton from "components/Containers/Button/Button/ViewButton";
import {AssessmentTemplateCard} from "components/Containers/Cards/AssessmentCard/AssessmentTemplateCard";
import SizedBox from "components/SizedBox/SizedBox";

import strings from "globals/constants/strings.json";
import {AppColors} from "globals/appcolors";
import {AssesmentLinks} from "globals/constants/routeLinks";
import {
  AssessmentResponse,
  DeleteAssessmentResponse,
  ExpiredAssessMentResue,
} from "globals/types/APIResponses";
import {
  AssessmentData,
  AssessmentFilterSource,
  CSS,
  DatePickerBox,
} from "globals/types/globalTypes";
import {postGoogleAnalyticsEvent} from "globals/helpers";
import {APIEndPoints} from "globals/constants/endPoints";
import Modal from "react-responsive-modal";
import axiosGetModule from "network/Get/AxiosGetModule";
import axiosDeleteModule from "network/Delete/axiosDeleteModule";
import {Images} from "Images";
import {axiosPatchModule} from "network/Patch/AxiosPatchModule";
import {toast} from "react-toastify";

interface Props {}

let currentPage: number = 0;

const ExpiredAssessment: React.FC<Props> = () => {
  const navigate = useNavigate();
  const eventTracker = postGoogleAnalyticsEvent("Assessment");

  const datePickerInputStyle: CSS = {
    height: 50,
    background: "transparent",
    width: "100%",
  };

  const datePickerContainerStyle: DatePickerBox = {
    background: AppColors.WHITE,
    paddingX: "10px",
    height: 50,
    borderRadius: "6px",
    border: `1px solid ${AppColors.WHITE_300}`,
    boxShadow: `0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);`,
  };

  const [assessments, setAssessments] = useState<AssessmentData[] | undefined>(
    []
  );
  const [rescheduledDate, setRescheduledDate] = React.useState<Moment | null>(
    moment()
  );
  const [showLoadMore, setshowLoadMore] = useState(false);
  const [loadingMoreRecords, setLoadingMoreRecords] = useState<boolean>(false);
  const [delFlag, setdelFlag] = useState<boolean>(false);
  const [reuseModal, setReuseModal] = useState<boolean>(false);
  const [assessmentId, setAssessmentId] = useState<number>();
  const [modalLoading, setModalLoading] = useState<boolean>(false);

  const {
    loading,
    setLoading,
    assessments: cachedAssessments,
    setAssessments: cacheAssessments,
    scrollOffset,
    setScrollOffset,
  } = useAppCommonDataProvider();

  useEffect(() => {
    setLoading?.(true);
    currentPage = 1;
    fetchAssessments();
  }, []);

  useEffect(() => {
    currentPage = 1;
    if (scrollOffset) {
      window.scrollTo({
        top: scrollOffset,
        behavior: "smooth",
      });
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollOffset?.(position);
  };

  const fetchRemotely = async (filter: string) => {
    eventTracker(
      "Expired Assessments  Viewed",
      "Expired Assessment Page Viewed"
    );
    let params: {page?: number} = {};

    if (currentPage) {
      params.page = currentPage;
      setLoadingMoreRecords(true);
    }

    await axiosGetModule(
      APIEndPoints.GET_EXPIRED_ASSESSMENTS_NODE +
        `?page=${currentPage - 1}&key=${filter}`
    )
      .then((res: AssessmentResponse) => {
        if (res?.data?.length == 14) {
          console.log("dgklsbjfbjngkls");
          setshowLoadMore(true);
        }
        setAssessments(res?.data);
        setLoading?.(false);
        setTimeout(() => {
          setLoading?.(false);
        }, 1000);
      })
      .catch((err) => {
        setLoading?.(false);
      })
      .finally(() => setLoadingMoreRecords(false));
  };

  const fetchAssessments = async (direction?: string) => {
    if (direction) fetchRemotely("");
    else {
      if (cachedAssessments) {
        setAssessments(cachedAssessments?.data);
        setLoading?.(false);
        if ((currentPage = cachedAssessments?.meta?.last_page))
          setshowLoadMore(false);
        else setshowLoadMore(true);
      } else {
        fetchRemotely("");
      }
    }
  };

  const reschedulingDate = (newdate: Moment | null) => {
    setRescheduledDate(newdate);
  };

  const filterAssessments = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    currentPage = 1;
    fetchRemotely(value);
  };

  const debounceSearch = useCallback(debounce(filterAssessments, 500), []);

  const deleteAssessment = async (id: number) => {
    try {
      axiosDeleteModule(APIEndPoints.ASSESMENT_NODE_OF_ID(id)).then(
        (res: DeleteAssessmentResponse) => {
          if (Object.keys(res?.data).length) {
            eventTracker("Assessment Deleted", "An assessment was deleted");
            setdelFlag(true);
            // fetchRemotely("");
          }
        }
      );
    } catch (e) {}
  };
  if (delFlag === true) {
    fetchRemotely("");
    setdelFlag(false);
  }

  const clearFilters = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    fetchRemotely("");
  };

  const rescheduleAssessment = async () => {
    setModalLoading(true);
    let date = rescheduledDate?.format("YYYY-MM-DD");
    await axiosPatchModule(
      APIEndPoints.RESCHEDULE_ASSESSMENT(assessmentId?.toString()!),
      {scheduled_date: date}
    )
      .then((res: ExpiredAssessMentResue) => {
        if (res.code == 200) {
          toast.success("Assessment Re-schedule", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setModalLoading(false);
          setReuseModal(false);
        }
        fetchRemotely("");
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  if (loading) return <Loader />;

  if (!assessments?.length)
    return (
      <div className="flex justify-center flex-col items-center pt-8">
        <img
          src={Images.NoAssessments}
          alt=""
          className="object-contain w-1/3"
        />
        <SizedBox height="2rem" />
        <p className="text-2xl">{strings.create_assessment.no_assessments}</p>
        <SizedBox height="1rem" />
        <Link
          to={`/${AssesmentLinks.Create_Assessment}`}
          className="bg-primary text-white-900 px-8 py-2 rounded-lg flex items-center justify-center"
        >
          <Add size={20} />
          <p className="ml-1 font-medium">
            {strings.create_assessment.no_assessments_button_text}
          </p>
        </Link>
      </div>
    );

  return (
    <div className="mt-4">
      <div className="flex items-center justify-between">
        <p className="text-black font-bold text-2xl">Expired Assessments</p>
      </div>
      <div className="my-3">
        <div className="flex items-center w-80">
          {assessments?.length ? (
            <div className="w-1/2 mr-8 bg-blue-200">
              <input
                type="text"
                placeholder={
                  strings.assessment_filters.candidate_and_assessment
                }
                onChange={debounceSearch}
                className="w-full h-10 px-2 text-sm focus:outline-none shadow-md rounded border border-white-300"
                style={{height: 50}}
              />
            </div>
          ) : (
            ""
          )}

          {/* filter by date */}
          {/* <div>
            <DatePicker
              onChange={filterAssessmentsByDate}
              value={filterDate}
              format={"DD-MM-YYYY"}
              customInputStyle={datePickerInputStyle}
              customContainerStyle={datePickerContainerStyle}
              isCustomInput
            />
          </div> */}
        </div>
      </div>
      <div className="flex">
        <div className="flex items-center flex-1 flex-wrap">
          {assessments?.length ? (
            assessments?.map((assessment) => {
              return (
                <AssessmentTemplateCard
                  assessmentDetails={assessment}
                  onClick={() => {}}
                  key={assessment.id}
                  onDeleteItem={deleteAssessment}
                  source="assessment"
                  showShowDetailBtn={false}
                  reuseAssessment={() => {
                    setReuseModal(true);
                    setAssessmentId(assessment.id);
                  }}
                />
              );
            })
          ) : (
            <div className="flex justify-center flex-col items-center w-full mt-8">
              <img
                src={Images.NoAssessments}
                alt=""
                className="object-contain w-1/3"
              />
              <SizedBox height="2rem" />
              <p className="text-xl">
                {strings.assessment_test.no_templates_after_filtering}
              </p>
              <SizedBox height="1rem" />
              <button
                className="bg-primary text-white-900 px-10 py-2 rounded-lg flex items-center justify-center"
                onClick={clearFilters}
              >
                <Clear color={AppColors.WHITE} size={20} />
                <p className="ml-1 font-medium">
                  {strings.assessment_test.reset_filters}
                </p>
              </button>
            </div>
          )}
        </div>
      </div>
      {showLoadMore && (
        <div
          className="flex flex-col items-center"
          style={{opacity: !assessments?.length ? 0 : 1}}
        >
          <SizedBox height="10px" />
          <ViewButton
            invert
            name={loadingMoreRecords ? <Spinner size={15} /> : strings.more}
            onClick={() => {
              currentPage++;
            }}
            isDisabled={loadingMoreRecords}
            style={{
              paddingInline: "50px",
              pointerEvents: !assessments?.length ? "none" : "all",
            }}
          />
        </div>
      )}
      <SizedBox height="20px" />
      <Modal
        open={reuseModal}
        onClose={() => setReuseModal(false)}
        showCloseIcon={false}
        center
        styles={{modal: {padding: "2rem", borderRadius: "1rem"}}}
      >
        <div>
          <p className="text-black font-bold text-2xl">Reuse Assessment</p>
          <p className="text-black text-sm mb-4">
            You can only schedule it for same candidate!
          </p>
          <DatePicker
            onChange={reschedulingDate}
            value={rescheduledDate}
            format={"DD-MM-YYYY"}
            customInputStyle={datePickerInputStyle}
            customContainerStyle={datePickerContainerStyle}
            isCustomInput
            minDate={moment()}
          />
          <ViewButton
            name={modalLoading ? <Spinner size={15} /> : "Re-schedule"}
            onClick={rescheduleAssessment}
            isDisabled={modalLoading}
            style={{marginTop: "10%"}}
          />
          {/* <button onClick={rescheduleAssessment} className="my-4 py-2 px-4 border bg-blue-900 rounded-lg text-white-900 item-right">
                            Re-schedule
                        </button> */}
        </div>
      </Modal>
    </div>
  );
};
export default ExpiredAssessment;
