import {useQuery, useQueryClient, UseQueryResult} from "@tanstack/react-query";
import axios from "axios";
import cookies from "react-cookies";

import {COOKIESKEYS} from "globals/constants/cookiesKeys";
import {APIEndPoints} from "globals/constants/endPoints";
import {Session} from "globals/types/APIResponses";
import {QuestionOption} from "globals/types/globalTypes";

interface Data {
  remainingTime: number;
  answers: QuestionOption[];
}

export function useFetchUpdatedSession(
  assessmentId: string,
  duration: string
): UseQueryResult<Data, unknown> {
  const headers = {
    Authorization: `Bearer ${cookies.load(COOKIESKEYS.CANDIDATE_TOKEN)}`,
    Accept: "application/json",
  };
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ["updatedSession"],
    queryFn: async () => {
      return axios
        .get<Session>(APIEndPoints.GET_UPDATED_SESSION(assessmentId), {
          headers,
        })
        .then((res) => {
          if (res.data.success === true && res.data.code === 200) {
            let remainingTime;
            if (res.data.data[0].time_remaining !== null) {
              remainingTime = res.data.data[0].time_remaining * 60;
            } else remainingTime = parseInt(duration) * 60;

            const data: Data = {
              remainingTime,
              answers: res.data.data[0].responses,
            };
            queryClient.invalidateQueries(["updatedSession"]);
            return data;
          }
        });
    },
    onError: (e) => e,
    enabled: false,
  });
}
