import {
  Document,
  Font,
  Image,
  Link,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import Loader from "components/Loader";
import {newInstance} from "globals/axiosConstants";
import {useFetchAssessmentDetails} from "hooks/interview/useFetchAssessmentDetails";
import {Images} from "Images";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";

const socialLinkData = [
  {
    name: "web",
    icon: Images.ReportCardWeb,
    class: {
      background: "#F6F3FF",
      color: "#7C3AED",
      border: "#7C3AED",
    },
  },
  {
    name: "linkedin",
    icon: Images.ReportCardLinkedin,
    class: {
      borderColor: "#0A66C2",
      background: "#F1F8FF",
      color: "#0A66C2",
    },
  },
  {
    name: "figma",
    icon: Images.ReportCardFigma,
    class: {
      borderColor: "#F24E1E",
      background: "#FFF4F1",
      color: "#F24E1E",
    },
  },
  {
    name: "github",
    icon: Images.ReportCardGithub,
    class: {
      borderColor: "#222222",
      background: "#F2F2F2",
      color: "#222222",
    },
  },
];

const contactData = [
  {
    name: "location",
    icon: Images.ReportCardMap,
    class: {
      width: "5.76px",
      Height: "7px",
    },
  },
  {
    name: "phone",
    icon: Images.ReportCardPhone,
    class: {width: "7px", height: "7px"},
  },
  {
    name: "email",
    icon: Images.ReportCardEmail,
    class: {width: "7px", height: "4.67px"},
  },
];

const styles = StyleSheet.create({
  mainContainer: {padding: 24},
  section: {marginBottom: 16},
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 16,
  },
  usernameWrapper: {},
  userName: {
    color: "#334155",
    fontSize: 28,
    fontWeight: 800,
  },
  profile: {fontSize: 10, marginTop: 4, fontWeight: 500, color: "#64748B"},
  button: {
    width: "58px",
    backgroundColor: "#F6F3FF",
    border: "0.5px solid #000000",
    padding: "4px 8px",
    borderRadius: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  buttonText: {fontSize: "8px", color: "#000000", fontWeight: "semibold"},
  icon: {width: 10, height: 10, marginLeft: 4},
  contactWrapper: {flexDirection: "row", gap: 8, marginBottom: "8px"},
  interviewHead: {
    display: "flex",
    fontSize: "9px",
    fontWeight: 700,
    color: "#334155",
  },
  contactIcon: {width: 7, height: 7},
  socialLink: {
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
    gap: 2,
    borderRadius: "10px",
    padding: "4px 8px",
  },
  socialIcon: {width: 12, height: 12, marginRight: 4},
  interviewWrapper: {marginTop: 2},
  experienceWrapper: {display: "flex", gap: "45px", flexDirection: "row"},
  companyName: {fontSize: 9, fontWeight: "bold", color: "#334155"},
  role: {fontSize: "9px", fontWeight: "normal", color: "#334155"},
  calendarWrapper: {
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
  },
  calendarIcon: {width: 10, height: 10, marginRight: 4},
  calenderText: {fontSize: "8px", fontWeight: "medium", color: "#334155"},
  divider: {
    borderBottomWidth: 0.5,
    marginTop: 18,
    borderColor: "#E2E8F0",
    marginBottom: 10,
  },
  divider2: {
    borderBottomWidth: 0.5,
    marginTop: 10,
    borderColor: "#E2E8F0",
    marginBottom: 20,
  },
});

const desiredOrder = [
  "strongest_area",
  "strongest_aspect",
  "needs_improvement",
  "suggestions",
];
type AnyObject = {[key: string]: any};

interface MergedObject {
  head: string;
  pointers: any[];
}

function mergeObjectsToArray(obj1: AnyObject, obj2: AnyObject): MergedObject[] {
  const result: MergedObject[] = [];

  const allKeys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);

  allKeys.forEach((key) => {
    const values: any[] = [];

    if (obj1[key] !== undefined) values.push(obj1[key]);
    if (obj2[key] !== undefined) values.push(obj2[key]);

    result.push({head: key, pointers: values});
  });

  return result;
}

Font.register({
  family: "Urbanist",
  fonts: [
    {src: "/font/Urbanist-Bold.ttf", fontWeight: 700},
    {src: "/font/Urbanist-ExtraBold.ttf", fontWeight: 800},
    {src: "/font/Urbanist-Medium.ttf", fontWeight: 500},
    {src: "/font/Urbanist-Regular.ttf", fontWeight: 400},
    {src: "/font/Urbanist-SemiBold.ttf", fontWeight: 600},
  ],
});

const extractUsername = (url: string) => {
  const parts = url.split("/");
  return parts[parts.length - 1] || parts[parts.length - 2] || "User"; // Fallback to 'User' if username extraction fails
};

const MyPDFDocument = () => {
  const [searchParams] = useSearchParams();
  const assesmentId = searchParams.get("assessment");
  const aiId = searchParams.get("aiId");

  const {refetch: getAssessmentDetails} = useFetchAssessmentDetails(
    assesmentId!
  );

  const [userData, setUserData] = useState<any>();
  const [scheduleDate, setScheduleDate] = useState<any>();
  const [resumeUrl, setResumeUrl] = useState<any>();
  const [timeTaken, setTimeTaken] = useState<any>();
  const [techAnalysis, setTechAnalysis] = useState<any>();
  const [behaviouralAnalysis, setBehaviouralAnalysis] = useState<any>();
  const [communicationAnalysis, setCommunicationAnalysis] = useState<any>();
  const [finalEvaluatedData, setFinalEvaluatedData] = useState<any>();
  const [codingAnalysis, setCodingAnalysis] = useState<any>();

  const parseResumeBody = new FormData();

  const [keyHighlightData, setKeyHighlights] = useState<MergedObject[]>();
  const [loading, setLoading] = useState(true);

  const fetchAllData = async (
    resumeParsePayload: any,
    evaluationPayload: any
  ) => {
    try {
      const apiCalls = [
        newInstance.post("resume-parse", resumeParsePayload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }),
        newInstance.post(
          "calculate-evaluation-for-technical-analysis",
          evaluationPayload,
          {
            headers: {"Content-Type": "multipart/form-data"},
          }
        ),
        newInstance.post(
          "calculate-evaluation-for-behavioral-analysis",
          evaluationPayload,
          {
            headers: {"Content-Type": "multipart/form-data"},
          }
        ),
        newInstance.post(
          "calculate-evaluation-for-communication-analysis",
          evaluationPayload,
          {
            headers: {"Content-Type": "multipart/form-data"},
          }
        ),
        newInstance.post(
          "calculate-evaluation-for-coding_analysis",
          evaluationPayload,
          {
            headers: {"Content-Type": "multipart/form-data"},
          }
        ),
      ];

      const [userRes, techRes, behavRes, commRes, codeRes] = await Promise.all(
        apiCalls.map((call) => {
          return call
            .then((res) => {
              return res;
            })
            .catch((error) => {
              return error;
            });
        })
      );
      if (techRes && userRes && behavRes && commRes && codeRes) {
        setLoading(false);

        if (techRes?.status === 200) {
          setTechAnalysis(techRes?.data);
        }
        if (userRes?.status === 200) {
          setUserData(userRes?.data?.resume_content);
        }
        if (behavRes?.status === 200) {
          setBehaviouralAnalysis(behavRes?.data);
        }
        if (commRes?.status === 200) {
          setCommunicationAnalysis(commRes?.data);
        }
        if (codeRes?.status === 200) {
          setCodingAnalysis(codeRes?.data);
        }

        if (
          techRes?.data?.evaluation_summary &&
          behavRes?.data?.evaluation_summary
        ) {
          const data = mergeObjectsToArray(
            techRes?.data?.evaluation_summary,
            behavRes?.data?.evaluation_summary
          );
          const sortedArray = data.sort((a, b) => {
            const indexA = desiredOrder.indexOf(a.head);
            const indexB = desiredOrder.indexOf(b.head);
            return (
              (indexA !== -1 ? indexA : Infinity) -
              (indexB !== -1 ? indexB : Infinity)
            );
          });

          setKeyHighlights(sortedArray);
        }
        if (
          techRes?.status === 200 &&
          codeRes?.status === 200 &&
          commRes?.status === 200 &&
          behavRes?.status === 200
        ) {
          try {
            const finalRes = await newInstance.post(
              "final-evaluation",
              evaluationPayload,
              {
                headers: {"Content-Type": "multipart/form-data"},
              }
            );
            if (finalRes?.status === 200) {
              setFinalEvaluatedData(finalRes?.data);
            }
          } catch (error) {}
        }
      }
    } catch (error) {
      console.error("Error in API calls:", error);
      setLoading(false);
    }
  };

  const fetchAssessmentDetails = () => {
    getAssessmentDetails().then(async (res) => {
      if (res.isError === false) {
        if (res.data !== undefined && res?.data?.data?.assessment !== null) {
          const {resume_url, main_question_id, scheduled_date, time_taken} =
            res?.data?.data?.assessment;
          const {
            user: {first_name, last_name, email},
          } = res?.data?.data?.assessment?.candidate;
          setScheduleDate(scheduled_date);
          setResumeUrl(resume_url);
          setTimeTaken(time_taken);
          const evaluationbody = new FormData(),
            parseResumesBody = new FormData();
          evaluationbody.append("main_question_id", main_question_id!);
          parseResumesBody.append("s3_url", resume_url);
          parseResumeBody.append("main_question_id", main_question_id!);

          fetchAllData(parseResumesBody, evaluationbody);
        }
      }
    });
  };

  useEffect(() => {
    fetchAssessmentDetails();
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <PDFViewer showToolbar={true} style={{height: "100vh", width: "100vw"}}>
      <Document>
        <Page
          size={{width: 595.28, height: 2000}}
          style={{fontFamily: "Urbanist", width: 595.28, height: 1683.78}}
        >
          <View style={{padding: "0px 0px 20px 0px"}}>
            <View
              style={{
                position: "relative",
                padding: "28px 50px 0px 50px",
                width: "100%",
              }}
            >
              <Image
                src={Images.ReportCardHeaderBG}
                style={{height: "100%", width: "595px", position: "absolute"}}
              />
              <View style={styles.row}>
                <View style={styles.usernameWrapper}>
                  <Text style={styles.userName}>{userData?.name}</Text>
                  <Text style={styles.profile}>{userData?.title}</Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <Link src={resumeUrl}>
                    <View style={{...styles.button}}>
                      <Text style={styles.buttonText}>View CV</Text>
                      <Image
                        src={Images.ReportCardCTAArrow}
                        style={{width: "10px", height: "10px"}}
                      />
                    </View>
                  </Link>
                </View>
              </View>

              <View
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <View>
                  <View style={styles.contactWrapper}>
                    {userData?.contact_information &&
                      Object.entries(userData?.contact_information)?.map(
                        ([key, value], i) => {
                          const contact = contactData.find(
                            (c) => c.name === key
                          );
                          return (
                            <View
                              key={i}
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                                gap: "2px",
                              }}
                            >
                              <Image
                                src={contact?.icon}
                                style={{...contact?.class}}
                              />
                              <Text style={{fontSize: "7px", color: "#334155"}}>
                                {value as string}
                              </Text>
                            </View>
                          );
                        }
                      )}
                  </View>

                  <View style={{display: "flex", flexDirection: "row", gap: 7}}>
                    {Object.keys(userData?.links || {}).map((key, i) => {
                      const social = socialLinkData.find((s) => s.name === key);
                      return (
                        social && (
                          <Link src={userData?.links?.[key]}>
                            <View
                              key={i}
                              style={{
                                ...styles.socialLink,
                                backgroundColor: social?.class?.background,
                                borderWidth: "0.8px",
                                borderColor: social?.class?.color,
                              }}
                            >
                              <Image
                                src={social?.icon}
                                style={{width: "8px", height: "8px"}}
                              />
                              <Text
                                style={{
                                  fontWeight: "semibold",
                                  fontSize: "7px",
                                  color: social?.class?.color,
                                }}
                              >
                                {extractUsername(userData?.links?.[key])}
                              </Text>
                            </View>
                          </Link>
                        )
                      );
                    })}
                  </View>
                </View>
                <View
                  style={{
                    ...styles.interviewWrapper,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <View style={{display: "flex", flexDirection: "row"}}>
                    <Text style={{...styles.interviewHead, marginBottom: 8}}>
                      Interview Date
                    </Text>
                    <Text
                      style={{
                        fontSize: "9px",
                        fontWeight: "normal",
                        color: "#334155",
                      }}
                    >
                      {" "}
                      &mdash;{" "}
                      {moment(new Date(scheduleDate)).format("DD MMM YYYY")}
                    </Text>
                  </View>
                  {timeTaken && (
                    <View style={{display: "flex", flexDirection: "row"}}>
                      <Text style={{...styles.interviewHead, marginBottom: 8}}>
                        Total Time Spent
                      </Text>
                      <Text
                        style={{
                          fontSize: "9px",
                          fontWeight: "normal",
                          color: "#334155",
                        }}
                      >
                        {" "}
                        &mdash; {timeTaken}
                      </Text>
                    </View>
                  )}
                </View>
              </View>
              <View style={styles.divider} />
            </View>
            {/* <View style={styles.divider} /> */}

            <View style={{padding: "0px 50px 0px 50px"}}>
              <Text
                style={{
                  fontSize: "8px",
                  fontWeight: 500,
                  color: "#334155",
                  marginBottom: "6px",
                }}
              >
                Interview Rounds Completed
              </Text>
              <View style={{display: "flex", flexDirection: "row", gap: 8}}>
                {[
                  "Technical Round",
                  "Coding Round",
                  "Behavioural Evaluation",
                ].map((round, i) => (
                  <Text
                    style={{
                      padding: "4px 8px",
                      border: "0.8px solid #02BC7D",
                      backgroundColor: "#FFFFFF",
                      color: "#02BC7D",
                      fontSize: "8px",
                      fontWeight: "semibold",
                      borderRadius: "10px",
                    }}
                    key={i}
                  >
                    {round}
                  </Text>
                ))}
              </View>
              <View style={styles.divider2} />
            </View>

            <View
              style={{...styles.experienceWrapper, paddingHorizontal: "50px"}}
            >
              <View>
                <Text
                  style={{
                    fontSize: "9px",
                    fontWeight: "bold",
                    color: "#334155",
                    marginBottom: 2,
                  }}
                >
                  Experience
                </Text>
                <Text
                  style={{
                    fontSize: "9px",
                    fontWeight: "normal",
                    color: "#334155",
                  }}
                >
                  {" "}
                  ({userData?.total_experience_years}+ Yrs)
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                {userData?.experience_summary?.map((exp: any, i: number) => (
                  <View
                    style={{
                      justifyContent: "space-between",
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                    key={i}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "85%",
                      }}
                    >
                      <Text style={styles.companyName}>
                        {exp?.company?.split(" ")?.join("")}{" "}
                      </Text>

                      {exp?.title ? (
                        <Text style={styles.role}>&mdash; {exp?.title}</Text>
                      ) : (
                        <></>
                      )}
                    </View>
                    {exp?.duration && (
                      <View style={{...styles.calendarWrapper}}>
                        <Image
                          src={Images.ReportCardCalendar}
                          style={{width: "10px", height: "10px"}}
                        />
                        <Text style={styles.calenderText}>{exp?.duration}</Text>
                      </View>
                    )}
                  </View>
                ))}
              </View>
            </View>
          </View>
          <View>
            {techAnalysis && (
              <Evaluation
                background={"#637520"}
                head={"Technical Analysis"}
                overallScore={techAnalysis?.overall_score}
                cards_scores={
                  techAnalysis?.type_scores
                    ? Object.entries(techAnalysis?.type_scores)?.map(
                        ([key, value]) => {
                          return {
                            head: key,
                            value: value,
                          };
                        }
                      )
                    : []
                }
                evaluation_summary={
                  techAnalysis?.evaluation_summary
                    ? Object.entries(techAnalysis?.evaluation_summary)
                        .sort(
                          (a, b) =>
                            desiredOrder.indexOf(a[0]) -
                            desiredOrder.indexOf(b[0])
                        )
                        .map(([key, value]) => value)
                    : [""]
                }
              />
            )}
            {codingAnalysis && (
              <Evaluation
                background={"#205475"}
                head={"Coding"}
                overallScore={codingAnalysis?.overall_score}
                cards_scores={
                  codingAnalysis?.type_scores
                    ? Object.entries(codingAnalysis?.type_scores)?.map(
                        ([key, value]) => {
                          return {
                            head: key,
                            value: value,
                          };
                        }
                      )
                    : []
                }
                evaluation_summary={
                  codingAnalysis?.evaluation_summary
                    ? Object.entries(codingAnalysis?.evaluation_summary)
                        ?.filter(([key]) => desiredOrder.includes(key))
                        ?.sort(
                          (a, b) =>
                            desiredOrder.indexOf(a[0]) -
                            desiredOrder.indexOf(b[0])
                        )
                        ?.map(([key, value]) => value)
                    : [""]
                }
              />
            )}
            {/*  */}
            {behaviouralAnalysis && (
              <Evaluation
                background={"#207565"}
                head={"Behavioural Evaluation"}
                overallScore={behaviouralAnalysis?.overall_score}
                cards_scores={
                  behaviouralAnalysis?.type_scores
                    ? Object.entries(behaviouralAnalysis?.type_scores)?.map(
                        ([key, value]) => {
                          return {
                            head: key,
                            value: value,
                          };
                        }
                      )
                    : []
                }
                evaluation_summary={
                  behaviouralAnalysis?.evaluation_summary
                    ? Object.entries(behaviouralAnalysis?.evaluation_summary)
                        ?.filter(([key]) => desiredOrder.includes(key))
                        ?.sort(
                          (a, b) =>
                            desiredOrder.indexOf(a[0]) -
                            desiredOrder.indexOf(b[0])
                        )
                        .map(([key, value]) => value)
                    : [""]
                }
              />
            )}
            {communicationAnalysis && (
              <Evaluation
                background={"#672075"}
                head={"Communication"}
                overallScore={
                  communicationAnalysis?.overall_communication_score
                }
                cards_scores={
                  communicationAnalysis?.grammar_score !== null &&
                  communicationAnalysis?.professional_language_score !== null
                    ? Object.entries({
                        grammar: communicationAnalysis?.grammar_score,
                        "professional language":
                          communicationAnalysis?.professional_language_score,
                      })?.map(([key, value]) => {
                        return {
                          head: key,
                          value: value,
                        };
                      })
                    : []
                }
                evaluation_summary={
                  communicationAnalysis?.communication_evaluation
                    ? Object.entries(
                        communicationAnalysis?.communication_evaluation
                      )
                        ?.filter(([key]) => desiredOrder.includes(key))
                        ?.sort(
                          (a, b) =>
                            desiredOrder.indexOf(a[0]) -
                            desiredOrder.indexOf(b[0])
                        )
                        ?.map(([key, value]) => value)
                    : [""]
                }
              />
            )}
            {/* @ts-ignore */}
            {keyHighlightData && <KeyHighlights data={keyHighlightData} />}
            {finalEvaluatedData && (
              <FinalEvaluation pointers={finalEvaluatedData?.reasons} />
            )}
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

const evaluationStyle = StyleSheet.create({
  main_container: {},
  flex: {
    paddingTop: 20,
    paddingLeft: 50,
    paddingBottom: 20,
    paddingRight: 31,
    display: "flex",
    flexDirection: "row",
    gap: 24,
  },
  wordBreak: {
    minWidth: 60,
    wordBreak: "break-word",
  },
  boldText: {
    fontWeight: "bold",
    color: "#FFFFFF",
    fontSize: 9,
  },
  scoreHead: {
    color: "#FFFFFF",
    fontSize: 9,
    fontWeight: "bold",
    marginBottom: "14px",
  },
  scoreValue: {
    marginBottom: 14,
    fontSize: 9,
  },
  wrap: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px 12px",
  },
  listItem: {
    display: "flex",
    gap: 6,
    alignItems: "baseline",
  },
  dot: {
    width: 5,
    height: 5,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginTop: "2px",
    backgroundColor: "#FFFFFF",
    borderRadius: 1,
  },
  listText: {
    fontSize: "9px",
    color: "#FFFFFF",
  },
  cardWrapper: {
    width: 140,
  },
  evaluationList: {
    marginTop: 20,
    gap: 9,
    display: "flex",
    flexDirection: "column",
  },
});

interface EvaluationProps {
  head: string;
  background: string;
  overallScore: number;
  cards_scores: Array<any>;
  evaluation_summary: Array<any>;
}

const Evaluation: React.FC<EvaluationProps> = ({
  head,
  background,
  overallScore,
  cards_scores,
  evaluation_summary,
}) => {
  return (
    <View style={evaluationStyle.main_container}>
      <View style={{...evaluationStyle.flex, backgroundColor: background}}>
        <View style={evaluationStyle.wordBreak}>
          <Text style={evaluationStyle.boldText}>{head?.split(" ")?.[0]}</Text>
          <Text style={evaluationStyle.boldText}>{head?.split(" ")?.[1]}</Text>
        </View>

        <View style={{display: "flex", flexDirection: "column"}}>
          <Text style={evaluationStyle.scoreHead}>
            Score &mdash;{" "}
            <Text style={evaluationStyle.scoreValue}>{overallScore}%</Text>
          </Text>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "12px 10px",
            }}
          >
            {cards_scores?.length > 0 &&
              cards_scores?.map((ele, index) => (
                <View style={{width: "120px"}}>
                  <EvaluationCard
                    key={index.toString()}
                    head={ele.head}
                    value={ele.value}
                  />
                </View>
              ))}
          </View>

          <View style={evaluationStyle.evaluationList}>
            {evaluation_summary?.length > 0 &&
              evaluation_summary
                ?.filter((ele) => {
                  return ele !== "";
                })
                ?.map((ele, index) => (
                  <View
                    key={index.toString()}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      // alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <View style={evaluationStyle.dot} />
                    <View style={{width: "400px"}}>
                      <Text
                        style={{
                          ...evaluationStyle.listText,
                          lineHeight: "1.7px",
                        }}
                      >
                        {ele}
                      </Text>
                    </View>
                  </View>
                ))}
          </View>
        </View>
      </View>
    </View>
  );
};

const evaluationCardStyles = StyleSheet.create({
  cardContainer: {
    paddingRight: 20,
    // pr-5 (5 * 4px)
    width: "120px",
  },
  cardHead: {
    backgroundColor: "#FFFFFF14",
    alignSelf: "flex-start", // w-max equivalent in React PDF
    borderRadius: 2,
    paddingVertical: 3, // py-[3px]
    paddingHorizontal: 6, // px-1.5 (1.5 * 4px)
    color: "#FFFFFF",
    fontSize: 8,
    // lineHeight: '9.6px',
    fontWeight: "semibold",
    marginBottom: 4, // mb-1 (1 * 4px)
    textTransform: "capitalize",
    // whiteSpace: 'nowrap',
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
    // maxWidth: '200px',
  },
  progressBarContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 5, // gap-[5px]
    alignItems: "center",
  },
  progressTrack: {
    flex: 1, // w-full
    position: "relative",
    backgroundColor: "#FFFFFF14",
    height: 3, // h-[3px]
    borderRadius: 10, // rounded-[10px]
  },
  progressFill: {
    position: "absolute",
    height: "100%", // h-full
    borderRadius: 10,
    backgroundColor: "#FFF",
  },
  valueText: {
    color: "#FFFFFF",
    fontSize: 10,
    // lineHeight: '9.6px',
    fontWeight: "semibold",
    // whiteSpace: 'nowrap',
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
    // maxWidth: '200px',
  },
});

const EvaluationCard = ({head, value}: any) => {
  return (
    <View style={evaluationCardStyles.cardContainer}>
      <Text style={evaluationCardStyles.cardHead}>
        {head?.split("_")?.join("")?.length <= 19
          ? head?.split("_")?.join("")
          : `${head?.split("_")?.join("")?.substring(0, 19)}...`}
      </Text>

      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <View style={evaluationCardStyles.progressTrack}>
          <View
            style={{...evaluationCardStyles.progressFill, width: `${value}%`}}
          />
        </View>
        <Text style={{...evaluationCardStyles.valueText}}>{value}%</Text>
      </View>
    </View>
  );
};

interface KeyHighlightsProps {
  data: {head: string; pointers: string[]}[];
}

const KeyHighlights: React.FC<KeyHighlightsProps> = ({data}) => {
  return (
    <View style={{padding: "20px 50px", gap: "20px", flexDirection: "row"}}>
      <View style={{width: "58px"}}>
        <Text
          style={{
            color: "#334155",
            fontWeight: "bold",
            fontSize: "9px",
            width: "58px",
          }}
        >
          Key Higlights
        </Text>
      </View>
      <View style={{display: "flex", flexDirection: "column", gap: "24px"}}>
        {data?.length > 0 &&
          data?.map((ele, index) => {
            return (
              <View key={index.toString()} style={{width: "400px"}}>
                <KeyHighlightCard head={ele?.head} pointers={ele?.pointers} />
              </View>
            );
          })}
      </View>
    </View>
  );
};

interface KeyHighlightCardProps {
  head: string;
  pointers: string[];
}

export const KeyHighlightCard: React.FC<KeyHighlightCardProps> = ({
  head,
  pointers,
}) => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        width: "100%",
      }}
    >
      <Text
        style={{
          color: "#334155",
          fontSize: "9px",
          textTransform: "capitalize",
          fontWeight: "bold",
        }}
      >
        {head?.split("_")?.join(" ")}
      </Text>
      <View
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        {pointers?.length > 0 &&
          pointers
            ?.filter((ele) => ele !== "")
            ?.map((ele, index) => {
              return (
                <View
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    gap: "6px",
                  }}
                  key={index.toString()}
                >
                  <View
                    style={{
                      display: "flex",
                      marginTop: "2px",
                      flexShrink: 0,
                      width: "5px",
                      height: "5px",
                      backgroundColor: "#CBD5E1",
                      borderRadius: "1.5px",
                    }}
                  ></View>
                  <Text
                    style={{
                      width: "100%",
                      color: "#334155",
                      fontSize: "9px",
                      fontWeight: "normal",
                      lineHeight: "1.7px",
                    }}
                  >
                    {ele}
                  </Text>
                </View>
              );
            })}
      </View>
    </View>
  );
};

interface FinalEvaluationProps {
  pointers: Array<string>;
}

const FinalEvaluation: React.FC<FinalEvaluationProps> = ({pointers}) => {
  return (
    <View
      style={{
        paddingTop: "21px",
        paddingBottom: "22px",
        paddingLeft: "64px",
        paddingRight: "31.44px",
        backgroundColor: "#000000",
      }}
    >
      <Text
        style={{
          marginBottom: "10px",
          color: "#FFFFFF",
          fontSize: "16px",
          fontWeight: "bold",
        }}
      >
        {" "}
        Final Recommendation
      </Text>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <View
          style={{
            width: "263px",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          {pointers?.length > 0 &&
            pointers?.map((ele, index) => {
              return (
                <View
                  style={{
                    display: "flex",
                    gap: "6px",
                    // alignItems: "center",
                    flexDirection: "row",
                  }}
                  key={index?.toString()}
                >
                  <View
                    style={{
                      display: "flex",
                      width: "5px",
                      height: "5px",
                      marginTop: "2px",
                      borderRadius: "1.5px",
                      flexShrink: 0,
                      backgroundColor: "#fff",
                    }}
                  />
                  <Text
                    style={{
                      width: "100%",
                      color: "#fff",
                      fontWeight: "normal",
                      fontSize: "9px",
                      lineHeight: "1.7px",
                    }}
                  >
                    {ele}
                  </Text>
                </View>
              );
            })}
        </View>

        <View>
          <Link
            style={{
              textDecoration: "none",
            }}
            src={"https://sourcebae.com/"}
          >
            <Image
              src={Images?.ReportCardLogo}
              style={{width: "89px", height: "28px"}}
            />
            <Text
              style={{
                color: "#fff",
                fontWeight: "normal",
                fontSize: "9px",
                lineHeight: "1.7px",
                marginTop: "8px",
              }}
            >
              connect@sourcebae.com
            </Text>
          </Link>
        </View>
      </View>
    </View>
  );
};

const Hero = () => <MyPDFDocument />;
export default Hero;
