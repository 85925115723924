import axios from "axios";
import CryptoJS from "crypto-js";
import {SignJWT} from "jose";
import "react-toastify/dist/ReactToastify.css";

const baseURL = process.env.REACT_APP_BASE_URL;

const interceptedInstance = axios.create({
  baseURL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
  },
});

const candidateInstance = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
  },
});

candidateInstance.interceptors.request.use((config) => {
  //@ts-ignore
  config.headers["Authorization"] = `Bearer ${localStorage.getItem(
    "candidateToken"
  )}`;
  return config;
});

const assembly = axios.create({
  baseURL: "https://api.assemblyai.com/v2",
  headers: {
    authorization: "01a903b2f5b849a49826c12e88602bfb",
  },
});

export {assembly, candidateInstance, interceptedInstance as default};

export const newInstance = axios.create({
  baseURL: process.env.REACT_APP_PYTHON_ENDPOINT,
  params: {},
  data: {},
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 60000,
});

newInstance.interceptors.request.use((config) => {
  let token = localStorage.getItem("token");
  if (token !== null) {
    token = `Bearer ${token}`;
    config.headers!.Authorization = `${token}`;
  }
  return config;
});

const createEncryptedHash = (text: string, secret: string): string => {
  const encrypted = CryptoJS.AES.encrypt(text, secret).toString();
  return encrypted;
};

const generateToken = async (): Promise<string> => {
  const mySecretKey = "n4HdFq0dAZF04eZnosKI";
  const plaintext = `${Date.now()}/Sourcebae.com$2017$987789!#/${Date.now()}`;
  const apiKey = createEncryptedHash(plaintext, mySecretKey ?? "");
  const encoded = {
    key: apiKey,
  };
  const token = await new SignJWT(encoded)
    .setProtectedHeader({alg: "HS256"})
    .setExpirationTime("2 minute")
    .sign(new TextEncoder().encode(mySecretKey));
  return token;
};

export const sourcebaeInstance = axios.create({
  baseURL: process.env.REACT_APP_SOURCEBAE_URL,
  params: {},
  data: {},
  headers: {
    Authorization: localStorage.getItem("token") ?? "",
  },
});
sourcebaeInstance.interceptors.request.use(async (config) => {
  config.headers!.apiKey = await generateToken();
  return config;
});

export const emotionInstance = axios.create({
  baseURL: process.env.REACT_APP_PYTHON_ENDPOINT,
  params: {},
  data: {},
  headers: {
    "Content-Type": "application/json",
  },
});
