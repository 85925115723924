import React, {useEffect, useRef, useState} from "react";
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/library.css";
import {useNavigate, useSearchParams} from "react-router-dom";
import Select, {Theme} from "react-select";
import makeAnimated from "react-select/animated";
import {toast} from "react-toastify";

import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import ViewButton from "components/Containers/Button/Button/ViewButton";
import CustomTextArea from "components/CustomTextArea/CustomTextArea";
import {
  Cancel,
  Checked,
  ChevronLeft,
  UncheckedRadioButton,
} from "components/Icons";
import CustomSelect from "components/Inputs/Select/CustomSelect";
import RedStar from "components/RedStar/RedStar";
import SizedBox from "components/SizedBox/SizedBox";

import {AppColors} from "globals/appcolors";
import {
  difficultyOptions,
  questionOptions,
} from "globals/constants/dropdownConstants";
import {APIEndPoints} from "globals/constants/endPoints";
import strings from "globals/constants/strings.json";
import {SelectStyles} from "globals/constants/styles";
import {
  GetAllQuestionsResponse,
  SkillsResponse,
} from "globals/types/APIResponses";
import {
  SkillsData,
  UploadCSV,
  UserDetailsCookie,
} from "globals/types/globalTypes";

import {
  handleInputAnswerProps,
  optionsType,
  skillsType,
} from "routes/question/CreateQuestion/types";
import TopTabs from "routes/question/TopTabs/TopTabs";

import {Images} from "Images";

import {
  decodeEncodedAPIResponse,
  postGoogleAnalyticsEvent,
} from "globals/helpers";
import axiosGetModule from "network/Get/AxiosGetModule";
import {axiosPatchModule} from "network/Patch/AxiosPatchModule";
import axiosPostModule from "network/Post/AxiosPostModule";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import CreateQuestionModal from "./CreateQuestionModal";

export interface GetQuestionsResponse {
  data: {
    created_at: string;
    created_by: any;
    deletedAt: null;
    id: number;
    is_always_included: number;
    is_paid_question: number;
    organization_id: number;
    question: string;
    question_duration: number;
    question_level: number;
    options?: [
      {
        option_id: number;
        is_correct_option: number;
        option: string;
      }
    ];
    question_options?: [
      {
        is_correct_option: number;
        option: string;
      }
    ];
    question_points: number;
    question_type: number;
    shuffle_options: number;
    skill: {
      skill: string;
    };

    skill_id: number;
    updated_at: string;
    updated_by: number;
    user: {
      email: string;
      first_name: string;
      last_name: string;
    };
  };
}

export default function CreateQuestion({isNotGlobal = false}) {
  const navigate = useNavigate();
  const {questionData, setAllQuestions} = useAppCommonDataProvider();
  const [searchParams] = useSearchParams();
  const csvFileRef = useRef<HTMLInputElement>(null);
  const eventTracker = postGoogleAnalyticsEvent("Questions");

  let userData: UserDetailsCookie = JSON.parse(
    localStorage.getItem("USER_DETAILS")!
  );

  const [questionTypes, setquestionTypes] = useState<{
    label: string;
    value: string;
  }>(questionOptions[1]);
  const [skills, setskills] = useState<Array<skillsType> | []>();
  const [creatingQuestion, setcreatingQuestion] = useState<boolean>(false);
  const [selectedSkill, setselectedSkill] = useState<skillsType>();
  const [question, setquestion] = useState<string>("");
  const [csvFile, setCsvFile] = useState<any>(null);
  const [difficultyLevel, setdifficultyLevel] = useState<{
    label: string;
    value: string;
  }>();
  const [options, setoptions] = useState<Array<optionsType>>([
    {
      id: Math.floor(Math.random() * 100000),
      option: "",
      is_correct_option: 0,
    },
    {
      id: Math.floor(Math.random() * 100000),
      option: "",
      is_correct_option: 0,
    },
    {
      id: Math.floor(Math.random() * 100000),
      option: "",
      is_correct_option: 0,
    },
    {
      id: Math.floor(Math.random() * 100000),
      option: "",
      is_correct_option: 1,
    },
  ]);
  const [uploadingCsv, setUploadingCsv] = useState<boolean>(false);
  const [questionDuration, setQuestionDuration] = useState<string>(
    isNotGlobal ? "60" : ""
  );
  const [isQuestionPublic, setIsQuestionPublic] = useState<boolean>();
  const [open, setOpen] = useState(false);
  const [prequestions, setprequestions] = useState<any>({});
  const [values, setValues] = useState([]);

  useEffect(() => {
    fetchSkills();
    if (doEdit()) fetchQuestionForEdit();
  }, [questionData]);

  const Question: React.FC<{question: string; showStar?: boolean}> = ({
    question,
    showStar = true,
  }) => (
    <div className="flex">
      <p className="font-semibold text-lightBlack-300">{question}</p>
      {showStar && <RedStar />}
    </div>
  );

  const doEdit = () => questionData && searchParams.get("edit");

  const handleInputAnswer: handleInputAnswerProps = (ans, index) => {
    let updatedOptions = [...options];
    updatedOptions[index].option = ans;
    setoptions(updatedOptions);
  };

  const removeOption = (index: number) => {
    let filtered = options;
    filtered?.pop();
    if (filtered.length === index) {
      let isAlreadySelected = filtered.some((item) => item.is_correct_option);
      !isAlreadySelected &&
        (filtered[filtered.length - 1].is_correct_option = 1);
    }
    setoptions([...filtered]);
  };

  const addOption = () => {
    let pushOneOption = [
      ...options,
      {
        option: "",
        is_correct_option: 0,
        id: Math.floor(Math.random() * 100000),
      },
    ];
    setoptions(pushOneOption);
  };

  const formatSkills = (list: SkillsData[]) => {
    let generatedFormat: Array<skillsType> = [];
    list.forEach((element) => {
      generatedFormat.push({
        label: element.skill,
        value: element.id,
      });
    });
    setskills(generatedFormat);
  };

  const selectCorrectAnswer: (data: {
    option: string;
    is_correct_option: number;
    id?: number;
  }) => void = (data) => {
    const {value} = questionTypes;
    let updatedOptions: Array<optionsType> = value === "2" ? [...options] : [];
    if (value === "2") {
      for (let i = 0; i < updatedOptions.length; i++) {
        if (updatedOptions[i].id === data.id) {
          updatedOptions[i].is_correct_option === 1
            ? (updatedOptions[i].is_correct_option = 0)
            : (updatedOptions[i].is_correct_option = 1);
          break;
        }
      }
    } else if (value === "1") {
      options.forEach((item) => {
        updatedOptions.push({
          id: item.id,
          option: item.option,
          is_correct_option: item.id === data.id ? 1 : 0,
        });
      });
    }
    setoptions(updatedOptions);
  };

  const fetchSkills = async () => {
    await axiosGetModule(APIEndPoints.GET_SKILLS_NODE)
      .then((res: SkillsResponse) => {
        formatSkills(res.data);
      })
      .catch((err) => console.log(err));
  };

  const autoFillFields = (questionData: any) => {
    if (questionData.question_type === 1) setquestionTypes(questionOptions[1]);
    else if (questionData.question_type === 2) {
      setquestionTypes(questionOptions[2]);
    }
    let level = difficultyOptions.filter(
      (item) => item?.value === questionData.question_level.toString()
    );
    setdifficultyLevel(level[0]);
    setquestion(questionData?.question);
    setQuestionDuration(questionData.question_duration.toString());
    setselectedSkill({
      label: questionData?.skill.skill,
      value: questionData?.skill_id,
    });
    let options: optionsType[] = [];
    questionData?.question_options?.forEach((ele: optionsType) => {
      options.push({
        id: ele.id,
        option: ele.option,
        is_correct_option: ele.is_correct_option,
      });
    });
    setoptions(options);
  };

  // userID is required to create the question. hence, below 3 lines.
  const userDetails = JSON.parse(localStorage.getItem("USER_DETAILS")!);
  const {user} = userDetails || {};
  const userId: string = user.id.toString();

  const createMyQuestion = async () => {
    setUploadingCsv(true);
    const duration = Math.floor(parseInt(questionDuration));
    let body = {
      question: question,
      organization_id: userData.organization_id,
      question_level: parseInt(difficultyLevel!?.value),
      question_points: 1,
      skill_id: selectedSkill!?.value,
      question_duration: duration,
      is_always_included: isQuestionPublic ? 1 : 0,
      question_type: parseInt(questionTypes.value),
      options: options.map((item) => ({
        option_id: Math.floor(Math.random() * 100000),
        option: item.option,
        is_correct_option: item.is_correct_option,
      })),
    };

    axiosPostModule(APIEndPoints.QUESTIONS_NODE(userId), body)
      .then(() => {
        toast.success(strings.create_question.successMsgs.questionCreation);
        eventTracker("Question Created", "New Question Created");
        axiosGetModule(APIEndPoints.GET_ALL_QUESTIONS_NODE)
          .then((res) => {
            res;
            const decryptedData: GetAllQuestionsResponse =
              decodeEncodedAPIResponse(res.data, res);
            setAllQuestions?.(decryptedData.data);

            if (searchParams.get("isFromCreate")) {
              window.opener.postMessage(
                "some-action-complete",
                window.location.origin
              );
            } else {
              navigate(-1);
            }
          })
          .catch((err) => console.log({msg: err}));
      })
      .catch((err) => {
        const errors = Object.keys(err.data.message);
        const e = err.data.errors[errors[0]];
        toast.error(e[0]);
      })
      .finally(() => {
        setcreatingQuestion(false);
        setUploadingCsv(false);
      });
  };

  const updateMyQuestion = async () => {
    setUploadingCsv(true);

    const duration = Math.floor(parseInt(questionDuration));

    let body = {
      question: question,
      organization_id: userData.organization_id,
      question_level: parseInt(difficultyLevel!?.value),
      question_points: 1,
      skill_id: selectedSkill!?.value,
      question_duration: duration,
      is_always_included: isQuestionPublic ? 1 : 0,
      question_type: parseInt(questionTypes.value),
      options: options.map((item) => ({
        option_id: item.id,
        option: item.option,
        is_correct_option: item.is_correct_option,
      })),
    };

    await axiosPatchModule(
      APIEndPoints.UPDATE_QUESTION_NODE(questionData?.id.toString()!),
      body
    )
      .then(() => {
        toast.success(strings.create_question.successMsgs.questionUpdation);
        eventTracker("Question Edited", "Existing question edited");
        axiosGetModule(APIEndPoints.GET_ALL_QUESTIONS_NODE).then((res) => {
          const decryptedResponse: GetAllQuestionsResponse =
            decodeEncodedAPIResponse(res.data, res);
          setAllQuestions?.(decryptedResponse.data);
          navigate(-1);
        });
      })
      .catch((err: any) => {
        const errors = Object.keys(err.data.message);
        const e = err.data.errors[errors[0]];
        toast.error(strings.create_question.errorMsgs.questionUpdation);
      })
      .finally(() => {
        setcreatingQuestion(false);
        setUploadingCsv(true);
      });
  };

  const validateForm = () => {
    if (csvFile) return csvFormatter(csvFile);

    let errorMsg: string = "";
    !difficultyLevel &&
      (errorMsg = strings.create_question.errorMsgs.difficulty);
    !selectedSkill?.value &&
      (errorMsg = strings.create_question.errorMsgs.skills);
    !questionDuration &&
      (errorMsg = strings.create_question.errorMsgs.questionDuration);
    questionDuration &&
      isNaN(parseInt(questionDuration)) &&
      (errorMsg = strings.create_question.errorMsgs.invalidDuration);
    if (questionTypes.value === difficultyOptions[1].value) {
      options.forEach(
        (item) =>
          !item.option &&
          (errorMsg = strings.create_question.errorMsgs.optionsInput)
      );
      options.length < 2 &&
        (errorMsg = strings.create_question.errorMsgs.optionsCount);
    }
    question &&
      question.length < 10 &&
      (errorMsg = strings.create_question.errorMsgs.questionLength);
    !question && (errorMsg = strings.create_question.errorMsgs.question);
    if (errorMsg) return toast.error(errorMsg);
    setcreatingQuestion(true);
    doEdit() ? updateMyQuestion() : createMyQuestion();
  };

  const fetchQuestionForEdit = () => {
    axiosGetModule(
      APIEndPoints.QUESTION_OF_ID_NODE(questionData?.id.toString()!)
    )
      .then((res) => {
        const decryptedResponse: GetQuestionsResponse =
          decodeEncodedAPIResponse(res.data, res);
        if (decryptedResponse.data.is_always_included == 1)
          setIsQuestionPublic(true);
        else setIsQuestionPublic(false);
        autoFillFields(res.data);
      })
      .catch((err) => console.log(err));
  };

  const csvFormatter = async (event: any) => {
    // var name = event.name;
    const reader = new FileReader();
    let data: any;

    reader.onload = async (evt) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt?.target?.result!;
      const wb = await XLSX.read(bstr, {type: "binary"});
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      //@ts-ignore
      data = await XLSX.utils.sheet_to_csv(ws, {header: 1});
      //@ts-ignore
      Papa.parse(data, {
        header: true,
        skipEmptyLines: true,
        complete: function (results: any) {
          const rowsArray: any = [];
          const valuesArray: any = [];
          // Iterating data to get column name and their values
          results.data.map((d: any) => {
            rowsArray.push(Object.keys(d));
            valuesArray.push(Object.values(d));
          });
          // Parsed Data Response in array format
          setprequestions(results.data);
          // Filtered Values
          setValues(valuesArray);
        },
      });
      setOpen(true);
    };
    reader.readAsBinaryString(event);
  };

  const readTextFromCsv = async () => {
    setUploadingCsv(true);
    let body = new FormData();
    body.append("file", csvFile, "file.xlsx");
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
      Accept: "application/json",
    };
    await axiosPostModule(APIEndPoints.UPLOAD_CSV_NODE, body, {}, headers).then(
      (res: UploadCSV) => {
        if (res && res.data) {
          setTimeout(() => {
            setUploadingCsv(false);
            toast.success("CSV uploaded", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }, 600);
        } else {
          toast.error("Something went wrong while uploading the CSV", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setUploadingCsv(false);
        }
      }
    );
  };

  const handleQuestionTypeChange = (val: any) => {
    const {value} = val;
    setquestionTypes(val);
    if (value === "1") {
      let firstCorrectOption = 0;
      for (let i = 0; i < options.length; i++) {
        if (options[i].is_correct_option === 1) {
          firstCorrectOption = i;
          break;
        }
      }
      setoptions(
        [...options].map((option, index) => ({
          ...option,
          is_correct_option: index === firstCorrectOption ? 1 : 0,
        }))
      );
    }
  };

  const onCloseModal = () => setOpen(false);

  return (
    <div className="p-1 mb-6">
      {!isNotGlobal && <TopTabs selectedTab={1} />}

      <SizedBox height="30px" />
      <div className="rounded-md border border-white-300 bg-white-900 p-10 shadow-lg">
        <div className="flex items-center">
          <div
            className="flex justify-center items-center h-8 w-8 bg-primary rounded cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <ChevronLeft color={AppColors.WHITE} size={25} />
          </div>
          <p className="text-lightBlack-500 font-bold text-xl ml-4">
            {doEdit()
              ? strings.create_question.editHeading
              : strings.create_question.createHeading}
          </p>
        </div>

        <div className="w-3/5">
          <SizedBox height="44px" />
          <Question question={strings.create_question.question.type} />
          <Select
            options={questionOptions}
            value={questionTypes}
            onChange={handleQuestionTypeChange}
            placeholder={strings.create_question.question.type}
            components={makeAnimated()}
            styles={{} ?? SelectStyles}
            theme={(theme: Theme) => ({
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#fafafa",
                primary: AppColors.PRIMARYCOLOR,
              },
            })}
          />

          <SizedBox height="44px" />
          <Question question={strings.create_question.question.description} />
          <CustomTextArea
            placeholder="Start from here..."
            onChangeText={(text) => setquestion(text)}
            value={question}
          />
          {!!question.length && (
            <p
              className={`flex justify-end ${
                question.length < 10 ? "text-red-500" : "text-lightBlack-500"
              } text-xs `}
            >
              {`${question.length} / 10 or more`}
            </p>
          )}

          {(questionTypes.value === questionOptions[1].value ||
            questionTypes.value === questionOptions[2].value) && (
            <div>
              <SizedBox height="44px" />
              <Question
                question={strings.create_question.question.mcq}
                showStar={true}
              />
              {options.map((item, index: number) => (
                <div key={item.id} className="flex items-center mb-5 mt-1">
                  <div
                    className={`h-8 w-8 bg-c4c4c4-300 rounded-full cursor-pointer justify-center items-center flex`}
                    onClick={() => selectCorrectAnswer(item)}
                  >
                    {!!item.is_correct_option && (
                      <div className="h-8 w-8 bg-primary rounded-full flex justify-center items-center">
                        <div className="h-4 w-4 bg-white-900 rounded-full" />
                      </div>
                    )}
                  </div>
                  <SizedBox width="20px" />
                  <div className="w-2/4">
                    <textarea
                      name="text"
                      value={options[index].option}
                      placeholder={"Type here...."}
                      className="w-100 border px-2 py-1 bg-white-800 rounded-md border-1 border-solid border-white-200 text-xs outline-none h-9.5"
                      onChange={(ans) =>
                        handleInputAnswer(ans.target.value, index)
                      }
                    />
                  </div>
                  <SizedBox width="24px" />
                  {options.length === index + 1 &&
                    options.length > 2 &&
                    !doEdit() && (
                      <img
                        src={Images.Cross}
                        onClick={() => removeOption(index)}
                        style={{width: 20, height: 20}}
                      />
                    )}
                </div>
              ))}
              {options.length < 4 && !doEdit() && (
                <ViewButton
                  name="Add Option"
                  style={{height: "40px", alignItems: "center"}}
                  onClick={addOption}
                />
              )}
            </div>
          )}

          <SizedBox height="44px" />
          <Question question={strings.create_question.question.skills} />
          <CustomSelect
            options={skills}
            defaultValue={selectedSkill?.value}
            onChange={(val: any) => setselectedSkill(val)}
            placeholder={strings.create_question.question.type}
            {...(selectedSkill?.label ? {value: selectedSkill} : {})}
          />

          <SizedBox height="44px" />
          <Question question={strings.create_question.question.level} />
          <CustomSelect
            options={difficultyOptions}
            {...(difficultyLevel?.label ? {value: difficultyLevel} : {})}
            onChange={(val: {value: string; label: string}) =>
              setdifficultyLevel(val)
            }
            placeholder={strings.create_question.question.type}
          />
          <SizedBox height="44px" />
          <Question question={strings.create_question.question.duration} />
          <input
            type="text"
            value={questionDuration}
            onChange={(e) => setQuestionDuration(e.target.value)}
            placeholder={strings.create_question.question.duration_placeholder}
            className="border border-white-300 text-xs rounded-md font-light w-full h-12 p-2 bg-white-800 focus:outline-none"
          />
          <SizedBox height="44px" />
          <p className="font-semibold text-lightBlack-300">{`Make question public?`}</p>
          <div className="flex items-center mt-1">
            <div
              className="flex items-center cursor-pointer"
              onClick={() => setIsQuestionPublic(!isQuestionPublic)}
            >
              {!isQuestionPublic ? (
                <Cancel color={AppColors.UNANSWERED_RED} />
              ) : (
                <UncheckedRadioButton color={AppColors.PRIMARYCOLOR} />
              )}
              <p className={`ml-2`}>{strings.refutation}</p>
            </div>
            <div
              className="ml-4 flex items-center cursor-pointer"
              onClick={() => setIsQuestionPublic(!isQuestionPublic)}
            >
              {isQuestionPublic ? (
                <Checked color={AppColors.ANSWERED_GREEN} />
              ) : (
                <UncheckedRadioButton color={AppColors.PRIMARYCOLOR} />
              )}
              <p className={`ml-2`}>{strings.affirmation}</p>
            </div>
          </div>
          {!doEdit() && !isNotGlobal && (
            <>
              <SizedBox height="40px" />
              <div className="border-t-2 border-dashed border-t-lightBlack-300 border-opacity-60">
                <SizedBox height="30px" />
                <Question
                  question={strings.create_question.csvUploaderheading}
                />
                <div
                  className="mt-2 w-full border border-dotted flex items-center p-4 py-6 rounded relative"
                  style={{borderColor: AppColors.BLACK_BORDER_LIGHT}}
                >
                  <img
                    src={Images.Cross}
                    alt="cross"
                    className="absolute h-3 w-3 cursor-pointer -right-1.5 -top-1.5"
                    onClick={() => {
                      setCsvFile(null);
                      csvFileRef.current!.files = new DataTransfer().files;
                    }}
                  />
                  <div className="flex flex-0.3 items-center justify-center">
                    <button
                      style={{background: AppColors.LIGHT_BG_BLUE}}
                      className="p-2 rounded text-blue-500 font-bold text-sm px-6"
                      onClick={() => csvFileRef.current?.click()}
                    >
                      {strings.upload}
                    </button>
                  </div>
                  <div className="flex flex-0.7 text-black-700 items-center text-sm">
                    {csvFile ? csvFile.name : strings.create_question.no_file}
                    <form action="">
                      <input
                        type="file"
                        // accept=".csv"
                        accept=".xlsx"
                        ref={csvFileRef}
                        onChange={(e) => setCsvFile(e.target.files![0])}
                        style={{display: "none"}}
                      />
                    </form>
                  </div>
                </div>
              </div>
            </>
          )}

          <SizedBox height="44px" />
          <div className="flex">
            <button
              disabled={uploadingCsv}
              onClick={validateForm}
              style={{width: "144px", height: "50px", borderRadius: 6}}
              className={`border bg-primary hover:bg-white-900 hover:text-primary text-white-900 hover:border-primary font-bold`}
            >
              {uploadingCsv ? (
                <Bounce speed={0.5} />
              ) : csvFile ? (
                strings.create_question.upload_qith_csv
              ) : (
                strings.saveAndNext
              )}
            </button>
            <CreateQuestionModal
              open={open}
              onCloseModal={onCloseModal}
              readTextFromCsv={readTextFromCsv}
              prequestions={prequestions}
            />
            <SizedBox width="46px" />
            <ViewButton
              name={strings.cancel}
              invert
              style={{width: "144px", height: "50px"}}
              onClick={() => navigate(-1)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
