import {Editor} from "@monaco-editor/react";
import {Button} from "@mui/material";
import {Language} from "globals/types/globalTypes";
import {memo, useRef, useState} from "react";
import {Windmill} from "react-activity";
import {IoIosPlay} from "react-icons/io";
import {CODE_SNIPPETS} from "./DefaultCodeSnippets";
import LanguageSelector from "./LanguageSelector";
import Output from "./Output";

interface Props {
  handleQuestionChange: (codeOutput: string) => void;
  currentQuestionNumber: number;
}

const CodeEditor = ({handleQuestionChange, currentQuestionNumber}: Props) => {
  const editorRef = useRef<typeof Editor | null>(null);
  const [language, setLanguage] = useState<string>("java");
  const [langId, setLangId] = useState<number>(91);
  const [codeOutput, setCodeOutput] = useState<string>("");
  const [code, setCode] = useState<string | undefined>(CODE_SNIPPETS["java"]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function submitCode() {
    setIsLoading(true);
    fetch("https://judge0-ce.p.rapidapi.com/submissions", {
      method: "POST",
      headers: {
        "x-rapidapi-key": "71f3636c91msh5328f61f4937f45p18ec70jsn74e4e77f11e3",
        "x-rapidapi-host": "judge0-ce.p.rapidapi.com",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        source_code: code,
        language_id: langId,
      }),
    })
      .then((res) => res.json())
      .then(({token}) => {
        const id = setInterval(() => {
          fetch(`https://judge0-ce.p.rapidapi.com/submissions/${token}`, {
            headers: {
              "x-rapidapi-key":
                "71f3636c91msh5328f61f4937f45p18ec70jsn74e4e77f11e3",
              "x-rapidapi-host": "judge0-ce.p.rapidapi.com",
              "Content-Type": "application/json",
            },
          })
            .then((res) => res.json())
            .then((response) => {
              if (response.status.description === "Processing") {
                return;
              }
              clearInterval(id);
              if (response.stderr) {
                setCodeOutput(response.stderr);
                return;
              }
              if (response.stdout) {
                setCodeOutput(response.stdout);
                return;
              }
              if (response.compile_output) {
                setCodeOutput(response.compile_output);
                return;
              }
            })
            .catch(() => clearInterval(id))
            .finally(() => {
              setIsLoading(false);
            });
        }, 500);
      })
      .catch((e) => {
        console.log("ran into an error");
      });
  }

  const onMount = (editor: any) => {
    editorRef.current = editor;
    editor.focus();
  };

  const onSelect = (language: Language) => {
    const modifyStr = language?.name?.split("(")?.[0]?.trim().toLowerCase();
    const selectedLanguage = modifyStr as keyof typeof CODE_SNIPPETS;
    setLanguage(modifyStr);
    setCode(CODE_SNIPPETS[selectedLanguage] || "");
    setLangId(language.id);
  };

  return (
    <div className="relative w-full h-full">
      <LanguageSelector
        language={language}
        onSelect={onSelect}
        langId={langId}
      />
      <Editor
        options={{
          minimap: {
            enabled: false,
          },
        }}
        height="50vh"
        theme="vs-dark"
        language={language}
        defaultValue={CODE_SNIPPETS[language as keyof typeof CODE_SNIPPETS]}
        onMount={onMount}
        value={code}
        onChange={(e) => setCode(e)}
        className="rounded-10 bg-[#211C2D]"
      />
      <Output codeOutput={codeOutput} />

      <div className="absolute bottom-5 right-4 flex gap-7 items-center ">
        <Button
          variant="outlined"
          onClick={submitCode}
          disabled={isLoading}
          endIcon={isLoading ? <Windmill /> : null}
          sx={{
            height: "46px",
            rounded: "6px",
            fontWeight: "500px",
            background: "#fff",
            color: "#1B1829",
            boxShadow: "0px 29px 64px 0px #BABABA1A",
            fontSize: "18px",
            "&:hover": {
              backgroundColor: "#fff",
            },
          }}
        >
          {isLoading ? "Running..." : "Run Code"}
          {!isLoading ? <IoIosPlay /> : null}
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            handleQuestionChange(code ?? " ");
            setCodeOutput("");
            setCode(CODE_SNIPPETS[language as keyof typeof CODE_SNIPPETS]);
          }}
          sx={{
            height: "46px",
            rounded: "6px",
            fontWeight: "500px",
            background: "#D66BFB",
            color: "#fff",
            boxShadow: "0px 29px 64px 0px #BABABA1A",
            fontSize: "18px",
            "&:hover": {
              backgroundColor: "#D66BFB",
            },
          }}
        >
          {currentQuestionNumber === 3 ? "Submit" : "Next"}
        </Button>
      </div>
    </div>
  );
};

export default memo(CodeEditor);
