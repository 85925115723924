import {useEffect, useRef} from "react";
import WaveSurfer from "wavesurfer.js";
import {useChat} from "./ChatProvider";
import {Row} from "./Row";

type UserChatProps = {
  chat: string;
  index: number;
  audioChunks: Blob[];
};

const UserChat = ({chat, index, audioChunks}: UserChatProps) => {
  const {chats} = useChat();
  const isTranscribingQuestion = index === chats.length - 1;

  const waveSurfer = useRef<WaveSurfer | null>(null);

  useEffect(() => {
    if (audioChunks.length > 0 && waveSurfer) {
      const audioBlob = new Blob(audioChunks, {type: "audio/wav"});
      waveSurfer.current?.loadBlob(audioBlob);
    }
  }, [audioChunks]);

  useEffect(() => {
    waveSurfer.current = WaveSurfer.create({
      container: `#waveform-${index}`,
      waveColor: "black",
      progressColor: "orange",
      cursorColor: "transparent",
      height: "auto",
      dragToSeek: false,
    });
  }, []);

  return (
    <Row
      classNames={`bg-[#785DD2] mr-4 text-white-900 max-w-3/4 min-w-3/4 py-3 px-4 rounded-lg text-sm font-bold justify-center flex-col`}
    >
      <p>{chat}</p>
      <Row
        classNames={`w-full ${
          isTranscribingQuestion ? "bg-white-900" : "transparent"
        } justify-center rounded-lg mt-4`}
      >
        <div
          style={{display: isTranscribingQuestion ? "block" : "none"}}
          className={`h-12 w-1/2`}
          id={`waveform-${index}`}
        ></div>
      </Row>
    </Row>
  );
};

export default UserChat;
