import React, {useState} from "react";
import moment, {Moment} from "moment";

import Datepicker from "components/Pickers/DatePicker/DatePicker";
import SizedBox from "components/SizedBox/SizedBox";
import {Search} from "components/Icons";

import {AppColors} from "globals/appcolors";
import {CSS, DatePickerBox} from "globals/types/globalTypes";
import string from "globals/constants/strings.json";

interface ChildComponentProps {
  filterdata: Function;
  dateHandler: Function;
  clearFilter: Function;
}
const Filter: React.FC<ChildComponentProps> = (props) => {
  const [filterDate, setfilterDate] = useState<Moment | null>(null);
  const [filterValues, setFilterValues] = useState<{
    search_value: string;
    score?: number | string;
  }>({search_value: ""});

  const datePickerInputStyle: CSS = {
    height: 38,
    background: "transparent",
    width: "90%",
    fontSize: "12px",
    outline: "none",
  };

  const datePickerContainerStyle: DatePickerBox = {
    background: AppColors.WHITE_800,
    paddingX: "10px",
    height: 40,
    borderRadius: "6px",
    border: `1px solid ${AppColors.WHITE_300}`,
  };

  const handleChange = (event: any | Moment) => {
    const name = event.target.name;
    const value = event.target.value;
    let filterObj = {...filterValues, [name]: value};
    setFilterValues(filterObj);
    props.filterdata(filterObj);
  };

  const clearFilterHandle = () => {
    props.clearFilter();
    setfilterDate(null);
    setFilterValues((prev) => ({...prev, search_value: "", score: ""}));
  };

  return (
    <div className="border border-white-300 rounded-md bg-white-900 p-5">
      <div className="flex justify-between items-center">
        <p className="capitalize text-lg font-semibold text-lightBlack-500">
          {string.filter}
        </p>

        <div
          className="border border-blue-500 rounded-md bg-blue-200 p-1.5 cursor-pointer"
          onClick={clearFilterHandle}
        >
          <p className="text-blue-500 text-base font-normal">clear filters</p>
        </div>
      </div>

      <div className="flex flex-col sm:flex-row">
        <div className="basis-1/3 mx-2">
          <SizedBox height="32px " />
          <div className="flex  items-center">
            <p>Name</p>
          </div>

          <SizedBox height="12px " />
          <div className="flex  items-center">
            <div className="flex w-full relative h-10 justify-start">
              <input
                type="text"
                className="border bg-white-800 border-white-200 rounded-md p-3 flex pr-12 outline-none w-90"
                placeholder="Enter name, email or Assessment name"
                name="search_value"
                value={filterValues.search_value}
                onChange={handleChange}
              />
              <Search
                size={20}
                color={AppColors.PRIMARYCOLOR}
                className="relative top-3 right-8"
              />
            </div>
          </div>
        </div>

        <div className="basis-1/3 mx-2">
          <SizedBox height="32px " />
          <div className="flex  items-center">
            <p>Date</p>
          </div>

          <SizedBox height="12px" />
          <div className="w-90">
            <Datepicker
              value={filterDate}
              onChange={(newdate: Moment | null) => {
                setfilterDate(newdate), props.dateHandler(newdate);
              }}
              format={"DD-MM-YYYY"}
              maxDate={moment()}
              customInputStyle={datePickerInputStyle}
              isCustomInput
              customContainerStyle={datePickerContainerStyle}
            />
          </div>
        </div>

        <div className="basis-1/3 mx-2">
          <SizedBox height="32px " />
          <div className="flex  items-center">
            <p>Score</p>
          </div>

          <SizedBox height="12px " />
          <div className="flex  items-center">
            <div className="flex w-full relative h-10 justify-start">
              <input
                type="number"
                className="border bg-white-800 border-white-200 rounded-md p-3 flex pr-12 outline-none w-90"
                placeholder="Enter the score"
                name="score"
                value={filterValues.score}
                onChange={handleChange}
              />
              <Search
                size={20}
                color={AppColors.PRIMARYCOLOR}
                className="relative top-3 right-8"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filter;
