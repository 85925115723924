import {useQuery, UseQueryResult} from "@tanstack/react-query";
import axios, {AxiosResponse} from "axios";
import {APIEndPoints} from "globals/constants/endPoints";
import {staticHeaders} from "globals/helpers";
import {CandidateDetailsResponse} from "globals/types/APIResponses";

export function useCandidateDetails(
  candidateId: string
): UseQueryResult<CandidateDetailsResponse, unknown> {
  return useQuery({
    queryKey: ["candidateDetail"],
    queryFn: () =>
      axios
        .get<CandidateDetailsResponse>(
          APIEndPoints.CANDIDATE_OF_ID_NODE(candidateId),
          {
            headers: staticHeaders(),
          }
        )
        .then((res) => {
          if (res.data.success === true && res.data.code === 200)
            return res.data;
        }),
    enabled: false,
    onError: (e) => e,
  });
}
