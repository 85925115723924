import SourcebaeLogo from "./images/Sourcebae-logo.png";
import testimonialsImage from "./images/Testimonials.png";
import aiFormulated from "./images/aiFormulated.png";
import arrow from "./images/arrow.png";
import assessmentPageBg from "./images/assessmentPageBg.webp";
import ctaArrow from "./images/ctaArrow.png";
import dashboard from "./images/dashbooard.png";
import getHired from "./images/getHired.png";
import hcl from "./images/hcl.png";
import JoinJourneyBg from "./images/joinJourneyBg.png";
import members from "./images/members.png";
import persistent from "./images/persistent.png";
import results from "./images/results.png";
import sendInvite from "./images/sendInvite.png";
import sourcebaeScreening from "./images/sourcebaeScreening.png";
import swiggy from "./images/swiggy1.png";
import zomato from "./images/zomato1.png";

export const Images = {
  assessmentPageBg,
  members,
  persistent,
  getHired,
  swiggy,
  hcl,
  zomato,
  sourcebaeScreening,
  aiFormulated,
  results,
  dashboard,
  JoinJourneyBg,
  sendInvite,
  SourcebaeLogo,
  testimonialsImage,
  arrow,
  ctaArrow,
};
