import {
  useMutation,
  useQueryClient,
  UseMutationResult,
} from "@tanstack/react-query";
import axios, {AxiosResponse} from "axios";
import cookies from "react-cookies";

import {APIEndPoints} from "globals/constants/endPoints";
import {AssessmentData} from "globals/types/globalTypes";
import {COOKIESKEYS} from "globals/constants/cookiesKeys";

interface Variables {
  status?: number;
  ip_address?: string;
  ip_change_count?: number;
  time_remaining?: number;
  tab_switches?: number;
  time_taken?: number;
  location?: string;
  assembly_ai_id?: string;
}

interface Response {
  success: boolean;
  code: number;
  data: {
    assesment: AssessmentData;
    attempts: number;
    jwt: string;
  };
}

export function useUpdateAssessment(
  assessmentId: string
): UseMutationResult<
  AxiosResponse<Response, Variables> | undefined,
  unknown,
  Variables,
  unknown
> {
  const headers = {
    Authorization: `Bearer ${cookies.load(COOKIESKEYS.CANDIDATE_TOKEN)}`,
    Accept: "application/json",
  };
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["updateAssessment"],
    mutationFn: async (data: Variables) => {
      return axios
        .patch<Response>(
          APIEndPoints.UPDATE_IP_SESSION_TIME_REMAINING_ASSESMENT(assessmentId),
          data,
          {headers: headers}
        )
        .then((res) => {
          if (res.data.success === true && res.data.code === 201) {
            queryClient.invalidateQueries(["updateAssessment"]);
            return res;
          }
        });
    },
    onError: (e) => e,
  });
}
