"use client";
import {FormEvent, useState} from "react";
import {toast} from "react-toastify";
import {Images} from "../../assets/Image";
import styles from "./style.module.css";

interface FormValues {
  name: string;
  email: string;
}

// const formik = useFormik();
interface ContactForm {
  otherStyles?: string;
}

const ContactForm: React.FC<ContactForm> = ({otherStyles}) => {
  const [formValues, setFormValues] = useState({name: "", email: ""});
  const [error, setError] = useState({name: "", email: ""});
  const validation = (value: string, name: string) => {
    switch (name) {
      case "name":
        if (value == "") {
          setError((prev) => {
            return {...prev, name: "Name cannot be empty"};
          });
        } else if (value.length < 3) {
          setError((prev) => {
            return {...prev, name: "it's too short!"};
          });
        } else if (value.length > 150) {
          setError((prev) => {
            return {...prev, name: "it's too Long!"};
          });
        } else {
          setError((prev) => {
            return {...prev, name: ""};
          });
        }
        break;
      case "email":
        const test = value
          .toLowerCase()
          .match(/^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/);
        if (value === "") {
          setError((prev) => {
            return {...prev, email: "Email cannot be empty"};
          });
        } else if (!test) {
          setError((prev) => {
            return {...prev, email: "Inavalid email!"};
          });
        } else {
          setError((prev) => {
            return {...prev, email: ""};
          });
        }
        break;
    }
  };

  const handleSubmit = () => {
    validation(formValues.name, "name");
    validation(formValues.email, "email");

    if (
      error.name === "" &&
      error.email === "" &&
      formValues.email !== "" &&
      formValues.name !== ""
    ) {
      setFormValues({
        name: "",
        email: "",
      });
      toast.success("Congrats!! You are on our waitlist.");
    }
  };
  return (
    <div
      className={`${styles.main_container} ${otherStyles}`}
      id={"contact-form"}
    >
      <p className={styles.head}>Join us, and get you early access</p>
      <p className={styles.subhead}>
        Join our waitlist now to connect and get launch updates!
      </p>
      <div className={styles.members_wrapper}>
        <img src={Images.members} className={styles.members_image} />
        <p className={styles.text}>100+ members have already signed up</p>
      </div>
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          handleSubmit();
        }}
        className="flex flex-col gap-6 xxl:gap-8"
      >
        <div className="w-full">
          <input
            className={`${styles.input_field} focus:outline-none ${
              error?.name ? "border-[2px] border-[#a63f30]" : ""
            }`}
            name="name"
            value={formValues.name}
            onChange={(event) => {
              validation(event.target.value, "name");
              setFormValues((prev) => {
                return {...prev, name: event.target.value};
              });
            }}
            placeholder="Enter full name (minimum 3 letters)"
            type="text"
            onBlur={(e) => {
              validation(e.target.value, "name");
            }}
          />
          {/* {error.name !== "" ? (
            <p className={styles.error}>{error.name}</p>
          ) : (
            <></>
          )} */}
        </div>
        <div className="w-full">
          <input
            className={`${styles.input_field} focus:outline-none ${
              error?.email ? "border-[2px] border-[#a63f30]" : ""
            }`}
            name="email"
            type="email"
            value={formValues.email}
            placeholder="Your work email"
            onChange={(e) => {
              validation(e.target.value, "email");
              setFormValues({...formValues, email: e.target.value});
            }}
            onBlur={(e) => {
              validation(e.target.value, "email");
            }}
          />
          {/* {error.email !== "" ? (
            <p className={styles.error}>{error.email}</p>
          ) : (
            <></>
          )} */}
        </div>
        <button className={`${styles.btn} flex items-center`}>
          Join the waitlist{" "}
          <img
            src={Images.ctaArrow}
            alt="arrow"
            className=" w-[22px] h-[22px] 1750:w-[26px] 1750:h-[26px] mr-2.5 mt-[-1px]"
          />
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
