import {useQuery, useQueryClient, UseQueryResult} from "@tanstack/react-query";
import axios from "axios";
import cookies from "react-cookies";

import {COOKIESKEYS} from "globals/constants/cookiesKeys";
import {APIEndPoints} from "globals/constants/endPoints";
import {decodeEncodedAPIResponse} from "globals/helpers";
import {InterviewQuestionsResponse} from "globals/types/APIResponses";

export function useFetchInterviewQuestions(
  assessmentId: string
): UseQueryResult<InterviewQuestionsResponse, unknown> {
  const headers = {
    Authorization: `Bearer ${cookies.load(COOKIESKEYS.CANDIDATE_TOKEN)}`,
    Accept: "application/json",
  };
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ["fetchInterviewQuestions"],
    queryFn: async () => {
      return axios
        .get(`${APIEndPoints.GET_INTERVIEW_INSTRUCTIONS}/${assessmentId}`, {
          headers,
        })
        .then((res) => {
          if (res.data.success === true && res.data.code === 200) {
            const {data} = res.data;
            const decodedResponse: InterviewQuestionsResponse =
              decodeEncodedAPIResponse(data, res.data);
            const reducedQuestions =
              decodedResponse.data.assessment.assessment_questions.filter(
                (question) => question.question.question_options.length > 0
              );
            decodedResponse.data.assessment.assessment_questions =
              reducedQuestions;
            queryClient.invalidateQueries(["fetchInterviewQuestions"]);
            return decodedResponse;
          }
          return res;
        });
    },
    enabled: false,
    onError: (e) => e,
  });
}
