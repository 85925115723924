import React, {useEffect, useRef} from "react";
import {Typography} from "@mui/material";
import {FullScreen, FullScreenHandle} from "react-full-screen";
import styles from "./Interview.module.scss";
import Question, {Click_Direction} from "./Question";
import {ResponsesOverview} from "./ResponsesOverview";
import {Steps} from "./Steps";

import {Row} from "components/Row";
import {CountDownTimer} from "components/Timer";

import {InterviewQuestionsResponse} from "globals/types/APIResponses";
import {InterviewQuestion, QuestionOption} from "globals/types/globalTypes";
import {AppColors} from "globals/appcolors";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";

interface IProps {
  currentQuestion: {
    index: number;
    question: InterviewQuestion;
  } | null;
  questions: InterviewQuestionsResponse | undefined;
  answers: QuestionOption[];
  uniqueResponses: number[];
  isTestFinished: boolean;
  remainingTime: number;
  fullScreenHandle: FullScreenHandle;
  setCurrenQuestion: React.Dispatch<
    React.SetStateAction<{index: number; question: InterviewQuestion} | null>
  >;
  finishTest: () => void;
  onChangeAnswer: (
    options: QuestionOption[] | undefined,
    direction: Click_Direction
  ) => void;
  onInterviewFinished: () => void;
  clearSelection: (question: InterviewQuestion | undefined) => void;
  updateTimer: (remainingTime: number) => void;
  handleFullScreenChange: (state: boolean, handle: any) => void;
  captureScreenshot: () => void;
}

export const InterviewFlow: React.FC<IProps> = ({
  currentQuestion,
  questions,
  answers,
  uniqueResponses,
  isTestFinished,
  remainingTime,
  fullScreenHandle,
  setCurrenQuestion,
  finishTest,
  onChangeAnswer,
  clearSelection,
  onInterviewFinished,
  updateTimer,
  handleFullScreenChange,
  captureScreenshot,
}) => {
  const assessmentName = localStorage.getItem("AssesmentName");
  const screenshotTimerId = useRef<NodeJS.Timer | undefined>(undefined);

  const {interview_details} = useAppCommonDataProvider();
  const {include_intro, include_round_one, include_round_two} =
    interview_details?.data.assessment || {};

  useEffect(() => {
    screenshotTimerId.current = setInterval(() => captureScreenshot(), 35000);
    return () => clearInterval(screenshotTimerId.current);
  }, []);

  return (
    <FullScreen
      className="fullscreenStyle"
      handle={fullScreenHandle}
      onChange={handleFullScreenChange}
    >
      <div className={styles.container}>
        <header className={`${styles.behavior__header}`}>
          <Row
            style={{background: AppColors.REPORTCARD_SECONDARY}}
            classNames="w-full justify-between px-8 py-4"
          >
            <Typography
              className="font-bold"
              variant="h4"
              fontWeight={"bold"}
              color={AppColors.WHITE}
            >
              Sourcebae
            </Typography>
            {isTestFinished === false && (
              <Row classNames="text-white-900">
                <Typography className="pr-3">Time remaining</Typography>
                <CountDownTimer
                  time={remainingTime}
                  onStopTimer={onInterviewFinished}
                  returnUpdatedValue={updateTimer}
                />
              </Row>
            )}
          </Row>
          <section className="w-full px-8 py-4 bg-[#fafafc]">
            <Steps
              currentStep={
                include_intro === true &&
                include_round_one === true &&
                include_round_two === true
                  ? 3
                  : // added
                  include_intro === false &&
                    include_round_one === true &&
                    include_round_two === false
                  ? 1
                  : //
                    2
              }
              interviewDetails={interview_details}
            />
          </section>
        </header>

        <section className={styles.behaviour__questionContainer}>
          <div className={styles.behavior__questionWrapper}>
            <div className={styles.behavior__questionInnerWrapper}>
              <section
                className={`${styles.question__scrollableArea} disable-scrollbar`}
              >
                <div className={styles.question__scrollableAreaTopStrip}>
                  <Typography className={styles.question__number}>
                    Question #{currentQuestion?.index! + 1}
                  </Typography>
                  <div className={styles.assessment__nameContainer}>
                    <Typography className="font-bold capitalize truncate">
                      {assessmentName}
                    </Typography>
                  </div>
                </div>

                <Question
                  question={currentQuestion?.question}
                  isAtLastQuestion={
                    currentQuestion?.index ===
                    questions!.data.assessment.assessment_questions.length - 1
                  }
                  onChangeAnswer={onChangeAnswer}
                  answer={answers}
                  clearSelection={clearSelection}
                />
              </section>

              <section className={styles.behavior__responsesOverviewContainer}>
                <ResponsesOverview
                  questions={questions}
                  answers={answers}
                  setCurrentQuestion={setCurrenQuestion}
                  uniqueResponses={uniqueResponses}
                  finishTest={finishTest}
                />
              </section>
            </div>
          </div>
        </section>
      </div>
    </FullScreen>
  );
};
