import {Button, Checkbox, Radio, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import React, {useEffect, useRef, useState} from "react";
import Modal from "react-responsive-modal";

import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import {Row} from "components/Row";
import {AppColors} from "globals/appcolors";
import {getElementDimensions} from "globals/helpers";
import {CustomModalBaseProps} from "globals/types/globalTypes";

interface IProps extends CustomModalBaseProps {
  title: string;
  open: boolean;
  primaryBtnLabel: string;
  subtitle?: string | null;
  onClose: () => void;
  primaryBtnAction: () => void;
  secondaryBtnAction: () => void;
}

type CandidateType = "contractual" | "individual";
type Flavor =
  | "normal"
  | "intro_only"
  | "second_only"
  | "intro_and_tech"
  | "intro_and_second"
  | "tech_only";

const useStyles = makeStyles({
  dialog: {
    width: "auto",
    height: "auto",
    borderRadius: "10px",
  },
  buttonSection: {
    display: "flex",
    columnGap: 10,
    marginTop: "20px",
    justifyContent: "flex-end",
  },
});

export const CreateAssessmentType: React.FC<IProps> = ({
  open,
  title,
  subtitle = null,
  primaryBtnLabel,
  onClose,
  primaryBtnAction,
  secondaryBtnAction,
}) => {
  const candidateType: CandidateType[] = ["contractual"];

  const formType = useRef<"variant" | "contractual_combinations">("variant");

  const classes = useStyles();
  const {setCreateAssesmentValues, createAssesmentValues} =
    useAppCommonDataProvider();

  const [showAssessmentContextMenu, setShowAssessmentContextMenu] =
    useState<boolean>(false);
  const [selectedCandidateType, setSelectedCandidateType] = useState<
    CandidateType | ""
  >("");

  const onCreateSingle = () => {
    setShowAssessmentContextMenu(!showAssessmentContextMenu);
  };

  const ContractualForm: React.FC = () => {
    const formOneRef = useRef<HTMLDivElement>(null);
    const formTwoRef = useRef<HTMLDivElement>(null);

    const [contractualFlavor, setContractualFlavor] =
      useState<Flavor>("normal");
    const [parentHeight, setParentHeight] = useState<number>(0);

    useEffect(() => {
      caluclateParentHeight();
    }, []);

    const caluclateParentHeight = () => {
      const {height: formOneHeight} =
        getElementDimensions(
          document.getElementsByClassName("candidate__type")[0]
        ) || {};

      const {height: formTwoHeight} =
        getElementDimensions(
          document.getElementsByClassName("assessment__flavors")[0]
        ) || {};

      if (formType.current === "variant") {
        if (formOneHeight !== undefined) {
          setParentHeight(formOneHeight);
        }
      } else {
        if (formTwoHeight !== undefined) setParentHeight(formTwoHeight);
      }
    };

    const toggleForms = () => {
      formOneRef.current?.classList.toggle("contractual_formOneTransformed");
      formTwoRef.current?.classList.toggle("contractual_formTwoTransformed");
      formType.current =
        formType.current === "variant" ? "contractual_combinations" : "variant";

      caluclateParentHeight();
    };

    const submit = () => {
      // @ts-ignore
      setCreateAssesmentValues({
        ...createAssesmentValues,
        include_intro:
          contractualFlavor === "normal"
            ? 1
            : contractualFlavor === "intro_only"
            ? 1
            : contractualFlavor === "intro_and_tech"
            ? 1
            : contractualFlavor === "intro_and_second"
            ? 1
            : 0,
        include_round_one:
          contractualFlavor === "normal"
            ? 1
            : contractualFlavor === "intro_and_tech"
            ? 1
            : contractualFlavor === "tech_only"
            ? 1
            : 0,
        include_round_two:
          contractualFlavor === "normal"
            ? 1
            : contractualFlavor === "second_only"
            ? 1
            : contractualFlavor === "intro_and_second"
            ? 1
            : 0,
      });
      primaryBtnAction();
    };

    const handleNextButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (selectedCandidateType === "contractual") {
        if (formType.current === "contractual_combinations") {
          submit();
          return;
        }
        toggleForms();
      } else if (selectedCandidateType === "individual") primaryBtnAction();
    };

    const handleBackButtonClick = () => {
      if (formType.current === "contractual_combinations") {
        toggleForms();
      } else {
        onCreateSingle();
      }
    };

    return (
      <div>
        <button className="mb-3" onClick={handleBackButtonClick}>
          <Typography sx={{color: AppColors.PRIMARYCOLOR}}>Go back</Typography>
        </button>

        <form>
          <Row style={{height: parentHeight}}>
            <section
              className="min-w-full contractual_formOneInitial self-start candidate__type"
              ref={formOneRef}
            >
              <p style={{fontSize: 16, fontWeight: "bold", marginBottom: 5}}>
                What type of candidate are you looking to interview?
              </p>
              {candidateType.map((candidate) => (
                <Row key={candidate} classNames="ml-4">
                  <Checkbox
                    checked={candidate === selectedCandidateType}
                    onChange={() => {
                      setSelectedCandidateType(candidate);
                      // @ts-ignore
                      setCreateAssesmentValues?.({
                        ...createAssesmentValues,
                        is_contractual: candidate === "contractual" ? 1 : 0,
                      });
                    }}
                    sx={{
                      "&.Mui-checked": {
                        color: AppColors.PRIMARYCOLOR,
                      },
                    }}
                  />
                  <Typography
                    sx={{textTransform: "capitalize"}}
                    variant="body2"
                  >
                    {candidate}
                  </Typography>
                </Row>
              ))}
            </section>

            <section
              className="min-w-full contractual_formTwoInitial assessment__flavors"
              ref={formTwoRef}
            >
              <p style={{fontSize: 16, fontWeight: "bold", marginBottom: 5}}>
                Please select one of the following flavors of assessments:
              </p>

              {/* <Row classNames="ml-4">
                <Radio
                  checked={contractualFlavor === "normal"}
                  onChange={(e) =>
                    setContractualFlavor(
                      e.target.checked ? "normal" : contractualFlavor
                    )
                  }
                  sx={{
                    "&.Mui-checked": {
                      color: AppColors.PRIMARYCOLOR,
                    },
                  }}
                />
                <Typography sx={{textTransform: "capitalize"}} variant="body2">
                  {"Normal flow"}
                </Typography>
              </Row>

              <Row classNames="ml-4">
                <Radio
                  checked={contractualFlavor === "intro_only"}
                  onChange={(e) =>
                    setContractualFlavor(
                      e.target.checked ? "intro_only" : contractualFlavor
                    )
                  }
                  sx={{
                    "&.Mui-checked": {
                      color: AppColors.PRIMARYCOLOR,
                    },
                  }}
                />
                <Typography sx={{textTransform: "capitalize"}} variant="body2">
                  {"Intro only"}
                </Typography>
              </Row>

              <Row classNames="ml-4">
                <Radio
                  checked={contractualFlavor === "intro_and_tech"}
                  onChange={(e) =>
                    setContractualFlavor(
                      e.target.checked ? "intro_and_tech" : contractualFlavor
                    )
                  }
                  sx={{
                    "&.Mui-checked": {
                      color: AppColors.PRIMARYCOLOR,
                    },
                  }}
                />
                <Typography sx={{textTransform: "capitalize"}} variant="body2">
                  {"Intro + Tech"}
                </Typography>
              </Row>

              <Row classNames="ml-4">
                <Radio
                  checked={contractualFlavor === "tech_only"}
                  onChange={(e) =>
                    setContractualFlavor(
                      e.target.checked ? "tech_only" : contractualFlavor
                    )
                  }
                  sx={{
                    "&.Mui-checked": {
                      color: AppColors.PRIMARYCOLOR,
                    },
                  }}
                />
                <Typography sx={{textTransform: "capitalize"}} variant="body2">
                  {"Only Tech Round"}
                </Typography>
              </Row>

              <Row classNames="ml-4">
                <Radio
                  checked={contractualFlavor === "intro_and_second"}
                  onChange={(e) =>
                    setContractualFlavor(
                      e.target.checked ? "intro_and_second" : contractualFlavor
                    )
                  }
                  sx={{
                    "&.Mui-checked": {
                      color: AppColors.PRIMARYCOLOR,
                    },
                  }}
                />
                <Typography sx={{textTransform: "capitalize"}} variant="body2">
                  {"Intro + Second Round"}
                </Typography>
              </Row> */}

              <Row classNames="ml-4">
                <Radio
                  checked={contractualFlavor === "second_only"}
                  onChange={(e) =>
                    setContractualFlavor(
                      e.target.checked ? "second_only" : contractualFlavor
                    )
                  }
                  sx={{
                    "&.Mui-checked": {
                      color: AppColors.PRIMARYCOLOR,
                    },
                  }}
                />
                <Typography sx={{textTransform: "capitalize"}} variant="body2">
                  {"Only second round"}
                </Typography>
              </Row>
            </section>
          </Row>
          <div className="mt-2 flex justify-end">
            <Button
              onClick={handleNextButtonClick}
              type="submit"
              disabled={selectedCandidateType === ""}
            >
              <Typography>Next</Typography>
            </Button>
          </div>
        </form>
      </div>
    );
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      showCloseIcon={false}
      styles={{
        modal: {
          borderRadius: 15,
          width: "35vw",
          padding: "2rem",
        },
      }}
    >
      {showAssessmentContextMenu === true ? (
        <ContractualForm />
      ) : (
        <>
          <section>
            <Typography fontWeight={"bold"}>{title}</Typography>
            <Typography>{subtitle}</Typography>
          </section>
          <section className={classes.buttonSection}>
            <Button onClick={onCreateSingle} variant="contained">
              {primaryBtnLabel}
            </Button>
          </section>
        </>
      )}
    </Modal>
  );
};
