import React from "react";
import {Typography} from "@mui/material";
import styles from "./Interview.module.scss";

import {InterviewQuestion, QuestionOption} from "globals/types/globalTypes";
import strings from "globals/constants/strings.json";
import {LEGEND} from "globals/constants/InterviewConstants";
import {AppColors} from "globals/appcolors";

interface IProps {
  questions: InterviewQuestion[];
  answers: QuestionOption[];
  uniqueResponses: number[];
  setCurrentQuestion: React.Dispatch<
    React.SetStateAction<{index: number; question: InterviewQuestion} | null>
  >;
  proceed: () => void;
}

export const BehaviorResponses: React.FC<IProps> = ({
  questions,
  answers,
  uniqueResponses,
  setCurrentQuestion,
  proceed,
}) => {
  return (
    <>
      <div className={styles.behavior__responsesOverviewWrapper}>
        <div
          className={`${styles.behavior__responsesScrollableArea} disable-scrollbar`}
        >
          <div className={styles.behavior__responsesScrollableAreaTopStrip}>
            <Typography className={styles.behavior__overviewText}>
              Questions Overview
            </Typography>
            <button
              className={styles.behavior__finishTestButton}
              onClick={proceed}
            >
              {`Proceed`}
            </button>
          </div>

          <section
            className={`${styles.behavior__questionListContainer} disable-scrollbar`}
          >
            {questions.map((question, index) => {
              const answerDetails =
                answers.filter(
                  (answer) => answer.question_id === question.question_id
                ).length > 0;
              return (
                <button
                  className={styles.behaviour__questionTile}
                  key={question.id.toString()}
                  onClick={() =>
                    setCurrentQuestion({
                      index,
                      question: questions[index]!,
                    })
                  }
                  style={{
                    background: answerDetails
                      ? AppColors.ANSWERED_GREEN
                      : AppColors.WHITE_300,
                    borderTopLeftRadius: answerDetails ? "12px" : "50%",
                    borderBottomRightRadius: answerDetails ? "12px" : "50%",
                    borderTopRightRadius: answerDetails ? "2px" : "50%",
                    borderBottomLeftRadius: answerDetails ? "2px" : "50%",
                    color: AppColors.WHITE,
                    fontSize: "12px",
                  }}
                >
                  {index + 1}
                </button>
              );
            })}
          </section>

          <section>
            {LEGEND.map((legend) => (
              <div
                className={styles.behavior__legendContainer}
                key={legend.title}
              >
                <div
                  className={styles.behavior__legendCircle}
                  style={{
                    background: legend.backgroundColor,
                  }}
                ></div>
                <Typography className="text-sm" sx={{marginLeft: 1}}>
                  {legend.title}
                  <span
                    className={`font-bold ${
                      legend.title === strings.interview.legend.attempted
                        ? "text-green-200"
                        : "text-black-500"
                    }`}
                  >
                    {legend.title === strings.interview.legend.attempted
                      ? ` (${uniqueResponses.length})`
                      : ` (${questions.length - uniqueResponses.length})`}
                  </span>
                </Typography>
              </div>
            ))}
          </section>
        </div>
      </div>
    </>
  );
};
