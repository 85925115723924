import React from "react";

interface SizedBoxProps {
  height?: string;
  width?: string;
}

const SizedBox: React.FC<SizedBoxProps> = ({height, width}) => {
  return <div style={{height, width}}></div>;
};

export default SizedBox;
