"use client";
import styles from "./styles.module.css";

type TitleProps = {
  subHeading: string;
  heading: string;
  className?: string;
  alignment?: "start" | "end" | "center";
  otherStyles?: string;
  width?: string;
  headingStyles?: string;
  subheadingStyles?: string;
};

const Title = ({
  className,
  alignment = "center",
  subHeading,
  heading,
  otherStyles,
  width,
  headingStyles,
  subheadingStyles,
}: TitleProps) => {
  return (
    <div
      className={`${className}
          flex
          flex-col
          ${
            alignment === "center"
              ? "items-center"
              : alignment === "start"
              ? "items-start"
              : "items-end"
          }
        `}
    >
      <p
        className={`${styles.subhead}
            ${
              alignment === "center"
                ? "text-center"
                : alignment === "start"
                ? "text-start"
                : "text-end"
            }
            ${otherStyles}
            ${subheadingStyles}
          `}
      >
        {subHeading}
      </p>
      <p
        className={`${styles.head}
            ${
              alignment === "center"
                ? "text-center"
                : alignment === "start"
                ? "text-start"
                : "text-end"
            }
            ${otherStyles}
            ${width}
            ${headingStyles}
          `}
      >
        {heading}
      </p>
    </div>
  );
};

export default Title;
