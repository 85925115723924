import {Chat} from "globals/types/globalTypes";
import {ReactElement, createContext, useContext, useState} from "react";

export type ChatProviderProps = {
  answer: string;
  setAnswer: ((value: string) => void) | null;
  images: string[];
  setImages: (value: string[]) => void;
  audio: HTMLAudioElement | null;
  setAudio: ((audio: HTMLAudioElement) => void) | null;
  chats: Chat[];
  setChats: ((chats: Chat[]) => void) | null;
};

const ChatContext = createContext<ChatProviderProps>({
  answer: "",
  setAnswer: () => {},
  images: [],
  setImages: () => {},
  audio: null,
  setAudio: () => {},
  chats: [],
  setChats: () => {},
});

export const ChatProvider = ({children}: {children: ReactElement}) => {
  const [answer, setAnswer] = useState<string>("");
  const [images, setImages] = useState<string[]>([]);
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [chats, setChats] = useState<Chat[]>([]);

  return (
    <ChatContext.Provider
      value={{
        answer,
        setAnswer,
        images,
        setImages,
        audio,
        setAudio,
        chats,
        setChats,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error("useChat must be used within a ChatProvider");
  }
  return context;
};
