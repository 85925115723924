import {ButtonSubmit, CSS} from "globals/types/globalTypes";
import React from "react";
import styles from "./CustomButton.module.scss";

interface Props {
  icon?: string | null;
  name?: string | JSX.Element;
  primaryColor?: string;
  isFilled?: boolean;
  buttonStyle?: CSS;
  containerStyle?: CSS;
  onclick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  type?: ButtonSubmit;
}

const Buttons: React.FC<Props> = ({
  icon = null,
  name = "Submit",
  primaryColor = "#345B63",
  isFilled = true,
  buttonStyle,
  containerStyle,
  onclick,
  type,
}) => {
  return (
    <div style={containerStyle}>
      <button
        className={`${styles.button} ${
          isFilled ? styles.filled_button : styles.light_button
        }`}
        style={buttonStyle}
        onClick={onclick}
        type={type}
      >
        {icon && <img src={icon} alt="plusicon" />}
        <div className={styles.content}>{name}</div>
      </button>
    </div>
  );
};

export default Buttons;
