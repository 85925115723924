import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";

import styles from "components/QuestionCard/QuestionCard.module.scss";

import SizedBox from "components/SizedBox/SizedBox";
import ClickableTags from "components/ClickableTag/ClickableTags";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";

import strings from "globals/constants/strings.json";
import {QuestionLinks} from "globals/constants/routeLinks";
import {GetAllQuestionData} from "globals/types/globalTypes";
import {difficultyOptions} from "globals/constants/dropdownConstants";
import {CsvQuestionObjType} from "./types";

import {Images} from "Images";

interface Props {
  mapdata: CsvQuestionObjType;
  canView?: boolean;
  canEdit?: boolean;
}

const CreateQuesCard: React.FC<Props> = ({
  mapdata,
  canEdit = true,
  canView = true,
}) => {
  const {setquestionData} = useAppCommonDataProvider();
  const navigate = useNavigate();
  const questionLevel = (val: number) => difficultyOptions[val - 1]?.label;

  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const {questionData} = useAppCommonDataProvider();

  const navigateToCreateQuestion = () => {
    // setquestionData?.(data);
    navigate(`/${QuestionLinks.Create_Question}?edit=true`);
  };
  let diffvalue: number = mapdata.question_level;

  let opt;
  if (mapdata.Correct_Answer?.length > 1) {
    opt = mapdata.Correct_Answer.split(",");
  }

  // console.log('que card data :', mapdata)

  return (
    <>
      <div className={styles.container}>
        <div className="flex justify-between items-center">
          <p
            className={styles.question__difficulty}
          >{`difficulty level- ${questionLevel(mapdata.question_level)}`}</p>

          {/* <div className="flex">

            <SizedBox width="10px" />
            {canEdit && (
              <img
                className="cursor-pointer"
                src={Images.EditIcon}
                onClick={navigateToCreateQuestion}
              />
            )}
            <SizedBox width="10px" />
            {(

              <img
                className="cursor-pointer"
                src={Images.Dustbin}
                width={"26px"}
                height={"26px"}
              // onClick={onDelete}
              />
            )}
          </div> */}
        </div>

        <SizedBox height={"10px"} />
        <TextareaAutosize
          value={mapdata.Question}
          className="text-sm font-semibold"
        >
          {/* {data.question} */}
        </TextareaAutosize>

        <SizedBox height="10px" />

        <p className="text-sm font-semibold flex py-1">
          <div className={`${styles.correct__optionContainer}`}>
            {mapdata.Correct_Answer == "1" || opt?.includes("1") ? (
              <div className={`${styles.correct__optionOuterCircle}`}>
                {" "}
                <div className={`${styles.correct__optionInnerCircle}`}></div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <div className="px-2">{mapdata.Option_1}</div>{" "}
        </p>

        <p className="text-sm font-semibold flex py-1">
          <div className={`${styles.correct__optionContainer}`}>
            {mapdata.Correct_Answer == "2" || opt?.includes("2") ? (
              <div className={`${styles.correct__optionOuterCircle}`}>
                {" "}
                <div className={`${styles.correct__optionInnerCircle}`}></div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <div className="px-2">{mapdata.Option_2}</div>{" "}
        </p>

        <p className="text-sm font-semibold flex py-1">
          <div className={`${styles.correct__optionContainer}`}>
            {mapdata.Correct_Answer == "3" || opt?.includes("3") ? (
              <div className={`${styles.correct__optionOuterCircle}`}>
                {" "}
                <div className={`${styles.correct__optionInnerCircle}`}></div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <div className="px-2">{mapdata.Option_3}</div>{" "}
        </p>

        <p className="text-sm font-semibold flex py-1">
          <div className={`${styles.correct__optionContainer}`}>
            {mapdata.Correct_Answer == "4" || opt?.includes("4") ? (
              <div className={`${styles.correct__optionOuterCircle}`}>
                {" "}
                <div className={`${styles.correct__optionInnerCircle}`}></div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <div className="px-2">{mapdata.Option_4}</div>{" "}
        </p>

        <SizedBox height="20px" />
        <div className="flex">
          <h1 className={styles.miscellaneous__title}>Question Duration</h1>
          <p className="px-2">{mapdata.question_duration}</p>
        </div>
        <SizedBox height="10px" />
        <div className="flex">
          <h1 className={styles.miscellaneous__title}>Question Points</h1>
          <p className="px-2">{mapdata.question_points}</p>
        </div>

        <SizedBox height="10px" />
      </div>
    </>
  );
};

export default CreateQuesCard;
