import {ListItemText, Menu, MenuItem, MenuList} from "@mui/material";
import {Language} from "globals/types/globalTypes";
import React, {memo, useEffect, useState} from "react";
import {IoIosArrowDown} from "react-icons/io";

interface LanguageSelectorProps {
  language: string;
  onSelect: (lang: Language) => void;
  langId: number;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  language,
  langId,
  onSelect,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [languages, setLanguages] = useState<Language[]>([]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    fetch("https://ce.judge0.com/languages/")
      .then((res) => res.json())
      .then((res) => {
        setLanguages(res);
      });
  }, []);

  return (
    <div className=" absolute z-40 top-[2px] right-0">
      <div
        onClick={(e) => handleClick(e)}
        className="text-white-900 text-16 font-medium rounded-[6px] px-3 py-2 bg-[#785DD2] w-[200px] flex justify-between items-center cursor-pointer"
      >
        <p>
          Language: <span className="font-bold capitalize">{language}</span>
        </p>
        <span className="">
          <IoIosArrowDown color="#fff" />
        </span>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disablePortal
        sx={{
          mt: 1,
          "& .MuiPaper-root": {
            borderRadius: "6px",
            width: "200px",
            height: "350px",
            overflow: "scroll",
          },
        }}
      >
        <MenuList
          sx={{
            p: 0,
          }}
        >
          {languages.map((item: Language, index) => {
            const modifyItem = item?.name
              ?.split("(")?.[0]
              ?.trim()
              .toLowerCase();
            return (
              <MenuItem
                key={index}
                selected={langId === item.id}
                onClick={() => {
                  onSelect(item);
                  handleClose();
                }}
                sx={{
                  background: langId === item.id ? "#F1F8FF" : "inherit",
                  width: "100%",
                }}
              >
                <ListItemText
                  primary={item.name}
                  primaryTypographyProps={{
                    color: langId === item.id ? "#0A66C2" : "inherit",
                  }}
                  className="text-wrap w-full"
                />
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </div>
  );
};

export default memo(LanguageSelector);
