import React, {useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {Typography} from "@mui/material";
import cookies from "react-cookies";
import styles from "./Interview.module.scss";
import {BehaviorResponses} from "./BehaviorResponses";
import Question, {Click_Direction} from "./Question";
import {Steps} from "./Steps";

import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import Loader from "components/Loader";
import {Row} from "components/Row";
import {BehaviourSetComplete} from "components/Popups/BehaviourSetComplete";
import {CountDownTimer} from "components/Timer";

import {
  InterviewQuestion,
  InterviewQuestionOption,
  QuestionOption,
} from "globals/types/globalTypes";
import {RouteLinks} from "globals/constants/routeLinks";
import {COOKIESKEYS} from "globals/constants/cookiesKeys";
import {AppColors} from "globals/appcolors";

import {useUpdateAssessment} from "hooks/interview/useUpdateAssessment";
import {useUpdateBehaviourResponse} from "hooks/interview/useUpdateBehaviourResponse";
import {useFetchBehaviorSession} from "hooks/interview/useFetchBehaviorSession";
import {useBehaviorQuestions} from "hooks/assessment/useBehaviorQuestions";
import {useFetchAssessmentDetails} from "hooks/interview/useFetchAssessmentDetails";

export const BehaviorQuestions: React.FC = () => {
  const assessmentName = localStorage.getItem("AssesmentName");

  const {assessmentId, id} = useParams();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();

  const duration = queryParams.get("duration");

  const {refetch: getBehaviorQuestions} = useBehaviorQuestions(assessmentId!);
  const {mutateAsync: updateAnswer} = useUpdateBehaviourResponse(assessmentId!);
  const {refetch: getBehaviorSession} = useFetchBehaviorSession(assessmentId!);
  const {mutateAsync: updateAssessment} = useUpdateAssessment(assessmentId!);
  const {refetch: getAssessmentDetails} = useFetchAssessmentDetails(
    assessmentId!
  );

  const {loading, interview_details, setLoading, setInterview_details} =
    useAppCommonDataProvider();

  const [behaviourQuestions, setBehaviourQuestions] = useState<
    InterviewQuestion[]
  >([]);
  const [currentQuestion, setCurrentQuestion] = useState<{
    index: number;
    question: InterviewQuestion;
  } | null>(null);
  const [answers, setAnswers] = useState<QuestionOption[]>([]);
  const [uniqueResponses, setUniqueResponses] = useState<number[]>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [isBehaviourSetComplete, setIsBehaviourSetComplete] =
    useState<boolean>(false);

  useEffect(() => {
    fetchBehaviorQuestions();
    fetchSavedResponses();
    fetchAssessmentDetails();
  }, []);

  useEffect(() => {
    let count: number[] = [];
    if (answers.length > 0) {
      answers.forEach((answer, index) => {
        if (count.includes(answer.question_id) === false)
          count.push(answer.question_id);
        if (
          answers[index + 1] === undefined &&
          count.includes(answer.question_id) === false
        )
          count.push(answer.question_id);
        if (
          answers[index + 1] !== undefined &&
          answer.question_id === answers[index + 1].question_id &&
          count.includes(answer.question_id) === false
        )
          count.push(answer.question_id);
      });
      setUniqueResponses(count);
    }
  }, [answers]);

  function fetchAssessmentDetails() {
    getAssessmentDetails().then((res) => {
      if (res.isError === false) {
        if (res.data !== undefined) {
          setInterview_details?.(res.data);
          const {assessment} = res.data.data;
          const {status} = assessment;
          if (status === 12) {
            setIsBehaviourSetComplete(true);
          }
        }
      }
    });
  }

  function fetchBehaviorQuestions() {
    setLoading?.(true);
    getBehaviorQuestions().then((res) => {
      if (res.isError === false) {
        if (res.data !== undefined) {
          setBehaviourQuestions(
            res.data.data.assessment.assessment_behaviour_questions
          );
          setCurrentQuestion({
            index: 0,
            question:
              res.data.data.assessment.assessment_behaviour_questions[0],
          });
          setLoading?.(false);
        }
      }
    });
  }

  function changeAnswers(
    options: QuestionOption[] | undefined,
    direction: Click_Direction
  ) {
    if (options !== undefined) {
      if (options.length > 0) {
        let tempAnswers = [...answers];
        const answerExists = tempAnswers.filter(
          (answer) => answer.question_id === options[0].question_id
        );

        if (answerExists.length === 0) {
          options.forEach((option) => tempAnswers.push(option));
          setAnswers(tempAnswers);
        } else {
          tempAnswers = tempAnswers.filter(
            (answer) => answer.question_id !== options[0].question_id
          );
          options.forEach((option) => tempAnswers.push(option));
          setAnswers(tempAnswers);
        }

        if (direction === "proceed") {
          currentQuestion?.index! + 1 < behaviourQuestions.length! &&
            setCurrentQuestion({
              index: currentQuestion!.index + 1,
              question: behaviourQuestions[currentQuestion?.index! + 1]!,
            });
          syncAnswer(options);
        }
      } else {
        // const { assessment_questions } = interviewQuestions!.data.assessment;
        // currentQuestion?.index! + 1 < assessment_questions.length! &&
        //   setCurrentQuestion({
        //     index: currentQuestion?.index! + 1,
        //     question: assessment_questions[currentQuestion?.index! + 1]!,
        //   });
      }
    }
  }

  function syncAnswer(options: InterviewQuestionOption[]) {
    updateAnswer({
      questionId: options[0].question_id,
      payload: {
        option_id: options[0].id.toString(),
      },
    });
  }

  function clearSelection(question: InterviewQuestion | undefined) {
    if (question !== undefined) {
      const responses = [...answers].filter(
        (response) => response.question_id !== question.question_id
      );
      setAnswers(responses);
    }
  }

  function fetchSavedResponses() {
    getBehaviorSession().then((res) => {
      if (res.isError === false) {
        if (res.data !== undefined) {
          setAnswers(res.data.data.data[0].responses);
        }
      }
    });
  }

  function proceed() {
    setSubmitting(true);
    updateAssessment({status: 12}).then((res) => {
      cookies.save(
        COOKIESKEYS.DURATION,
        res!.data.data.assesment.assessment_duration,
        {path: "/"}
      );
      setSubmitting(false);
      navigate(
        `/${RouteLinks.Interview}/${id}/${assessmentId}/introduction?duration=${duration}`
      );
    });
  }

  if (isBehaviourSetComplete === true)
    return (
      <BehaviourSetComplete
        open={isBehaviourSetComplete}
        onClose={() => {}}
        id={id!}
        assessmentId={assessmentId!}
        duration={duration!}
      />
    );
  else if (loading === true)
    return (
      <>
        <Loader />
        {submitting === true && (
          <Typography
            className="text-xl font-medium text-center"
            sx={{
              marginTop: "-35vh",
              fontWeight: "bold",
              fontSize: 20,
              color: AppColors.PRIMARYCOLOR,
            }}
          >
            Submitting
          </Typography>
        )}
      </>
    );
  else
    return (
      <div>
        (
        <div className={styles.container} style={{background: AppColors.WHITE}}>
          <header className={`${styles.behavior__header}`}>
            <Row
              style={{background: AppColors.REPORTCARD_SECONDARY}}
              classNames="w-full justify-between px-8 py-4"
            >
              <Typography
                className="font-bold"
                variant="h4"
                fontWeight={"bold"}
                color={AppColors.WHITE}
              >
                Sourcebae
              </Typography>
              <Row>
                <Typography
                  variant="body2"
                  color={AppColors.WHITE}
                  sx={{fontWeight: "bold"}}
                >
                  Behaviour Test:
                </Typography>
                {submitting === false && (
                  <CountDownTimer
                    time={600}
                    sx={{
                      color: AppColors.WHITE,
                      fontWeight: "bold",
                      marginLeft: 1,
                    }}
                    variant="body2"
                    onStopTimer={proceed}
                  />
                )}
              </Row>
            </Row>
            <section className="w-full px-8 py-4 bg-[#fafafc]">
              <Steps currentStep={1} interviewDetails={interview_details} />
            </section>
          </header>

          <section className={styles.behaviour__questionContainer}>
            <div className={styles.behavior__questionWrapper}>
              <div className={styles.behavior__questionInnerWrapper}>
                <section
                  className={`${styles.question__scrollableArea} disable-scrollbar`}
                >
                  <div className={styles.question__scrollableAreaTopStrip}>
                    <Typography className={styles.question__number}>
                      Question #{currentQuestion?.index! + 1}
                    </Typography>
                    <div className={styles.assessment__nameContainer}>
                      <Typography className="font-bold capitalize truncate">
                        {assessmentName}
                      </Typography>
                    </div>
                  </div>
                  {currentQuestion !== null && (
                    <Question
                      question={currentQuestion?.question}
                      isAtLastQuestion={
                        currentQuestion?.index === behaviourQuestions.length - 1
                      }
                      onChangeAnswer={changeAnswers}
                      answer={answers}
                      clearSelection={clearSelection}
                    />
                  )}
                </section>

                <section
                  className={styles.behavior__responsesOverviewContainer}
                >
                  <BehaviorResponses
                    questions={behaviourQuestions}
                    answers={answers}
                    uniqueResponses={uniqueResponses}
                    setCurrentQuestion={setCurrentQuestion}
                    proceed={proceed}
                  />
                </section>
              </div>
            </div>
          </section>
        </div>
        )
      </div>
    );
};
