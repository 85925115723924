import axios from "axios";
import {staticHeaders} from "globals/helpers";
import {errorHandler} from "network/ResponseHandlers/errorHandler";
import {successHandler} from "network/ResponseHandlers/successHandler";

export const axiosPatchModule = async (
  url: string,
  body: object,
  params?: object,
  headers: {[key: string]: string} = staticHeaders()
) => {
  return await axios
    .patch(url, body, {headers, params})
    .then((res) => successHandler(res.data))
    .catch((err) => errorHandler(err));
};
