import {memo} from "react";

const SystemChat = ({chat}: {chat: string}) => {
  return (
    <div className="bg-[#f5f6fa] ml-4 max-w-70 py-3 px-4 rounded-lg text-sm font-bold">
      {chat}
    </div>
  );
};

export default memo(SystemChat);
