import React, {CSSProperties, useEffect, useState} from "react";
import CustomSelect from "components/Inputs/Select/CustomSelect";
import ViewButton from "components/Containers/Button/Button/ViewButton";
import CustomButton from "components/Button/CustomButton";
import SizedBox from "components/SizedBox/SizedBox";
import strings from "globals/constants/strings.json";
import {
  AssessmentSkill,
  CreateAssessmentTemplate,
  SelectOption,
  UserDetailsCookie,
} from "globals/types/globalTypes";
import {useNavigate} from "react-router-dom";
import {RouteLinks} from "globals/constants/routeLinks";
import {APIEndPoints} from "globals/constants/endPoints";
import axiosGetModule from "network/Get/AxiosGetModule";
import axiosPostModule from "network/Post/AxiosPostModule";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import Loader from "components/Loader";
import {toast} from "react-toastify";
import {postGoogleAnalyticsEvent} from "globals/helpers";

const CreateAssessmentTest: React.FC = () => {
  const navigate = useNavigate();
  const {loading, setLoading} = useAppCommonDataProvider();
  const eventTracker = postGoogleAnalyticsEvent("Assessment Template");
  let userData: UserDetailsCookie = JSON.parse(
    localStorage.getItem("USER_DETAILS")!
  );

  const customStyles = {
    control: (base: CSSProperties) => ({
      ...base,
      background: "#FAFAFB",
      border: "1px solid #E1E1E1",
      boxSizing: "border-box",
      fontSize: "0.75rem",
      borderRadius: "6px",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #E1E1E1",
      },
    }),
    container: (provided: CSSProperties) => ({
      ...provided,
      outline: "none",
      fontSize: "0.75rem",
      borderRadius: "6px",
      background: "#ef5455",
    }),
    option: (provided: CSSProperties, state: any) => ({
      ...provided,
    }),
  };

  const [assessmentName, setAssessmentName] = useState<string>("");
  const [skills, setSkills] = useState<AssessmentSkill[]>([]);
  const [selectedSkills, setSelectedSkills] = useState<SelectOption[] | []>([]);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    retrieveSkills();
  }, []);

  const retrieveSkills = async () => {
    setLoading?.(true);
    try {
      await axiosGetModule(APIEndPoints.GET_SKILLS_NODE).then(
        (res: {data: AssessmentSkill[]}) => {
          const assessmentSkills = [...res.data].map((skill) => ({
            ...skill,
            label: skill.skill,
            value: skill.id,
          }));
          setSkills(assessmentSkills);
        }
      );
    } catch (e) {
      console.log("error fetching skills", e);
    } finally {
      setLoading?.(false);
    }
  };

  const createTemplate = async () => {
    if (assessmentName.length < 5) {
      setErrors([...errors, "name"]);
      return;
    }
    if (selectedSkills.length <= 0) {
      setErrors([...errors, "skill"]);
      return;
    }
    setLoading?.(true);
    const body: CreateAssessmentTemplate = {
      organization_id: userData.user.teams[0].id,
      name: assessmentName,
      skill_ids: [...selectedSkills.map((skill) => skill.value)],
    };
    try {
      axiosPostModule(APIEndPoints.CREATE_ASSESSMENT_TEMPLATE_NODE, body)
        .then((res) => {
          setTimeout(() => {
            if (res.data.id) {
              navigate(`/${RouteLinks.Assessment_Test}`);
              eventTracker(
                "Assessment Template Created",
                "New Template Created"
              );
            }
          }, 500);
        })
        .catch((err) => {
          const keys = Object.keys(err.data.errors);
          const error = err.data.errors[keys[0]];
          toast.error(error[0]);
        });
    } catch (e) {
      console.log(e);
    } finally {
      setTimeout(() => setLoading?.(false), 500);
    }
  };

  const handleSkillsChange = (newElements: SelectOption[]) => {
    (selectedSkills.length < 6 || newElements.length < selectedSkills.length) &&
      setSelectedSkills(newElements);
  };

  const clearFilters = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSelectedSkills([]);
    setAssessmentName("");
  };

  if (loading) return <Loader />;
  else
    return (
      <div
        className="flex p-4 bg-white-900 flex-col my-8 rounded-lg overflow-hidden"
        style={{height: "90vh"}}
      >
        <h1 className="text-lightBlack-500 font-bold text-2xl">
          Create New Assessment Test
        </h1>
        <div className="flex flex-col w-50">
          <div className="flex flex-col my-6 ">
            <label
              className="text-lightBlack-300 font-semibold text-sm"
              htmlFor=""
            >
              {strings.create_assessment_test.assessment_name}
            </label>
            <SizedBox height="8px" />
            <input
              type="text"
              className="w-100 border px-2 py-1 bg-white-800 rounded-md border-1 border-solid border-white-200 text-xs outline-none h-9.5"
              value={assessmentName}
              onChange={(e) => {
                setAssessmentName(e.target.value);
                setErrors([]);
              }}
              placeholder="Type here"
              style={{minHeight: "38px"}}
            />
            {errors.includes("name") && (
              <p className="text-red-500 text-xs font-semibold mt-1">
                Template length should be more than 5 characters
              </p>
            )}
            <SizedBox height="8px" />
          </div>
          <div className="flex flex-col my-1 ">
            <label className="text-lightBlack-300 font-semibold text-sm mb-2">
              {strings.skills}
            </label>
            <CustomSelect
              options={skills}
              onChange={handleSkillsChange}
              defaultValue={""}
              value={selectedSkills}
              isAnimated
              customStyles={customStyles}
              isMulti
            />
          </div>
          {errors.includes("skill") && (
            <p className="text-red-500 text-xs font-semibold mt-1">
              Skills cannot be empty
            </p>
          )}
          <div className="flex my-8 justify-between">
            <div className="flex">
              <ViewButton
                name={`${strings.clear} All`}
                style={{padding: " 0px 20px"}}
                onClick={clearFilters}
              />
            </div>
            <div className="flex">
              <ViewButton
                name={strings.cancel}
                style={{padding: " 0px 20px"}}
                onClick={() => navigate(-1)}
              />
              <CustomButton
                name={strings.create}
                buttonStyle={{padding: "6px 20px", margin: "0 0 0 1rem"}}
                onclick={createTemplate}
              />
            </div>
          </div>
        </div>
      </div>
    );
};

export default CreateAssessmentTest;
