import React, {useState, useEffect} from "react";
import Modal from "react-responsive-modal";
import strings from "globals/constants/strings.json";
import ViewButton from "components/Containers/Button/Button/ViewButton";
import SizedBox from "components/SizedBox/SizedBox";
import CreateQuesCard from "./CreateQuesCard";
import {CsvQuestionObjType} from "./types";

interface Props {
  open: boolean;
  heading?: string;
  description?: string;
  onNo?: () => void;
  // onYes?: (argument?: any) => any;
  onCloseModal: () => void;
  showButtons?: boolean;
  buttonTitles?: {yes: string; no: string};
  readTextFromCsv: Function;
  prequestions: any;
}

const CreateQuestionModal: React.FC<Props> = ({
  open,
  onCloseModal,
  readTextFromCsv,
  onNo,
  buttonTitles,
  prequestions,
  showButtons = true,
}) => {
  let data = prequestions;
  const [filteredQues, setfilteredQues] = useState<any>([data]);
  const [diff, setdiff] = useState<{
    show: boolean;
    value?: string;
  }>({show: false});

  let buttonStyles = {width: "100px", height: "38px"};
  let clickfunction = () => {
    readTextFromCsv();
  };

  let filterDifficulty = (level: string) => {
    // data = data.filter((x: any) => x.question_level === level)
    setdiff({show: true, value: level});
  };

  let bluebutton =
    "border border-primary focus:outline focus:ring-4 focus:ring-gray-200 font-medium rounded-full text-md px-4 py-1.5 mr-2 mb-2 ";

  // dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700'
  return (
    <Modal
      center
      open={open}
      showCloseIcon={false}
      onClose={onCloseModal}
      styles={{modal: {borderRadius: 10, width: "90%"}}}
    >
      <div className="flex flex-col justify-center ">
        <div className="flex">
          <div
            className={`${bluebutton} ${
              diff.value == "1"
                ? "bg-primary text-white-900"
                : "bg-white-900 text-primary"
            }`}
            onClick={() => filterDifficulty("1")}
          >
            Simple
          </div>
          <div
            className={`${bluebutton} ${
              diff.value == "2"
                ? "bg-primary text-white-900"
                : "bg-white-900 text-primary"
            }`}
            onClick={() => filterDifficulty("2")}
          >
            Medium
          </div>
          <div
            className={`${bluebutton} ${
              diff.value == "3"
                ? "bg-primary text-white-900"
                : "bg-white-900 text-primary"
            }`}
            onClick={() => filterDifficulty("3")}
          >
            Hard
          </div>
        </div>

        {data.length >= 1 ? (
          diff.show ? (
            data
              .filter((x: any) => x.question_level === diff.value)
              .map((ele: any) => (
                <div key={ele.id} className="pb-6 px-6">
                  <CreateQuesCard mapdata={ele} />
                </div>
              ))
          ) : (
            data.map((ele: any) => (
              <div key={ele.Question} className="pb-6 px-6">
                <CreateQuesCard mapdata={ele} />
              </div>
            ))
          )
        ) : (
          <h4>No Questions uploaded</h4>
        )}

        {/* <CreateQuesCard data={prequestions} /> */}
        {showButtons && (
          <div className="flex justify-evenly w-full">
            <ViewButton
              name="Discard"
              style={buttonStyles}
              onClick={onNo ? onNo : onCloseModal}
            />
            <ViewButton
              name="Upload"
              invert
              style={{height: "38px", minWidth: "100px"}}
              onClick={clickfunction}
            />
          </div>
        )}
        <SizedBox height="10px" />
      </div>
    </Modal>
  );
};

export default CreateQuestionModal;
