import CandidateDetails from "components/CandidateDetails";
import strings from "globals/constants/strings.json";
import {
  CandidateDetails_NewAssessment,
  NewCandidateFields,
} from "globals/types/globalTypes";
import React, {useState} from "react";

const ReuseAssessment: React.FC = () => {
  const [candidateDetails, setCandidateDetails] =
    useState<CandidateDetails_NewAssessment>({
      firstName: "",
      lastName: "",
      email: "",
      experience: 1,
      source: "",
      resume: undefined,
    });

  const handleCandidateDetailsFormChanges = (
    field: NewCandidateFields,
    value: string | number | any
  ) => {
    const values = {...candidateDetails};
    if (field === "firstName") values.firstName = value.toString();
    if (field === "lastName") values.lastName = value.toString();
    if (field === "email") values.email = value.toString();
    if (field === "experience") values.experience = parseInt(value.toString());
    if (field === "source") values.source = value.toString();
    if (field === "resume") values.resume = value;
    setCandidateDetails(values);
  };
  return (
    <div>
      <div className="bg-white-900 my-4 px-4 py-8 rounded">
        <CandidateDetails
          title={strings.reuse_template.title}
          values={candidateDetails}
          handleFieldChanges={handleCandidateDetailsFormChanges}
          assessmentType="regular"
        />
      </div>
    </div>
  );
};

export default ReuseAssessment;
