import React from "react";
import strings from "globals/constants/strings.json";
import {useNavigate} from "react-router-dom";

interface TopTabsProps {
  selectedTab: number;
  onTabsClick: (num: number) => void;
}

const TableTopTabs: React.FC<TopTabsProps> = (props) => {
  const navigate = useNavigate();
  return (
    <div className="flex cursor-pointer">
      <Tab
        selectedTab={props.selectedTab === 0}
        onClick={() => props.onTabsClick(0)}
        label={strings.dashboard.pending_assessments}
      />
      <Tab
        selectedTab={props.selectedTab === 1}
        onClick={() => props.onTabsClick(1)}
        label={strings.dashboard.currentTable}
      />
      <Tab
        selectedTab={props.selectedTab === 2}
        // onClick={() => props.onTabsClick(2)}
        onClick={() => navigate("/all-interviews")}
        label={strings.dashboard.allInterviews}
      />
    </div>
  );
};

interface TabProps {
  label: string;
  selectedTab: boolean;
  onClick: () => void;
}

const Tab: React.FC<TabProps> = (props) => (
  <div
    onClick={props.onClick}
    className={`justify-center flex pb-2 border-b-2 ${
      props.selectedTab ? "border-primary" : "border-monoGrey-400"
    } mr-8`}
  >
    <span
      className={`font-semibold text-xl capitalize ${
        props.selectedTab ? "text-primary" : "text-monoGrey-400"
      } `}
    >
      {props.label}
    </span>
  </div>
);

export default TableTopTabs;
