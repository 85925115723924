import React, {useState} from "react";
import cookies from "react-cookies";
import {toast} from "react-toastify";
import {Bounce} from "react-activity";
import styles from "./Profile.module.scss";

import ViewButton from "components/Containers/Button/Button/ViewButton";
import {Eye, EyeHidden} from "components/Icons";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";

import strings from "globals/constants/strings.json";
import {COOKIESKEYS} from "globals/constants/cookiesKeys";
import {APIEndPoints} from "globals/constants/endPoints";

import {Images} from "Images";
import {axiosPatchModule} from "network/Patch/AxiosPatchModule";

const Profile: React.FC = () => {
  const userDetails = JSON.parse(localStorage.getItem("USER_DETAILS")!);
  const {loading, setLoading} = useAppCommonDataProvider();

  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [passwordsVisibility, setPasswordsVisibility] = useState<{
    newPassword: boolean;
  }>({
    newPassword: true,
  });

  const updatePassword = async (e: React.MouseEvent<HTMLButtonElement>) => {
    setLoading?.(true);
    const request = {
      token: currentPassword,
      password: password,
      password_confirmation: confirmPassword,
    };
    await axiosPatchModule(
      `${APIEndPoints.RESET_PASSWORD}?user=${userDetails.user.id}`,
      request
    )
      .then((res: {message: string}) => {
        toast.success(res.message);
        setCurrentPassword("");
        setPassword("");
        setConfirmPassword("");
      })
      .catch((err) => {
        const {status} = err;
        if (status === 400) toast.error(err.data.message);
        if (status === 422) {
          const error_keys = Object.keys(err.data.errors);
          const error = err.data.errors[error_keys[0]];
          toast.error(error[0]);
        }
      })
      .finally(() => setTimeout(() => setLoading?.(false), 700));
  };

  return (
    <div className={styles.container}>
      <div className="w-95%">
        <p className="font-bold text-xl">{strings.profile.title}</p>
        <div className="flex mt-12">
          <div className="flex-0.6">
            <p className="font-bold text-base">{strings.username_title}</p>
            <input
              value={
                userDetails &&
                `${userDetails.user.first_name} ${userDetails.user.last_name}`
              }
              className={styles.input}
              readOnly
            />

            <p className={styles.input__label}>{strings.email}</p>
            <input
              value={userDetails && `${userDetails.user.email}`}
              className={styles.input}
              readOnly
            />

            <p className={styles.input__label}>{`Old ${strings.password}`}</p>
            <div className="relative">
              <input
                value={currentPassword}
                className={styles.input}
                onChange={(e) => setCurrentPassword(e.target.value)}
                type={"password"}
                placeholder="Enter current password"
              />
            </div>

            <p className={styles.input__label}>{strings.password}</p>
            <div className="relative">
              <input
                value={password}
                className={styles.input}
                onChange={(e) => setPassword(e.target.value)}
                type={passwordsVisibility.newPassword ? "text" : "password"}
                placeholder="Enter new password"
              />
              {!passwordsVisibility.newPassword ? (
                <div
                  onClick={() =>
                    setPasswordsVisibility({
                      ...passwordsVisibility,
                      newPassword: !passwordsVisibility.newPassword,
                    })
                  }
                  className="hover:cursor-pointer flex items-center absolute top-4"
                  style={{right: "12%"}}
                >
                  <Eye size={23} />
                </div>
              ) : (
                <div
                  onClick={() =>
                    setPasswordsVisibility({
                      ...passwordsVisibility,
                      newPassword: !passwordsVisibility.newPassword,
                    })
                  }
                  className="hover:cursor-pointer flex items-center absolute top-4"
                  style={{right: "12%"}}
                >
                  <EyeHidden size={23} />
                </div>
              )}
            </div>

            <p className={styles.input__label}>{strings.confirm_password}</p>
            <input
              value={confirmPassword}
              className={styles.input}
              onChange={(e) => setConfirmPassword(e.target.value)}
              type={passwordsVisibility.newPassword ? "text" : "password"}
              placeholder="Confirm password"
            />
          </div>
          <div className="flex-0.4 flex justify-center">
            <img
              src={Images.ProfilePlaceholder}
              className={styles.placeholder__image}
            />
          </div>
        </div>

        <div className="mt-8">
          <ViewButton
            name={loading ? <Bounce /> : strings.save_and_update}
            style={{height: "40px", width: "20%"}}
            onClick={updatePassword}
            isDisabled={loading ? true : false}
          />
        </div>
      </div>
    </div>
  );
};

export default Profile;
