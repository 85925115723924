import {SourcebaeFAQ} from "@sourcebae/components";
import {useState} from "react";

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(0);

  return <SourcebaeFAQ openIndex={openIndex} setOpenIndex={setOpenIndex} />;
  return <></>;
};

export default FAQ;
