import {Images} from "../../assets/Image";
import CompanyTicker from "../CompanyTicker";
import ContactForm from "../ContactForm";
import styles from "./style.module.css";

const Hero = () => {
  return (
    <div className="relative">
      <div className={styles.main_container}>
        <img className={styles.background} src={Images.assessmentPageBg} />
        <div className=" flex justify-start w-full ">
          <div className={styles.wrapper}>
            <div className={styles.content_wrapper}>
              <button className={styles.btn}>Launching Soon</button>
              <h1 className={styles.heading}>
                <span>Cut Time,</span>
                <span>Not Corners.</span>
              </h1>
              <p className={styles.desc}>
                Sourcebae’s AI automates candidate screening and ranking, making
                your hiring process faster and more efficient.
              </p>
            </div>
            <ContactForm otherStyles="mt-20 1750:mt-[100px]" />
          </div>
        </div>
      </div>
      <div className="absolute px-[80px] xxl:px-[100px] w-[90%] bottom-[-60px] xxl:bottom-[-87px]  1750:bottom-[-83px]">
        <CompanyTicker />
      </div>
    </div>
  );
};

export default Hero;
