import {
  AssessmentData,
  AssessmentMeta,
  AssessmentSkill,
  AssessmentTemplateData,
  CandidateData,
  CandidateDetailsData,
  CandidateDetailsScreenshots,
  CandidateExperience,
  CandidateProject,
  CandidateResumeData,
  CreateQuestionData,
  GetAllQuestionData,
  GetAllQuestionDataNode,
  InterviewAssessmentDetails,
  InterviewQuestion,
  IntrodutionVideo,
  PaginationLinks,
  QuestionOption,
  SkillsData,
} from "./globalTypes";

export interface CommonResponseFields {
  code: number;
  success: boolean;
  message: string;
}

export interface LoginResponse {
  data: {
    access_token: string;
    accessToken: string;
    organizationId: number;
    roleId: number;
    teamId?: number;
    user: {
      email: string;
      first_name: string;
      last_name: string;
      id: number;
      is_user_verified: number | null;
      role_id: number;
      roles: Role[];
    };
  };
}

interface Role {
  Role: string;
  id: number;
}

export interface AssessmentResponse {
  data: AssessmentData[];
  links: PaginationLinks;
  meta: AssessmentMeta;
}
export interface AssessmentsResponse {
  data: {
    assesments: AssessmentData[];
    links: PaginationLinks;
    meta: AssessmentMeta;
  };
}

export interface AssessmentTemplateResponse {
  data: AssessmentTemplateData[];
  links: PaginationLinks;
  meta: AssessmentMeta;
}

export interface GetAllQuestionsResponse {
  data: GetAllQuestionDataNode[];
  links: PaginationLinks;
  meta: AssessmentMeta;
}

export interface GetAllQuestionsResponseWithMeta {
  data: {
    data: GetAllQuestionDataNode[];
    links: PaginationLinks;
    meta: AssessmentMeta;
  };
}

export interface SkillsResponse {
  data: SkillsData[];
  links: PaginationLinks;
  meta: AssessmentMeta;
}

export interface DetailedSkillsResponse {
  data: SkillsData[];
}

export interface CreateQuestionResponse {
  data: CreateQuestionData;
}

export interface CreateAssessmentResponse {
  data: {
    code: number;
    success: boolean;
    message: string;
    data: any;
  };
}
export interface CreateAssessmentTemplateResponse {
  created_at: string;
  created_by: number;
  deleted_at: string | null | undefined;
  id: number;
  name: string;
  organization: {
    id: number;
    name: string;
    logo: URL | string;
  };
  organization_id: number;
  skills: AssessmentSkill[];
  updated_at: string;
  updated_by: number;
}

export interface DeleteAssessmentTestResponse {
  data: {
    id: number;
    organization_id: number;
    name: string;
    created_by: number;
    updated_by: number;
    created_at: string;
    updated_at: string;
    deleted_at: string;
    organization: {
      id: number;
      name: string;
      logo: URL | string;
    };
    skills: AssessmentSkill[];
  };
}

export interface DeleteAssessmentResponse {
  data: {
    id: number;
    organization_id: number;
    assessment_template_id: number;
    candidate_id: number;
    name: string;
    scheduled_date: string;
  };
}

export interface InterviewInstructionsResponse {
  data: {
    canStart: boolean;
    canReschedule: boolean;
    assessment: InterviewAssessmentDetails;
    instructions: string;
    // jump_to_question_index: number;
    shouldRecordIntro: boolean;
  };
}

export interface InterviewQuestionsResponse {
  data: {
    assessmentScores: Array<{
      points_scored: number | null;
      name: string;
      totalPoints: string;
    }>;
    assessment: {
      assessment_duration: number;
      assessment_level: number;
      assessment_points_scored: number;
      assessment_questions: InterviewQuestion[];
      assessment_template: {
        id: number;
        name: string;
      };
      assessment_total_points: string;
      id: number;
      ip_address: string;
      name: string;
      number_of_questions: number;
      scheduled_date: string;
      status: number;
      time_remaining: number;
      time_taken: number | null;
      total_tab_switches: number | null;
    };
  };
}

export interface CandidateResponse {
  data: {
    data: CandidateData[];
    meta: {
      current_page: number;
      from: number;
      last_page: number;
      per_page: number;
      to: number;
      total: number;
    };
  };
  links: PaginationLinks;
  meta: AssessmentMeta;
}

export interface CandidateDetailsResponse {
  data: CandidateDetailsData;
  code: number;
  success: boolean;
}

export interface IntroductionVideoResponse {
  data: IntrodutionVideo;
  success: boolean;
}

export interface CandidateDetails {
  data: {
    assessmentScores: Array<{
      points_scored: string | number | null;
      name: string;
      totalPoints: string;
      id: number;
    }>;
    assessment: {
      ai_assessment_id: string;
      assessment_duration: number;
      assessment_level: number;
      assessment_points_scored: string;
      assessment_questions: InterviewQuestion[];
      assessment_template_id: number;
      assessment_total_points: string;
      assessment_url: string;
      assessment_template: {name: string};
      candidate_id: number;
      created_at: string;
      created_by: number;
      deleted_at: string | null;
      experience_duration: string;
      hold_reason: any;
      id: number;
      ip_address: string;
      ip_change_count: string;
      include_organization_questions: number;
      location: string;
      name: string;
      number_of_questions: number;
      organization_id: number;
      candidate: {
        communication_points_scored:
          | "poor"
          | "average"
          | "good"
          | "excellent"
          | null;
        user: {
          first_name: string;
          last_name: string;
          email: string;
        };
      };
      scheduled_date: string;
      screenshots: CandidateDetailsScreenshots[];
      status: number;
      total_tab_switches: null | number;
      updated_at: string;
      updated_by: number;
    };
  };
}

export interface CandidateReport {
  data: {
    assessment_duration: number;
    assessment_level: number;
    assessment_points_scored: string;
    assessment_template_id: number;
    assessment_total_points: string;
    assessment_url: string;
    candidate: {
      experience_level: number;
      id: number;
      user: {
        first_name: string;
        last_name: string;
      };
    };
    candidate_id: number;
    created_at: string;
    created_by: number;
    experience_duration: string;
    experiences: CandidateExperience[];
    id: number;
    include_organization_questions: number;
    name: string;
    projects: CandidateProject[];
    scheduled_date: string;
    screenshots: CandidateDetailsScreenshots[];
    skills: AssessmentSkill[];
    status: number;
    template: {
      created_at: string;
      name: string;
    };
    time_taken: string | null;
  };
}

export interface ExpiredAssessMentResue {
  code: number;
  message: string;
}

export interface CreateUserResponse {
  code: number;
  message: string;
}

export interface Roles {
  id: number;
  role: string;
}

export interface MetaData {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number;
}

export interface UserData {
  email: string;
  first_name: string;
  id: number;
  isActive: number;
  last_name: string;
  roles: Roles[];
}

export interface GetUserResponse {
  data: {
    data: UserData[];
    meta: MetaData;
  };
}

export interface GetRolesResponse {
  data: Roles[];
}

export interface updateAPIResponse {
  code: number;
  message: string;
  success: boolean;
}

export interface JWTList {
  code: number;
  success: boolean;
  data: {
    attempts: number;
    jwt: string;
  };
}

export interface Session extends CommonResponseFields {
  data: Array<{time_remaining: null | number; responses: QuestionOption[]}>;
}

export interface BehaviorQuestions {
  code: number;
  success: boolean;
  data: {
    assessment: {
      assessment_behaviour_questions: InterviewQuestion[];
    };
  };
}

export interface BehaviorSession {
  code: number;
  success: boolean;
  data: [{responses: QuestionOption[]}];
}

export interface ResumeData {
  code: number;
  success: boolean;
  data: {
    assessment: CandidateResumeData;
  };
}

export interface ParseResume {
  data: {
    dateOfBirth: string;
    phoneNumbers: Array<string>;
    languages: Array<string>;
    skills: Array<string>;
    education: Array<{
      // completion_date: string;
      course: string;
      id: number;
      organization: string;
      dates: {
        completionDate: string;
        startDate: string;
      };
      accreditation: {
        education: string;
      };
    }>;
    workExperience: Array<{
      jobTitle: string;
      jobDescription: string;
      organization: string;
      designation: string;
      dates: {
        endDate: string;
        startDate: string;
      };
      experience_description: string;
      id: number;
    }>;
  };
}

export const response: InterviewQuestionsResponse = {
  data: {
    assessmentScores: [
      {
        points_scored: 1,
        name: "Test",
        totalPoints: "10",
      },
    ],
    assessment: {
      assessment_duration: 10000,
      assessment_level: 2,
      assessment_points_scored: 1,
      assessment_questions: [
        {
          assessment_id: 2,
          created_at: new Date(),
          created_by: 1,
          deletedAt: null,
          id: 100,
          question: {
            id: "10",
            is_always_included: 1,
            is_paid_question: 0,
            question: "test question",
            question_duration: 10,
            question_level: 1,
            question_options: [
              {
                id: 1,
                is_correct_option: 1,
                option: "option 1",
                question_id: 10,
              },
            ],
            question_type: 1,
            skill_id: 4,
          },
          question_id: 5,
          skill: {id: 5, skill: "react"},
          status: 4,
          updated_at: new Date(),
        },
      ],
      assessment_template: {
        id: 5,
        name: "test",
      },
      assessment_total_points: "10",
      id: 2,
      ip_address: "",
      name: "",
      number_of_questions: 50,
      scheduled_date: "",
      status: 2,
      time_remaining: 6000,
      time_taken: 4000,
      total_tab_switches: 0,
    },
  },
};
