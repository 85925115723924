import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import Buttons from "components/Containers/Button/Button/Buttons";
import ViewButton from "components/Containers/Button/Button/ViewButton";
import CustomSelect from "components/Inputs/Select/CustomSelect";
import Loader from "components/Loader";
import Datepicker from "components/Pickers/DatePicker/DatePicker";
import QuestionCard from "components/QuestionCard/QuestionCard";
import SizedBox from "components/SizedBox/SizedBox";
import {AppColors} from "globals/appcolors";
import {difficultyOptions} from "globals/constants/dropdownConstants";
import {APIEndPoints} from "globals/constants/endPoints";
import {QuestionLinks} from "globals/constants/routeLinks";
import {sortIdDesc} from "globals/constants/sortingConstants";
import strings from "globals/constants/strings.json";
import {decodeEncodedAPIResponse, formatYYYYMMDD} from "globals/helpers";
import {
  GetAllQuestionsResponseWithMeta,
  SkillsResponse,
} from "globals/types/APIResponses";
import {
  CSS,
  DatePickerBox,
  GetAllQuestionData,
  GetAllQuestionDataNode,
  SkillsData,
} from "globals/types/globalTypes";
import {Images} from "Images";
import moment, {Moment} from "moment";
import axiosDeleteModule from "network/Delete/axiosDeleteModule";
import axiosGetModule from "network/Get/AxiosGetModule";
import React, {CSSProperties, useEffect, useState} from "react";
import {Spinner} from "react-activity";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import TopTabs from "routes/question/TopTabs/TopTabs";

let currentPage: number = 0;

export type filterObjects = {
  field: string;
  operator: string;
  value?: string | Array<number> | Moment;
};
const datePickerInputStyle: CSS = {
  height: 38,
  background: "transparent",
  width: "100%",
  fontSize: "12px",
  outline: "none",
};

const datePickerContainerStyle: DatePickerBox = {
  background: AppColors.WHITE_800,
  paddingX: "10px",
  height: 40,
  borderRadius: "6px",
  border: `1px solid ${AppColors.WHITE_300}`,
};
const Question: React.FC = () => {
  const {
    allQuestions,
    setAllQuestions,
    totalNumberOfQuestions,
    setTotalNumberOfQuestions,
    scrollOffset,
    setScrollOffset,
    loading,
    setLoading,
  } = useAppCommonDataProvider();

  const [selectedSkill, setselectedSkill] = useState<{
    label: string;
    value: number;
  } | null>();
  const [showLoadMore, setshowLoadMore] = useState<boolean>(true);
  const [questions, setquestions] = useState<
    Array<GetAllQuestionDataNode> | []
  >([]);
  const [skills, setskills] = useState<Array<{label: string; value: number}>>();
  const [isFetching, setisFetching] = useState<boolean>(false);
  const [totalQuestions, settotalQuestions] = useState<number>(0);
  const [filterDate, setfilterDate] = useState<{
    show: boolean;
    value?: Moment | null;
  }>({show: false, value: null});
  const [filterDifficulty, setfilterDifficulty] = useState<{
    show: boolean;
    value?: string;
  }>({show: false});
  const [showDeleteConfirmation, setshowDeleteConfirmation] = useState<{
    id?: string | number;
    show: boolean;
  }>({show: false});
  const [filters, setFilters] = useState<filterObjects[]>([]);
  const [clearFilterFlag, setclearFilterFlag] = useState<boolean>(false);
  const [skillsResponse, setSkillsResponse] = useState<SkillsData[]>();
  const [simpleQuestions, setSimpleQuestions] = useState<number>(0);
  const [mediumQuestions, setMediumQuestions] = useState<number>(0);
  const [hardQuestions, setHardQuestions] = useState<number>(0);

  const role: string = localStorage.getItem("Role")!;

  const buttonStyles: CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 45,
    padding: "0 1.5rem",
  };

  useEffect(() => {
    fetchskills();
  }, []);

  useEffect(() => {
    if (scrollOffset)
      window.scrollTo({
        top: scrollOffset,
        behavior: "smooth",
      });
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const fetchPublicQuestions = (
    skillId: string,
    queryParams?: string | null
  ) => {
    return new Promise<GetAllQuestionData[]>((resolve, reject) => {
      axiosGetModule(APIEndPoints.GET_ALL_QUESTIONS_NODE)
        .then((res) => {
          const decryptedData = decodeEncodedAPIResponse(res.data, res);
          return resolve(decryptedData.data);
        })
        .catch((err) => reject([]));
    });
  };

  let clearFilterHandle = () => {
    setfilterDate({...filterDate, show: false, value: null});
    setfilterDifficulty({...filterDifficulty, show: false, value: ""});
    setselectedSkill(null);
    setclearFilterFlag(true);
  };

  const fetchQuestions = async (
    search?: string | number,
    direction?: string
  ) => {
    if (currentPage === 1 && !search) setisFetching(true);
    let params: {
      page?: number;
      question_level?: number;
      skill_id?: number | string;
      created_at?: string;
    } = {};
    let body: {filters: filterObjects[]; sort: [{[key: string]: string}]} = {
      filters: [],
      sort: [sortIdDesc],
    };

    if (currentPage) {
      setLoading?.(true);
      params.page = currentPage;
    }
    if (filterDate.show) {
      params.created_at = formatYYYYMMDD(filterDate.value);
    }
    if (filterDifficulty.show) {
      params.question_level = parseInt(filterDifficulty.value!);
    }
    if (search) {
      if (typeof search === "string") {
        params.skill_id = parseInt(search);
      } else if (typeof search === "number") {
        params.skill_id = search;
      }
    }

    if (direction && filters.length) {
      body.filters = filters;
    } else {
      if (search) {
        if (typeof search === "string") {
          body.filters.push({
            field: "question_level",
            operator: "in",
            value: [parseInt(search)],
          });
        } else if (typeof search === "number") {
          body.filters.push({
            field: "skill_id",
            operator: "in",
            value: [search],
          });
        }
      }
      if (filterDate.show) {
        body.filters.push({
          field: "created_at",
          operator: "=",
          value: formatYYYYMMDD(filterDate.value),
        });
      }
      if (filterDifficulty.show) {
        body.filters.push({
          field: "question_level",
          operator: "=",
          value: filterDifficulty.value,
        });
      }
    }
    setFilters(body.filters);
    body.filters.length && console.log(typeof body.filters[0].value, body);
    const publicQuestionsPromise = fetchPublicQuestions(
      body.filters.length &&
        typeof body.filters[0].value === "object" &&
        body.filters[0].field === "skill_id"
        ? //@ts-ignore
          body.filters[0].value[0]
        : "0",
      body.filters.length &&
        typeof body.filters[0].value === "object" &&
        body.filters[0].field === "question_level"
        ? //@ts-ignore
          `question_level=${body.filters[0].value[0]}`
        : null
    );
    Promise.all([publicQuestionsPromise]).then((publicQuestions) => {
      axiosGetModule(
        APIEndPoints.GET_ALL_QUESTIONS_NODE +
          `?&page=${currentPage}&question_level=${
            filterDifficulty.show ? filterDifficulty.value : ""
          }&skill_id=${search ? search : ""}&created_at=${
            filterDate.show ? formatYYYYMMDD(filterDate.value) : ""
          }`
      )
        .then((res) => {
          const decryptedData: GetAllQuestionsResponseWithMeta =
            decodeEncodedAPIResponse(res.data, res);
          if (res) {
            setquestions(
              currentPage === 1
                ? [...decryptedData.data.data]
                : [...questions, ...decryptedData.data.data]
            );
          }
          settotalQuestions(decryptedData.data.meta.total);
          setTotalNumberOfQuestions?.(decryptedData.data.meta.total);
          if (currentPage === decryptedData.data.meta.last_page)
            setshowLoadMore(false);
          else setshowLoadMore(true);
        })
        .catch((err) => {
          console.log({msg: err});
          setquestions([]);
        })
        .finally(() =>
          setTimeout(() => {
            setisFetching(false);
            setLoading?.(false);
          }, 300)
        );
    });
  };

  useEffect(() => {
    setclearFilterFlag(false);
    fetchQuestions(selectedSkill?.value);
    currentPage = 1;
    // allQuestions ? setquestions(allQuestions) : fetchQuestions();
  }, [selectedSkill?.value, filterDate, filterDifficulty]);

  const fetchskills = async () => {
    axiosGetModule(APIEndPoints.GET_SKILL_WITH_QUE_COUNT_NODE)
      .then((res: SkillsResponse) => {
        setSkillsResponse(res.data);
        let non_organization_question_level_1 = 0;
        let organization_question_level_1 = 0;
        let non_organization_question_level_2 = 0;
        let organization_question_level_2 = 0;
        let non_organization_question_level_3 = 0;
        let organization_question_level_3 = 0;
        let array: Array<{label: string; value: number}> = [];
        res.data.forEach((item) => {
          array.push({
            label: item.skill,
            value: item.id,
          });
          non_organization_question_level_1 +=
            item.question_count?.non_organization_question_level_1!;
          organization_question_level_1 +=
            item.question_count?.organization_question_level_1!;
          non_organization_question_level_2 +=
            item.question_count?.non_organization_question_level_2!;
          organization_question_level_2 +=
            item.question_count?.organization_question_level_2!;
          non_organization_question_level_3 +=
            item.question_count?.non_organization_question_level_3!;
          organization_question_level_3 +=
            item.question_count?.organization_question_level_3!;
        });
        setskills(array);
        setSimpleQuestions(
          organization_question_level_1 + non_organization_question_level_1
        );
        setMediumQuestions(
          organization_question_level_2 + non_organization_question_level_2
        );
        setHardQuestions(
          organization_question_level_3 + non_organization_question_level_3
        );
      })
      .catch((err) => console.log(err));
  };

  const deleteQuestion = () => {
    axiosDeleteModule(
      APIEndPoints.DELETE_QUESTION_NODE(showDeleteConfirmation.id)
    )
      .then((res) => {
        toast.success(strings.all_questions.question_delete_msg);
        //@ts-ignore
        fetchQuestions(
          (selectedSkill && selectedSkill.value) ||
            (filterDifficulty.value && filterDifficulty.value)
        );
      })
      .catch((res) => {
        toast.error(strings.generalErrorMsg);
      });
    onCloseModal();
  };

  const onCloseModal = () => setshowDeleteConfirmation({show: false, id: ""});

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollOffset?.(position);
  };

  useEffect(() => {
    let non_organization_question_level_1 = 0;
    let organization_question_level_1 = 0;
    let non_organization_question_level_2 = 0;
    let organization_question_level_2 = 0;
    let non_organization_question_level_3 = 0;
    let organization_question_level_3 = 0;
    skillsResponse?.forEach((item: any) => {
      if (item.id === selectedSkill?.value) {
        non_organization_question_level_1 +=
          item.question_count?.non_organization_question_level_1!;
        organization_question_level_1 +=
          item.question_count?.organization_question_level_1!;
        non_organization_question_level_2 +=
          item.question_count?.non_organization_question_level_2!;
        organization_question_level_2 +=
          item.question_count?.organization_question_level_2!;
        non_organization_question_level_3 +=
          item.question_count?.non_organization_question_level_3!;
        organization_question_level_3 +=
          item.question_count?.organization_question_level_3!;
      }
    });
    setSimpleQuestions(
      organization_question_level_1 + non_organization_question_level_1
    );
    setMediumQuestions(
      organization_question_level_2 + non_organization_question_level_2
    );
    setHardQuestions(
      organization_question_level_3 + non_organization_question_level_3
    );
  }, [selectedSkill]);

  if (isFetching) return <Loader />;

  return (
    <div className="p-1">
      <TopTabs selectedTab={0} />

      <SizedBox height={"36px"} />
      <img src={Images.QuestionBanner} className="h-44" />

      <SizedBox height={"48px"} />
      <div>
        <p className="font-bold text-base text-monoGrey-800">
          {strings.all_questions.tagsHeading}
        </p>
        <SizedBox height={"20px"} />
        <CustomSelect
          options={skills}
          isClearable
          onChange={(val: any) => {
            setselectedSkill(val);
            fetchQuestions(val!.value);
          }}
          isAnimated={false}
          isMulti={false}
        />

        <SizedBox height={"36px"} />
        <div className="flex items-center">
          <img src={Images.OutlinedFlag} />
          <SizedBox width={"16px"} />
          <p className="text-2xl text-monoGrey-400 font-bold">
            {`Total ${
              totalNumberOfQuestions ? totalNumberOfQuestions : totalQuestions
            } Questions${
              selectedSkill?.label ? ` related to ${selectedSkill?.label}` : ""
            }`}
          </p>
        </div>

        <SizedBox height={"36px"} />
        {role == "Super Admin" ? (
          <div className="flex">
            {questions.length ? (
              loading ? (
                <Loader
                  containerClasses={`w-full items-center justify-center h-30vh`}
                  loaderStyles={`w-full h-4/5 object-contain`}
                />
              ) : (
                <div className="w-3/4 border-r border-lightBlack-300">
                  {questions?.map((ele) => (
                    <div key={ele.id} className="pb-6 px-6">
                      <QuestionCard
                        data={ele}
                        onDelete={() =>
                          setshowDeleteConfirmation({show: true, id: ele.id})
                        }
                      />
                    </div>
                  ))}
                  {showLoadMore && (
                    <div className="flex flex-col items-center">
                      <ViewButton
                        invert
                        name={loading ? <Spinner size={15} /> : strings.more}
                        isDisabled={loading ? true : false}
                        onClick={() => {
                          currentPage++;
                          fetchQuestions("", "view_more");
                        }}
                        style={{paddingInline: "50px"}}
                      />
                      <SizedBox height="20px" />
                    </div>
                  )}
                </div>
              )
            ) : (
              <div className="flex  w-3/4 justify-center items-center">
                <img src={Images.EmptyQuestions} />
                <SizedBox width={"30px"} />
                <div>
                  <p className="text-3xl">
                    {strings.question.no_question_text}
                  </p>
                  <SizedBox height={"10px"} />
                  <Link to={`/${QuestionLinks.Create_Question}`}>
                    <Buttons
                      text={strings.question.create_new_question}
                      extraStyles={buttonStyles}
                    />
                  </Link>
                </div>
              </div>
            )}
            <div className="pl-6 w-1/4">
              <div className="border border-white-300 rounded-md bg-white-900 p-5 w-full">
                <div className="flex justify-between items-center">
                  <p className="capitalize text-lg font-semibold text-lightBlack-500">
                    {strings.filter}
                  </p>
                  <div
                    className="border border-blue-500 rounded-md bg-blue-200 p-1.5 cursor-pointer"
                    onClick={clearFilterHandle}
                  >
                    <p className="text-blue-500 text-base font-normal">
                      clear filters
                    </p>
                  </div>
                </div>

                <SizedBox height="32px" />
                <div className="flex justify-between items-center">
                  <p>{strings.all_questions.filter_date}</p>
                </div>

                <SizedBox height="12px" />
                <Datepicker
                  onChange={(newdate: Moment | null) => {
                    setfilterDate({
                      ...filterDate,
                      value: newdate,
                      show: true,
                    });
                    // fetchQuestions(newdate);
                  }}
                  value={filterDate.value}
                  format={"DD-MM-YYYY"}
                  maxDate={moment()}
                  customInputStyle={datePickerInputStyle}
                  isCustomInput
                  customContainerStyle={datePickerContainerStyle}
                />

                <SizedBox height="32px" />
                <div className="flex justify-between items-center">
                  <p>{strings.all_questions.filter_Dificulty}</p>
                </div>

                <SizedBox height="12px" />
                <CustomSelect
                  options={difficultyOptions}
                  onChange={(val: any) => {
                    setfilterDifficulty({
                      ...filterDifficulty,
                      value: val?.value,
                      show: true,
                    });
                    // setselectedSkill(null);
                  }}
                  isAnimated={false}
                  defaultValue={filterDifficulty.value}
                  isMulti={false}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-3 border-t-2 border-b-2 border-monoGrey-400 p-2 pt-5 pb-5">
            <div className="flex flex-col items-center">
              <p className="text-xl font-semibold text-lightBlack-500">
                Simple Level
              </p>
              <SizedBox height="10px" />
              <p className="text-lg font-normal text-primary">
                {simpleQuestions}
              </p>
            </div>
            <div className="flex flex-col items-center">
              <p className="text-xl font-semibold text-lightBlack-500">
                Medium Level
              </p>
              <SizedBox height="10px" />
              <p className="text-lg font-normal text-primary">
                {mediumQuestions}
              </p>
            </div>
            <div className="flex flex-col items-center">
              <p className="text-xl font-semibold text-lightBlack-500">
                Hard Level
              </p>
              <SizedBox height="10px" />
              <p className="text-lg font-normal text-primary">
                {hardQuestions}
              </p>
            </div>
          </div>
        )}

        <ConfirmationModal
          open={showDeleteConfirmation.show}
          onCloseModal={onCloseModal}
          heading={strings.deleteConfirmation}
          onYes={deleteQuestion}
        />
      </div>
    </div>
  );
};

export default Question;
