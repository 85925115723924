import React, {CSSProperties} from "react";
import {useNavigate} from "react-router-dom";
import {Column, Row, useTable} from "react-table";
import styles from "./UserTableStyle.module.scss";

import {AppColors} from "globals/appcolors";
import {CandidateLinks} from "globals/constants/routeLinks";
import {postGoogleAnalyticsEvent} from "globals/helpers";

interface TableProps {
  columns: Array<Column>;
  data: Array<any>;
  className?: CSSProperties | undefined;
}

interface Props {
  column: any;
  row: Array<any>;
}

const Table: React.FC<TableProps> = ({columns, data}) => {
  const navigate = useNavigate();
  const eventTracker = postGoogleAnalyticsEvent("Candidate");
  // Use the state and functions returned from useTable to build your UI
  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable({
      //@ts-ignore
      columns,
      data,
    });

  const openCandidateDetails = (row: Row<{}>) => {
    eventTracker(
      "Candidate Details viewed",
      "Fetching all assessments of a candidate"
    );
    navigate(
      // @ts-ignore
      `/${CandidateLinks.Candidate_Detail}?candidate=${row?.original?.candidate_id}`
    );
  };

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                style={{fontSize: 15, fontWeight: "bold"}}
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell, index) => {
                return (
                  <td key={index.toString()} style={{width: "80px"}}>
                    <div className="flex w-100 px-3 justify-center">
                      <p
                        style={{
                          color:
                            index === 3
                              ? cell.value
                                ? AppColors.BLUE
                                : AppColors.GREY
                              : AppColors.BLACK,
                          cursor:
                            index === 0
                              ? "pointer"
                              : index === 3 && cell.value
                              ? "pointer"
                              : "default",
                          fontSize: 14,
                          fontWeight: "600",
                        }}
                        onClick={() =>
                          index === 0
                            ? openCandidateDetails(row)
                            : index === 3 &&
                              cell.value &&
                              window.open(cell.value)
                        }
                        className="text-ellipsis whitespace-nowrap overflow-hidden"
                      >
                        {index === 4
                          ? cell.value
                            ? "View"
                            : "N.A."
                          : cell.value}
                      </p>
                    </div>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const UserTable: React.FC<Props> = ({column, row}) => {
  type tableRow = {
    id: string;
    candidateName: string;
    email: string;
    interviewLink: string;
    interviewStatus: string;
    score: number | string;
    result: string;
    resume: string;
  };

  const columns: Array<Column> = React.useMemo(() => [...column], []);
  const data: Array<tableRow> = React.useMemo(() => [...row], [row]);

  return (
    <div className={styles.react_table}>
      <Table columns={columns} data={data} className={styles.react_table} />
    </div>
  );
};

export default UserTable;
