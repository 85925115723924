import React, {CSSProperties} from "react";
import {Typography} from "@mui/material";

import {Row} from "components/Row";

import {AppColors} from "globals/appcolors";
import {CandidateDetails} from "globals/types/APIResponses";
import SizedBox from "components/SizedBox/SizedBox";
import CustomSelect from "components/Inputs/Select/CustomSelect";
import {communiationSkillOptions} from "globals/constants/dropdownConstants";
import axios from "axios";
import {APIEndPoints} from "globals/constants/endPoints";
import {staticHeaders} from "globals/helpers";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import {toast} from "react-toastify";

interface IProps {
  assessmentDetails: CandidateDetails;
  fetchAssessmentDetails: (id: string) => void;
}

export const CandidateScore: React.FC<IProps> = ({
  assessmentDetails,
  fetchAssessmentDetails,
}) => {
  const selectStyles = {
    control: (base: CSSProperties) => ({
      ...base,
      background: AppColors.WHITE,
      boxSizing: "border-box",
      fontSize: "16px",
      borderRadius: "6px",
      boxShadow: "none",
      borderColor: AppColors.WHITE,
      "&:hover": {
        border: `1px solid ${AppColors.WHITE}`,
      },
    }),
    container: (provided: CSSProperties) => ({
      ...provided,
      outline: "none",
      fontSize: "16px",
      borderRadius: "6px",
      background: AppColors.WHITE,
    }),
    option: (provided: CSSProperties) => ({
      ...provided,
    }),
  };

  const overallScore = assessmentDetails.data.assessmentScores.reduce(
    (sum: number, card: any) =>
      sum + parseInt(card.points_scored !== null ? card.points_scored : "0"),
    0
  );

  const {setLoading} = useAppCommonDataProvider();

  function updateCommunication(val: {label: string; value: string}) {
    setLoading?.(true);
    const body = {status: val.value};
    axios
      .patch(
        APIEndPoints.COMMUNICATION_SKILL_SCORE(
          assessmentDetails.data.assessment.candidate_id,
          val.value
        ),
        body,
        {headers: staticHeaders()}
      )
      .then(() => {
        toast.success("Communication score updated");
        fetchAssessmentDetails(assessmentDetails.data.assessment.id.toString());
      });
  }

  return (
    <div className="flex border-r border-dashed border-monoGrey-400">
      <div className="flex flex-col w-10/12">
        <Typography
          fontWeight={"bold"}
          fontSize={16}
          color={AppColors.LIGHTBLACK_500}
          textAlign="center"
        >
          Technologies
        </Typography>
        <SizedBox height="20px" />
        {assessmentDetails.data.assessmentScores.map((skill, index) => (
          <Row
            classNames="justify-between items-start py-1"
            key={`${skill.name} ${index}`}
          >
            <Typography>{skill.name}</Typography>
            <div>
              <Typography>
                {`${
                  skill.points_scored !== null
                    ? parseInt(skill.points_scored as string).toFixed(0)
                    : "0"
                }/${parseInt(skill.totalPoints).toFixed(0)}`}
              </Typography>
            </div>
          </Row>
        ))}

        <SizedBox height="40px" />

        <Row classNames="bg-primary py-2 px-5 justify-between rounded-md text-white-900">
          <Typography variant="button" fontWeight={"bold"}>
            Overall score
          </Typography>
          <Typography variant="button" fontSize={16} fontWeight="bold">
            {overallScore}
          </Typography>
        </Row>

        <SizedBox height="40px" />

        <div>
          <Typography
            fontWeight={"bold"}
            fontSize={16}
            color={AppColors.LIGHTBLACK_500}
          >
            Communication score
          </Typography>
          <SizedBox height="10px" />
          <CustomSelect
            options={communiationSkillOptions}
            customStyles={selectStyles}
            value={
              assessmentDetails.data.assessment.candidate
                .communication_points_scored
                ? {
                    label:
                      assessmentDetails.data.assessment.candidate
                        .communication_points_scored,
                  }
                : {label: "Give Score", value: "0"}
            }
            onChange={updateCommunication}
          />
        </div>
      </div>
    </div>
  );
};
