import React from "react";
import {Typography} from "@mui/material";
import styles from "routes/Interview/Interview.module.scss";

import strings from "globals/constants/strings.json";

export const GenericError: React.FC = () => {
  const {generic_error} = strings.interview.permissions_error;
  const {title, scenario1, scenario2, scenario3, scenario4} = generic_error;

  return (
    <div>
      <Typography variant="h4" sx={{fontWeight: 600}}>
        {title}
      </Typography>
      <ul className="mt-4 list-disc px-6">
        <li>
          <p className={styles.permission__error}>{scenario1}</p>
        </li>
        <li>
          <p className={styles.permission__error}>{scenario2}</p>
        </li>
        <li>
          <p className={styles.permission__error}>{scenario3}</p>
        </li>
        <li>
          <p className={styles.permission__error}>{scenario4}</p>
        </li>
      </ul>
    </div>
  );
};
