import {RouteLinks} from "globals/constants/routeLinks";
import {Images} from "../../assets/Image";
import styles from "./style.module.css";
import {useNavigate} from "react-router-dom";

const Header: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.main_container}>
      <img className={styles.logo} src={Images.SourcebaeLogo} alt="Sourcebae" />
      <button
        className={styles.btn}
        onClick={() => navigate(`/${RouteLinks.Login}`)}
      >
        Login
      </button>
    </div>
  );
};

export default Header;
