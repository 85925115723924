export const microCopies = {
  INTERVIEW_ROUND_TWO_HEADING: "interview.round2.heading",
  INTERVIEW_ROUND_TWO_PREVIEW_HEADING: "interview.round2.previewHeading",
  INTERVIEW_MIC_CAMERA_CHECK: "interview.miccameracheck",
  INTERVIEW_ROUND_TWO_BEHAVIOURAL_ROUND_HEADING:
    "interview.round2.behaviouralround.heading",
  INTERVIEW_PHASE1_COMPLETED_TITLE: "interview.phase1.completed.title",
  INTERVIEW_PHASE1_COMPLETED_MESSAGE: "interview.phase1.completed.message",
  INTERVIEW_UPLOAD_RESUME_HEADING: "interview.upload.resume.heading",
  CHECK_CONNECTION_VIDEO_QUALITY: "check.connection.video.quality",
  MICROPHONE_TEST_ADJUST_VOLUME: "microphone.test.adjust.volume",
} as const;

export type MicroCopyObj = typeof microCopies;

export type MicroCopyValues = MicroCopyObj[keyof MicroCopyObj] | "";
