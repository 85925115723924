import React, {CSSProperties, useEffect, useRef, useState} from "react";
import Modal from "react-responsive-modal";
import cookies from "react-cookies";
import {Bounce} from "react-activity";
import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestMediaPermissions,
} from "mic-check";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import moment from "moment";
import {toast} from "react-toastify";
import styles from "./Interview.module.scss";

import {Checked} from "components/Icons";
import SizedBox from "components/SizedBox/SizedBox";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import {UserDeniedPermissions} from "components/InterviewPermissions/UserDeniedPermissions";
import {SystemDeniedPermissions} from "components/InterviewPermissions/SystemDeniedPermissions";
import {Row} from "components/Row";
import {GenericError} from "components/InterviewPermissions/GenericError";
import {TrackError} from "components/InterviewPermissions/TrackError";
import {AssessmentExpired} from "components/Popups/AssessmentExpired";
import {InterviewSteps} from "components/Popups/InterviewSteps";

import {AppColors} from "globals/appcolors";
import strings from "globals/constants/strings.json";
import {APIEndPoints} from "globals/constants/endPoints";
import {COOKIESKEYS} from "globals/constants/cookiesKeys";
import {getIPOfUser} from "globals/helpers";
import {DialogType} from "globals/types/globalTypes";
import {RouteLinks} from "globals/constants/routeLinks";

import {Images} from "Images";
import {axiosPatchModule} from "network/Patch/AxiosPatchModule";

import {useFetchAssessmentDetails} from "hooks/interview/useFetchAssessmentDetails";
import {useUpdateIp} from "hooks/assessment/useUpdateIp";

enum Levels {
  Beginner = "Beginner",
  Intermediate = "Intermediate",
  Expert = "Expert",
}

const modalContainerStyles: CSSProperties | undefined = {
  background: AppColors.WHITE,
  borderRadius: 15,
  padding: "1.5rem 2rem",
  maxWidth: "60vw",
  maxHeight: "70vh",
  overflowY: "scroll",
};

const InterviewInstructions: React.FC = () => {
  const headers = {
    Authorization: `Bearer ${cookies.load(COOKIESKEYS.CANDIDATE_TOKEN)}`,
    Accept: "application/json",
  };

  const params = useParams();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();

  const {id, assessmentId} = params;
  const assessmentDuration = queryParams.get("duration");

  const {refetch: getRules, isLoading} = useFetchAssessmentDetails(id!);
  const {refetch: updateIp} = useUpdateIp(assessmentId!);
  const {
    loading,
    permissions,
    interview_details,
    setLoading,
    setPermissions,
    setInterview_details,
  } = useAppCommonDataProvider();

  const [isTnCSelected, setIsTnCSelected] = useState<boolean>(false);
  const [isPermissionsModalOpen, setIsPermissionsModalOpen] =
    useState<boolean>(false);
  const [renderable, setRenderable] = useState<boolean>(true);
  const [permissionError, setPermissionError] = useState<DialogType | null>(
    null
  );
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [showInterviewSteps, setShowInterviewSteps] = useState<boolean>(false);

  const showDialogRef = useRef<DialogType | null>(permissionError);
  showDialogRef.current = permissionError;

  useEffect(() => {
    cookies.remove(COOKIESKEYS.INTERVIEW_DETAILS, {path: "/"});
    localStorage.removeItem("INTERVIEW_DETAILS");
    cookies.remove(COOKIESKEYS.RESPONSES, {path: "/"});
    cookies.remove(COOKIESKEYS.UNIQUE_RESPONSES, {path: "/"});
    cookies.remove(COOKIESKEYS.DURATION, {path: "/"});
    checkPermissions();
    fetchRules();
  }, []);

  useEffect(() => {
    if (permissions?.camera && permissions.microphone)
      setIsPermissionsModalOpen(false);
  }, [permissions]);

  async function fetchRules() {
    const userIP = await getIPOfUser();
    getRules().then((res) => {
      if (res.isError === false && res.data !== undefined) {
        const {assessment} = res.data.data;
        const {
          assessment_duration,
          ip_address,
          id,
          assessment_template,
          status,
          time_remaining,
          scheduled_date,
          assessment_url,
        } = assessment;

        setInterview_details?.(res.data);

        if (moment(scheduled_date).diff(moment(), "days") < 0) {
          setIsExpired(true);
          return;
        }

        if (userIP && ip_address && ip_address !== userIP) {
          updateIp();
        }

        cookies.save(COOKIESKEYS.DURATION, assessment_duration, {path: "/"});
        localStorage.setItem("duration", assessment_duration.toString());
        localStorage.setItem("INTERVIEW_DETAILS", JSON.stringify(res.data));
        localStorage.setItem("AssesmentName", assessment_template?.name!);

        if (status === 3) {
          time_remaining &&
            cookies.save(COOKIESKEYS.DURATION, time_remaining, {path: "/"});
          navigate(
            `/${
              RouteLinks.Interview
            }/${assessment_url}/${id}/questions?duration=${
              assessmentDuration ?? assessment_duration
            }`
          );
        }

        if (status === 12) {
          time_remaining &&
            cookies.save(COOKIESKEYS.DURATION, time_remaining, {path: "/"});
          navigate(
            `/${
              RouteLinks.Interview
            }/${assessment_url}/${id}/introduction?duration=${
              assessmentDuration ?? assessment_duration
            }`
          );
        }
      }
    });
  }

  function checkForExplanationDialog() {
    if (
      (!permissions?.camera || !permissions.microphone) &&
      showDialogRef.current === null
    )
      setPermissionError(DialogType.explanation);
  }

  async function checkPermissions() {
    if (!("navigator" in window)) {
      setIsPermissionsModalOpen(false);
      setRenderable(false);
    } else {
      requestMediaPermissions()
        .then(() => {
          setIsPermissionsModalOpen(false);
          setPermissions?.({
            ...permissions,
            camera: true,
            microphone: true,
          });
        })
        .catch((e: MediaPermissionsError) => {
          setIsPermissionsModalOpen(true);
          const {type} = e;
          if (type === MediaPermissionsErrorType.SystemPermissionDenied)
            setPermissionError(DialogType.systemDenied);
          else if (type === MediaPermissionsErrorType.UserPermissionDenied)
            setPermissionError(DialogType.userDenied);
          else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource)
            setPermissionError(DialogType.trackError);
          else setPermissionError(DialogType.explanation);
        });

      setTimeout(() => checkForExplanationDialog(), 500);
    }
  }

  async function addIP(assessmentId: string | undefined, ip: any) {
    return new Promise((resolve, reject) => {
      axiosPatchModule(
        APIEndPoints.UPDATE_IP_SESSION_TIME_REMAINING_ASSESMENT(assessmentId),
        {
          ip_address: ip,
          ip_change_count: 1,
        },
        {},
        headers
      )
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  }

  async function updateJWT() {
    return new Promise((resolve, reject) => {
      const time = moment().toDate().toString();
      const candidateId = cookies.load("candidateID");
      axiosPatchModule(
        APIEndPoints.UPDATE_JWT(
          interview_details!.data.assessment.id.toString()
        ),
        {jwt: time, creatorId: candidateId},
        {},
        headers
      )
        .then((res) => {
          resolve({res, time});
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function updateAssessmentStatus() {
    return new Promise((resolve, reject) => {
      axiosPatchModule(
        APIEndPoints.UPDATE_IP_SESSION_TIME_REMAINING_ASSESMENT(
          interview_details!.data.assessment.id.toString()
        ),
        {status: "2"},
        {},
        headers
      )
        .then((res) => resolve(res))
        .catch((err) => {
          if (err.status === 422)
            toast.error(
              "We're sorry but this interview has already been started."
            );
          reject(err);
          setLoading?.(false);
        });
    });
  }

  async function startAssessment() {
    const {
      is_contractual,
      include_intro,
      include_round_one,
      include_round_two,
    } = interview_details!.data.assessment;
    setLoading?.(true);
    const userIP = await getIPOfUser();
    const ipUpdated = await addIP(
      interview_details?.data.assessment.id.toString(),
      userIP
    );
    const jwtUpdate = await updateJWT();
    const updateAssessment = await updateAssessmentStatus();
    Promise.all([jwtUpdate, updateAssessment])
      .then((data: any[]) => {
        const jwt = data[0].time;
        const duration = data[1].data.assessment_duration;
        cookies.save(COOKIESKEYS.DURATION, duration, {
          path: "/",
        });
        cookies.save(COOKIESKEYS.JWT, jwt, {path: "/"});
        setLoading?.(false);

        if (is_contractual === false)
          navigate(`behavior?duration=${assessmentDuration}`);
        else if (is_contractual === true) {
          if (
            include_intro === true &&
            include_round_one === true &&
            include_round_two === true
          ) {
            console.log("in 1st");
            navigate(`behavior?duration=${assessmentDuration}`);
          }
          // added from here
          else if (
            include_intro === true &&
            include_round_one === false &&
            include_round_two === true
          ) {
            console.log("in 2nd");
            navigate(
              `/${RouteLinks.Interview}/${id}/${assessmentId}/introduction?duration=${assessmentDuration}`
            );
          } else if (
            include_intro === false &&
            include_round_one === true &&
            include_round_two === false
          ) {
            console.log("in 3rd");
            navigate(
              `/${RouteLinks.Interview}/${id}/${assessmentId}/questions?duration=${assessmentDuration}`
            );
          }
          // to
          else {
            console.log("in 4rth");
            navigate(
              `/${RouteLinks.Interview}/${id}/${assessmentId}/introduction?duration=${assessmentDuration}`
            );
          }
        }
      })
      .catch((err) => {
        toast.error(
          "Something went wrong on our end. Please try checking in later or contacting somemone",
          {
            autoClose: 3000,
          }
        );
        setLoading?.(false);
      })
      .finally(() => setLoading?.(false));
  }

  async function proceed(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    if (isTnCSelected) {
      setShowInterviewSteps(true);
    } else alert("Please select TnC");
  }

  const renderSystemDeniedPermissions = () => <SystemDeniedPermissions />;

  const renderUserDeniedPermissions = () => <UserDeniedPermissions />;

  const renderTrackError = () => <TrackError />;

  const renderGenericError = () => <GenericError />;

  const renderDialogContent = () => {
    switch (permissionError) {
      case DialogType.systemDenied:
        return renderSystemDeniedPermissions();
      case DialogType.userDenied:
        return renderUserDeniedPermissions();
      case DialogType.trackError:
        return renderTrackError();
      case DialogType.explanation:
        return renderGenericError();
    }
  };

  if (renderable) {
    return isLoading ? (
      <Row classNames="h-screen w-screen justify-center">
        <Bounce color={AppColors.REPORTCARD_SECONDARY} size={40} />
      </Row>
    ) : (
      <>
        <div className="flex py-4 px-4 bg-[#51226F] w-full justify-between items-center">
          <div className="flex text-white-800 font-bold text-32 items-center justify-center">
            <img src={Images.LogoWithoutText} style={{width: 50, height: 50}} />
            <p className="ml-1 text-2xl">Sourcebae</p>
          </div>
        </div>
        <div className={styles.instructions__container}>
          <div className={styles.instructions__wrapper}>
            <div className="flex-0.5 justify-center items-center flex">
              <img src={Images.InterviewInstruction} alt="" />
            </div>
            <div className={styles.instructions__instructionsSection}>
              <div className="flex-0.9">
                <p className={styles.instructions__assessmentName}>
                  {interview_details?.data?.assessment.name}
                </p>
                <SizedBox height="2rem" />
                <div className="flex items-center">
                  <div className={styles.instructions__descriptor}>
                    <p className="font-medium pr-2">Technology</p>
                    <p>:</p>
                  </div>
                  <div className={styles.instructions__descriptorValue}>
                    <p>
                      {
                        interview_details?.data?.assessment?.assessment_template
                          ?.name
                      }
                    </p>
                  </div>
                </div>
                <div className="flex items-center mt-2">
                  <div className={styles.instructions__descriptor}>
                    <p className="font-medium pr-2">Experience</p>
                    <p>:</p>
                  </div>
                  <div className={styles.instructions__descriptorValue}>
                    <p>
                      {interview_details?.data?.assessment.assessment_level ===
                      1
                        ? Levels.Beginner
                        : interview_details?.data?.assessment
                            .assessment_level === 2
                        ? Levels.Intermediate
                        : Levels.Expert}
                    </p>
                  </div>
                </div>
                <SizedBox height="1.5rem" />
                <div className="rounded overflow-scroll">
                  <p className="text-20 font-bold mb-2">
                    {strings.instructions} :
                  </p>

                  <div className={styles.instruction}>
                    <p className="text-16 font-bold">01 </p>
                    <p className="pl-2 text-black-150 text-16">
                      Stable internet connection
                    </p>
                  </div>

                  <div className={styles.instruction}>
                    <p className="text-16 font-bold">02 </p>
                    <p className="pl-2 text-black-150 text-16">
                      Tab switching not allowed
                    </p>
                  </div>
                </div>

                <div className={styles.instruction}>
                  <p className="text-16 font-bold">03 </p>
                  <p className="pl-2 text-black-150 text-16">
                    Don't look away from the screen
                  </p>
                </div>

                <div className={styles.instruction}>
                  <p className="text-16 font-bold">04 </p>
                  <p className="pl-2 text-black-150 text-16">
                    Don’t talk to anyone or read the questions aloud during test
                  </p>
                </div>

                <SizedBox height="1.5rem" />
                <div className="flex items-center cursor-pointer">
                  <div
                    className={styles.instructions__tncSection}
                    onClick={() => setIsTnCSelected(!isTnCSelected)}
                  >
                    {isTnCSelected && (
                      <Checked size={10} color={AppColors.PRIMARYCOLOR} />
                    )}
                  </div>
                  <div
                    className="ml-4"
                    onClick={() => setIsTnCSelected(!isTnCSelected)}
                  >
                    <span>{strings.tnc_read}</span>
                    <span>{` ${strings.tnc_instruction} `}</span>
                    <span>{`${strings.tnc_and} `}</span>
                    <a href="">
                      <span className="text-blue-500">
                        {strings.tnc_conditions}
                      </span>
                    </a>
                  </div>
                </div>
                <SizedBox height="1rem" />
                <div className="w-full flex">
                  <button
                    style={{
                      background: !(
                        isTnCSelected && interview_details?.data?.canStart
                      )
                        ? `${AppColors.REPORTCARD_SECONDARY}a0`
                        : AppColors.REPORTCARD_SECONDARY,
                    }}
                    onClick={proceed}
                    className="text-white-900 rounded w-1/3 h-12 disabled:cursor-not-allowed font-bold"
                    type="submit"
                    disabled={
                      !(isTnCSelected && interview_details?.data?.canStart)
                    }
                  >
                    {strings.start_now}
                  </button>
                  <Backdrop
                    sx={{
                      color: AppColors.PRIMARYCOLOR,
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={loading ? true : false}
                    onClick={() => {}}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>

                  {/* permissions modal */}
                  <Modal
                    open={isPermissionsModalOpen}
                    center
                    onClose={() => {}}
                    styles={{modal: modalContainerStyles}}
                    showCloseIcon={false}
                  >
                    {renderDialogContent()}
                  </Modal>
                </div>
              </div>
            </div>
          </div>

          {isExpired && (
            <AssessmentExpired
              open={isExpired}
              onClose={() => {
                window.open("about:blank", "_self");
                window.close();
              }}
            />
          )}

          {showInterviewSteps === true && (
            <InterviewSteps
              open={showInterviewSteps}
              onClose={() => setShowInterviewSteps(false)}
              onClickStart={startAssessment}
            />
          )}
        </div>
      </>
    );
  } else
    return (
      <div className="min-h-screen flex items-center justify-center max-h-screen">
        <p>
          Your browser is incapable of giving the assessment due to some missing
          features that are a must for us. Please consider changing your browser
        </p>
      </div>
    );
};

export default InterviewInstructions;
