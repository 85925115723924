import React, {CSSProperties, useCallback, useEffect, useState} from "react";
import moment, {Moment} from "moment";
import {useNavigate} from "react-router-dom";
import {debounce} from "lodash";
import {Spinner} from "react-activity";
import styles from "./Profile.module.scss";

import Buttons from "components/Containers/Button/Button/Buttons";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import Loader from "components/Loader";
import ViewButton from "components/Containers/Button/Button/ViewButton";
import SizedBox from "components/SizedBox/SizedBox";
import strings from "globals/constants/strings.json";
import CustomSelect from "components/Inputs/Select/CustomSelect";
import {AppColors} from "globals/appcolors";
import {
  CreateUserResponse,
  GetUserResponse,
  MetaData,
  UserData,
  Roles,
  GetRolesResponse,
  updateAPIResponse,
} from "globals/types/APIResponses";
import {UserTableData, UserDataState} from "globals/types/globalTypes";
import {postGoogleAnalyticsEvent} from "globals/helpers";
import {UserTableRowProps} from "routes/dashboard/DashboardHome/Types";
import {APIEndPoints} from "globals/constants/endPoints";
import axiosPostModule from "network/Post/AxiosPostModule";
import axiosGetModule from "network/Get/AxiosGetModule";
import axiosDeleteModule from "network/Delete/axiosDeleteModule";
import {useTable, Column} from "react-table";
import Modal from "react-responsive-modal";
import {toast} from "react-toastify";
import {axiosPatchModule} from "network/Patch/AxiosPatchModule";
import {Delete} from "components/Icons";

interface TableProps {
  columns: Array<Column>;
  data: Array<any>;
  className?: CSSProperties | undefined;
  roles: RoleOptions[];
  assignRole: any;
  updateStatus: any;
  deleteUser: any;
}

export interface RoleOptions {
  label: string;
  value: number;
}

interface Props {}

const RoleManagement: React.FC<Props> = () => {
  const buttonStyles: CSSProperties = {
    padding: ".5rem 1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const [delFlag, setdelFlag] = useState<boolean>(false);
  const [createRoleModal, setCreateRoleModal] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [current_page, setcurrent_page] = useState<number>(1);
  const [userTableData, setUserTableData] = useState<UserTableData[]>([]);
  const [roles, setRoles] = useState<RoleOptions[]>([]);
  const [metaData, setMetaData] = useState<MetaData>();
  const [userData, setUserData] = useState<UserDataState>({
    first_name: "",
    last_name: "",
    email: "",
    role: 0,
  });

  const {loading, setLoading, scrollOffset, setScrollOffset} =
    useAppCommonDataProvider();

  useEffect(() => {
    setLoading?.(true);
  }, []);

  useEffect(() => {
    setcurrent_page(1);
    if (scrollOffset) {
      window.scrollTo({
        top: scrollOffset,
        behavior: "smooth",
      });
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollOffset?.(position);
  };

  useEffect(() => {
    getUsers("");
  }, [current_page]);

  const filterAssessments = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setcurrent_page(1);
    getUsers(value);
    return value;
  };

  const debounceSearch = useCallback(debounce(filterAssessments, 500), []);

  if (delFlag === true) {
    setdelFlag(false);
  }

  const handleFieldChanges = (field: string, value: string) => {
    const values = {...userData};
    if (field === "first_name") {
      values.first_name = value.toString();
    }
    if (field === "last_name") {
      values.last_name = value.toString();
    }
    if (field === "email") {
      values.email = value.toString();
    }
    if (field === "role") {
      values.role = parseInt(value);
    }
    setUserData(values);
  };

  const validateDetails = () => {
    let temp = [];
    if (userData.first_name == "") {
      toast.error("First Name is Required");
      temp.push(1);
    }
    if (userData.last_name == "") {
      toast.error("Last Name is Required");
      temp.push(1);
    }
    if (userData.email == "") {
      toast.error("Email is Required");
      temp.push(1);
    }
    if (userData.role == 0) {
      toast.error("Role is Required");
      temp.push(1);
    }
    if (userData.role == 3) {
      toast.error("You can not assign Developer");
      temp.push(1);
    }
    if (userData.role == 15) {
      toast.error("You can not assign Interviewer");
      temp.push(1);
    }
    if (temp.length === 0) return true;
    else return false;
  };

  const createUser = async () => {
    setModalLoading(true);
    if (validateDetails()) {
      await axiosPostModule(APIEndPoints.CREATE_USER, userData)
        .then((res: CreateUserResponse) => {
          toast.success(res.message);
          setTimeout(() => {
            getUsers("");
          }, 300);
          setCreateRoleModal(false);
        })
        .catch((err: any) => {
          if (err.data.code == 403) {
            toast.error(err.data.message);
          }
          console.log(err);
        })
        .finally(() => {
          setModalLoading(false);
        });
    } else setModalLoading(false);
  };

  useEffect(() => {
    getRoles();
    getUsers("");
  }, []);

  const loadMore = () => {
    let temp = current_page + 1;
    setcurrent_page(temp);
  };

  const userDetails = JSON.parse(localStorage.getItem("USER_DETAILS")!);

  const getUsers = async (filter: string) => {
    let formattedData: UserTableData[] = [];
    await axiosGetModule(
      APIEndPoints.GET_ALL_USERS + `?page=${current_page}&key=${filter}`
    )
      .then((res: GetUserResponse) => {
        setMetaData(res.data.meta);
        res?.data.data.forEach((element: UserData) => {
          if (element.id !== userDetails.user.id) {
            formattedData.push({
              name: `${element?.first_name} ${element?.last_name}`,
              email: `${element.email}`,
              role: `${element.roles[0].id}`,
              status: `${element.isActive}`,
              delete: "Delete",
              user_id: `${element.id}`,
            });
          }
        });
        if (current_page == 1) setUserTableData([...formattedData]);
        else setUserTableData([...userTableData, ...formattedData]);
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        setLoading?.(false);
      });
  };

  const clearFilter = () => {
    getUsers("");
  };

  const getRoles = async () => {
    let temp: RoleOptions[] = [];
    await axiosGetModule(APIEndPoints.GET_ALL_ROLES)
      .then((res: GetRolesResponse) => {
        res.data.forEach((item: Roles) => {
          temp.push({label: `${item.role}`, value: parseInt(`${item.id}`)});
        });
        setRoles(temp);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  if (loading) return <Loader />;

  if (!userTableData?.length)
    return (
      <div className="flex justify-center flex-col mt-20 items-center pt-8">
        <p className="text-2xl mb-8">No Users Found</p>
        <ViewButton name={"Clear Filter"} onClick={clearFilter} />
      </div>
    );

  const roleColumn = [
    {Header: "Name", accessor: "name"},
    {Header: "Email", accessor: "email"},
    {Header: "Role", accessor: "role"},
    {Header: "Status", accessor: "status"},
    {Header: "", accessor: "delete"},
  ];

  const assignRole = async (role: number, user_id: number) => {
    setLoading?.(true);
    let body = {
      user_id: user_id,
      role_id: role,
    };
    let newData: any = [];
    await axiosPatchModule(APIEndPoints.ASSIGN_ROLE, body)
      .then((res: updateAPIResponse) => {
        if (res.success) {
          toast.success("Role Updated SuccessFully");
          newData = userTableData.map((ele: any) => {
            if (ele.user_id === user_id) {
              ele.role = role;
            }
            return ele;
          });
          setUserTableData(newData);
        }
        setLoading?.(false);
      })
      .catch((err: any) => {
        console.log(err);
        setLoading?.(false);
      });
  };

  const updateStatus = async (status: number, user_id: number) => {
    setLoading?.(true);
    let newData: any = [];
    let body = {};
    await axiosPatchModule(
      APIEndPoints.UPDATE_USER_STATUS(status, user_id),
      body
    )
      .then((res: updateAPIResponse) => {
        if (res.success) {
          toast.success("Status Updated SuccessFully");
          newData = userTableData.map((ele: any) => {
            if (ele.user_id === user_id) {
              if (ele.status == "0") ele.status = "1";
              else ele.status = "0";
            }
            return ele;
          });
          setUserTableData(newData);
        }
        setLoading?.(false);
      })
      .catch((err: any) => {
        console.log(err);
        setLoading?.(false);
      });
  };

  const deleteUser = async (user_id: number) => {
    setLoading?.(true);
    let newData: any = [];
    await axiosDeleteModule(APIEndPoints.DELETE_USER(user_id))
      .then((res: updateAPIResponse) => {
        newData = userTableData.filter((ele: any) => {
          return ele.user_id !== user_id;
        });
        setUserTableData(newData);
        toast.success("User Deleted");
        setLoading?.(false);
      })
      .catch((err: any) => {
        console.log(err);
        setLoading?.(false);
      });
  };

  return (
    <div className="mt-4">
      <div className="flex items-center justify-between">
        <p className="text-black font-bold text-2xl">Users & Roles</p>
        <div className="w-1/3 flex justify-end">
          <button
            onClick={() => {
              setCreateRoleModal(true);
            }}
          >
            <Buttons text={"Create User"} extraStyles={buttonStyles} />
          </button>
        </div>
      </div>
      <div className="my-3">
        <div className="flex items-center w-80">
          {userTableData?.length ? (
            <div className="w-1/2 mr-8 bg-blue-200">
              <input
                type="text"
                placeholder="Search Users"
                onChange={debounceSearch}
                className="w-full h-10 px-2 text-sm focus:outline-none shadow-md rounded border border-white-300"
                style={{height: 50}}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div>
        <div className="mt-12">
          <div className={styles.react_table}>
            <Table
              columns={roleColumn}
              data={userTableData}
              className={styles.react_table}
              roles={roles}
              assignRole={assignRole}
              updateStatus={updateStatus}
              deleteUser={deleteUser}
            />
          </div>
        </div>
      </div>
      <SizedBox height="20px" />
      {metaData?.current_page !== metaData?.last_page ? (
        <div className="flex flex-col items-center">
          <ViewButton
            invert
            name={loading ? <Spinner size={15} /> : strings.more}
            isDisabled={loading ? true : false}
            onClick={loadMore}
            style={{paddingInline: "50px"}}
          />
          <SizedBox height="20px" />
        </div>
      ) : null}
      <Modal
        open={createRoleModal}
        onClose={() => setCreateRoleModal(false)}
        showCloseIcon={false}
        center
        styles={{modal: {borderRadius: 10}}}
      >
        <div>
          <p className="text-black font-bold text-2xl">Create User</p>
          <div className="flex justify-between">
            <div className="mt-8">
              <p className="text-md">
                First Name
                <span className="text-red-500"> *</span>
              </p>
              <div className="relative">
                <input
                  type="text"
                  onChange={(e) =>
                    handleFieldChanges("first_name", e.target.value)
                  }
                  className="mt-2 bg-white-800 h-10 w-100 border border-white-300 border-solid rounded pl-2 text-xs"
                  placeholder="Enter First Name"
                />
              </div>
            </div>

            <div className="mt-8 ml-8">
              <p className="text-md">
                Last Name
                <span className="text-red-500"> *</span>
              </p>
              <div className="relative">
                <input
                  type="text"
                  onChange={(e) =>
                    handleFieldChanges("last_name", e.target.value)
                  }
                  className="mt-2 bg-white-800 h-10 w-100 border border-white-300 border-solid rounded pl-2 text-xs"
                  placeholder="Enter Last Name"
                />
              </div>
            </div>
          </div>

          <div className="mt-8">
            <p className="text-md">
              Email
              <span className="text-red-500"> *</span>
            </p>
            <div className="relative">
              <input
                type="email"
                onChange={(e) => handleFieldChanges("email", e.target.value)}
                className="mt-2 bg-white-800 h-10 w-100 border border-white-300 border-solid rounded pl-2 text-xs"
                placeholder="Enter Email"
              />
            </div>
          </div>

          <div className="mt-8">
            <p className="text-md">
              Assign Role
              <span className="text-red-500"> *</span>
            </p>
            <div className={styles.selectdiv}>
              <select
                name="role"
                onChange={(e) => {
                  handleFieldChanges("role", e.target.value);
                }}
              >
                <option value="" disabled selected>
                  Select Role
                </option>
                {roles?.map((item: RoleOptions) => {
                  return (
                    <option
                      value={item.value}
                      className="mt-2 bg-white-800 h-10 w-100 border border-white-300 border-solid rounded px-2 text-md"
                    >
                      {item.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <ViewButton
            name={modalLoading ? <Spinner size={15} /> : "Create User"}
            onClick={createUser}
            isDisabled={modalLoading}
            style={{marginTop: "10%", width: "150px"}}
          />
        </div>
      </Modal>
    </div>
  );
};
export default RoleManagement;

const Table: React.FC<TableProps> = ({
  columns,
  data,
  roles,
  assignRole,
  updateStatus,
  deleteUser,
}) => {
  const styles = {
    control: (base: CSSProperties) => ({
      ...base,
      background: AppColors.WHITE,
      boxSizing: "border-box",
      width: "100px",
      fontSize: "16px",
      borderRadius: "6px",
      boxShadow: "none",
      borderColor: AppColors.WHITE,
      "&:hover": {
        border: `1px solid ${AppColors.WHITE}`,
      },
    }),
    container: (provided: CSSProperties) => ({
      ...provided,
      width: "100px",
      outline: "none",
      fontSize: "16px",
      borderRadius: "6px",
      background: AppColors.WHITE,
    }),
    option: (provided: CSSProperties) => ({
      ...provided,
    }),
  };

  const userStatus = [
    {label: "Inactive", value: 0},
    {label: "Active", value: 1},
  ];

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable({
      //@ts-ignore
      columns,
      data,
    });

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, index) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr className="my-4" {...row.getRowProps()}>
              {row.cells.map((cell, index) => {
                return (
                  <td key={index.toString()} style={{width: "70px"}}>
                    <p
                      className={`text-base font-semibold text-ellipsis whitespace-nowrap overflow-hidden ${
                        [4].includes(index)
                          ? "text-blue-900 cursor-pointer"
                          : "text-lightBlack-300 cursor-auto"
                      }`}
                      style={index === 2 ? {width: "200px"} : {}}
                    >
                      {cell.value ? (
                        index == 2 ? (
                          <CustomSelect
                            options={roles}
                            value={roles.find(
                              (item: RoleOptions) => item.value == cell.value
                            )}
                            customStyles={{styles}}
                            onChange={(e: any) => {
                              //@ts-ignore
                              assignRole(e.value, cell.row.original.user_id);
                            }}
                          />
                        ) : cell.value ? (
                          index == 3 ? (
                            <CustomSelect
                              options={userStatus}
                              value={userStatus.find(
                                (item: RoleOptions) => item.value == cell.value
                              )}
                              customStyles={{styles}}
                              onChange={(e: any) => {
                                updateStatus(
                                  e.value,
                                  //@ts-ignore
                                  cell.row.original.user_id
                                );
                              }}
                            />
                          ) : index == 4 ? (
                            <span
                              onClick={() => {
                                //@ts-ignore
                                deleteUser(cell.row.original.user_id);
                              }}
                            >
                              <Delete color={"red"} size={20} />
                            </span>
                          ) : (
                            cell.value
                          )
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </p>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
