import {Images} from "Images";

const NotFound = () => {
  return (
    <div className="flex justify-center items-center" style={{height: "80vh"}}>
      <img src={Images.NotFound} alt="not found" />
    </div>
  );
};

export default NotFound;
