import React, {useState} from "react";
import {Formik} from "formik";
import * as yup from "yup";
import {createBrowserHistory} from "history";
import {Bounce} from "react-activity";
import styles from "./ResetPassword.module.scss";

import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import SizedBox from "components/SizedBox/SizedBox";

import sourcebae from "Images/sourcebae-cropped.svg";
import resetPassImg from "Images/forgot_password.svg";
import backArrow from "Images/Back.svg";

import {APIEndPoints} from "globals/constants/endPoints";
import {axiosPatchModule} from "network/Patch/AxiosPatchModule";

type initialValueForm1 = {
  email: string;
};

const ResetPassword: React.FC = () => {
  const {loading, setLoading} = useAppCommonDataProvider();
  const history = createBrowserHistory();

  let initialValueForm1: initialValueForm1 = {
    email: "",
  };

  const schemaForm1 = yup.lazy((v) =>
    yup.object().shape({
      email: yup
        .string()
        .required("Email is required")
        .email("Enter a valid email"),
    })
  );

  const [showForm, setShowForm] = useState(true);

  const onclick = async (data: initialValueForm1) => {
    setLoading?.(true);
    await axiosPatchModule(APIEndPoints.FORGOT_PASSWORD_NODE, {
      email: data.email,
    })
      .then((res) => setShowForm(false))
      .catch((err) => console.log(err))
      .finally(() => setTimeout(() => setLoading?.(false)));
  };

  return (
    <div className={`${styles.reset__password__wrap}`}>
      <div className={`${styles.logo__row}`}>
        <img src={sourcebae} alt="" />
      </div>
      <div className={`${styles.reset__password__detail}`}>
        <div className={`${styles.reset__password__img}`}>
          <img
            src={resetPassImg}
            alt=""
            className="w-65"
            style={{marginBottom: "2.3rem"}}
          />
        </div>
        <div className={`${styles.reset__password__info}`}>
          <div>
            <h1 className="font-bold text-3xl ">Reset Password</h1>
            <SizedBox height="10px" />
            <p
              className="opacity-50 text-sm"
              style={{color: "rgba(29, 36, 52, 0.7)"}}
            >
              Don’t Worry !! we are here to help you, just put down your mail in
              box and we are sending you a reset password link.
            </p>
          </div>
          <SizedBox height="40px" />

          {showForm ? (
            <div className={`${styles.reset__password__form_1}`}>
              <Formik
                initialValues={initialValueForm1}
                enableReinitialize
                onSubmit={onclick}
                validationSchema={schemaForm1}
              >
                {({handleSubmit, handleChange, errors, touched}) => {
                  return (
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                    >
                      <div
                        className={`${styles.reset__password__input__feild}`}
                      >
                        <label className="font-semibold" htmlFor="">
                          Enter Your Registerd Email Id
                        </label>
                        <input
                          type="email"
                          placeholder="Example@gmali.com"
                          id="email"
                          name="email"
                          onChange={handleChange("email")}
                          className="opacity-50 text-sm"
                        />
                        {errors.email && touched.email && (
                          <span className={styles.error_message}>
                            {errors.email}
                          </span>
                        )}
                      </div>
                      <SizedBox height="40px" />

                      <div className={`${styles.reset__password__buttons}`}>
                        <div
                          className={`${styles.reset__password__back_btn} text-sm`}
                          onClick={() => {
                            //@ts-ignore
                            history.back();
                          }}
                        >
                          <img src={backArrow} alt="" style={{width: "25%"}} />
                          Back To Login
                        </div>
                        <div className={`${styles.reset__password__send_btn}`}>
                          <button
                            type="submit"
                            name="Send"
                            className="w-full"
                            style={{
                              border: "none",
                              padding: "0.4rem 2rem",
                              borderRadius: "0.3rem",
                              width: "100%",
                              color: "#ffffff",
                              justifyContent: "center",
                              backgroundColor: "#BC53FF",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              handleSubmit();
                            }}
                            disabled={loading ? true : false}
                          >
                            {loading ? <Bounce /> : `Send`}
                          </button>
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          ) : (
            <div className={styles.reset__password__form_2}>
              {" "}
              <h3>Please check your email.</h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
