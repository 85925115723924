import axios from "axios";
import {staticHeaders} from "globals/helpers";
import {errorHandler} from "network/ResponseHandlers/errorHandler";
import {successHandler} from "network/ResponseHandlers/successHandler";

const axiosGetModule = async (
  url: string,
  headers: {[key: string]: string} = staticHeaders()
) => {
  return await axios
    .get(url, {headers})
    .then((res) => successHandler(res?.data))
    .catch((err) => errorHandler(err));
};

export default axiosGetModule;
