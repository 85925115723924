import React, {memo, useEffect, useRef, useState} from "react";
import styles from "./styles.module.css";

interface AudioLevelTrackerProps {
  startAudioTracker: boolean;
  setRecorder: (arg: MediaRecorder | null) => void;
  setRecordedChunks: React.Dispatch<React.SetStateAction<Blob[]>>;
}

function AudioLevelTracker({
  startAudioTracker,
  setRecorder,
  setRecordedChunks,
}: AudioLevelTrackerProps) {
  const [audioLevel, setAudioLevel] = useState<number>(0);
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const dataArrayRef = useRef<Uint8Array | null>(null);

  const numLines = 14;
  const filledLines = Math.round(audioLevel * numLines);

  useEffect(() => {
    let isAudioContextActive = false;
    if (startAudioTracker) {
      setRecordedChunks([]);
      const setupAudioProcessing = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            audio: {echoCancellation: true, noiseSuppression: true},
          });

          const mimeType = MediaRecorder.isTypeSupported("audio/mp4")
            ? "audio/mp4"
            : "audio/webm";

          const newRecorder = new MediaRecorder(stream, {
            mimeType: mimeType,
          });
          setRecorder(newRecorder);

          newRecorder.ondataavailable = (e) => {
            setRecordedChunks((prev) => [...prev, e.data]);
          };
          newRecorder.start(600);

          const AudioContext =
            window.AudioContext || (window as any).webkitAudioContext;

          if (
            !audioContextRef.current ||
            audioContextRef.current.state === "closed"
          ) {
            const audioContext = new AudioContext();
            audioContextRef.current = audioContext;
            isAudioContextActive = true;
          }

          const analyser = audioContextRef.current!.createAnalyser();
          analyser.fftSize = 256;
          analyserRef.current = analyser;

          const bufferLength = analyser.frequencyBinCount;
          const dataArray = new Uint8Array(bufferLength);
          dataArrayRef.current = dataArray;
          const source =
            audioContextRef.current!.createMediaStreamSource(stream);
          source.connect(analyser);

          const updateAudioLevel = () => {
            if (analyserRef.current && dataArrayRef.current) {
              analyserRef.current.getByteFrequencyData(dataArrayRef.current);
              const values = dataArrayRef.current.reduce(
                (acc, val) => acc + val,
                0
              );
              const average = values / dataArrayRef.current.length;

              setAudioLevel(average / 256);
            }
            if (isAudioContextActive) {
              requestAnimationFrame(updateAudioLevel);
            }
          };

          updateAudioLevel();
        } catch (err) {
          console.error("Error accessing the microphone", err);
        }
      };

      setupAudioProcessing();
    }

    return () => {
      if (
        audioContextRef.current &&
        audioContextRef.current.state !== "closed"
      ) {
        audioContextRef.current
          .close()
          .then(() => {})
          .catch((err) => {
            console.error("Error closing AudioContext", err);
          });
      }
    };
  }, [startAudioTracker]);

  return (
    <div className={styles.main_wrapper}>
      <h3 className="font-semibold text-14 lg:text-16">Level Input</h3>
      <div className={styles.wave_container}>
        {Array.from({length: numLines}).map((_, index) => (
          <div
            key={index}
            className={`${styles.vertical_line} ${
              index < filledLines ? "bg-[#EB4EB0]" : "bg-[#FBEAE8]"
            }`}
            style={{transition: "background-color 0.3s ease"}}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default memo(AudioLevelTracker);
