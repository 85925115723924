import {AppColors} from "globals/appcolors";
import strings from "globals/constants/strings.json";

export const QuestionTimer = 60;
export const SecondRoundPerQuestionTime = 50; // 1.5 minutes => 90 seconds
export const SecondRoundScreenshotsPerQuestion = 5;
export const NumberOfQuestionsInSecondRound = 10;

export const IntroductionQuestions = [
  "Please tell us something about yourself",
  "How many projects have you worked on till now?",
  "Please mention what's your stregth and how it helps you in your working.",
];

export const LEGEND = [
  {
    title: strings.interview.legend.attempted,
    backgroundColor: AppColors.ANSWERED_GREEN,
  },
  {
    title: strings.interview.legend.unanswered,
    backgroundColor: AppColors.WHITE_300,
  },
];

export const interviewHeaders = {
  Authorization: `Bearer ${localStorage.getItem("candidateToken")}`,
  Accept: "application/json",
};

export enum SENTIMENTS {
  POSITIVE = "POSITIVE",
  NEGATIVE = "NEGATIVE",
  NEUTRAL = "NEUTRAL",
}

export const TabSwitchWarning =
  "You're not allowed to switch tabs. Please return back to the page before your test ends.";
