import React, {ChangeEvent, useEffect, useRef} from "react";
import strings from "globals/constants/strings.json";
import {
  CandidateDetails_NewAssessment,
  NewCandidateFields,
} from "globals/types/globalTypes";
import Select from "components/Inputs/Select/CustomSelect";
import {experienceOptions} from "globals/constants/dropdownConstants";
import {AppColors} from "globals/appcolors";
import {Field} from "formik";

interface Props {
  title: string;
  handleFieldChanges: (
    field: NewCandidateFields,
    value: string | number | any
  ) => void;
  values: CandidateDetails_NewAssessment;
  assessmentType: "regular" | "bulk";
  errors?: any;
}

const CandidateDetails: React.FC<Props> = ({
  title,
  handleFieldChanges,
  values,
  assessmentType,
  errors,
}) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleFieldChanges("resume", event.target.files![0]);
  };

  const uploadResume = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    hiddenFileInput.current?.click();
  };
  useEffect(() => {
    console.log(errors, "errorsssssssss on candidateDetails form");
  });

  return (
    <div>
      <div>
        <p className="font-bold text-2xl">{title}</p>
      </div>
      <div className="pl-2 max-w-3/5">
        <div className={`mt-8 ${assessmentType === "bulk" ? "hidden" : ""}`}>
          <div className="flex items-center">
            <div className="flex-1 relative">
              <p className="text-sm">
                {strings.first_name}
                <span className="text-red-500"> *</span>
              </p>
              <Field
                type="text"
                name="firstname"
                // value={values.firstName}
                // onChange={(e: any) =>
                //   handleFieldChanges("firstName", e.target.value)
                // }
                className="mt-2 bg-white-800 w-4/5 h-10 border border-white-300 border-solid rounded pl-2 text-xs"
                placeholder={strings.first_name}
              />
              {errors.firstname && (
                <p className="text-red-500 text-xs absolute -bottom-4 left-0">
                  {errors.firstname}
                </p>
              )}

              {/* <Field name="token" type="tel" style={{ border: "2px solid black", marginTop: '2rem' }} />
              {errors.token && <p className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.token}</p>} */}

              {/* {errors!.includes(strings.create_assessment.errors.firstName) && (
                <p className="text-red-500 text-xs absolute -bottom-4 left-0">
                  Enter first name
                </p>
              )} */}
            </div>

            <div className="flex-1 relative">
              <p className="text-sm">
                {strings.last_name}
                <span className="text-red-500"> *</span>
              </p>
              <Field
                type="text"
                name="lastname"
                // value={values.lastName}
                // onChange={(e: any) => handleFieldChanges("lastName", e.target.value)}
                className="mt-2 bg-white-800 w-4/5 h-10 border border-white-300 border-solid rounded pl-2 text-xs"
                placeholder={strings.last_name}
              />
              {errors.lastname && (
                <p className="text-red-500 text-xs absolute -bottom-4 left-0">
                  {errors.lastname}
                </p>
              )}

              {/* {errors!.includes(strings.create_assessment.errors.lastName) && (
                <p className="text-red-500 text-xs absolute -bottom-4 left-0">
                  Enter last name
                </p>
              )} */}
            </div>
          </div>
        </div>

        <div className={`mt-8 ${assessmentType === "bulk" ? "hidden" : ""}`}>
          <p className="text-sm">
            {strings.email}
            <span className="text-red-500"> *</span>
          </p>
          <div className="relative">
            <Field
              type="email"
              name="email"
              // value={values.email}
              // onChange={(e: any) => handleFieldChanges("email", e.target.value)}
              className="mt-2 bg-white-800 w-3/4 h-10 border border-white-300 border-solid rounded pl-2 text-xs"
              placeholder={strings.email}
            />
            {errors.email && (
              <p className="text-red-500 text-xs absolute -bottom-4 left-0">
                {errors.email}
              </p>
            )}
          </div>
        </div>
        <div className="mt-8 w-3/4 relative">
          <p className="text-sm mb-2">
            {strings.experience}
            <span className="text-red-500"> *</span>
          </p>
          <Select
            options={experienceOptions}
            defaultValue={experienceOptions[0].value}
            onChange={(evt: {value: number}) => {
              handleFieldChanges("experience", evt.value);
            }}
            isMulti={false}
            isAnimated={false}
          />
          {/* {errors!.includes(strings.create_assessment.errors.experience) && (
            <p className="text-red-500 text-xs absolute -bototm-4 left-0">
              Select experience
            </p>
          )} */}
        </div>
        <div className="mt-8">
          <p className="text-sm">
            {strings.create_assessment.candidate_source}
            <span className="text-red-500"> *</span>
          </p>
          <div className="relative">
            <Field
              type="text"
              name="source"
              // value={values.source}
              // onChange={(e: any) => handleFieldChanges("source", e.target.value)}
              className="mt-2 bg-white-800 w-3/4 h-10 border border-white-300 border-solid rounded pl-2 text-xs"
              placeholder={strings.create_assessment.candidate_source}
            />
            {errors.source && (
              <p className="text-red-500 text-xs absolute -bottom-4 left-0">
                {errors.source}
              </p>
            )}

            {/* {errors!.includes(strings.create_assessment.errors.source) && (
              <p className="text-red-500 text-xs absolute -bottom-4 left-0">
                Enter source
              </p>
            )} */}
          </div>
        </div>
        <div className={`mt-8 ${assessmentType === "bulk" ? "hidden" : ""}`}>
          <p className="text-sm">{strings.create_assessment.resume}</p>
          <div
            className="mt-2 w-full border border-dotted flex items-center p-4 py-6 rounded"
            style={{borderColor: AppColors.BLACK_BORDER_LIGHT}}
          >
            <div className="flex flex-0.3 items-center justify-center">
              <button
                style={{background: AppColors.LIGHT_BG_BLUE}}
                className="w-1/2 h-10 rounded text-blue-500 font-bold text-sm"
                onClick={uploadResume}
              >
                {strings.upload}
              </button>
            </div>
            <div className="flex flex-0.7 text-black-700 items-center text-sm">
              {values.resume
                ? values.resume.name
                : strings.create_assessment.no_resume}
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{display: "none"}}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateDetails;
