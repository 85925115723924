import InterviewHeader from "components/InterviewHeader";
import {memo} from "react";
import commonStyles from "./InterviewPreview.module.css";
import styles from "./ThankYouScreen.module.css";

const ThankYouScreen = () => {
  return (
    <div className={`${commonStyles.main_wrapper} h-[100vh]`}>
      <InterviewHeader title="Interview Completed" />
      <div className={styles.wrapper}>
        <h1 className={styles.head}>Thank you</h1>
        <p className={styles.content}>
          <span className="text-[#02BC7D] font-bold">Congrats!</span> Thank you
          for completing the interview! We are currently analyzing your
          performance data and will share your Interview Report Card with you
          via email shortly.
        </p>
        <p className={styles.content}>
          We appreciate your patience, and if you have any questions in the
          meantime, feel free to reach out. at{" "}
          <a href="mailto:help@recruitbae.com" className="font-bold">
            help@recruitbae.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default memo(ThankYouScreen);
