import React from "react";
import "./Search.scss";
import searchImg from "Images/Search.svg";

interface Props {
  placeholder?: string;
  onChange?: (value: string) => void;
  value?: string;
}

const Search: React.FC<Props> = ({
  placeholder = "Search by name",
  onChange,
  value,
}) => {
  return (
    <div className="search__wrap">
      <input
        className="search__input"
        type="text"
        placeholder={placeholder}
        onChange={(e) => onChange?.(e.target.value)}
        // {...(selectedSkill?.label ? { value: selectedSkill } : {})}
        {...(value ? {value: value} : {})}
      />
      <img src={searchImg} alt="search" className="search__img" />
    </div>
  );
};

export default Search;
