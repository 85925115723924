import {useQuery, useQueryClient} from "@tanstack/react-query";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import {APIEndPoints} from "globals/constants/endPoints";
import {getIPOfUser} from "globals/helpers";
import {candidateInstance} from "globals/axiosConstants";

export function useUpdateIp(assessmentId: string) {
  const {interview_details} = useAppCommonDataProvider();
  const {assessment} = interview_details?.data || {};
  const {ip_change_count} = assessment || {};

  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ["updateIp"],
    queryFn: async () => {
      const userIP = await getIPOfUser();
      return candidateInstance
        .patch(
          APIEndPoints.UPDATE_IP_SESSION_TIME_REMAINING_ASSESMENT(assessmentId),
          {
            ip_address: userIP,
            ip_change_count: ip_change_count
              ? parseInt(ip_change_count) + 1
              : 1,
          }
        )
        .then((res) => {
          queryClient.invalidateQueries(["updateIp"]);
          if (res.data.success === true && res.data.code === 201) return res;
        });
    },
    onError: (e) => e,
    enabled: false,
  });
}
