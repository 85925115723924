import React, {useState} from "react";
import {Button, Typography} from "@mui/material";
import {saveAs} from "file-saver";

import {Row} from "components/Row";
import SizedBox from "components/SizedBox/SizedBox";

import {AppColors} from "globals/appcolors";
import {CandidateDetails} from "globals/types/APIResponses";
import {DownloadBold} from "components/Icons";
import {makeStyles} from "@mui/styles";
import {AllScreenshots} from "components/Popups/AllScreenshots";
import Modal from "react-responsive-modal";

interface IProps {
  assessmentDetails: CandidateDetails;
}

const useStyles = makeStyles({
  button: {
    height: 70,
    width: 150,
    marginTop: 25,
    background: AppColors.WHITE,
    color: AppColors.BLACK,
    border: `1px solid ${AppColors.WHITE_300}`,

    "&:hover": {
      background: AppColors.WHITE,
    },
  },
});

export const VideoAndScreenshots: React.FC<IProps> = ({assessmentDetails}) => {
  const {screenshots} = assessmentDetails.data.assessment;
  const screenshotList = screenshots.filter(
    (screenshot) => screenshot.title === "Screenshot"
  );

  const [showScreenShotModal, setShowScreenShotModal] =
    useState<boolean>(false);
  const [showScreenshot, setShowScreenshot] = useState<{
    visible: boolean;
    url: string;
  }>({visible: false, url: ""});

  const classes = useStyles();

  return (
    <Row classNames="flex-col border-r border-dashed border-monoGrey-400 px-2">
      <Typography
        fontWeight={"bold"}
        fontSize={16}
        color={AppColors.LIGHTBLACK_500}
      >
        Introduction Video
      </Typography>
      <SizedBox height="20px" />
      <div>
        <Button
          sx={{paddingY: 1, paddingX: 3}}
          onClick={() => saveAs(screenshots[0].path, "video.mp4")}
        >
          <Typography
            sx={{fontWeight: "bold", marginRight: 1}}
            variant="button"
          >
            Download
          </Typography>
          <DownloadBold size={15} />
        </Button>
      </div>

      <SizedBox height="40px" />
      <Typography
        fontWeight={"bold"}
        fontSize={16}
        color={AppColors.LIGHTBLACK_500}
      >
        Screenshots
      </Typography>
      <SizedBox height="20px" />
      <Row classNames="flex-wrap justify-evenly">
        {screenshotList.length > 0 ? (
          screenshotList.slice(0, 6).map((screenshot, index) => {
            return index < 5 ? (
              <img
                src={screenshot.path}
                alt=""
                key={screenshot.id}
                style={{
                  width: 180,
                  height: 120,
                  marginTop: 15,
                  marginRight: 4,
                }}
                className="rounded cursor-pointer"
                onClick={() =>
                  setShowScreenshot({
                    visible: true,
                    url: screenshot.path,
                  })
                }
              />
            ) : (
              <Button
                className={classes.button}
                key={"no-ss-btn"}
                onClick={() => setShowScreenShotModal(true)}
              >
                <Typography>{`+${
                  assessmentDetails.data.assessment?.screenshots.length - 6
                } more`}</Typography>
              </Button>
            );
          })
        ) : (
          <Typography
            sx={{
              textAlign: "center",
              color: AppColors.LIGHTBLACK_300,
              fontStyle: "italic",
              fontWeight: "400",
              paddingX: 2,
            }}
          >
            No screenshots found for this assessment
          </Typography>
        )}

        {showScreenShotModal === true && (
          <AllScreenshots
            open={showScreenShotModal}
            onClose={() => setShowScreenShotModal(false)}
            screenshots={screenshotList}
          />
        )}

        <Modal
          open={showScreenshot.visible}
          onClose={() => setShowScreenshot({visible: false, url: ""})}
          center
          showCloseIcon={false}
          styles={{modal: {borderRadius: 10, padding: 0}}}
        >
          <img
            src={showScreenshot.url}
            alt=""
            style={{width: "50vw", height: "60vh"}}
          />
        </Modal>
      </Row>
    </Row>
  );
};
