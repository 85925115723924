import React, {useEffect, useState} from "react";
import {Formik} from "formik";
import * as yup from "yup";
import cookies from "react-cookies";
import styles from "./ResetPassword.module.scss";

import sourcebae from "Images/sourcebae-cropped.svg";
import resetPassImg from "Images/generatedPassword.svg";
import bookImg from "Images/generatedPasswordBook.svg";
import arrow from "Images/generatePasswordBG.svg";
import CustomButton from "components/Button/CustomButton";

import SizedBox from "components/SizedBox/SizedBox";
import {Images} from "Images";
import {axiosPatchModule} from "network/Patch/AxiosPatchModule";
import {APIEndPoints} from "globals/constants/endPoints";
import {COOKIESKEYS} from "globals/constants/cookiesKeys";
import {toast} from "react-toastify";
import {useNavigate, useSearchParams} from "react-router-dom";
import {RouteLinks} from "globals/constants/routeLinks";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import {Eye, EyeHidden} from "components/Icons";
import {Bounce} from "react-activity";
import {AppColors} from "globals/appcolors";
import {createMixPanelUser} from "globals/helpers";

type initialValues = {
  password: string;
  confirmPassword: string;
};

const PasswordChange: React.FC = () => {
  const {loading, setLoading} = useAppCommonDataProvider();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [formType, setFormType] = useState<"reset" | "forgot" | "">("");

  let initialValues: initialValues = {
    password: "",
    confirmPassword: "",
  };

  const schema = yup.lazy((v) =>
    yup.object().shape({
      password: yup
        .string()
        .required("Password is required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&^!])[A-Za-z\d@$!%*#?&^!]{8,}$/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
      confirmPassword: yup
        .string()
        .required("Password is required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&^!])[A-Za-z\d@$!%*#?&^!]{8,}$/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        )
        .test("confirmPassword", "Password should be same!", () => {
          return v.password === v.confirmPassword;
        }),
    })
  );

  useEffect(() => {
    configurePage();
  }, []);

  const configurePage = () => {
    const direction = queryParams.get("forgot_token");
    if (direction) setFormType("forgot");
    else setFormType("reset");
  };

  const forgotPassword = async (data: initialValues) => {
    setLoading?.(true);
    const forgot_token = queryParams.get("forgot_token");
    const user = queryParams.get("user");
    const payload = {
      password: data.password,
      password_confirmation: data.confirmPassword,
    };
    await axiosPatchModule(
      `${APIEndPoints.UPDATE_PASSWORD_NODE}?user=${user}&forgot_token=${forgot_token}`,
      payload
    )
      .then((res: {message: string}) => {
        toast.success(res.message);
        navigate(`/${RouteLinks.Login}`);
      })
      .catch((err) => {
        const {status} = err;
        if (status === 400) toast.error(err.data.message);
        if (status === 422) {
          const error_keys = Object.keys(err.data.errors);
          const error = err.data.errors[error_keys[0]];
          toast.error(error[0]);
        }
      })
      .finally(() => setTimeout(() => setLoading?.(false), 800));
  };

  const handleResetPass = async (data: initialValues) => {
    setLoading?.(true);
    const user = JSON.parse(localStorage.getItem("USER_DETAILS")!);
    const password = cookies.load(COOKIESKEYS.PASSWORD);
    const body = {
      old_password: password,
      password: data.password,
      password_confirmation: data.confirmPassword,
    };
    console.log(body);
    await axiosPatchModule(
      `${APIEndPoints.RESET_PASSWORD_NODE}?id=${user.user.id}`,
      body
    )
      .then((res: {message: string}) => {
        toast.success(res.message);
        cookies.remove(COOKIESKEYS.PASSWORD);
        navigate(`/${RouteLinks.Dashboard}`);
        createMixPanelUser(user);
      })
      .catch((err) => {
        const {status} = err;
        if (status === 400) toast.error(err.data.message);
        if (status === 422) {
          const error_keys = Object.keys(err.data.errors);
          const error = err.data.errors[error_keys[0]];
          toast.error(error[0]);
        }
      })
      .finally(() => setTimeout(() => setLoading?.(false), 800));
  };

  return (
    <div className={`${styles.reset__password__wrap}`}>
      <div className={`${styles.logo__row}`}>
        <img src={sourcebae} alt="" />
      </div>
      <div
        className="flex m-auto bg-no-repeat bg-left-bottom bg-contain  "
        style={{
          backgroundImage: `url(${Images.bottomBg})`,
          height: "-webkit-fill-available",
        }}
      >
        <div className="flex m-auto w-85">
          <div className={`${styles.reset__password__img}`}>
            <img src={resetPassImg} alt="" />
          </div>
          <div className="flex items-end opacity-10 ">
            <img src={bookImg} alt="" />
          </div>
          <div className="flex flex-col pl-8">
            {formType === "reset" && (
              <>
                <div className="flex flex-col pr-8">
                  <h1 className="font-bold text-3xl ">Hii, Welcome</h1>
                  <SizedBox height="10px" />
                  <p
                    className="text-sm"
                    style={{color: "rgba(29, 36, 52, 0.7)"}}
                  >
                    We Are Happy To See You There, Here You Are Creating Your
                    Own Password.
                  </p>
                </div>
                <SizedBox height="40px" />

                <div className={`${styles.reset__password__form_2}`}>
                  <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    onSubmit={(data) => {
                      handleResetPass(data);
                    }}
                    validationSchema={schema}
                  >
                    {({
                      values,
                      handleSubmit,
                      handleChange,
                      setFieldValue,
                      errors,
                      status,
                      touched,
                    }) => {
                      return (
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                          }}
                        >
                          <div
                            className={`${styles.reset__password__input__feild}`}
                          >
                            <label htmlFor="">Create Password</label>
                            <div className="relative">
                              <input
                                type={showPassword ? "text" : "password"}
                                id="password"
                                name="password"
                                onChange={handleChange("password")}
                              />
                              {!showPassword ? (
                                <div
                                  onClick={() => setShowPassword(!showPassword)}
                                  className="hover:cursor-pointer absolute right-4 top-2 z-10"
                                >
                                  <Eye size={23} />
                                </div>
                              ) : (
                                <div
                                  onClick={() => setShowPassword(!showPassword)}
                                  className="hover:cursor-pointer absolute right-4 top-2 z-10"
                                >
                                  <EyeHidden size={23} />
                                </div>
                              )}
                            </div>
                            {errors.password && touched.password && (
                              <span className={styles.error_message}>
                                {errors.password}
                              </span>
                            )}
                          </div>
                          <SizedBox height="10px" />
                          <div
                            className={`${styles.reset__password__input__feild} ${styles.reset__password__input__feild_form_2} `}
                          >
                            <label htmlFor="">Re-enter password</label>
                            <div className="relative">
                              <input
                                type={showPassword ? "text" : "password"}
                                id="confirmPassword"
                                name="confirmPassword"
                                onChange={handleChange("confirmPassword")}
                              />
                            </div>
                            {errors.confirmPassword &&
                              touched.confirmPassword && (
                                <span className={styles.error_message}>
                                  {errors.confirmPassword}
                                </span>
                              )}
                          </div>
                          <SizedBox height="40px" />
                          <div className="flex">
                            <div
                              className={`${styles.reset__password__send_btn}`}
                            >
                              <CustomButton
                                type="submit"
                                isFilled={false}
                                name={loading ? <Bounce /> : "Send"}
                                containerStyle={{width: "100%"}}
                                buttonStyle={{
                                  border: "none",
                                  padding: "0.4rem 2rem",
                                  borderRadius: "0.3rem",
                                  width: "100%",
                                  color: AppColors.WHITE,
                                  backgroundColor: "#BC53FF",
                                }}
                              />{" "}
                            </div>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </>
            )}

            {formType === "forgot" && (
              <>
                <div className="flex flex-col pr-8">
                  <h1 className="font-bold text-3xl ">Hii</h1>
                  <SizedBox height="10px" />
                  <p
                    className="text-sm"
                    style={{color: "rgba(29, 36, 52, 0.7)"}}
                  >
                    We see you forgot your password. Don't worry. It happens
                    with the best of us!
                  </p>
                </div>
                <SizedBox height="40px" />

                <div className={`${styles.reset__password__form_2}`}>
                  <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    onSubmit={(data) => {
                      forgotPassword(data);
                    }}
                    validationSchema={schema}
                  >
                    {({
                      values,
                      handleSubmit,
                      handleChange,
                      setFieldValue,
                      errors,
                      status,
                      touched,
                    }) => {
                      return (
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                          }}
                        >
                          <div
                            className={`${styles.reset__password__input__feild}`}
                          >
                            <label htmlFor="">Enter Password</label>
                            <div className="relative">
                              <input
                                type={showPassword ? "text" : "password"}
                                id="password"
                                name="password"
                                onChange={handleChange("password")}
                              />
                              {!showPassword ? (
                                <div
                                  onClick={() => setShowPassword(!showPassword)}
                                  className="hover:cursor-pointer absolute right-4 top-2 z-10"
                                >
                                  <Eye size={23} />
                                </div>
                              ) : (
                                <div
                                  onClick={() => setShowPassword(!showPassword)}
                                  className="hover:cursor-pointer absolute right-4 top-2 z-10"
                                >
                                  <EyeHidden size={23} />
                                </div>
                              )}
                            </div>
                            {errors.password && touched.password && (
                              <span className={styles.error_message}>
                                {errors.password}
                              </span>
                            )}
                          </div>
                          <SizedBox height="10px" />
                          <div
                            className={`${styles.reset__password__input__feild} ${styles.reset__password__input__feild_form_2} `}
                          >
                            <label htmlFor="">Re-enter password</label>
                            <div className="relative">
                              <input
                                type={showPassword ? "text" : "password"}
                                id="confirmPassword"
                                name="confirmPassword"
                                onChange={handleChange("confirmPassword")}
                              />
                            </div>
                            {errors.confirmPassword &&
                              touched.confirmPassword && (
                                <span className={styles.error_message}>
                                  {errors.confirmPassword}
                                </span>
                              )}
                          </div>
                          <SizedBox height="40px" />
                          <div className="flex">
                            <div
                              className={`${styles.reset__password__send_btn}`}
                            >
                              <CustomButton
                                type="submit"
                                isFilled={false}
                                name={loading ? <Bounce /> : "Send"}
                                containerStyle={{width: "100%"}}
                                buttonStyle={{
                                  border: "none",
                                  padding: "0.4rem 2rem",
                                  borderRadius: "0.3rem",
                                  width: "100%",
                                  color: "#ffffff",
                                  backgroundColor: "#BC53FF",
                                }}
                                onclick={(e) => {
                                  e.preventDefault();
                                  handleSubmit();
                                }}
                              />{" "}
                            </div>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </>
            )}
          </div>
          <div className="flex items-center opacity-10 relative right-20 top-16">
            <img src={arrow} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordChange;
