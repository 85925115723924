import React from "react";
import Modal from "react-responsive-modal";
import {Typography} from "@mui/material";

import SizedBox from "components/SizedBox/SizedBox";
import ViewButton from "components/Containers/Button/Button/ViewButton";

import {CSS, CustomModalBaseProps} from "globals/types/globalTypes";
import strings from "globals/constants/strings.json";
import styles from "routes/Interview/Interview.module.scss";

interface IProps extends CustomModalBaseProps {
  finishTest: () => void;
}

export const ConfirmTestFinish: React.FC<IProps> = ({
  open,
  onClose,
  finishTest,
}) => {
  const modalButtonStyles: CSS = {
    display: "flex",
    padding: "10px",
    fontWeight: "bold",
    fontSize: "16px",
    textTransform: "capitalize",
    boxSizing: "border-box",
    borderRadius: "6px",
    justifyContent: "center",
    alignItems: "center",
    width: "100px",
    height: "38px",
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      showCloseIcon={false}
      styles={{modal: {borderRadius: 10, width: "35vw", maxWidth: "35vw"}}}
    >
      <div className={styles.finish__testModalContainer}>
        <SizedBox height="10px" />
        <Typography className={styles.confirm__text}>{`Confirm...`}</Typography>
        <SizedBox height="10px" />
        <p
          className={styles.confirmation__text}
        >{`Are you sure you want to finish the test?`}</p>
        <SizedBox height="20px" />
        <div className={styles.buttons__container}>
          <button
            onClick={finishTest}
            className={`${styles.confirmation__button}`}
            style={modalButtonStyles}
          >
            <span>{strings.affirmation}</span>
          </button>
          <ViewButton
            name={strings.refutation}
            style={{width: "100px", height: "38px"}}
            onClick={onClose}
          />
        </div>
        <SizedBox height="10px" />
      </div>
    </Modal>
  );
};
