import React from "react";
import strings from "globals/constants/strings.json";
import {Typography} from "@mui/material";

export const TrackError: React.FC = () => {
  const {track_error} = strings.interview.permissions_error;
  const {title, description} = track_error;
  return (
    <div>
      <Typography variant="h4" sx={{fontWeight: 600, marginBottom: 1}}>
        {title}
      </Typography>
      <Typography variant="subtitle1">{description}</Typography>
    </div>
  );
};
