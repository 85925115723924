import {Images} from "../../assets/Image";
import React from "react";
import styles from "./style.module.css";

// const cards = [
//   { alt: "", img: Images.sendInvite },
//   { alt: "", img: Images.sourcebaeScreening },
//   { alt: "", img: Images.aiFormulated },
//   { alt: "", img: Images.dashboard },
//   { alt: "", img: Images.results }
// ];

const GetHired: React.FC = () => {
  return (
    <div className={styles.main_container}>
      <div className="flex justify-center pr-20 xl:pr-[100px">
        <h2 className={styles.heading}>Land a job in one week</h2>
      </div>
      <div className={styles.image_container}>
        <div className="w-max">
          <img
            className={"h-[250px] object-contain flex shrink-0"}
            src={Images.getHired}
            alt={"Land a job in one week"}
          />
        </div>
      </div>
    </div>
  );
};

export default GetHired;
