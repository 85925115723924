import React from "react";
import {Typography} from "@mui/material";

import {Row} from "components/Row";
import {Checked} from "components/Icons";

import {InterviewInstructionsResponse} from "globals/types/APIResponses";
import {Images} from "Images";

interface IProps {
  currentStep: number;
  interviewDetails?: InterviewInstructionsResponse | null;
}

const stepsData = [
  {
    image: Images.BehaviourIcon,
    text: "Behaviour Test",
    key: 0,
  },
  {
    image: Images.StepsForIntro,
    text: "Introduction",
    key: 1,
  },
  {
    image: Images.StepsForAssessment,
    text: "Assessment",
    key: 2,
  },
];

export const Steps: React.FC<IProps> = ({currentStep, interviewDetails}) => {
  const [steps, setSteps] = React.useState(stepsData);

  React.useEffect(() => {
    const data = [...stepsData];
    if (interviewDetails !== null && interviewDetails !== undefined) {
      const {include_intro, include_round_one, include_round_two} =
        interviewDetails?.data.assessment || {};
      if (
        include_intro === true &&
        include_round_one === true &&
        include_round_two === true
      )
        setSteps(data);
      if (
        include_intro === true &&
        include_round_one === false &&
        include_round_two === false
      )
        setSteps(data.filter((e) => e.key === 1));
      if (
        include_intro === true &&
        include_round_one === false &&
        include_round_two === true
      )
        setSteps(data.filter((e) => e.key === 1));
      if (
        include_intro === false &&
        include_round_one === true &&
        include_round_two === false
      ) {
        setSteps(data.filter((e) => e.key === 2));
      }
      if (
        include_intro === true &&
        include_round_one === true &&
        include_round_two === false
      )
        setSteps(data.filter((e) => e.key !== 0));
    } else setSteps([]);
  }, [currentStep, interviewDetails]);

  return (
    <Row>
      <Typography
        className="text-blue-600"
        fontWeight={"bold"}
      >{`Steps: `}</Typography>
      <Row classNames="ml-6">
        {steps.map((step, index) => {
          const status: "active" | "inactive" | "completed" =
            index + 1 === currentStep
              ? "active"
              : index >= currentStep
              ? "inactive"
              : "completed";
          return (
            <Row classNames="mr-12" key={index.toString()}>
              <Row
                classNames="justify-center rounded-50 mr-3"
                style={{
                  background:
                    status === "completed"
                      ? "rgba(0, 123, 64, 0.4)"
                      : status === "active"
                      ? "rgba(19, 19, 19, 0.5)"
                      : "rgba(19, 19, 19, 0.2)",
                  height: 30,
                  width: 30,
                }}
              >
                {status !== "completed" ? (
                  <img
                    src={step.image}
                    alt=""
                    style={{width: 15, height: 15, objectFit: "contain"}}
                  />
                ) : (
                  <Checked color={"#007B40"} />
                )}
              </Row>
              <Typography
                color={
                  status === "completed"
                    ? "#007B40"
                    : status === "active"
                    ? "rgba(19, 19, 19, 0.5)"
                    : "rgba(19, 19, 19, 0.2)"
                }
              >
                {step.text}
              </Typography>
            </Row>
          );
        })}
      </Row>
    </Row>
  );
};
