import {CSS} from "globals/types/globalTypes";
import {Images} from "Images";
import React from "react";
import styles from "./ButtonSass.module.scss";

interface Props {
  name: string | JSX.Element;
  style?: CSS;
  onClick?: (e: any | undefined) => void;
  invert?: boolean | null | undefined;
  loading?: boolean;
  isDisabled?: boolean;
}

const ViewButton: React.FC<Props> = ({
  onClick,
  name,
  style,
  invert,
  loading = false,
  isDisabled = false,
}) => {
  return (
    <div className={`${styles.view__button__wrap}`}>
      <button
        disabled={isDisabled}
        className={`
          ${styles.button__template}
          border
          ${
            !invert
              ? "bg-primary hover:bg-white-900 hover:text-primary text-white-900 hover:border-primary"
              : "bg-white-900 hover:bg-primary hover:text-white-900 text-primary border-primary"
          } 
        `}
        onClick={onClick}
        style={style}
      >
        {!loading ? (
          <span style={{paddingTop: "2px"}}>{name}</span>
        ) : (
          <div className="overflow-hidden">
            <img
              src={Images.loader2}
              alt="CircleLoader"
              width={"100px"}
              height={"50px"}
              style={{overflow: "hidden"}}
            />
          </div>
        )}
      </button>
    </div>
  );
};

export default ViewButton;
