"use client";
import {Images} from "../../assets/Image";
import {Icons} from "../../assets/icons";
import React from "react";
import styles from "./style.module.css";

const cards = [
  {
    icon: Icons.connect,
    desc: "Transparent and Unbiased Approach – Fair Play: Purely Merit-Based",
  },
  {
    icon: Icons.resume,
    desc: "Simple Scheduling: Choose Your Time, Stress-Free",
  },
  {
    icon: Icons.letter,
    desc: "Human-Like AI: Conversing with Our AI Feels Like Talking to a Friend",
  },
  {
    icon: Icons.search,
    desc: "Transparent Review: Know Your Performance – No Uncertainty",
  },
];

const WhySourcebae: React.FC = () => {
  return (
    <div className={styles.main_container}>
      <div className="flex justify-center">
        <div className={styles.content_wrapper}>
          <div className={styles.section1}>
            <h2 className={styles.heading}>
              Why Candidates
              <span className={styles.heading_span}>
                Prefer Sourcebae
                {/* <img src={Icons.heart} className={styles.icon} />{" "}
                <span className="pt-1 xxl:pt-1.5">Sourcebae</span> */}
              </span>
            </h2>
            <p className={styles.desc}>
              Sourcebae interviews have a 94% completion rate and a 4.8/5
              rating, indicating their smooth and engaging experience for
              candidates.
            </p>
            <button
              className={`${styles.btn} flex`}
              onClick={() => {
                document
                  .getElementById("join-journey")
                  ?.scrollIntoView({behavior: "smooth"});
              }}
            >
              Join the waitlist{" "}
              <img
                src={Images.ctaArrow}
                alt="arrow"
                className=" w-[22px] h-[22px] 1750:w-[26px] 1750:h-[26px] mr-2.5 mt-[-1px]"
              />
            </button>
          </div>
          <div className={styles.section2}>
            {cards.map((cardData, index) => {
              return (
                <div className={styles.cards} key={index.toString()}>
                  <img
                    className={styles.cards_icon}
                    src={cardData.icon}
                    alt={cardData.desc}
                  />
                  <p className={styles.card_desc}>{cardData.desc}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhySourcebae;
