import React from "react";
import Modal from "react-responsive-modal";
import strings from "globals/constants/strings.json";
import ViewButton from "components/Containers/Button/Button/ViewButton";
import SizedBox from "components/SizedBox/SizedBox";

interface Props {
  open: boolean;
  heading?: string;
  description?: string;
  onNo?: () => void;
  onYes?: (argument?: any) => any;
  onCloseModal: () => void;
  showButtons?: boolean;
  buttonTitles?: {yes: string; no: string};
}

const ConfirmationModal: React.FC<Props> = ({
  open,
  heading,
  description,
  onCloseModal,
  onYes,
  onNo,
  buttonTitles,
  showButtons = true,
}) => {
  let buttonStyles = {width: "100px", height: "38px"};

  return (
    <Modal
      center
      open={open}
      showCloseIcon={false}
      onClose={onCloseModal}
      styles={{modal: {borderRadius: 10, width: "400px"}}}
    >
      <div className="flex flex-col justify-center items-center">
        <SizedBox height="10px" />
        <p className="font-bold text-xl text-center">{heading}</p>
        <SizedBox height="10px" />
        <p className="font-light text-lg text-center">{description}</p>
        <SizedBox height="20px" />
        {showButtons && (
          <div className="flex justify-evenly w-full">
            <ViewButton
              name={buttonTitles?.yes ?? strings.affirmation}
              invert
              style={{height: "38px", minWidth: "100px"}}
              onClick={onYes}
            />
            <ViewButton
              name={buttonTitles?.no ?? strings.refutation}
              style={buttonStyles}
              onClick={onNo ? onNo : onCloseModal}
            />
          </div>
        )}
        <SizedBox height="10px" />
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
