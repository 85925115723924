import {Alert, Box, Snackbar, Typography} from "@mui/material";
import {memo, useState} from "react";
import {LANGUAGE_VERSIONS} from "./DefaultCodeSnippets";

interface OutputProps {
  codeOutput: string | undefined;
}

type Language = keyof typeof LANGUAGE_VERSIONS;

export const executeCode = async (sourceCode: any) => {};

const Output: React.FC<OutputProps> = ({codeOutput}) => {
  const [isError, setIsError] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box
      sx={{
        padding: 2,
        borderColor: isError ? "error.main" : "grey.500",
        color: isError ? "error.main" : "#fff",
        position: "relative",
        height: "26vh",
        maxHeight: "26vh",
        background: "#181526",
      }}
    >
      <Typography marginBottom={2}>Output</Typography>
      <Box
        sx={{
          maxHeight: "25vh",
          overflow: "scroll",
        }}
      >
        {codeOutput ? (
          <Typography variant="body2">{codeOutput}</Typography>
        ) : (
          'Click "Run Code" to see the output here'
        )}
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default memo(Output);
