import React, {useCallback, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {debounce} from "lodash";

import Buttons from "components/Containers/Button/Button/Buttons";
import Search from "components/Inputs/Search/Search";
import CustomSelect from "components/Inputs/Select/CustomSelect";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import Loader from "components/Loader";
import SizedBox from "components/SizedBox/SizedBox";
import {Add, Clear} from "components/Icons";
import {AssessmentTemplateCard} from "components/Containers/Cards/AssessmentCard/AssessmentTemplateCard";

import strings from "globals/constants/strings.json";
import {AssesmentTemplateLinks, RouteLinks} from "globals/constants/routeLinks";
import {
  AssessmentData,
  AssessmentFilterSource,
  AssessmentSkill,
  AssessmentTemplateData,
  CSS,
} from "globals/types/globalTypes";
import {AssessmentTemplateResponse} from "globals/types/APIResponses";
import {
  deleteAssessmentTest,
  fetchSkills,
  getDateDifferenceInSeconds,
  postGoogleAnalyticsEvent,
} from "globals/helpers";
import {APIEndPoints} from "globals/constants/endPoints";
import {AppColors} from "globals/appcolors";
import {sortIdDesc} from "globals/constants/sortingConstants";

import axiosPostModule from "network/Post/AxiosPostModule";
import {Images} from "Images";
import axiosGetModule from "network/Get/AxiosGetModule";

let currentPage: number = 0;
const AssessmentTest: React.FC = () => {
  const navigate = useNavigate();
  const eventTracker = postGoogleAnalyticsEvent("Assessment Template");

  const [templates, setTemplates] = useState<
    AssessmentTemplateData[] | undefined
  >([]);
  const [filterOptions, setFilterOptions] = useState<
    AssessmentSkill[] | undefined
  >([]);
  const [nodata__source, setNodata__source] =
    useState<AssessmentFilterSource>("default");
  const [filterText, setFilterText] = useState<string>("");
  const [selectedOptions, setSelectedOptions] = useState<
    AssessmentSkill[] | undefined
  >([]);

  const {
    loading,
    setLoading,
    assessments_template_timestamp,
    setAssessments_template_timestamp,
    assessmentTemplates: cachedTemplates,
    setAssessmentTemplates: cacheTemplates,
    setAssessment_details,
  } = useAppCommonDataProvider();

  const buttonStyles: CSS = {
    padding: ".5rem 1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const customStyles = {
    control: (base: CSS, state: any) => ({
      ...base,
      background: "#ffffff",
      border: "1px solid #E1E1E1",
      boxSizing: "border-box",
      borderRadius: "6px",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #E1E1E1",
      },
    }),
    container: (provided: CSS) => ({
      ...provided,
      width: "18rem",
      outline: "none",
      backgroundColor: "#ffffff",
      borderRadius: "6px",
    }),
    option: (provided: CSS, state: any) => ({
      ...provided,
    }),
  };

  useEffect(() => {
    setLoading?.(true);
    currentPage = 1;
    fetchAssessmentTemplates("");
    retrieveSkills();
  }, []);

  useEffect(() => {
    fetchRemotely();
  }, [selectedOptions]);

  const retrieveSkills = async () => {
    await fetchSkills().then((res) => {
      if (res) {
        const skills = res?.map((skill) => ({
          id: skill.id,
          skill: skill.skill,
          value: skill.id,
          label: skill.skill,
        }));
        setFilterOptions(skills);
      }
    });
  };

  const fetchRemotely = async (search?: string | number[]) => {
    eventTracker("Templates Viewed", "Assessment Templates Viewed");
    let body = null;
    if (!search)
      body = {
        sort: [sortIdDesc],
      };
    else {
      if (typeof search === "string") {
        body = {
          search: {value: search},
          sort: [sortIdDesc],
        };
      } else {
        if (search.length) {
          body = {
            filters: [
              {
                field: "skills.skill_id",
                operator: "in",
                value: search,
              },
            ],
            sort: [sortIdDesc],
          };
        } else {
          body = {
            sort: [sortIdDesc],
          };
        }
      }
    }
    // (!search || (typeof search === "object" && !search.length)) &&
    //   setLoading?.(true);
    try {
      let params: {page?: number} = {};
      if (currentPage) {
        params.page = currentPage;
      }

      let skills = "";
      selectedOptions?.map(
        (item: any, index: number) =>
          (skills += `&skill_ids[${index}]=${item.id}`)
      );

      axiosGetModule(
        APIEndPoints.GET_ASSESSMENT_TEMPLATES_NODE +
          `?${skills !== "" ? skills : ""}&key=${search ? search : ""}&page=${
            currentPage - 1
          }`
      ).then((res: AssessmentTemplateResponse) => {
        if (res?.data?.length) {
          let tempTemplates: AssessmentTemplateData[] = [];
          // tempTemplates.push(res.data[0]);
          tempTemplates = tempTemplates.concat(res?.data);
          setTemplates(tempTemplates);

          //COMMENTING THIS BECAUSE PAGINATION REMOVED FROM BACKEND
          // res.meta.current_page === 1
          //   ? setTemplates([...tempTemplates])
          //   : setTemplates([...templates!, ...tempTemplates]);

          //COMMENTING THIS BECAUSE PAGINATION REMOVED FROM BACKEND
          // if (currentPage === res.meta.last_page) setshowLoadMore(false);
          // else setshowLoadMore(true);

          //SET GLOBAL STATE ACCORDINGLY
          setAssessments_template_timestamp?.(new Date());
          cacheTemplates?.(res);
        } else {
          setAssessments_template_timestamp?.(null);
          setTemplates([]);
          setNodata__source(search ? "filter" : "default");
        }
        setTimeout(() => {
          setLoading?.(false);
        }, 1000);
      });
    } catch (e) {
      setLoading?.(false);
    }
  };

  const fetchAssessmentTemplates = (search: string) => {
    if (!assessments_template_timestamp) fetchRemotely();
    else {
      if (
        getDateDifferenceInSeconds(assessments_template_timestamp, new Date()) <
        5
      )
        fetchRemotely();
      else fetchRemotely();
    }
  };

  const searchByName = (value: string) => {
    setFilterText(value);
    fetchRemotely(value);
  };

  const debounceSearch = useCallback(debounce(searchByName, 500), []);

  const deleteTest = async (id: number) => {
    await deleteAssessmentTest(id).then((res) => {
      if (res && res?.status === 200) {
        eventTracker("Assessment Template deleted", "A template was deleted");
        fetchRemotely("");
      }
    });
  };

  const viewTemplateDetails = (
    details: AssessmentData | AssessmentTemplateData
  ) => {
    setAssessment_details?.(details);
    navigate(`/${RouteLinks.Assessment_Test}/${details.id}`);
  };

  const handleDropdDownChange = (newItems: AssessmentSkill[]) => {
    setSelectedOptions(newItems);
    fetchRemotely("");
  };

  const clearFilters = () => {
    setFilterText("");
    setSelectedOptions([]);
    fetchRemotely("");
  };

  const navigateToCreateTemplate = () => {
    navigate(`/${AssesmentTemplateLinks.Create_Assessment_Test}`);
    eventTracker(
      "Create New Assessment Template CTA Clicked",
      "Navigating to create template from assessment template home page"
    );
  };

  if (loading) return <Loader />;

  if (!templates?.length && nodata__source === "default")
    return (
      <div className="flex justify-center flex-col items-center mt-5">
        <img src={Images.NoTemplates} alt="" className="object-contain w-1/3" />
        <SizedBox height="2rem" />
        <p className="text-2xl">{strings.assessment_test.no_templates}</p>
        <SizedBox height="1rem" />
        <Link
          to={`/${AssesmentTemplateLinks.Create_Assessment_Test}`}
          className="bg-primary text-white-900 px-10 py-2 rounded-lg flex items-center justify-center"
        >
          <Add size={20} />
          <p className="ml-1 font-medium">
            {strings.assessment_test.no_templates_button_text}
          </p>
        </Link>
      </div>
    );

  return (
    <div className="mt-4 min-h-70vh">
      <div className="flex items-center justify-between">
        <p className="text-black font-bold text-2xl">
          {strings.assessment_test.title}
        </p>
        <div className="w-1/3 flex justify-end">
          <button onClick={navigateToCreateTemplate}>
            <Buttons text={"Add New"} extraStyles={buttonStyles} />
          </button>
        </div>
      </div>
      {templates?.length ? (
        <div className="flex my-3">
          <div className="flex">
            <Search
              placeholder={strings.assessment_test.filter_placeholder}
              onChange={debounceSearch}
            />
          </div>
          <div className="felx ml-6">
            <CustomSelect
              options={filterOptions}
              onChange={handleDropdDownChange}
              defaultValue={""}
              customStyles={customStyles}
              isMulti
              isAnimated
              value={selectedOptions}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="flex">
        <div className="flex items-center flex-wrap w-100">
          {templates?.length ? (
            templates?.map((template, index) => {
              return (
                <AssessmentTemplateCard
                  assessmentDetails={template}
                  key={template.id}
                  onClick={viewTemplateDetails}
                  onDeleteItem={deleteTest}
                  source="assessment_template"
                  onUseAssessment={() =>
                    navigate(
                      `/${AssesmentTemplateLinks.Reuse_Assessment}/${template.id}`
                    )
                  }
                />
              );
            })
          ) : (
            <div className="flex justify-center flex-col items-center w-full mt-8">
              <img
                src={Images.NoTemplates}
                alt=""
                className="object-contain w-1/3"
              />
              <SizedBox height="2rem" />
              <p className="text-xl">
                {strings.assessment_test.no_templates_after_filtering}
              </p>
              <SizedBox height="1rem" />
              <button
                className="bg-primary text-white-900 px-10 py-2 rounded-lg flex items-center justify-center"
                onClick={clearFilters}
              >
                <Clear color={AppColors.WHITE} size={20} />
                <p className="ml-1 font-medium">
                  {strings.assessment_test.reset_filters}
                </p>
              </button>
            </div>
          )}
        </div>
      </div>

      {/* removing due to removal of pagination from backend */}
      {/* {showLoadMore && (
        <div
          className="flex flex-col items-center"
          style={{ opacity: !templates?.length ? 0 : 1 }}
        >
          <ViewButton
            invert
            name={strings.more}
            onClick={() => {
              currentPage++;
              fetchAssessmentTemplates("");
            }}
            style={{ paddingInline: "50px", pointerEvents: !templates?.length ? "none" : "all", }}
          />
          <SizedBox height="20px" />
        </div>
      )} */}
    </div>
  );
};

export default AssessmentTest;
