import React from "react";
import {CSS} from "globals/types/globalTypes";

interface IProps {
  classNames?: string;
  style?: CSS;
  children?:
    | React.ReactElement
    | React.ReactElement[]
    | React.ReactNode
    | React.ReactNode[];
}

export const Row: React.FC<IProps> = ({classNames, children, style}) => {
  let className = "";
  className =
    classNames !== undefined
      ? `flex items-center ${classNames}`
      : "flex items-center";
  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};
