import {Images} from "../../assets/Image";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Title from "../Title";
import styles from "./styles.module.css";

const Testimonals = () => {
  return (
    <div className={styles.wrapper} id="test">
      <div className={styles.main_container}>
        <Title
          heading={
            "Sourcebae simplifies the application to interview process—intelligent, fast, and fair"
          }
          subHeading={"From application to interview"}
          alignment={"center"}
          otherStyles={"!text-black !font-bold"}
          headingStyles="!text-[#601147]"
          subheadingStyles={
            "flex-col flex font-extrabold !text-[48px] !leading-[57px] xxl:!text-[56px] xxl:!leading-[75px] xxl-plus:!text-[62px] xxl-plus:!leading-[78px] 1750:!text-[72px] 1750:!leading-[92px] !tracking-[-0.03em] !text-center !text-[#120A2C]"
          }
        />
        <img
          src={Images.testimonialsImage}
          className="mt-[60px] xxl:mt-[80px]"
          alt={""}
        />

        {/* <p className={styles.desc}>
          Trusted by 12 Unicorns, 32 YC-funded companies, 7 Fortune 500
          Companies, and thousands of customers.
        </p> */}
        {/* <div className="landing_page_carousel"> */}
        {/* <div className={`hidden md:block ${styles.carousel_wrapper}`}> */}
        {/* <Carousel
              showIndicators={false}
              showArrows={false}
              showStatus={false}
              width={"100%"}
            > */}
        {/* <TestimonalsCards /> */}
        {/* </Carousel> */}
      </div>

      {/* <div className={`md:hidden mt-10 ${styles.carousel_wrapper}`}> */}
      {/* <Carousel showArrows={false} showStatus={false} width={"100%"}>
              <TestimonalCol1 />
              <TestimonalCol2 />
              <TestimonalCol3 />
            </Carousel> */}
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
    </div>
  );
};

export default Testimonals;
