import React from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import styles from "./Interview.module.scss";
import {RouteLinks} from "globals/constants/routeLinks";
import {Images} from "Images";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";

const InterviewFlash = () => {
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const navigate = useNavigate();
  const [queryParams] = useSearchParams();

  const {interview_details, setLoading} = useAppCommonDataProvider();

  const duration = queryParams.get("duration");

  React.useEffect(() => {
    setTimeout(() => {
      if (buttonRef.current) buttonRef.current.click();
    }, 4000);
  }, []);

  return (
    <div className={styles.screen__dimensions}>
      <button
        className="hidden"
        onClick={() => {
          setLoading?.(true);
          navigate(
            `/${RouteLinks.Interview}/${interview_details?.data.assessment.assessment_url}/${interview_details?.data.assessment.id}/questions?duration=${duration}`
          );
        }}
        ref={buttonRef}
      >
        click
      </button>
      <div className={styles.screen__dimensions}>
        <div className={styles.screen__dimensions}>
          <img
            src={Images.InterviewFlash}
            className={styles.screen__dimensions}
            alt=""
          />
        </div>
      </div>
      <p className={styles.interview__flashWish}>Best of luck 🖐</p>
      {/* promotional text */}
      <div className="fixed right-4 bottom-4 flex items-center">
        <span className="font-bold text-xs opacity-30">Powered by</span>
        <div className="flex justify-center">
          <img src={Images.SourceBaeLogo} alt="" className="w-6 h-6" />
        </div>
      </div>
    </div>
  );
};

export default InterviewFlash;
