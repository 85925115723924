import React, {CSSProperties, useState} from "react";
import moment from "moment";
import Popover from "@mui/material/Popover";
import {useNavigate} from "react-router-dom";
import {styled} from "@mui/material/styles";
import styles from "./AssessmentTemplateCard.module.scss";

import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import {Ellipses} from "components/Icons";
import SkillsButton from "components/Containers/Button/Button/SkillsButton";

import {
  AssessmentData,
  AssessmentTemplateData,
  CSS,
} from "globals/types/globalTypes";
import strings from "globals/constants/strings.json";
import {experienceOptions} from "globals/constants/dropdownConstants";
import {
  AssesmentLinks,
  AssesmentTemplateLinks,
} from "globals/constants/routeLinks";
import {Tooltip, tooltipClasses, TooltipProps} from "@mui/material";

interface Props {
  assessmentDetails: AssessmentData | AssessmentTemplateData;
  onClick: (details: AssessmentData | AssessmentTemplateData) => void;
  onDeleteItem: (id: number) => void;
  source: "assessment" | "assessment_template";
  cardStyle?: CSSProperties;
  onUseAssessment?: () => void;
  showShowDetailBtn?: boolean;
  reuseAssessment?: (
    details: AssessmentData,
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>
  ) => void;
}

const ExtraTechnologies = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f7f7f7",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    padding: "8px 0",
  },
}));

export const AssessmentTemplateCard: React.FC<Props> = ({
  cardStyle,
  assessmentDetails,
  onDeleteItem,
  source,
  onUseAssessment,
  showShowDetailBtn = true,
  reuseAssessment,
}) => {
  const {name, skills} = assessmentDetails;
  const isAssessment = source === "assessment";

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [confirmationAlert, setconfirmationAlert] = useState<{
    open: boolean;
    data?: number;
  }>({open: false});
  const navigation = useNavigate();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onCloseModal = () => {
    setconfirmationAlert({open: false, data: undefined});
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const templateSpecificStyles: CSS = {
    display: source === "assessment_template" ? "none" : "flex",
    justifyContent: source === "assessment_template" ? "none" : "space-between",
  };

  return (
    <div className={styles.assessment__main__card} style={cardStyle}>
      <div className={styles.assessment__card__top__row}>
        <div className={styles.assessment__card__name}>
          <p className="font-bold" style={{fontSize: 14}}>
            {name.length > 35 ? `${name.substring(0, 35)}...` : name}
          </p>
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className={styles.assessment__card__popup}>
            <p
              className="hover:cursor-pointer"
              style={{display: isAssessment ? "block" : "none"}}
              onClick={(e) => {
                reuseAssessment?.(assessmentDetails as AssessmentData, e);
                setAnchorEl(null);
              }}
            >
              Reuse
            </p>
            <p
              className="hover:cursor-pointer"
              style={{
                display:
                  source === "assessment" || source === "assessment_template"
                    ? "none"
                    : "block",
              }}
            >
              Edit
            </p>
            <p
              className="hover:cursor-pointer"
              onClick={() => onUseAssessment?.()}
              style={{
                display:
                  source === "assessment" || source === "assessment_template"
                    ? "none"
                    : "block",
              }}
            >
              Use
            </p>
            <p
              className="hover:cursor-pointer"
              onClick={() => {
                handleClose();
                setconfirmationAlert({
                  open: true,
                  data: assessmentDetails.id,
                });
              }}
            >
              Delete
            </p>
          </div>
        </Popover>
        <button
          aria-describedby={id}
          className="hover:bg-blue-100 p-2 rounded-xl"
          onClick={handleClick}
          style={{display: "flex", justifyContent: "center"}}
        >
          <Ellipses size={20} className="hover:text-black-900" />
        </button>
      </div>

      {/* skills section */}
      <div className={styles.assessment__card__skills}>
        <div>
          <p
            className="font-semibold mb-4"
            style={{fontSize: 13}}
          >{`${strings.skills}`}</p>
        </div>
        <div className={styles.skills__row}>
          {skills.slice(0, isAssessment ? 2 : 3).map((skill) => (
            <div className="mr-3" id={skill.id.toString()} key={skill.id}>
              <SkillsButton skill={skill} />
            </div>
          ))}
          {(() => {
            if (
              skills.length > (isAssessment ? 1 : 2) &&
              skills.length - (isAssessment ? 2 : 3) > 0
            )
              return (
                <ExtraTechnologies
                  title={
                    <div className={`${styles.extra__skills}`}>
                      {skills.slice(isAssessment ? 2 : 3).map((skill) => (
                        <div key={skill.id} className="mx-3">
                          <SkillsButton skill={skill} />
                        </div>
                      ))}
                    </div>
                  }
                >
                  <p className="cursor-pointer" style={{fontSize: 12}}>
                    +{skills.length - (isAssessment ? 2 : 3)}
                  </p>
                </ExtraTechnologies>
              );
          })()}
        </div>
        <div
          className="mt-3 flex items-center justify-between w-full"
          style={{display: isAssessment ? "none" : "flex"}}
        >
          <p className="text-monoGrey-800" style={{fontSize: 13}}>
            Created on:{" "}
            {moment(assessmentDetails.created_at).format("D MMM YYYY")}
          </p>
        </div>
      </div>

      <div className={`${styles.assessment__card__date__detail}`}>
        <div
          className={`${styles.assessment__card__difficulty}`}
          style={templateSpecificStyles}
        >
          {" "}
          <h3 style={{fontSize: 12}}>
            {" "}
            Difficulty Level:{" "}
            <span>
              {
                experienceOptions?.[
                  assessmentDetails?.candidate?.experience_level - 1
                ]?.label
              }
            </span>
          </h3>
          <h3
            style={{fontSize: 12, color: "rgb(44 153 254)"}}
            className="cursor-pointer"
            onClick={() =>
              navigation(
                `/${AssesmentLinks.View_Assessment_Questions}?id=${assessmentDetails?.id}`
              )
            }
          >
            View
          </h3>
        </div>

        <ConfirmationModal
          open={confirmationAlert.open}
          onCloseModal={onCloseModal}
          heading="Are you sure you want to Delete?"
          description="You won't be able to restore back!"
          onYes={() => {
            onDeleteItem(confirmationAlert.data!);
            onCloseModal();
          }}
          onNo={onCloseModal}
        />
      </div>
    </div>
  );
};
