import {CSS} from "globals/types/globalTypes";
import React from "react";
import LoaderImage from "Images/loader2.gif";

interface Props {
  containerStyles?: CSS;
  containerClasses?: string;
  loaderStyles?: string;
}

const Loader: React.FC<Props> = ({
  containerClasses = `w-full h-80vh flex items-center justify-center`,
  containerStyles,
  loaderStyles = `object-contain h-1/4 w-1/5`,
}) => {
  return (
    <div style={containerStyles} className={containerClasses}>
      <img src={LoaderImage} className={loaderStyles} />
    </div>
  );
};

export default Loader;
