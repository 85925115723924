import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import Loader from "components/Loader";
import QuestionCard from "components/QuestionCard/QuestionCard";
import SizedBox from "components/SizedBox/SizedBox";
import {APIEndPoints} from "globals/constants/endPoints";
import {decodeEncodedAPIResponse} from "globals/helpers";
import {
  CandidateDetails,
  InterviewQuestionsResponse,
} from "globals/types/APIResponses";
import {GetAllQuestionDataNode} from "globals/types/globalTypes";
import {Images} from "Images";
import axiosGetModule from "network/Get/AxiosGetModule";
import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";

const AssessmentQuestions = () => {
  const [searchParams] = useSearchParams();
  const {loading, setLoading} = useAppCommonDataProvider();
  const navigate = useNavigate();
  const assessmentId = searchParams.get("id");

  const [questions, setQuestions] = useState<
    Array<GetAllQuestionDataNode> | []
  >([]);

  useEffect(() => {
    fetchAssessmentQuestions();
  }, []);

  // const fetchAssessmentQuestions = () => {
  //   setLoading?.(true);
  //   axiosGetModule(
  //     APIEndPoints.ASSESMENT_WITH_DETAILS(assessmentId!.toString())
  //   )
  //     .then((res) => {
  //       setQuestions(res.data.questions);
  //     })
  //     .catch((err) => console.log(err))
  //     .finally(() => setTimeout(() => setLoading?.(false), 700));
  // };

  const fetchAssessmentQuestions = () => {
    let temp: any[] = [];
    setLoading?.(true);
    axiosGetModule(
      APIEndPoints.CANDIDATE_DETAILS_NODE(assessmentId!.toString())
    )
      .then((res) => {
        const decodedData = decodeEncodedAPIResponse(res.data, res);
        decodedData.data.assessment.assessment_questions.map(
          (data: any, index: number) => {
            temp.push(
              (data = {
                created_at: data.created_at,
                created_by: data.created_by,
                deletedAt: data.deletedAt,
                id: data.id,
                is_always_included: data.question.is_always_included,
                is_paid_question: data.question.is_paid_question,
                organization_id: data.question.organization_id,
                question: data.question.question,
                question_duration: data.question.question_duration,
                question_level: data.question.question_level,
                question_points: data.question.question_points,
                question_type: data.question.question_type,
                shuffle_options: data.question.shuffle_options,
                skill: {
                  skill: data.skill.skill,
                },
                skill_id: data.skill_id,
                updated_at: data.updated_at,
                updated_by: data.updated_by,
                user: {
                  email: data.user.email,
                  first_name: data.user.first_name,
                  last_name: data.user.last_name,
                },
                // data.question.question_options.map((options:any)=>{
                //   question_options.push{
                //       is_correct_option: options.is_correct_option,
                //       option: options.option,
                //     }
                // }),
                question_options: data.question.question_options,
              })
            );
          }
        );
        // setQuestions(res.data.assessment.assessment_questions);
        setQuestions(temp);
      })
      .catch((err) => console.log(err))
      .finally(() => setTimeout(() => setLoading?.(false), 700));
  };

  if (loading) return <Loader />;

  return (
    <>
      <div
        className="flex text-sm pt-4 px-6 cursor-pointer"
        onClick={() => {
          navigate(-1);
        }}
      >
        <img src={Images.BackArrow} alt="" style={{width: "2%"}} />
        <p className="pl-2 font-bold px-6 cursor-pointer">Back</p>
      </div>
      <div className="flex pt-2 h-4/5 w-full">
        {questions && questions.length ? (
          <div className="w-full">
            {questions.slice(0, 10)?.map((ele) => (
              <div className="pb-6 px-6">
                <QuestionCard data={ele} canEdit={false} />
              </div>
            ))}
          </div>
        ) : (
          <div className="flex w-3/4 justify-center items-center">
            <img src={Images.EmptyQuestions} />
            <SizedBox width={"30px"} />
            <div>
              <p className="text-3xl">No Questions Found!</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AssessmentQuestions;
