import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import ViewButton from "components/Containers/Button/Button/ViewButton";
import {Clear, Search} from "components/Icons";
import Loader from "components/Loader";
import SizedBox from "components/SizedBox/SizedBox";
import {AppColors} from "globals/appcolors";
import {APIEndPoints} from "globals/constants/endPoints";
import strings from "globals/constants/strings.json";
import {postGoogleAnalyticsEvent} from "globals/helpers";
import {CandidateResponse} from "globals/types/APIResponses";
import {debounce} from "lodash";
import axiosGetModule from "network/Get/AxiosGetModule";
import React, {useRef} from "react";
import {Spinner} from "react-activity";
import UserTable from "./table/UserTable";
import tableStyle from "./tableStyle.module.scss";

let currentPage: number = 0;

const Candidate: React.FC = () => {
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const {loading, setLoading} = useAppCommonDataProvider();
  const eventTracker = postGoogleAnalyticsEvent("Candidate");

  type tableRow = {
    id: string;
    candidateName: string;
    email: string;
    interviewStatus: string;
    result: string;
    resume: string;
    interviewScore: number | string;
    candidate_id: number;
  };

  const column = [
    {
      Header: "Candidate Name",
      accessor: "candidateName",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Interview Status",
      accessor: "interviewStatus",
    },
    {
      Header: "Resume",
      accessor: "resume",
    },
  ];

  const [candidatesRow, setcandidatesRow] = React.useState<tableRow[] | []>([]);
  const [isFetching, setisFetching] = React.useState<boolean>(true);
  const [showLoadMore, setshowLoadMore] = React.useState(false);

  React.useEffect(() => {
    currentPage = 1;
    fetchCandidates("");
  }, []);

  const formateCandidates = (res: CandidateResponse) => {
    let formattedData: tableRow[] = [];
    res?.data?.data?.forEach((ele: any) => {
      let totalPoints: string = parseInt(
        ele!?.candidate?.assessments[0]?.assessment_total_points
      )?.toFixed(0);
      formattedData.push({
        id: ele?.id?.toString(),
        candidateName: `${ele!?.first_name} ${ele!?.last_name}`,
        email: ele!?.email,
        candidate_id: ele?.candidate?.id,
        //@ts-ignore
        interviewScore: ele!?.candidate?.assessments[0]
          ?.assessment_points_scored
          ? `${parseInt(
              ele?.candidate?.assessments[0]?.assessment_points_scored
            )?.toFixed(0)}/${totalPoints}`
          : `0/${totalPoints}`,

        interviewStatus:
          ele?.candidate?.assessments?.[0]?.status === 13
            ? "Started/In progress"
            : "Completed",
        result: "Result",
        resume: ele?.candidate?.assessments[0].resume_url,
      });
    });

    res?.data?.meta?.current_page === 1
      ? setcandidatesRow([...formattedData])
      : setcandidatesRow([...candidatesRow, ...formattedData]);

    if (currentPage === res?.data?.meta?.last_page) setshowLoadMore(false);
    else setshowLoadMore(true);
  };

  const fetchCandidates = async (searchedKeyword: string) => {
    if (searchedKeyword) {
      currentPage = 1;
    }
    eventTracker("Candidates Viewed", "Updated candidate list fetched");
    let params: {page?: number} = {};
    let body = {
      search: {
        value: searchedKeyword,
      },
      sort: [{field: "id", direction: "desc"}],
    };
    if (currentPage) {
      setLoading?.(true);
      params.page = currentPage;
    }

    await axiosGetModule(
      APIEndPoints.CANDIDATE_WITH_DETAILS_NODE +
        `?key=${searchedKeyword}&page=${currentPage}`
    )
      .then((res: CandidateResponse) => formateCandidates(res))
      .catch((err) => console.log(err))
      .finally(() =>
        setTimeout(() => {
          setisFetching(false);
          setLoading?.(false);
        }, 300)
      );
  };

  const debounceSearch = React.useCallback(debounce(fetchCandidates, 500), []);
  const clearFilters = () => {
    if (!candidatesRow.length) {
      if (searchInputRef.current !== null) searchInputRef.current.value = "";
    }
    fetchCandidates("");
  };

  if (isFetching) return <Loader />;

  return (
    <div>
      <SizedBox height="30px" />
      <div className="flex items-center justify-between">
        <p className="text-3xl font-bold text-monoGrey-800">
          {strings.candidate.heading}
        </p>
        <div className="w-1/3 flex justify-end"></div>
      </div>

      <SizedBox height="50px" />
      {/* {candidatesRow.length ? */}
      <div>
        <div className="flex justify-between w-100 items-center ">
          <div className="flex w-full relative h-11 justify-start">
            <input
              type="text"
              className="border bg-white-800 border-white-200 rounded-md p-3 flex pr-12 outline-none w-90"
              placeholder="Search by name"
              onChange={(e) => debounceSearch(e.target.value)}
              ref={searchInputRef}
            />
            <Search
              size={20}
              color={AppColors.PRIMARYCOLOR}
              className="relative top-3 right-8"
            />
          </div>
          <div className="flex w-6/12 items-center justify-center">
            {/* <CustomSelect
            options={allInterviewStatusesOptions}
            onChange={(val: { value: string; label: string }) =>
              console.log(val)
            }
            placeholder={strings.candidate.fiterOne}
            customStyles={styles}
          /> */}
          </div>
          {/* <div className='flex w-1/3 items-center justify-end' >
          <CustomSelect
            options={difficultyOptions}
            onChange={(val: { value: string, label: string }) => console.log(val)}
            placeholder={strings.candidate.fiterTwo}
            customStyles={styles}
          />
        </div> */}
        </div>
        {candidatesRow.length ? (
          <>
            <SizedBox height="20px" />
            <div className={tableStyle.candidate__dashboard__body}>
              <UserTable column={column} row={candidatesRow} />
            </div>
          </>
        ) : (
          <div className="flex justify-center w-full flex-col items-center">
            <p className="font-semibold text-lg text-lightBlack-500 text-center mt-8">
              No Candidate found
            </p>
            <SizedBox height="1rem" />
            <p className="text-xl">
              {strings.assessment_test.no_templates_after_filtering}
            </p>
            <SizedBox height="1rem" />
            <button
              className="bg-primary text-white-900 px-10 py-2  w-fit rounded-lg flex items-center justify-center"
              onClick={clearFilters}
            >
              <Clear color={AppColors.WHITE} size={20} />
              <p className="ml-1 font-medium">
                {strings.candidate.no_candidate_reset}
              </p>
            </button>
          </div>
        )}

        {showLoadMore && (
          <div className="flex flex-col items-center">
            <ViewButton
              invert
              name={loading ? <Spinner size={15} /> : strings.more}
              isDisabled={loading ? true : false}
              onClick={() => {
                currentPage++;
                fetchCandidates("");
              }}
              style={{paddingInline: "50px"}}
            />
            <SizedBox height="20px" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Candidate;
