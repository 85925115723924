import {AppColors} from "globals/appcolors";
import {DialogType} from "globals/types/globalTypes";
import {CSSProperties} from "react";
import Modal from "react-responsive-modal";
import {GenericError} from "./InterviewPermissions/GenericError";
import {SystemDeniedPermissions} from "./InterviewPermissions/SystemDeniedPermissions";
import {TrackError} from "./InterviewPermissions/TrackError";
import {UserDeniedPermissions} from "./InterviewPermissions/UserDeniedPermissions";

const modalContainerStyles: CSSProperties = {
  background: AppColors.WHITE,
  borderRadius: 15,
  padding: "1.5rem 2rem",
  maxWidth: "60vw",
  maxHeight: "70vh",
  overflowY: "scroll",
};

export type PermissionsErrorModalProps = {
  isOpen: boolean;
  errorType: DialogType | null;
};

const PermissionsErrorModal = ({
  isOpen,
  errorType,
}: PermissionsErrorModalProps) => {
  const renderSystemDeniedPermissions = () => <SystemDeniedPermissions />;

  const renderUserDeniedPermissions = () => <UserDeniedPermissions />;

  const renderTrackError = () => <TrackError />;

  const renderGenericError = () => <GenericError />;

  const renderDialogContent = () => {
    switch (errorType) {
      case DialogType.systemDenied:
        return renderSystemDeniedPermissions();
      case DialogType.userDenied:
        return renderUserDeniedPermissions();
      case DialogType.trackError:
        return renderTrackError();
      case DialogType.explanation:
        return renderGenericError();
    }
  };
  return (
    <Modal
      center
      open={isOpen}
      onClose={() => {}}
      showCloseIcon={false}
      styles={{modal: modalContainerStyles}}
    >
      {renderDialogContent()}
    </Modal>
  );
};

export default PermissionsErrorModal;
