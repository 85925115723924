import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import {candidateInstance} from "globals/axiosConstants";
import {APIEndPoints} from "globals/constants/endPoints";
import {BehaviorSession} from "globals/types/APIResponses";

export function useFetchBehaviorSession(
  assessmentId: string
): UseQueryResult<AxiosResponse<BehaviorSession>> {
  return useQuery({
    queryKey: ["behaviourSession"],
    queryFn: async () => {
      return candidateInstance.get<BehaviorSession>(
        APIEndPoints.GET_BEHAVIOUR_SESSION(assessmentId)
      );
    },
    onError: (e) => e,
    enabled: false,
  });
}
