import {Moment} from "moment";

export type TableRowProps = {
  user_id: string;
  name: string;
  test: string;
  experience: string;
  agencyName: string;
  date: string | Moment;
  interviewLink: string | URL;
};

export interface PendingTableRowProps {
  assessment_points_scored: string | null;
  assessment_total_points: string | null;
  candidate_id: number;
  location: string | null;
  name: string;
  status: string;
  total_tab_switches: number | null;
  updated_at: string;
}

export interface TotalTableRowProps {
  name: string | null;
  assessment_name: string | null;
  created_at: string | null;
  agency_name: string | null;
  assessment_points_scored: string | null;
  candidate_id: number;
}

export interface UserTableRowProps {
  name: string | null;
  email: string | null;
  role: string | null;
  status: string | null;
  delete: string | null;
  user_id: string | number | null;
}

export type ListTypes = {
  search_value: string;
  score?: number;
};

export type EventDataProps = {
  [key: string]: [
    {
      id: number;
      candidate_id: number;
      name: string;
      assessment_template_id: number;
      scheduled_date: string;
    }
  ];
};

export type ReportInfoProps = {
  label: string;
  value: string;
  description: string;
};

export enum AssessTypes {
  PENDING,
  NEW,
  ALL,
}

export type filterObjects = {
  field: string;
  operator: string;
  value?: string | Array<number> | Moment;
};
