import React, {useState, useEffect} from "react";
import styles from "./SeeAnswers.module.scss";
import TextareaAutosize from "react-textarea-autosize";
import {APIEndPoints} from "globals/constants/endPoints";
import axiosGetModule from "network/Get/AxiosGetModule";
import {useParams} from "react-router-dom";
import {SelectedOptions, Questions} from "globals/types/globalTypes";
import Loader from "components/Loader";

const SeeAnswers = () => {
  const [questions, setQuestions] = useState<Questions[]>();
  const [selectedOption, setSelectedOption] = useState<SelectedOptions[]>([]);
  const [name, setName] = useState<string>("");
  const [isFetching, setisFetching] = useState<boolean>(true);

  let {id} = useParams();

  useEffect(() => {
    getQuestions();
  }, []);

  const getQuestions = async () => {
    setisFetching(true);
    let getAnswer: any = [];
    let correctAnswer: string = "";
    await axiosGetModule(APIEndPoints.GET_ANSWERS(id!))
      .then((res: any) => {
        setName(res?.data.assessment.name);
        setSelectedOption(res?.data?.selectedOptions);
        res?.data?.assessment?.assessment_questions.forEach((item: any) => {
          item.question.question_options.forEach((ele: any) => {
            ele.is_correct_option ? (correctAnswer = ele.option) : null;
          });
          getAnswer.push({
            id: item.id,
            question_id: item.question_id,
            question_points_scored: item.question_points_scored,
            question_total_points: item.question_total_points,
            questions: item.question,
            correctAnswer: correctAnswer,
          });
        });
        setQuestions(getAnswer);
        setisFetching(false);
      })
      .catch((error: any) => {
        console.log("error", error);
        setisFetching(false);
      });
  };

  if (isFetching) return <Loader />;

  return (
    <div>
      <div className="flex flex-row">
        <div className={styles.question_screen}>
          <p className="text-2xl font-bold w-full mx-4 my-6">{name}</p>
          {questions?.map((ele: any, index: number) => {
            return (
              <div key={ele.id} className="border rounded-md m-2 p-2">
                <p className="text-lg font-semibold w-full mx-6 my-4">
                  {index + 1}. {ele?.questions?.question}
                </p>
                {ele?.questions?.question_options.map((item: any) => {
                  return (
                    <div key={item.id} className="relative">
                      <div className="flex py-1 text-lg font-semibold w-full mx-6 my-4">
                        <div className={styles.correct__optionContainer}>
                          {selectedOption.map((opt: any) => {
                            // { item.is_correct_option ? setCorrectAnswer(item.option) : setCorrectAnswer(null) }
                            return ele?.questions.id == opt.question_id &&
                              item.id == opt.question_option_id ? (
                              <div
                                className={styles.correct__optionOuterCircle}
                              >
                                <div
                                  className={styles.correct__optionInnerCircle}
                                />
                                <div
                                  className={
                                    item.is_correct_option
                                      ? "text-blue-500"
                                      : "text-black-500"
                                  }
                                />
                              </div>
                            ) : (
                              ""
                            );
                            // }
                          })}
                        </div>
                        <TextareaAutosize
                          value={item.option.replace("\n", "")}
                          className="px-2 resize-none"
                          style={{width: "100%"}}
                          readOnly
                        ></TextareaAutosize>
                      </div>
                    </div>
                  );
                })}
                <p className="p-4">
                  Correct Answer:{" "}
                  <span className="text-md font-semibold">
                    {ele.correctAnswer}
                  </span>
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SeeAnswers;
