import React from "react";
import {Button, Typography} from "@mui/material";
import Modal from "react-responsive-modal";

import {halfModalContainer} from "globals/constants/styles";
import {CustomModalBaseProps} from "globals/types/globalTypes";
import strings from "globals/constants/strings.json";
import {Row} from "components/Row";

interface IProps extends CustomModalBaseProps {
  stopRecording: () => void;
}

export const SecondRoundFinishConfirmation: React.FC<IProps> = ({
  open,
  onClose,
  stopRecording,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      styles={{modal: halfModalContainer}}
    >
      <Typography variant="h4" sx={{fontWeight: "bold"}}>
        {strings.wait_up}
      </Typography>
      <Typography
        sx={{marginTop: 1, fontSize: 16, fontWeight: "600"}}
        variant="body1"
      >
        {strings.secondRoundFinishConfirmation.confirmation}
      </Typography>
      <ul className="list-disc ml-6 mt-4">
        {strings.secondRoundFinishConfirmation.pointers.map((point, index) => (
          <li key={index.toString()} className="mb-3">
            <Typography variant="body2">{point}</Typography>
            {index ===
              strings.secondRoundFinishConfirmation.pointers.length - 1 && (
              <ol className="ml-10 mt-1 list-decimal">
                {strings.secondRoundFinishConfirmation.options.map(
                  (option, index) => (
                    <li key={index.toString()} className="mb-1 font-bold">
                      <Typography
                        key={index.toString()}
                        variant="body2"
                        sx={{fontWeight: "bold"}}
                      >
                        {option}
                      </Typography>
                    </li>
                  )
                )}
              </ol>
            )}
          </li>
        ))}
      </ul>

      <Row classNames="justify-end mt-6">
        <Button
          sx={{marginRight: 4, paddingX: 2}}
          onClick={() => window.location.reload()}
        >
          Restart
        </Button>
        <Button sx={{paddingX: 3}} onClick={stopRecording}>
          Finish
        </Button>
      </Row>
    </Modal>
  );
};
