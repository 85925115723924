import React, {useState} from "react";
import {Typography} from "@mui/material";
import {Spinner} from "react-activity";
import styles from "./Interview.module.scss";

import {CountDownTimer} from "components/Timer";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import {Webcam} from "components/Webcam";
import {Row} from "components/Row";

import {AppColors} from "globals/appcolors";
import {
  IntroductionQuestions,
  QuestionTimer,
} from "globals/constants/InterviewConstants";
import {experienceOptions} from "globals/constants/dropdownConstants";
import {Images} from "Images";

interface IProps {
  showTimer: boolean;
  remainingTime: number;
  uploadingVideo: boolean;
  setStart: React.Dispatch<React.SetStateAction<boolean>>;
  setShowTimer: React.Dispatch<React.SetStateAction<boolean>>;
  setRemainingTime: React.Dispatch<React.SetStateAction<number>>;
  onStopTimer: () => void;
}

export const IntroductoryVideo: React.FC<IProps> = ({
  showTimer,
  uploadingVideo,
  remainingTime,
  setShowTimer,
  setRemainingTime,
  onStopTimer,
  setStart,
}) => {
  const voice = new SpeechSynthesisUtterance();

  const {loading, interview_details} = useAppCommonDataProvider();

  const [questionRemainingTime, setQuestionRemainingTime] =
    useState<number>(QuestionTimer);
  const [questionIndex, setQuestionIndex] = useState<number>(0);

  const orateQuestion = (index: number) => {
    voice.text = IntroductionQuestions[index];
    window.speechSynthesis.speak(voice);
  };

  const changeQuestion = () => {
    orateQuestion(questionIndex + 1);
    setQuestionRemainingTime(QuestionTimer);
    setQuestionIndex(questionIndex + 1);
    setShowTimer(true);
  };

  const restartTimer = () => {
    setShowTimer(false);
    setTimeout(() => {
      changeQuestion();
    }, 100);
  };

  React.useEffect(() => {
    orateQuestion(questionIndex);
    return () => window.speechSynthesis.cancel();
  }, []);

  return (
    <div className="w-full">
      <div className="justify-center">
        <div className="w-full flex mx-8">
          <p className="flex bg-white-400 border border-white-200 py-4 px-8 text-15 font-semibold">
            {
              experienceOptions.filter(
                (exp) =>
                  interview_details?.data.assessment.assessment_level ===
                  +exp.value
              )[0].label
            }{" "}
            Interview
          </p>
          <p className="flex bg-white-400 border border-white-200 py-4 px-8 text-15 font-semibold">
            <img src={Images.TimerIcon} /> &nbsp;
            <span className="mr-1">{`Total time left:`}</span>
            <CountDownTimer
              time={remainingTime}
              onStopTimer={onStopTimer}
              returnUpdatedValue={(remaining) => setRemainingTime(remaining)}
              sx={{fontWeight: "bold"}}
            />
          </p>
          <p className="flex bg-white-400 border border-white-200 py-4 px-8 text-15 font-semibold">
            <img
              src={Images.RecordingIcon}
              className="animate-recording-pulse"
            />{" "}
            &nbsp; Screen Recording On
          </p>
        </div>
        <div className="flex mt-8">
          <div className="flex mx-8">
            {loading ? (
              <div className="flex flex-col items-center">
                <Spinner size={40} color={AppColors.PRIMARYCOLOR} />
                {uploadingVideo === true && (
                  <p className="mt-1 text-lightBlack-300">
                    We are uploading your recording. Please wait!!
                  </p>
                )}
              </div>
            ) : (
              <>
                <Row classNames="relative">
                  <Webcam
                    videoConstraints={{
                      height: window.innerHeight * 0.65,
                      width: window.innerWidth * 0.6,
                    }}
                    className={undefined}
                  />
                  <Row classNames="absolute left-recording-button top-6 bg-primary h-10 px-6 rounded">
                    <img
                      src={Images.Recording}
                      className="animate-recording-pulse"
                    />
                    <Typography
                      sx={{color: AppColors.WHITE, marginLeft: 1}}
                      fontWeight={"900"}
                    >
                      Recording
                    </Typography>
                  </Row>
                  <Row classNames="absolute bottom-4 flex justify-center items-center  w-full">
                    <div
                      className="px-4 py-4 flex items-center justify-center bg-[#1A1A1A] rounded-3xl cursor-pointer"
                      onClick={() => {
                        setStart(false);
                        setRemainingTime(
                          IntroductionQuestions.length * QuestionTimer
                        );
                      }}
                    >
                      <img src={Images.CallIcon} />
                    </div>
                  </Row>
                </Row>
              </>
            )}
          </div>
          {uploadingVideo === false && (
            <div
              className="flex flex-col bg-white-400 border border-[#D0D5DD] rounded py-4"
              style={{maxWidth: window.innerWidth * 0.3}}
            >
              <div className="flex justify-center flex-col ">
                <p
                  className={styles.interview__introductionWelcomeText}
                  style={{display: loading ? "none" : "block"}}
                >
                  Number of Questions {"=>"} {IntroductionQuestions.length}
                </p>
              </div>
              <div className="mt-4 px-4">
                {showTimer === true &&
                  questionIndex <= IntroductionQuestions.length - 1 && (
                    <>
                      <p className="text-2xl font-bold text-lightBlack-300 text-center">
                        <span>{`#${questionIndex + 1} `}</span>
                        <span className="text-lg">
                          {IntroductionQuestions[questionIndex]}
                        </span>
                      </p>
                      <Row classNames="justify-center text-lg p-4 mt-8 bg-white-800 text-lightBlack-500 text-lg">
                        <img
                          src={Images.Sandbox}
                          alt=""
                          style={{height: 40, width: 50}}
                        />
                        <CountDownTimer
                          time={questionRemainingTime}
                          onStopTimer={restartTimer}
                          returnUpdatedValue={(val) =>
                            setQuestionRemainingTime(val)
                          }
                          sx={{
                            fontSize: 18,
                            fontWeight: "bold",
                            color: AppColors.PRIMARYCOLOR,
                          }}
                        />
                      </Row>
                    </>
                  )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
