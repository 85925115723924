import {useMutation, UseMutationResult} from "@tanstack/react-query";
import axios from "axios";
import cookies from "react-cookies";

import {COOKIESKEYS} from "globals/constants/cookiesKeys";
import {APIEndPoints} from "globals/constants/endPoints";

interface Variables {
  pivot: {status: number};
  option_id: number[];
  creatorId: string;
}

interface RequestDetails {
  questionId: number;
  payload: Variables;
}

export function useUpdateAnswer(
  assessmentId: string
): UseMutationResult<unknown, unknown, RequestDetails> {
  const headers = {
    Authorization: `Bearer ${cookies.load(COOKIESKEYS.CANDIDATE_TOKEN)}`,
    Accept: "application/json",
  };
  return useMutation({
    mutationKey: ["updateAnswer"],
    mutationFn: async (data: RequestDetails) => {
      return axios.patch<unknown, unknown, Variables>(
        `${APIEndPoints.ASSESMENT_UPDATE_PIVOT(
          parseInt(assessmentId),
          data.questionId
        )}`,
        data.payload,
        {headers}
      );
    },
    onError: (e) => e,
  });
}
