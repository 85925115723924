import React, {CSSProperties} from "react";
import {
  FaApple,
  FaCheck,
  FaChrome,
  FaLock,
  FaUser,
  FaUsers,
} from "react-icons/fa";
import {RiDeleteBin5Line} from "react-icons/ri";
import {HiChevronUp, HiChevronDown, HiChevronLeft} from "react-icons/hi";
import {FiPlus} from "react-icons/fi";
import {
  BiReset,
  BiDotsVerticalRounded,
  BiRefresh,
  BiMailSend,
  BiArrowBack,
} from "react-icons/bi";
import {
  BsSearch,
  BsDownload,
  BsCameraVideoFill,
  BsMicFill,
  BsArrowRightShort,
  BsArrowLeftShort,
  BsFillPlayFill,
} from "react-icons/bs";
import {CgArrowLongLeft, CgArrowLongRight} from "react-icons/cg";
import {
  AiOutlineBell,
  AiOutlineDownload,
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineLogout,
  AiOutlineClockCircle,
  AiOutlineCloudUpload,
} from "react-icons/ai";
import {
  IoMdClose,
  IoIosAddCircleOutline,
  IoIosRemoveCircleOutline,
  IoIosMenu,
  IoMdMail,
} from "react-icons/io";
import {
  MdCancel,
  MdOutlineCallEnd,
  MdOutlineRadioButtonUnchecked,
} from "react-icons/md";
import {SiTwitter} from "react-icons/si";
import {ImLinkedin2} from "react-icons/im";

interface IconProps {
  size?: number;
  color?: CSSProperties["color"];
  className?: string;
}

export const Checked: React.FC<IconProps> = (props) => (
  <FaCheck size={props.size} color={props.color} {...props} />
);

export const ChevronUp: React.FC<IconProps> = (props) => (
  <HiChevronUp {...props} />
);

export const ChevronLeft: React.FC<IconProps> = (props) => (
  <HiChevronLeft {...props} />
);

export const ChevronDown: React.FC<IconProps> = (props) => (
  <HiChevronDown {...props} />
);

export const Delete: React.FC<IconProps> = (props) => (
  <RiDeleteBin5Line {...props} />
);

export const Add: React.FC<IconProps> = (props) => <FiPlus {...props} />;

export const Clear: React.FC<IconProps> = (props) => <BiReset {...props} />;

export const Eye: React.FC<IconProps> = (props) => <AiOutlineEye {...props} />;

export const EyeHidden: React.FC<IconProps> = (props) => (
  <AiOutlineEyeInvisible {...props} />
);

export const Ellipses: React.FC<IconProps> = (props) => (
  <BiDotsVerticalRounded {...props} />
);

export const Logout: React.FC<IconProps> = (props) => (
  <AiOutlineLogout {...props} />
);

export const Close: React.FC<IconProps> = (props) => <IoMdClose {...props} />;

export const User: React.FC<IconProps> = (props) => <FaUser {...props} />;

export const Users: React.FC<IconProps> = (props) => <FaUsers {...props} />;

export const NotificationBell: React.FC<IconProps> = (props) => (
  <AiOutlineBell {...props} />
);

export const Download: React.FC<IconProps> = (props) => (
  <AiOutlineDownload {...props} />
);

export const Refresh: React.FC<IconProps> = (props) => <BiRefresh {...props} />;

export const Search: React.FC<IconProps> = (props) => <BsSearch {...props} />;

export const Cancel: React.FC<IconProps> = (props) => <MdCancel {...props} />;

export const UncheckedRadioButton: React.FC<IconProps> = (props) => (
  <MdOutlineRadioButtonUnchecked {...props} />
);

export const AddCircleOutline: React.FC<IconProps> = (props) => (
  <IoIosAddCircleOutline {...props} />
);

export const RemoveCircleOutline: React.FC<IconProps> = (props) => (
  <IoIosRemoveCircleOutline {...props} />
);

export const LongArrowRight: React.FC<IconProps> = (props) => (
  <CgArrowLongRight {...props} />
);

export const LongArrowLeft: React.FC<IconProps> = (props) => (
  <CgArrowLongLeft {...props} />
);

export const Clock: React.FC<IconProps> = (props) => (
  <AiOutlineClockCircle {...props} />
);

export const FileUpload: React.FC<IconProps> = (props) => (
  <AiOutlineCloudUpload {...props} />
);

export const DownloadBold: React.FC<IconProps> = (props) => (
  <BsDownload {...props} />
);

export const ExpressMail: React.FC<IconProps> = (props) => (
  <BiMailSend {...props} />
);

export const BackArrow: React.FC<IconProps> = (props) => (
  <BiArrowBack {...props} />
);

export const CameraAccess: React.FC<IconProps> = (props) => (
  <BsCameraVideoFill {...props} />
);

export const RightArrow: React.FC<IconProps> = (props) => (
  <BsArrowRightShort {...props} />
);

export const LeftArrow: React.FC<IconProps> = (props) => (
  <BsArrowLeftShort {...props} />
);

export const Mic: React.FC<IconProps> = (props) => <BsMicFill {...props} />;

export const AppleLogo: React.FC<IconProps> = (props) => <FaApple {...props} />;

export const Chrome: React.FC<IconProps> = (props) => <FaChrome {...props} />;

export const Padlock: React.FC<IconProps> = (props) => <FaLock {...props} />;

export const Hamburger: React.FC<IconProps> = (props) => (
  <IoIosMenu {...props} />
);
export const Email: React.FC<IconProps> = (props) => <IoMdMail {...props} />;

export const Receiver: React.FC<IconProps> = (props) => (
  <MdOutlineCallEnd {...props} />
);

export const Play: React.FC<IconProps> = (props) => (
  <BsFillPlayFill {...props} />
);

export const Twitter: React.FC<IconProps> = (props) => <SiTwitter {...props} />;

export const Linkedin: React.FC<IconProps> = (props) => (
  <ImLinkedin2 {...props} />
);
