import React from "react";
import Modal from "react-responsive-modal";
import {Button, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";

import {Row} from "components/Row";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";

import {CustomModalBaseProps} from "globals/types/globalTypes";
import {halfModalContainer} from "globals/constants/styles";
import {AppColors} from "globals/appcolors";
import {Images} from "Images";

interface IProps extends CustomModalBaseProps {
  onClickStart: () => void;
}

const useStyles = makeStyles({
  button: {
    "&:hover": {
      backgroundColor: AppColors.REPORTCARD_SECONDARY,
    },
  },
});

export const InterviewSteps: React.FC<IProps> = ({
  open,
  onClose,
  onClickStart,
}) => {
  const classes = useStyles();
  const {interview_details} = useAppCommonDataProvider();
  const {include_intro, include_round_one, include_round_two} =
    interview_details!.data.assessment;

  const [steps, setSteps] = React.useState<
    Array<{image: any; text: string; key: number}>
  >([
    {
      image: Images.BehaviourIcon,
      text: "Behaviour Test",
      key: 0,
    },
    {
      image: Images.StepsForIntro,
      text: "Introduction",
      key: 1,
    },
    {
      image: Images.StepsForAssessment,
      text: "Assessment",
      key: 2,
    },
  ]);

  React.useEffect(() => {
    const data = [...steps];
    if (
      include_intro === true &&
      include_round_one === true &&
      include_round_two === true
    )
      setSteps(data);
    if (
      include_intro === true &&
      include_round_one === false &&
      include_round_two === false
    )
      setSteps(data.filter((e) => e.key === 1));
    if (
      include_intro === true &&
      include_round_one === false &&
      include_round_two === true
    )
      setSteps(data.filter((e) => e.key === 1));
    if (
      include_intro === false &&
      include_round_one === true &&
      include_round_two === false
    )
      setSteps(data.filter((e) => e.key === 2));
    if (
      include_intro === true &&
      include_round_one === true &&
      include_round_two === false
    )
      setSteps(data.filter((e) => e.key !== 0));
  }, []);

  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      showCloseIcon={false}
      styles={{
        modal: {
          ...halfModalContainer,
          //   height: "60vh",
        },
      }}
    >
      <section>
        <Typography variant="h4" fontWeight={"bold"} sx={{textAlign: "center"}}>
          Welcome 👋
        </Typography>

        <Typography
          variant="body1"
          color={AppColors.LIGHTBLACK_300}
          sx={{opacity: 0.5, marginTop: 1.5, textAlign: "center"}}
        >
          {`There ${steps.length === 1 ? "is" : "are"} ${steps.length} ${
            steps.length === 1 ? "step" : "steps"
          } in this test. You have to attempt all steps one by one and just the one if there is 1 step.`}
        </Typography>

        <Row classNames="mt-6 justify-evenly">
          {steps.map((step, index) => (
            <Row classNames="flex-col items-center" key={step.key}>
              <div
                style={{
                  width: 80,
                  height: 80,
                  background: AppColors.REPORTCARD_SECONDARY,
                  borderRadius: "50%",
                  padding: 15,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={step.image} alt="" />
              </div>
              <Typography
                sx={{
                  marginTop: 1,
                  color: AppColors.LIGHTBLACK_300,
                  opacity: 0.5,
                }}
                variant="body1"
              >{`Step ${index + 1}`}</Typography>
              <Typography fontWeight={"bold"} fontSize={20}>
                {step.text}
              </Typography>
            </Row>
          ))}
        </Row>

        <Row classNames="mt-8 justify-center">
          <Button
            sx={{
              background: AppColors.REPORTCARD_SECONDARY,
              width: "40%",
              height: 50,
            }}
            className={classes.button}
            onClick={onClickStart}
          >
            <Typography fontWeight={"bold"} variant="button" fontSize={16}>
              Let's rock!
            </Typography>
          </Button>
        </Row>
      </section>
    </Modal>
  );
};
