import React, {useEffect, useRef, useState} from "react";
import {Button, Typography} from "@mui/material";
import cookies from "react-cookies";
import {StatusMessages, useReactMediaRecorder} from "react-media-recorder";
import S3Client from "react-aws-s3-typescript";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import moment from "moment";
import {IntroductoryVideo} from "./IntroductoryVideo";
import {Steps} from "./Steps";
import styles from "./Interview.module.scss";

import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import {ScreenShareDenied} from "components/Popups/ScreenShareDenied";
import {Row} from "components/Row";
import {Webcam} from "components/Webcam";

import {getElementDimensions, getIPOfUser} from "globals/helpers";
import strings from "globals/constants/strings.json";
import {COOKIESKEYS} from "globals/constants/cookiesKeys";
import {s3Config} from "globals/constants/s3Config";
import {
  IntroductionQuestions,
  QuestionTimer,
} from "globals/constants/InterviewConstants";
import {RouteLinks} from "globals/constants/routeLinks";
import {InterviewInstructionsResponse} from "globals/types/APIResponses";

import {useUploadSessionRecording} from "hooks/interview/useUploadSessionRecording";
import {useUpdateAssessment} from "hooks/interview/useUpdateAssessment";
import {useFetchAssessmentDetails} from "hooks/interview/useFetchAssessmentDetails";
import {Images} from "Images";

const TIMER = IntroductionQuestions.length * QuestionTimer;

const InterviewIntroductionPreview: React.FC = () => {
  const s3Client = new S3Client(s3Config);

  const {startRecording, status, stopRecording, mediaBlobUrl} =
    useReactMediaRecorder({video: true, screen: true});
  const navigate = useNavigate();
  const {assessmentId, id} = useParams();
  const [queryParams] = useSearchParams();

  const duration = queryParams.get("duration");

  const previousRecorderStatus = useRef<StatusMessages | null>(null);
  const include_round_one = useRef<boolean>(false);
  const include_round_two = useRef<boolean>(false);

  const {setLoading, interview_details, setInterview_details} =
    useAppCommonDataProvider();
  const {mutateAsync: saveRecording} = useUploadSessionRecording(assessmentId!);
  const {mutateAsync: updateAssessment} = useUpdateAssessment(assessmentId!);
  const {refetch: getRules} = useFetchAssessmentDetails(id!);

  const [remainingTime, setRemainingTime] = useState<number>(TIMER);
  const [uploadingVideo, setUploadingVideo] = useState<boolean>(false);
  const [start, setStart] = useState<boolean>(false);
  const [showTimer, setShowTimer] = useState<boolean>(true);
  const [startUploading, setStartUploading] = useState(false);
  const [screenShareDenied, setScreenShareDenied] = useState<boolean>(false);
  const [videoConstraints, setVideoConstraints] =
    useState<MediaTrackConstraints>({
      width: 0,
      height: 0,
    });

  useEffect(() => {
    startRecording();
    getRules().then((res) => {
      if (res.isError === false && res.data !== undefined) {
        include_round_one.current = res.data.data.assessment.include_round_one;
        include_round_two.current = res.data.data.assessment.include_round_two;
        setInterview_details?.(res.data);
      }
    });
    window.addEventListener("resize", handleResize);
    window.removeEventListener("resize", handleResize);

    return () => {
      stopRecording();
    };
  }, []);

  useEffect(() => {
    handleScreenShareDenied();
  }, [status]);

  useEffect(() => {
    if (start === true) {
      if (remainingTime == 0) {
        if (mediaBlobUrl !== undefined && startUploading === false) {
          uploadRecording(mediaBlobUrl);
          setStartUploading(true);
        }
      } else {
        startRecording();
      }
    }
  }, [mediaBlobUrl]);

  function handleResize() {
    const dimensions = getElementDimensions(
      document.getElementsByClassName("webcam__container")[0]
    );
    console.log(document.getElementsByClassName("webcam__container")[0]);
    if (dimensions !== null) {
      const {height, width} = dimensions;
      setVideoConstraints({width, height});
    }
  }

  const handleScreenShareDenied = () => {
    if (
      ((previousRecorderStatus.current === "acquiring_media" &&
        status === "idle") ||
        status === "stopped") &&
      remainingTime > 0
    ) {
      if (start === true && remainingTime > 0) location.reload();
      else setScreenShareDenied(true);
    }
    if (status === "recording") setScreenShareDenied(false);

    previousRecorderStatus.current = status;
  };

  const startAssessment = async () => {
    const {include_round_one} = interview_details!.data.assessment;
    const {include_round_two} = interview_details!.data.assessment;
    const userIP = await getIPOfUser();
    updateAssessment({
      status: 3,
      ip_address: userIP.IPv4,
      ip_change_count: 1,
    }).then((res) => {
      cookies.save(
        COOKIESKEYS.DURATION,
        res!.data.data.assesment.assessment_duration,
        {path: "/"}
      );
      setLoading?.(false);
      setUploadingVideo(false);

      if (include_round_one === false) {
        console.log("i am in include round one false");
        if (include_round_two === true) {
          console.log("i am in include round one false and include r2 true");

          updateAssessment({
            status: 13,
            tab_switches: 0,
            location: "N.A.",
          }).then(() => {
            const token = localStorage.getItem("candidateToken");
            cookies.remove(COOKIESKEYS.DURATION, {path: "/"});
            cookies.remove(COOKIESKEYS.INTERVIEW_DETAILS, {path: "/"});
            cookies.remove(COOKIESKEYS.JWT, {path: "/"});
            cookies.remove(COOKIESKEYS.REMAINING_TIME, {path: "/"});
            cookies.remove(COOKIESKEYS.UNIQUE_RESPONSES, {path: "/"});
            cookies.remove(COOKIESKEYS.RESPONSES, {path: "/"});

            // added
            navigate(
              `/${RouteLinks.Interview_Round_2}?assessment=${assessmentId}&?token=${token}`
            );
            //
          });
        } else {
          updateAssessment({
            status: 4,
            tab_switches: 0,
            location: "N.A.",
          }).then(() => {
            cookies.remove(COOKIESKEYS.DURATION, {path: "/"});
            cookies.remove(COOKIESKEYS.INTERVIEW_DETAILS, {path: "/"});
            cookies.remove(COOKIESKEYS.JWT, {path: "/"});
            cookies.remove(COOKIESKEYS.REMAINING_TIME, {path: "/"});
            cookies.remove(COOKIESKEYS.UNIQUE_RESPONSES, {path: "/"});
            cookies.remove(COOKIESKEYS.RESPONSES, {path: "/"});
            localStorage.clear();
            navigate(`/${RouteLinks.Interview_Finish}`);
          });
        }
      } else
        navigate(
          `/${RouteLinks.Interview}/${res?.data.data.assesment.assessment_url}/${res?.data.data.assesment.id}/start?duration=${duration}`
        );
    });
  };

  const uploadRecording = async (video: string) => {
    setLoading?.(true);
    setUploadingVideo(true);
    const blob = await fetch(video).then((res) => res.blob());

    const file = new File([blob], `${moment.toString()}.mp4`);
    const s3Response = await s3Client.uploadFile(
      file,
      location.href.split("interview")[1].slice(1).split("/")[0]
    );
    if (s3Response.status === 204) {
      saveRecording({url: s3Response.location}).then((res) => {
        setStartUploading(false);
        startAssessment();
      });
    }
  };

  const onStopTimer = () => {
    stopRecording();
  };

  const startQuestioning = () => {
    setStart(true);
  };

  const onClickReshare = () => {
    setScreenShareDenied(false);
    startRecording();
  };

  const QuestionsPreview = () => (
    <section className="flex-0.4 flex flex-col justify-between ml-8 h-full bg-white-400 border border-[#D0D5DD] rounded p-4">
      <div className="flex flex-col">
        <p className="capitalize text-3xl font-black text-lightBlack-500">
          Hi {interview_details?.data.assessment.candidate.user.first_name}
        </p>
        <ul className="list-disc ml-8 mt-4">
          {strings.interview.intro_details.map((item, index) => (
            <li key={index.toString()}>
              <p className="font-semibold mb-2">{item}</p>
            </li>
          ))}
        </ul>
      </div>

      <div>
        <div className="flex flex-col justify-center items-center mt-0">
          <div className="flex justify-center w-5/6">
            <Button
              style={{
                background: "#7F31B3",
                padding: "10px 30px",
                width: "100%",
              }}
              onClick={startQuestioning}
              disabled={screenShareDenied || status !== "recording"}
            >
              <Typography
                variant="button"
                sx={{fontWeight: "bold", color: "white", fontSize: 18}}
              >
                {"Start now"}
              </Typography>
            </Button>
          </div>
        </div>
      </div>
    </section>
  );

  const Preview = () => (
    <section
      className="flex-0.6 flex overflow-hidden px-1 py-0 "
      style={{
        backgroundImage: `url(${Images.InterviewVideoBg})`,
        background: "norepeat",
        backgroundSize: "cover",
        borderRadius: "20px",
      }}
    >
      <Row
        classNames={`${styles.interview__introductionPreviewContainer} h-full w-full`}
      >
        <Webcam className="webcam__container" />
      </Row>
    </section>
  );

  return (
    <div className={styles.interview__introductionContainer}>
      <div className="flex py-4 px-4 bg-[#51226F] w-full justify-between items-center">
        <div className="flex text-white-800 font-bold text-32">Sourcebae</div>
      </div>
      <div className="w-full px-8 py-4 bg-[#fafafc]">
        <Steps
          currentStep={
            include_round_one.current === false
              ? 1
              : include_round_two.current === false
              ? 1
              : 2
          }
          interviewDetails={interview_details}
        />
      </div>
      {start === false ? (
        <div className="flex px-4 py-2 pl-8 flex-col">
          <p className="flex text-lightBlack-800 text-20 font-bold text-xl">
            Introduction
          </p>
        </div>
      ) : (
        ""
      )}

      {start === false ? (
        <>
          <Row classNames="flex-col w-full">
            <Row
              classNames={`${styles.interview__introductionVideoContainer} h-[68vh] w-[95%] mx-auto`}
            >
              <Preview />
              <QuestionsPreview />
            </Row>
          </Row>
        </>
      ) : null}

      {start === true ? (
        <IntroductoryVideo
          showTimer={showTimer}
          setShowTimer={setShowTimer}
          uploadingVideo={uploadingVideo}
          remainingTime={remainingTime}
          setRemainingTime={setRemainingTime}
          onStopTimer={onStopTimer}
          setStart={setStart}
        />
      ) : null}

      {screenShareDenied === true ? (
        <ScreenShareDenied open={screenShareDenied} onClick={onClickReshare} />
      ) : null}
    </div>
  );
};

export default InterviewIntroductionPreview;
