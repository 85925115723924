import SourcebaeFooter from "@sourcebae/components/sourcebae-footer";
import {useState} from "react";

const Footer = ({scrollToSection, showAllCompanyOptions = false}: any) => {
  const [moreResourcesOpen, setMoreResourcesOpen] = useState(false);
  const [moreDeveloperOpen, setMoreDeveloperOpen] = useState(false);

  return (
    <SourcebaeFooter
      moreDeveloperOpen={moreDeveloperOpen}
      setMoreDeveloperOpen={setMoreDeveloperOpen}
      moreResourcesOpen={moreResourcesOpen}
      setMoreResourcesOpen={setMoreResourcesOpen}
      domain="https://sourcebae.com"
      showAllCompanyOptions
    />
  );
};

export default Footer;
