import SizedBox from "components/SizedBox/SizedBox";

type Props = {label: string; value: string; description: string};

const ReportCard: React.FC<Props> = ({label, value, description}) => {
  return (
    <div
      className="bg-white-900 border border-white-300 p-5 flex flex-col justify-center rounded-lg py-7 shadow-md"
      style={{height: "47%"}}
    >
      <p className="text-xl font-bold text-lightBlack-500 capitalize text-center">
        {label}
      </p>
      <SizedBox height="6px" />
      <p
        className="text-42 font-bold text-primary text-center"
        style={{color: "#505050"}}
      >
        {value}
      </p>
      <p className="text-xs font-medium italic text-primary text-center">
        {description}
      </p>
    </div>
  );
};

export default ReportCard;
