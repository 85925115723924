import React from "react";
import {Typography} from "@mui/material";
import Head from "./Head/Head";

import CustomSelect from "components/Inputs/Select/CustomSelect";
import SizedBox from "components/SizedBox/SizedBox";
import {CandidateDetailsData} from "globals/types/globalTypes";

type Assesments = {label: string; value: number};

interface IProps {
  candidateId: string;
  candidateDetails: CandidateDetailsData | undefined;
  assessmentList: {
    label: string;
    value: number;
  }[];
  currentAssessmentId: number;
  setCurrentAssessmentId: React.Dispatch<React.SetStateAction<number>>;
  setSearchParams: (data: any) => void;
}

export const AssessmentListAndBasicDetails: React.FC<IProps> = ({
  candidateId,
  candidateDetails,
  assessmentList,
  currentAssessmentId,
  setCurrentAssessmentId,
  setSearchParams,
}) => {
  return (
    <>
      <Head userData={candidateDetails?.user} />
      <SizedBox height="40px" />
      <Typography fontWeight={"bold"} fontSize={22}>
        Choose Assessment
      </Typography>
      <SizedBox height="10px" />
      <CustomSelect
        options={assessmentList}
        value={
          assessmentList.length > 0
            ? assessmentList.find((e) => e.value === currentAssessmentId)
            : null
        }
        onChange={(item: Assesments) => {
          setCurrentAssessmentId(item.value);
          setSearchParams({
            candidate: candidateId!,
            assessment: item.value.toString(),
          });
        }}
      />
    </>
  );
};
