// NOTE: KEEP EVERYTHING IN ORDER

export const questionOptions = Object.freeze([
  {
    label: "Theoretical Question | coming soon...",
    value: "0",
    isDisabled: true,
  },
  {label: "Multiple Choice Question", value: "1"},
  {label: "Multiple-Answer Question", value: "2"},
]);

export const difficultyOptions = Object.freeze([
  {label: "Simple", value: "1"},
  {label: "Medium", value: "2"},
  {label: "Hard", value: "3"},
]);

export const experienceOptions = Object.freeze([
  {label: "Beginner", value: "1"},
  {label: "Intermediate", value: "2"},
  {label: "Expert", value: "3"},
]);

export const interviewStatusOptions = Object.freeze([
  {label: "Shortlisted", value: "5"},
  {label: "Hired", value: "6"},
  {label: "Rejected", value: "7"},
]);

export const communiationSkillOptions = Object.freeze([
  {label: "Poor", value: "1"},
  {label: "Average", value: "2"},
  {label: "Good", value: "3"},
  {label: "Excellent", value: "4"},
]);

export const allInterviewStatusesOptions = Object.freeze([
  {label: "Created", value: "1"},
  {label: "Scheduled", value: "2"},
  {label: "Started", value: "3"},
  {label: "Completed", value: "4"},
  {label: "Shortlisted", value: "5"},
  {label: "Hired", value: "6"},
  {label: "Rejected", value: "7"},
  {label: "Hold", value: "8"},
  {label: "Postponed", value: "9"},
  {label: "Error", value: "10"},
  {label: "Expired", value: "11"},
]);
