import React from "react";
import {useNavigate} from "react-router-dom";
import styles from "../CandidateDetails.module.scss";

import {ChevronLeft} from "components/Icons";
import SizedBox from "components/SizedBox/SizedBox";
import {AppColors} from "globals/appcolors";

type Props = {
  userData?: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    email_verified_at: null;
    phone: null;
    phone_verified_at: null;
    created_by?: number;
    updated_by?: number;
    created_at?: string;
    updated_at?: string;
    deleted_at?: null;
  };
};

const Head: React.FC<Props> = ({userData}) => {
  const navigate = useNavigate();

  return (
    <div className={styles.row}>
      <div className={styles.head__container}>
        <button
          onClick={() => navigate(-1)}
          className={styles.back__buttonContainer}
        >
          <ChevronLeft color={AppColors.WHITE} size={20} />
        </button>
        <SizedBox width="10px" />
        <p className={styles.user__name}>
          {`${userData?.first_name} ${userData?.last_name}`}
        </p>
      </div>
      <div>
        <p className={styles.user__email}>{userData?.email}</p>
      </div>
    </div>
  );
};

export default Head;
