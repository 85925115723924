import AppBody from "components/Containers/AppBody/AppBody";
import {specialRoutes} from "components/NavBars/AppNav/routes";
import {RouteLinks} from "globals/constants/routeLinks";
import React, {CSSProperties} from "react";
import {useLocation} from "react-router-dom";
import styles from "./MainContainer.module.scss";

const loginStyle: CSSProperties = {
  backgroundSize: "cover",
  backgroundColor: "#ffffff",
};

const backgroundRoutes = [
  "/",
  "/interview",
  `/${RouteLinks.Interview__Questions}`,
];

interface Props {
  children: React.ReactChild;
}

const MainContainer: React.FC<Props> = ({children}) => {
  const location = useLocation();
  let lochash = `/${location.pathname.replace("/", " ").trim().split("/")[0]}`;
  const isLoginPage =
    backgroundRoutes.includes(location.pathname) ||
    specialRoutes.includes(lochash);

  const isOneUpCreatePage = location.pathname.includes(
    RouteLinks.Create_Oneup_Assessment
  );

  return (
    <div
      className={styles.main_container}
      style={isLoginPage ? loginStyle : {}}
    >
      <div
        style={{
          width: isLoginPage || isOneUpCreatePage ? "100%" : "97%",
          height: "100%",
        }}
      >
        <AppBody>{children}</AppBody>
      </div>
    </div>
  );
};

export default MainContainer;
