import React from "react";
import styles from "../CandidateDetails.module.scss";

import SizedBox from "components/SizedBox/SizedBox";
import {Eye} from "components/Icons";
import {DownloadBold, ExpressMail} from "components/Icons";

import {CandidateDetails} from "globals/types/APIResponses";
import {RouteLinks} from "globals/constants/routeLinks";

interface Props {
  candidateAssessmentDetails: CandidateDetails | null;
  notifyCandidate: (event: React.MouseEvent<HTMLButtonElement>) => void;
  seeAnswers: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const evenSpacing = "15px";

export const CandidateKPI: React.FC<Props> = ({
  candidateAssessmentDetails,
  notifyCandidate,
  seeAnswers,
}) => {
  return (
    <div className={styles.kpi__container}>
      <p className={styles.kpi__title}>Anti-Cheating monitor</p>
      <SizedBox height="30px" />

      <div className={styles.row}>
        <p className={styles.kpi__name}>Device Used</p>
        <p className={styles.kpi__value}>Desktop</p>
      </div>
      <SizedBox height={evenSpacing} />

      <div className={styles.row}>
        <p className={styles.kpi__name}>Location</p>
        <p className={styles.kpi__value}>
          {candidateAssessmentDetails?.data?.assessment?.location}
        </p>
      </div>
      <SizedBox height={evenSpacing} />

      <div className={styles.row}>
        <p className={styles.kpi__name}>Window Switch During Interview</p>
        <p className={styles.kpi__value}>
          {candidateAssessmentDetails?.data?.assessment?.total_tab_switches
            ? candidateAssessmentDetails?.data?.assessment?.total_tab_switches
            : 0}
        </p>
      </div>
      <SizedBox height={evenSpacing} />

      <div className={styles.row}>
        <p className={styles.kpi__name}>Filled out just from one IP?</p>
        <p
          className={`${styles.kpi__value} ${styles.kpi__valueWithBackground}`}
        >
          {candidateAssessmentDetails?.data?.assessment?.ip_change_count === "1"
            ? "YES"
            : "NO"}
        </p>
      </div>
      <SizedBox height={evenSpacing} />

      <div className={styles.row}>
        <p className={styles.kpi__name}>Webcam enabled</p>
        <p
          className={`${styles.kpi__value} ${styles.kpi__valueWithBackground}`}
        >
          YES
        </p>
      </div>
      <SizedBox height={evenSpacing} />

      <div className={styles.row}>
        <p className={styles.kpi__name}>Mouse always in assessment window?</p>
        <p
          className={`${styles.kpi__value} ${styles.kpi__valueWithBackground}`}
        >
          {candidateAssessmentDetails?.data?.assessment?.total_tab_switches
            ? "NO"
            : "YES"}
        </p>
      </div>
      <SizedBox height={evenSpacing} />

      <div className={`${styles.row} mt-3`}>
        <button className={styles.notify__button} onClick={seeAnswers}>
          See Answers
          <div className="ml-2 text-WHITE_700 hover:text-primary">
            <Eye size={20} />
          </div>
        </button>
      </div>
      <SizedBox height={evenSpacing} />

      <div className={`${styles.row} mt-3`}>
        <button className={styles.notify__button} onClick={notifyCandidate}>
          Send result to candidate
          <div className="ml-2 text-WHITE_700 hover:text-primary">
            <ExpressMail size={20} />
          </div>
        </button>
      </div>
      <SizedBox height={evenSpacing} />

      <div className={`${styles.row} mt-3`}>
        <button
          className={styles.notify__button}
          onClick={() => {
            window.open(
              `/${RouteLinks.ReportCard}?assessment=${candidateAssessmentDetails?.data.assessment.id}&aiId=${candidateAssessmentDetails?.data.assessment.ai_assessment_id}`
            );
          }}
        >
          Download reportcard
          <div className="ml-2 text-WHITE_700 hover:text-primary">
            <DownloadBold size={20} />
          </div>
        </button>
      </div>
    </div>
  );
};
