const PRIMARYCOLOR = "#5E48E8";
const PRIMARY_600 = "#242F9B";
const SECONDARYCOLOR = "blue";
const TERTIARYCOLOR = "green";

const WHITE = "#ffffff";
const PRIMARY_GREEN = "#345B63";
const WHITE_300 = "#e1e1e1";
const WHITE_700 = "#f8f8f8";
const WHITE_800 = "#fafafb";

const BLACK = "#000000";
const BLACK_BORDER_LIGHT = "#rgba(80, 80, 80, .16)";

const LIGHTBLACK_300 = "#131313";
const LIGHTBLACK_500 = "#252525";
const LIGHTBLACK_600 = "#555555";

const BLUE_100 = "rgba(25, 118, 210, 0.04)";
const BLUE_600 = "#5C6DFF";
const BLUE = "#2C99FE";
const LIGHT_BG_BLUE = "rgba(44, 153, 254, 0.1)";

const LIGHT_RED = "#ff4d4d";

const GREY = "rgb(138, 138, 138)";

const ANSWERED_GREEN = "#007B40";
const UNANSWERED_RED = "#F03D3E";

const REPORTCARD_PRIMARY = "#7522FB";
const REPORTCARD_SECONDARY = "#51226f";

const LANDING_PAGE_PRIMARY = "#000212";

export const AppColors = {
  PRIMARYCOLOR,
  PRIMARY_600,
  SECONDARYCOLOR,
  TERTIARYCOLOR,
  WHITE,
  PRIMARY_GREEN,
  WHITE_300,
  WHITE_700,
  WHITE_800,
  BLACK,
  LIGHT_BG_BLUE,
  BLACK_BORDER_LIGHT,
  BLUE,
  BLUE_100,
  BLUE_600,
  LIGHT_RED,
  GREY,
  ANSWERED_GREEN,
  UNANSWERED_RED,
  LIGHTBLACK_300,
  LIGHTBLACK_500,
  LIGHTBLACK_600,
  REPORTCARD_PRIMARY,
  REPORTCARD_SECONDARY,
  LANDING_PAGE_PRIMARY,
};
