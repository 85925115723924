import {Typography} from "@mui/material";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import {LeftArrow, RightArrow} from "components/Icons";
import Loader from "components/Loader";
import {Row} from "components/Row";
import styles from "components/styles.module.scss";
import {Form, Formik} from "formik";
import {APIEndPoints} from "globals/constants/endPoints";
import {s3Config} from "globals/constants/s3Config";
import strings from "globals/constants/strings.json";
import {deleteAssessmentTest} from "globals/helpers";
import {
  CreateAsseessmentFields,
  CreateAssessmentTemplate,
  WorkExperienceFormValues,
} from "globals/types/globalTypes";
import {useCreateAssessment} from "hooks/assessment/useCreateAssessment";
import axiosGetModule from "network/Get/AxiosGetModule";
import axiosPostModule from "network/Post/AxiosPostModule";
import React, {useEffect, useState} from "react";
import S3Client from "react-aws-s3-typescript";
import {toast} from "react-toastify";
import * as Yup from "yup";
import stylesCss from "./style.module.scss";

interface IProps {
  next: () => void;
  back: () => void;
}

export const WorkExperienceDetails: React.FC<IProps> = ({back, next}) => {
  const {createAssesmentValues, setCreateAssesmentValues} =
    useAppCommonDataProvider();

  const [loading, setLoading] = useState<boolean>(false);

  const {mutateAsync: createUserAssessment} = useCreateAssessment(
    false,
    setLoading
  );

  const s3Client = new S3Client(s3Config);

  const initialValues: WorkExperienceFormValues = {
    experience: createAssesmentValues?.years_of_experience || "",
    number_of_questions: createAssesmentValues?.questions || "60",
    projectDescription: "",
    projectName: "",
    jd: createAssesmentValues?.jd ?? "",
  };

  const validateSchema = Yup.object().shape({
    experience: Yup.number().required("experience is required"),
    number_of_questions: Yup.string().required(
      "Number of questions is required"
    ),
  });

  const createTemplate = async (payload: CreateAssessmentTemplate) => {
    return await axiosPostModule(
      APIEndPoints.CREATE_ASSESSMENT_TEMPLATE_NODE,
      payload
    );
  };

  const submitForm = async () => {
    if (createAssesmentValues) {
      setLoading(true);
      const userOrgId: any = JSON.parse(localStorage.getItem("USER_DETAILS")!);
      const allSkills = await axiosGetModule(
        APIEndPoints.GET_SKILL_WITH_QUE_COUNT_NODE
      );
      const templateName = allSkills.data.find(
        (skill: any) => skill.id === createAssesmentValues?.skills[0]
      ).skill;
      const templatePayload: CreateAssessmentTemplate = {
        organization_id: userOrgId.user.teams[0].id,
        name: templateName,
        skill_ids: createAssesmentValues?.skills,
      };
      const {data} = await createTemplate(templatePayload);

      try {
        const {
          first_name,
          last_name,
          date,
          experience_value,
          candidate_source,
          email,
          resume,
          skills,
          years_of_experience,
          questions,
          project_info,
          switch: orgOnlyQuestions,
          is_contractual,
          // new keys for contractual hiring
          include_intro,
          include_round_one,
          include_round_two,
          jd,
        } = createAssesmentValues;
        let formData = new FormData();
        formData.append("file", resume);
        const {status, location: resume_url} = await s3Client.uploadFile(
          resume,
          `${first_name}-${last_name}-resume`
        );
        if (status === 204) {
          const payload: CreateAsseessmentFields = {
            resume_url,
            assessment_template_id: data.id.toString(),
            scheduled_date: date!.format("YYYY-MM-DD"),
            candidate_name: first_name,
            candidate_last_name: last_name,
            email,
            experience_duration: years_of_experience,
            number_of_questions: questions,
            experience_level: experience_value,
            source: candidate_source,
            include_organization_questions: orgOnlyQuestions.toString(),
            organization_id: userOrgId.user.teams[0].organization.id,
            skills_ids: skills.map((skill) => skill),
            project_title:
              project_info !== undefined
                ? project_info.map((project) =>
                    project.project_name !== "" ? project.project_name : "NA"
                  )
                : ["AAA"],
            project_description:
              project_info !== undefined
                ? project_info.map((project) =>
                    project.project_description !== ""
                      ? project.project_description
                      : "NA"
                  )
                : ["AAA"],
            is_contractual,
            include_intro,
            include_round_one,
            include_round_two,
            job_description: jd,
            responseURL: false,
          };

          createUserAssessment(payload).then((res) => {
            if (res) {
              if (res.data.success) {
                toast.success("Assessment Created", {
                  position: "bottom-left",
                });
                setLoading(false);
                next();
              }
            }
          });
        }
      } catch (e) {
        setLoading(false);
        next();
        toast.error(
          "We are sorry this assessment could not be created because of a technical error on our end."
        );
        deleteAssessmentTest(data.id);
      }
    }
  };

  useEffect(() => {
    //@ts-ignore
    setCreateAssesmentValues?.({
      ...createAssesmentValues,
      questions:
        createAssesmentValues?.questions === undefined
          ? "60"
          : createAssesmentValues.questions,
      years_of_experience:
        createAssesmentValues?.years_of_experience === undefined
          ? ""
          : createAssesmentValues?.years_of_experience,
      jd: createAssesmentValues?.jd ?? "",
    });
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <section className="mt-4">
      <div>
        <Typography variant="h5" fontWeight={"bold"}>
          {strings.new__candidate__experience.title}
        </Typography>
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={submitForm}
        validationSchema={validateSchema}
      >
        {({values, errors, handleSubmit, touched, setFieldValue}) => (
          <Form onSubmit={handleSubmit}>
            <section
              className={`${styles.newcandidate__experienceDetail} pl-0 max-w-full`}
            >
              <Row>
                <div className="flex flex-col flex-1">
                  <Typography>
                    {strings.new__candidate__experience.total_experience}
                  </Typography>
                  <input
                    onChange={(e) => {
                      if (isNaN(+e.target.value) === true) return;
                      setFieldValue("experience", e.target.value);
                      //@ts-ignore
                      setCreateAssesmentValues?.({
                        ...createAssesmentValues,
                        years_of_experience: e.target.value,
                      });
                    }}
                    value={values.experience}
                    className={styles.input}
                    placeholder="Experience in years"
                    maxLength={2}
                  />
                  {errors.experience && touched.experience && (
                    <span className="text-red-500 text-xs mt-2">
                      {errors.experience}
                    </span>
                  )}
                </div>

                <div className="flex-1 flex flex-col">
                  <Typography className={`font-normal text-sm`}>
                    {strings.new__candidate__experience.question_nos}
                    <span className="text-red-500">*</span>
                  </Typography>
                  <input
                    name="question"
                    id="question"
                    onChange={(e) => {
                      if (isNaN(+e.target.value) === true) return;
                      setFieldValue("number_of_questions", e.target.value);
                      //@ts-ignore
                      setCreateAssesmentValues?.({
                        ...createAssesmentValues,
                        questions: e.target.value,
                      });
                    }}
                    value={values.number_of_questions}
                    className={styles.input}
                    maxLength={3}
                  />
                </div>
              </Row>
            </section>

            <div className="mt-8">
              <Typography
                variant="h6"
                fontWeight={"bold"}
                className={styles.newcandidate__experienceTitle}
              >
                {strings.new__candidate__experience.jd}
              </Typography>
              <textarea
                className="border mt-4 w-full py-6 px-8 rounded-md min-h-72 text-black-150"
                placeholder="Enter a JD here..."
                value={values.jd}
                onChange={(e) => {
                  setFieldValue("jd", e.target.value);
                  //@ts-ignore
                  setCreateAssesmentValues?.({
                    ...createAssesmentValues,
                    jd: e.target.value,
                  });
                }}
              ></textarea>
            </div>

            <div className="flex w-full justify-between mt-6">
              <button className={stylesCss.back_btn_wrap} onClick={back}>
                <LeftArrow className={stylesCss.back_icon} size={20} />
                <Typography className={stylesCss.back_button}>Back</Typography>
                <div className={stylesCss.back_btn_after}></div>
              </button>

              <button className={stylesCss.next_btn_wrap} type="submit">
                <div className={stylesCss.next_btn_before}></div>
                <Typography className={stylesCss.next_button}>
                  Create
                </Typography>
                <RightArrow className={stylesCss.icon} size={20} />
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
};
