import React from "react";
import styles from "./SkillsButton.module.scss";
import {AssessmentSkill} from "globals/types/globalTypes";

interface Props {
  skill: AssessmentSkill;
}

const SkillsButton: React.FC<Props> = ({skill}) => {
  return (
    <div className={`${styles.skills__button__div}`}>
      <button className={`${styles.skills__btn}`}> {skill.skill}</button>
    </div>
  );
};

export default SkillsButton;
