export const COOKIESKEYS = {
  AUTH_TOKEN: "AUTH_TOKEN",
  USER_DETAILS: "USER_DETAILS",
  IS_VERIFIED: "IS_VERIFIED",
  PASSWORD: "PASSWORD",

  //INTERVIEW RELATED COOKIES FOR
  INTERVIEW_DETAILS: "INTERVIEW_DETAILS",
  RESPONSES: "RESPONSES",
  REMAINING_TIME: "REMAINING_TIME",
  DURATION: "DURATION",
  UNIQUE_RESPONSES: "UNIQUE_RESPONSES",
  JWT: "JWT",
  CANDIDATE_TOKEN: "CANDIDATE_TOKEN",

  //ROUND TWO KEYS
  ROUND_TWO_QUESTIONS: "ROUND TWO QUESTIONS",
};
