import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import InterviewActionButton from "components/Button/InterviewActionButton";
import InterviewHeader from "components/InterviewHeader";
import {newInstance} from "globals/axiosConstants";
import {APIEndPoints} from "globals/constants/endPoints";
import {microCopies} from "globals/constants/microcopies";
import {RouteLinks} from "globals/constants/routeLinks";
import {useFetchAssessmentDetails} from "hooks/interview/useFetchAssessmentDetails";
import axiosPostModule from "network/Post/AxiosPostModule";
import {memo, useEffect, useRef, useState} from "react";
import {IoIosPlay} from "react-icons/io";
import {IoPauseOutline} from "react-icons/io5";
import Modal from "react-responsive-modal";
import {useNavigate, useSearchParams} from "react-router-dom";
import styles from "./InterviewPreview.module.css";

interface CandidateData {
  role: string;
  skills: string[];
}

const flowPointers = [
  "Tech Screening",
  "Quick questions to assess your core skills",
  "Coding Challenge",
  "Show your problem-solving with real-world tasks",
  "Behavioral Questions",
  "Let's understand how you work in a team",
];

const expectations = [
  "Your approach to challenges",
  "Clear, efficient code",
  "Communication & collaboration",
  "Get Ready",
];

const InterviewPreview = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const assessmentId = searchParams.get("assessment");
  const token = searchParams.get("token");

  const {refetch: getAssessmentDetails} = useFetchAssessmentDetails(
    assessmentId!
  );
  const {getMicrocopy} = useAppCommonDataProvider();

  const [open, setOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [candidateData, setCandidateData] = useState<CandidateData>();

  const videoRef = useRef<HTMLVideoElement>(null);

  const closeModal = () => {
    setOpen(false);
    setIsPlaying(false); // Reset play state when closing modal
    if (videoRef.current) {
      videoRef.current.pause(); // Pause video when closing modal
    }
  };

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const getIntreviewRoleData = async () => {
    const {data: {data: {assessment: {resume_url = ""} = {}} = {}} = {}} =
      await getAssessmentDetails();

    const formData = new FormData();
    formData.append("s3_url", resume_url);

    const {
      data: {resume_content},
    } = await newInstance.post("/fetch-role-skills", formData);
    setCandidateData(resume_content as CandidateData);
  };

  const login = async () => {
    const loginData = await axiosPostModule(APIEndPoints.LOGIN_NODE, {
      email: "bindu@shethink.in",
      password: "Shethink@123",
      device_name: "macintosh",
    });
    localStorage.setItem("AUTH_TOKEN", loginData.data.accessToken);
    localStorage.setItem("USER_DETAILS", JSON.stringify(loginData.data));
  };

  useEffect(() => {
    if (assessmentId) {
      getIntreviewRoleData();
    }
  }, [assessmentId]);

  useEffect(() => {
    login();
  }, []);

  const skills = candidateData?.skills?.slice(0, 5);
  return (
    <div className={`${styles.main_wrapper} font-urbanist`}>
      <InterviewHeader
        title={getMicrocopy(microCopies.INTERVIEW_ROUND_TWO_PREVIEW_HEADING)}
      />

      <div className={styles.content_wrapper}>
        <div className={styles.frame_div}>
          <div className={styles.first_frame}>
            <div>
              <p className={styles.role_title}>Role</p>
              <p className={styles.role}>{candidateData?.role}</p>
            </div>

            <div>
              <p className={styles.role_title}>Skills Focus</p>
              <p className={styles.role}>
                {skills?.map(
                  (item: string, idx: number) =>
                    item + (idx !== skills?.length - 1 ? " | " : " ")
                )}
              </p>
            </div>
          </div>
        </div>

        <div className={styles.frame_div}>
          <p className={styles.label}>Interview Flow</p>

          <div className={styles.pills_wrapper}>
            {flowPointers?.map((item, idx) => (
              <div key={idx} className={styles.pills}>
                {item}
              </div>
            ))}
          </div>
        </div>

        <div className="flex gap-[20px]">
          <div className="w-1/2">
            <div className={styles.frame_div}>
              <p className={styles.label}>What we look for</p>
              <div className={styles.pills_wrapper}>
                {expectations?.map((item, idx) => (
                  <div key={idx} className={styles.pills}>
                    {item}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className={styles.vdeo_wrapper}>
            <button onClick={() => setOpen(true)} className={styles.watch_btn}>
              Watch Demo Video
            </button>
          </div>
        </div>

        <div className="flex justify-center w-full mt-5">
          <InterviewActionButton
            isDarkMode
            handleClick={() =>
              navigate(
                `/${RouteLinks.Interview_Ping}?assessment=${assessmentId}&token=${token}`
              )
            }
            label="Next"
          />
        </div>
      </div>

      <Modal
        open={open}
        onClose={closeModal}
        center
        styles={{
          modal: {
            backgroundColor: "black",
            border: "none",
            padding: "0",
            boxShadow: "none",
            borderRadius: "14px",
            background: "white",
            height: "70vh",
            width: "1040px",
            position: "relative",
            overflow: "visible",
          },
          closeButton: {
            background: "white",
            borderRadius: "100%",
            padding: "4px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "28px",
            height: "28px",
          },
        }}
      >
        <div className={styles.modalContent}>
          <video
            ref={videoRef}
            className={styles.video}
            src="https://nodejs-stl.s3.ap-south-1.amazonaws.com/ezgif-6-0763b66495-jpg.zip/images/loom-video_1.mp4"
          />
          <div className={styles.controls}>
            <button
              onClick={togglePlayPause}
              className={styles.playPauseButton}
            >
              {isPlaying ? (
                <IoPauseOutline size={44} />
              ) : (
                <IoIosPlay size={44} />
              )}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default memo(InterviewPreview);
