import React from "react";
import Modal from "react-responsive-modal";

import {GenericError} from "components/InterviewPermissions/GenericError";
import {SystemDeniedPermissions} from "components/InterviewPermissions/SystemDeniedPermissions";
import {TrackError} from "components/InterviewPermissions/TrackError";
import {UserDeniedPermissions} from "components/InterviewPermissions/UserDeniedPermissions";

import {AppColors} from "globals/appcolors";
import {CustomModalBaseProps, DialogType} from "globals/types/globalTypes";

interface IProps extends CustomModalBaseProps {
  permissionError: DialogType | null;
}

export const PermissionsChangedAlert: React.FC<IProps> = ({
  open,
  onClose,
  permissionError,
}) => {
  const renderDialogContent = () => {
    switch (permissionError) {
      case DialogType.systemDenied:
        return <SystemDeniedPermissions />;
      case DialogType.userDenied:
        return <UserDeniedPermissions />;
      case DialogType.trackError:
        return <TrackError />;
      case DialogType.explanation:
        return <GenericError />;
    }
  };

  return (
    <Modal
      open={open}
      center
      onClose={onClose}
      showCloseIcon={false}
      styles={{
        modal: {
          background: AppColors.WHITE,
          borderRadius: 15,
          padding: "1.5rem 2rem",
          maxWidth: "60vw",
          maxHeight: "70vh",
          overflowY: "scroll",
        },
      }}
    >
      {renderDialogContent()}
    </Modal>
  );
};
