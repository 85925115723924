import React, {useEffect, useState} from "react";
import TextareaAutosize from "react-textarea-autosize";
import ViewButton from "components/Containers/Button/Button/ViewButton";
import {
  InterviewQuestion,
  InterviewQuestionOption,
  QuestionOption,
} from "globals/types/globalTypes";
import strings from "globals/constants/strings.json";
import SizedBox from "components/SizedBox/SizedBox";
import {Checked} from "components/Icons";
import {AppColors} from "globals/appcolors";

export type Click_Direction = "click" | "proceed";

interface Props {
  question: InterviewQuestion | undefined;
  answer: QuestionOption[];
  isAtLastQuestion: boolean;
  clearSelection: (question: InterviewQuestion | undefined) => void;
  onChangeAnswer: (
    option: QuestionOption[] | undefined,
    direction: Click_Direction
  ) => void;
}

const Question: React.FC<Props> = ({
  question,
  answer,
  isAtLastQuestion,
  onChangeAnswer,
  clearSelection,
}) => {
  const {question_options: options} = question?.question || {};

  const [answer_to_question, setAnswer_to_question] = useState<
    QuestionOption[] | undefined
  >([]);

  useEffect(() => {
    setAnswer_to_question(
      [...answer].filter((item) => item.question_id === question?.question_id)
    );
  }, [question]);

  const storeAnswer = (option: QuestionOption) => {
    let optionExists = false;
    if (answer_to_question) {
      for (let i = 0; i < answer_to_question.length; i++) {
        const existingOption = answer_to_question[i];
        if (existingOption.id === option.id) {
          optionExists = true;
          break;
        }
      }
      if (optionExists) {
        const {question_type} = question!.question;
        if (question_type === 1) setAnswer_to_question([{...option}]);
        else if (question_type === 2)
          setAnswer_to_question(
            [...answer_to_question].filter((answer) => answer.id !== option.id)
          );
      } else {
        if (question?.question.question_type === 1) {
          let tempAnswers = [];
          tempAnswers.push(option);
          setAnswer_to_question(tempAnswers);
        } else if (question?.question.question_type === 2) {
          let tempAnswers = [...answer_to_question];
          tempAnswers.push(option);
          setAnswer_to_question(tempAnswers);
        }
      }
    }
  };

  const changeAnswer = (direction: Click_Direction) => {
    onChangeAnswer(answer_to_question, direction);
  };

  const Selection: React.FC<{
    option: QuestionOption;
    answers: QuestionOption[] | undefined;
  }> = ({option, answers}) => {
    const [selected, setSelected] = useState<boolean>(false);

    useEffect(() => {
      let isSelected = false;
      for (let i = 0; i < answers!.length; i++) {
        const answer = answers![i];
        if (answer.id === option?.id) {
          isSelected = true;
          break;
        }
      }
      setSelected(isSelected);
    }, [answers]);

    if (question!.question.question_type === 1)
      return (
        <div
          className={`w-7 h-7 rounded-50 flex items-center justify-center border border-solid border-monoGrey-400 mr-2 ${
            selected ? "bg-primary" : ""
          }`}
        >
          <div className="w-3 h-3 bg-[#fafafc] rounded-50"></div>
        </div>
      );
    else if (question!.question.question_type === 2)
      return (
        <div
          className={`w-6 h-6 rounded flex items-center justify-center border border-monoGrey-400 mr-2`}
        >
          {selected && <Checked color={AppColors.PRIMARYCOLOR} />}
        </div>
      );
    else return null;
  };

  return (
    <div className="relative" style={{height: "86%"}}>
      <div
        className="flex justify-center overflow-scroll"
        style={{height: "90%", maxHeight: "90%"}}
      >
        <div className="w-85 pb-3 pt-8 h-full overflow-scroll">
          <div className="h-full">
            <TextareaAutosize
              value={question?.question.question}
              className="text-base font-semibold outline-none w-100 resize-none bg-[transparent]"
              readOnly
              onCopy={(e) => e.preventDefault()}
              // maxRows={5}
            ></TextareaAutosize>
            {question!.question.question_type === 2 && (
              <p className="text-red-500 text-xs mt-0 pt-0 -translate-y-1">
                (This question has multiple correct answers *)
              </p>
            )}
            <ul className="list-none mt-2 overflow-y-scroll">
              {options?.map((option) => {
                return (
                  <React.Fragment key={option.id.toString()}>
                    <div
                      className="mb-3 flex items-center hover:cursor-pointer"
                      onClick={() => storeAnswer(option)}
                      // style={{
                      //   background:
                      //     option.is_correct_option === 1
                      //       ? "red"
                      //       : "transparent",
                      // }}
                    >
                      <div>
                        <Selection
                          option={option}
                          answers={answer_to_question}
                        />
                      </div>
                      <SizedBox width="6px" />
                      <TextareaAutosize
                        value={
                          option.option.indexOf("\n") == 0
                            ? option.option.substring(1)
                            : option.option.indexOf("\n") ==
                              option.option.length - 1
                            ? option.option.substring(
                                0,
                                option.option.length - 1
                              )
                            : option.option
                        }
                        className="text-sm outline-none resize-none noselect bg-[transparent]"
                        readOnly
                        onCopy={(e) => e.preventDefault()}
                        // maxRows={4}
                      ></TextareaAutosize>
                    </div>
                  </React.Fragment>
                );
              })}
            </ul>
          </div>
        </div>
      </div>

      <div
        className="flex justify-center w-full border-t border-y-monoGrey-400"
        style={{height: "15%", maxHeight: "15%"}}
      >
        <div className="w-85 flex items-center justify-between py-6">
          <ViewButton
            name={strings.interview.clear}
            invert
            style={{height: "35px"}}
            onClick={() => {
              setAnswer_to_question([]);
              clearSelection(question);
            }}
          />
          <ViewButton
            name={
              isAtLastQuestion ? strings.save : strings.interview.save_and_next
            }
            style={{
              height: "35px",
            }}
            onClick={() => changeAnswer("proceed")}
          />
        </div>
      </div>
    </div>
  );
};

export default Question;
