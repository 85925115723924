import React, {useRef, useState} from "react";
import * as Yup from "yup";
import {Box, TextField, Typography} from "@mui/material";
import {Formik, Form, Field} from "formik";
import {toast} from "react-toastify";
import strings from "globals/constants/strings.json";
import {AppColors} from "globals/appcolors";
import {LeftArrow, RightArrow} from "components/Icons";
import stylesCss from "./style.module.scss";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import {useCreateAssessment} from "hooks/assessment/useCreateAssessment";
import Loader from "components/Loader";
import {parseResume} from "globals/helpers";

interface Props {
  next: () => void;
  back: () => void;
}

const SourceAndResumeDetails: React.FC<Props> = ({next, back}) => {
  const validateSchema = Yup.object({
    source: Yup.string().required("Candidate source is required"),
    noOfQuestion: Yup.string().required(
      "Please mention the number of questions"
    ),
    resume: Yup.string().required("Pleaase upload a resume").nullable(),
  });
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const {createAssesmentValues, setCreateAssesmentValues} =
    useAppCommonDataProvider();
  const {mutateAsync: createAssessment} = useCreateAssessment(true, setLoading);

  const onSubmit = () => {
    if (createAssesmentValues) {
      setLoading(true);
      const userOrgId: any = JSON.parse(localStorage.getItem("USER_DETAILS")!);
      const {
        id,
        skills,
        date,
        experience_value,
        switch: orgOnlyQuestions,
        candidate_source,
        resume,
        questions,
      } = createAssesmentValues;

      let formData = new FormData();

      formData.append("assessment_template_id", id.toString());
      formData.append(
        "organization_id",
        userOrgId.user.teams[0].organization.id
      );
      formData.append("number_of_questions", questions);
      formData.append("candidate_experience_level", experience_value);
      skills.forEach((skill, index) => {
        formData.append(`skill_ids[${index}]`, skill);
      });
      formData.append("candidate_source", candidate_source);
      formData.append("scheduled_date", date!.format("YYYY-MM-DD"));
      formData.append(
        "include_organization_questions",
        orgOnlyQuestions.toString()
      );
      formData.append("file", resume);

      createAssessment(formData).then((res) => {
        if (res !== undefined) {
          if (res.data.success === true) {
            console.log("response data", res.data);
            toast.success("Assessment Created", {
              position: "bottom-left",
            });
            setLoading(false);
            next();
          } else {
            console.log("eeeee");
          }
        }
      });
    }
  };

  const uploadResume = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    hiddenFileInput.current?.click();
  };

  return loading ? (
    <Loader loaderStyles="h-36" />
  ) : (
    <Box component={"div"}>
      <Typography>Candidate Details</Typography>
      <Formik
        initialValues={{
          source:
            createAssesmentValues?.candidate_source !== ""
              ? createAssesmentValues?.candidate_source
              : "",
          noOfQuestion:
            createAssesmentValues?.questions !== ""
              ? createAssesmentValues?.questions
              : "",
          resume: null,
        }}
        validationSchema={validateSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="mt-8 ">
              <p className="text-sm">
                {strings.create_assessment.candidate_source}
              </p>
              <div className="relative flex flex-col">
                <input
                  type="text"
                  name="source"
                  onChange={(e) => {
                    const {value} = e.target;
                    setFieldValue("source", value);
                    //@ts-ignore
                    setCreateAssesmentValues?.({
                      ...createAssesmentValues,
                      candidate_source: value,
                    });
                  }}
                  onBlur={handleBlur}
                  value={values.source}
                  className="mt-2 bg-white-800 w-3/4 h-10 border border-white-300 border-solid rounded pl-2 text-xs"
                  placeholder={strings.create_assessment.candidate_source}
                />
                {errors.source && touched.source && (
                  <span className="text-red-500 text-xs mt-2">
                    {errors.source}
                  </span>
                )}
              </div>
            </div>

            <div className="mt-8 ">
              <p className="text-sm">
                {strings.create_assessment.no_of_question}
              </p>
              <div className="relative flex flex-col">
                <input
                  type="text"
                  name="noOfQuestion"
                  onChange={(e) => {
                    const {value} = e.target;
                    if (isNaN(+value) === true) return;
                    setFieldValue("noOfQuestion", value);
                    //@ts-ignore
                    setCreateAssesmentValues?.({
                      ...createAssesmentValues,
                      questions: value,
                    });
                  }}
                  onBlur={handleBlur}
                  value={values.noOfQuestion}
                  className="mt-2 bg-white-800 w-3/4 h-10 border border-white-300 border-solid rounded pl-2 text-xs"
                  placeholder={strings.create_assessment.no_of_question}
                  maxLength={3}
                />
                {errors.noOfQuestion && touched.noOfQuestion && (
                  <span className="text-red-500 text-xs mt-2">
                    {errors.noOfQuestion}
                  </span>
                )}
              </div>
            </div>

            <div className="mt-8 flex-flex-col">
              <p className="text-sm">{strings.create_assessment.resume}</p>
              <div
                className="mt-2 w-full border border-dotted flex items-center p-4 py-6 rounded"
                style={{borderColor: AppColors.BLACK_BORDER_LIGHT}}
              >
                <div className="flex flex-0.3 items-center justify-center">
                  <button
                    style={{background: AppColors.LIGHT_BG_BLUE}}
                    className="w-1/2 h-10 rounded text-blue-500 font-bold text-sm"
                    onClick={uploadResume}
                  >
                    {strings.upload}
                  </button>
                </div>
                <div className="flex flex-0.7 text-black-700 items-center text-sm">
                  {values?.resume
                    ? values?.resume
                    : strings.create_assessment.no_resume}
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={(e) => {
                      setFieldValue("resume", e.target.files![0].name);
                      //@ts-ignore
                      setCreateAssesmentValues?.({
                        ...createAssesmentValues,
                        resume: e.target.files![0],
                      });
                    }}
                    style={{display: "none"}}
                  />
                </div>
              </div>
              {errors.resume !== "" && touched.resume === true && (
                <span className="text-red-500 text-xs mt-2">
                  {errors.resume}
                </span>
              )}
            </div>

            <div className="flex w-full justify-between mt-6">
              <button className={stylesCss.back_btn_wrap} onClick={back}>
                <LeftArrow className={stylesCss.back_icon} size={20} />
                <Typography className={stylesCss.back_button}>Back</Typography>
                <div className={stylesCss.back_btn_after}></div>
              </button>

              <button className={stylesCss.next_btn_wrap} type="submit">
                <div className={stylesCss.next_btn_before}></div>
                <Typography className={stylesCss.next_button}>
                  Submit
                </Typography>
                <RightArrow className={stylesCss.icon} size={20} />
              </button>
            </div>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default SourceAndResumeDetails;
