import React, {CSSProperties} from "react";
import styles from "./ButtonSass.module.scss";
import plusicon from "Images/plusicon.svg";
import {ButtonSubmit} from "globals/types/globalTypes";

interface Props {
  text?: string;
  extraStyles?: CSSProperties | undefined;
  type?: ButtonSubmit;
  onClick?: () => void;
}

const Buttons: React.FC<Props> = ({text, extraStyles, type, onClick}) => {
  return (
    <div>
      <button
        className={`${styles.button} ${styles.button3}`}
        style={extraStyles}
        type={type}
        onClick={onClick}
      >
        <div>
          <img src={plusicon} alt="plusicon"></img>
        </div>
        <div className={styles.con}>{text}</div>
      </button>
    </div>
  );
};

export default Buttons;
