import React from "react";
import {Link, Typography} from "@mui/material";
import Bowser from "bowser";

import strings from "globals/constants/strings.json";

const browser = Bowser.getParser(window.navigator.userAgent);

export const SystemDeniedPermissions: React.FC = () => {
  const {description_text} =
    strings.interview.permissions_error.system_denied_permission;
  const SystemPreferencesLink = {
    macOS: {
      name: "System Preferences",
      link: "x-apple.systempreferences:com.apple.preference.security?Privacy_Camera",
    },
  };

  return (
    <div>
      <Typography variant="h4" sx={{fontWeight: 600}}>
        Can't use your camera or microphone
      </Typography>
      <Typography sx={{marginTop: 2, fontWeight: 500, fontSize: "1rem"}}>
        {description_text}
        {
          // @ts-ignore
          SystemPreferencesLink[browser.getOSName()] ? (
            <Link
              onClick={() => {
                window.open(
                  // @ts-ignore
                  SystemPreferencesLink[browser.getOSName()].link,
                  "_blank"
                );
              }}
            >
              {
                // @ts-ignore
                SystemPreferencesLink[browser.getOSName()].name
              }
            </Link>
          ) : (
            "Settings"
          )
        }
        .
      </Typography>
    </div>
  );
};
