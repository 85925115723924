import Box from "@mui/material/Box";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import Buttons from "components/Containers/Button/Button/Buttons";
import ViewButton from "components/Containers/Button/Button/ViewButton";
import {AssessmentTemplateCard} from "components/Containers/Cards/AssessmentCard/AssessmentTemplateCard";
import {DrawerView} from "components/Drawer";
import {Add, BackArrow, Clear} from "components/Icons";
import Loader from "components/Loader";
import {CreateAssessmentType} from "components/Popups/CreateAssessmentType";
import SizedBox from "components/SizedBox/SizedBox";
import {AppColors} from "globals/appcolors";
import {APIEndPoints} from "globals/constants/endPoints";
import {AssesmentLinks} from "globals/constants/routeLinks";
import strings from "globals/constants/strings.json";
import {postGoogleAnalyticsEvent} from "globals/helpers";
import {
  AssessmentsResponse,
  DeleteAssessmentResponse,
} from "globals/types/APIResponses";
import {
  AssessmentData,
  AssessmentFilterSource,
} from "globals/types/globalTypes";
import {Images} from "Images";
import {debounce} from "lodash";
import moment from "moment";
import axiosDeleteModule from "network/Delete/axiosDeleteModule";
import axiosGetModule from "network/Get/AxiosGetModule";
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {Spinner} from "react-activity";
import {useNavigate} from "react-router-dom";
import {BasicAssessmentDetails} from "../CreateAssessment/BasicAssessmentDetails";
import {IntervieweeDetails} from "../CreateAssessment/IntervieweeDetails";
import SourceAndResumeDetails from "../CreateAssessment/SourceAndResumeDetails";
import {WorkExperienceDetails} from "../CreateAssessment/WorkExperienceDetails";

const steps = ["Assessment Details", "Interviewee Details", "Work Experience"];

let currentPage: number = 0;

const AssessmentPage: React.FC = () => {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const eventTracker = postGoogleAnalyticsEvent("Assessment");

  const buttonStyles: CSSProperties = {
    padding: ".5rem 1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const [assessments, setAssessments] = useState<AssessmentData[] | undefined>(
    []
  );
  const [candidateName, setCandidateName] = React.useState<string>("");
  const [filterText, setFilterText] = React.useState<string>("");
  const [nodata__source, setNodata__source] =
    useState<AssessmentFilterSource>("default");
  const [showLoadMore, setshowLoadMore] = useState(true);
  const [loadingMoreRecords, setLoadingMoreRecords] = useState<boolean>(false);
  const [delFlag, setdelFlag] = useState<boolean>(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openDrawerForBulk, setOpenDrawerForBulk] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const onCloseModal = () => setOpenModal(false);

  const onOpenDrawer = () => {
    onCloseModal();
    setOpenDrawer(true);
  };

  const onOpenDrawerBulk = () => {
    setOpenModal(false);
    setOpenDrawerForBulk(true);
  };

  const {
    loading,
    setLoading,
    setAssessments_timestamp,
    assessments: cachedAssessments,
    scrollOffset,
    setScrollOffset,
    createAssesmentValues,
    setCreateAssesmentValues,
  } = useAppCommonDataProvider();

  useEffect(() => {
    setLoading?.(true);
    currentPage = 1;
    fetchAssessments();
  }, []);

  useEffect(() => {
    currentPage = 1;
    if (scrollOffset) {
      window.scrollTo({
        top: scrollOffset,
        behavior: "smooth",
      });
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollOffset?.(position);
  };

  const fetchRemotely = async (filter: string) => {
    currentPage === 1 && setLoading?.(true);
    eventTracker("Assessments  Viewed", "Assessment Page Viewed");
    let params: {page?: number} = {};

    if (currentPage) {
      params.page = currentPage;
      setLoadingMoreRecords(true);
    }

    await axiosGetModule(
      APIEndPoints.GET_ASSESSMENTS_NODE + `?page=${currentPage}&key=${filter}`
    )
      .then((res: AssessmentsResponse) => {
        if (res?.data?.assesments?.length) {
          res?.data?.meta?.current_page === 1
            ? setAssessments([...res?.data?.assesments])
            : setAssessments([...assessments!, ...res?.data?.assesments]);
          setAssessments_timestamp?.(new Date());
          if (currentPage === res?.data?.meta?.last_page)
            setshowLoadMore(false);
          else setshowLoadMore(true);
        } else {
          setAssessments_timestamp?.(null);
          setAssessments([]);
          setNodata__source(filter ? "filter" : "default");
        }
        setTimeout(() => {
          setLoading?.(false);
        }, 1000);
      })
      .catch(() => {
        setLoading?.(false);
      })
      .finally(() => setLoadingMoreRecords(false));
  };

  const fetchAssessments = async (direction?: string) => {
    if (direction) fetchRemotely("");
    else {
      if (cachedAssessments) {
        setAssessments(cachedAssessments?.data);
        setLoading?.(false);
        if ((currentPage = cachedAssessments?.meta?.last_page))
          setshowLoadMore(false);
        else setshowLoadMore(true);
      } else {
        fetchRemotely("");
      }
    }
  };

  const filterAssessments = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setCandidateName(value);
    currentPage = 1;
    fetchRemotely(value);
    setCandidateName("");
  };

  const debounceSearch = useCallback(debounce(filterAssessments, 500), []);

  const deleteAssessment = async (id: number) => {
    try {
      axiosDeleteModule(APIEndPoints.ASSESMENT_NODE_OF_ID(id)).then(
        (res: DeleteAssessmentResponse) => {
          if (Object.keys(res?.data).length) {
            eventTracker("Assessment Deleted", "An assessment was deleted");
            setdelFlag(true);
            // fetchRemotely("");
          }
        }
      );
    } catch (e) {}
  };

  if (delFlag) {
    fetchRemotely("");
    setdelFlag(false);
  }

  const clearFilters = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setFilterText("");
    setCandidateName("");
    fetchRemotely("");
    if (!assessments?.length) {
      //@ts-ignore
      searchInputRef.current.value = "";
    }
  };

  const reuseAssessment = (
    details: AssessmentData,
    event: React.MouseEvent<HTMLParagraphElement, MouseEvent>
  ) => {
    const {skills} = details;
    //@ts-ignore
    setCreateAssesmentValues?.({
      ...createAssesmentValues,
      id: details.assessment_template_id!,
      date: moment(details.created_at),
      experience_value: details.candidate.experience_level.toString(),
      candidate_source: details.candidate.source,
      first_name: details.candidate.user.first_name,
      last_name: details.candidate.user.last_name,
      email: details.candidate.user.email,
      years_of_experience: Math.trunc(+details.experience_duration).toString(),
      questions: details.candidate.number_of_questions,
      skills: skills.map((skill) => skill.id),
    });
    setOpenDrawer(true);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep === steps.length - 1) {
      handleDrawerClose();
      fetchAssessments();
    }
  };

  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  function resetCreateForm() {
    setCreateAssesmentValues?.(null);
    setActiveStep(0);
  }

  const handleDrawerClose = () => {
    setOpenDrawerForBulk(false);
    setOpenDrawer(false);
    resetCreateForm();
  };

  const onHandleBulkDrawerClose = () => {
    setOpenDrawerForBulk(false);
    resetCreateForm();
  };

  const renderCreateAssessmentForm = () => (
    <Box sx={{width: "100%"}} component={"div"}>
      <Box sx={{width: "100%"}} component={"div"}>
        <div className="bg-primary w-full flex text-white-900 items-center">
          <div
            onClick={openDrawer ? handleDrawerClose : onHandleBulkDrawerClose}
            className="cursor-pointer"
          >
            <BackArrow color={AppColors.WHITE} size={30} className="ml-4" />
          </div>
          <p className="flex px-4 py-2 m-2 text-3xl font-bold">
            {strings.candidate.create_new_assessment}
          </p>
        </div>
      </Box>
      <Box
        sx={{width: "100%", padding: "2rem"}}
        role="presentation"
        component={"div"}
      >
        <Box sx={{width: "100%"}} component={"div"}>
          {activeStep === steps.length ? null : (
            <React.Fragment>
              {openDrawer ? (
                <Box sx={{Width: "100%"}} component={"div"}>
                  {activeStep === 0 && (
                    <BasicAssessmentDetails next={handleNext} />
                  )}
                  {activeStep === 1 && (
                    <IntervieweeDetails next={handleNext} back={handleBack} />
                  )}
                  {activeStep === 2 && (
                    <WorkExperienceDetails
                      next={handleNext}
                      back={handleBack}
                    />
                  )}
                </Box>
              ) : openDrawerForBulk === true ? (
                <Box sx={{Width: "100%"}} component={"div"}>
                  {activeStep === 0 && (
                    <BasicAssessmentDetails next={handleNext} />
                  )}
                  {activeStep === 1 && (
                    <SourceAndResumeDetails
                      next={() => {
                        handleDrawerClose();
                        fetchAssessments();
                      }}
                      back={handleBack}
                    />
                  )}
                </Box>
              ) : null}
            </React.Fragment>
          )}
        </Box>
      </Box>
    </Box>
  );

  const navigateToExpiredAssessment = () => {
    eventTracker(
      "Expired Assessment CTA Clicked",
      "Navigating to expired assessments from assessment home page"
    );
    navigate(`/${AssesmentLinks.Expired_Assessment}`);
  };

  if (loading) return <Loader />;

  if (assessments?.length === 0 && nodata__source === "default")
    return (
      <div className="flex justify-center flex-col items-center pt-8">
        <img
          src={Images.NoAssessments}
          alt=""
          className="object-contain w-1/3"
        />
        <SizedBox height="2rem" />
        <p className="text-2xl">{strings.create_assessment.no_assessments}</p>
        <SizedBox height="1rem" />
        <button
          className="bg-primary text-white-900 px-8 py-2 rounded-lg flex items-center justify-center"
          onClick={() => setOpenModal(true)}
        >
          <Add size={20} />
          <p className="ml-1 font-medium">
            {strings.create_assessment.no_assessments_button_text}
          </p>
        </button>

        {openDrawer && (
          <DrawerView className="w-[40%]" onClose={handleDrawerClose}>
            {renderCreateAssessmentForm()}
          </DrawerView>
        )}

        {openDrawerForBulk && (
          <DrawerView className="w-[40%]" onClose={onHandleBulkDrawerClose}>
            {renderCreateAssessmentForm()}
          </DrawerView>
        )}

        {openModal && (
          <CreateAssessmentType
            open={openModal}
            onClose={onCloseModal}
            title={"What type of assessment do you want to create?"}
            primaryBtnLabel={"Single"}
            primaryBtnAction={onOpenDrawer}
            secondaryBtnAction={onOpenDrawerBulk}
          />
        )}
      </div>
    );

  return (
    <div className="mt-4">
      <div className="flex items-center justify-between">
        <p className="text-black font-bold text-2xl">Assessment</p>
        <div className="w-1/3 flex justify-end">
          <button onClick={() => setOpenModal(true)}>
            <Buttons text={"Create"} extraStyles={buttonStyles} />
          </button>

          {openDrawer && (
            <DrawerView className="w-[40%]" onClose={handleDrawerClose}>
              {renderCreateAssessmentForm()}
            </DrawerView>
          )}

          {openDrawerForBulk && (
            <DrawerView className="w-[40%]" onClose={onHandleBulkDrawerClose}>
              {renderCreateAssessmentForm()}
            </DrawerView>
          )}

          {openModal && (
            <CreateAssessmentType
              open={openModal}
              onClose={onCloseModal}
              title={"What type of assessment do you want to create?"}
              primaryBtnLabel={"Single"}
              primaryBtnAction={onOpenDrawer}
              secondaryBtnAction={onOpenDrawerBulk}
            />
          )}

          <button
            onClick={navigateToExpiredAssessment}
            className="m-4 py-2 px-4 border border-primary rounded-lg text-primary"
          >
            Expired
          </button>
        </div>
      </div>
      <div className="my-3">
        <div className="flex items-center w-80">
          <div className="w-1/2 mr-8 bg-blue-200">
            <input
              type="text"
              placeholder={strings.assessment_filters.candidate_and_assessment}
              onChange={debounceSearch}
              className="w-full h-10 px-2 text-sm focus:outline-none shadow-md rounded border border-white-300"
              style={{height: 50}}
              ref={searchInputRef}
            />
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="flex items-center flex-1 flex-wrap">
          {assessments?.length ? (
            assessments?.map((assessment) => {
              return (
                <AssessmentTemplateCard
                  assessmentDetails={assessment}
                  onClick={() => {}}
                  key={assessment.id}
                  onDeleteItem={deleteAssessment}
                  source="assessment"
                  showShowDetailBtn={false}
                  reuseAssessment={reuseAssessment}
                />
              );
            })
          ) : (
            <div className="flex justify-center flex-col items-center w-full mt-4 ">
              <div className=" items-center w-full flex justify-center">
                <img
                  // src={Images.NoAssessments}
                  src={Images.NewNoAssessments}
                  alt=""
                  className="object-contain flex w-[30%] "
                />
              </div>
              <SizedBox height="1rem" />
              <p className="text-xl">
                {strings.assessment_test.no_templates_after_filtering}
              </p>
              <SizedBox height="1rem" />
              <button
                className="bg-primary text-white-900 px-10 py-2 rounded-lg flex items-center justify-center"
                onClick={clearFilters}
              >
                <Clear color={AppColors.WHITE} size={20} />
                <p className="ml-1 font-medium">
                  {strings.assessment_test.reset_filters}
                </p>
              </button>
            </div>
          )}
        </div>
      </div>
      {showLoadMore && (
        <div
          className="flex flex-col items-center"
          style={{opacity: !assessments?.length ? 0 : 1}}
        >
          <SizedBox height="10px" />
          <ViewButton
            invert
            name={loadingMoreRecords ? <Spinner size={15} /> : strings.more}
            onClick={() => {
              currentPage++;
              fetchAssessments("more");
            }}
            isDisabled={loadingMoreRecords}
            style={{
              paddingInline: "50px",
              pointerEvents: !assessments?.length ? "none" : "all",
            }}
          />
        </div>
      )}
      <SizedBox height="20px" />
    </div>
  );
};
export default AssessmentPage;
