import React, {useEffect, useRef, useState} from "react";
import Modal from "react-responsive-modal";
import {Button, Typography} from "@mui/material";

import {halfModalContainer} from "globals/constants/styles";
import {CustomModalBaseProps} from "globals/types/globalTypes";
import strings from "globals/constants/strings.json";
import {useNavigate} from "react-router-dom";
import {RouteLinks} from "globals/constants/routeLinks";

interface IProps extends CustomModalBaseProps {
  id: string;
  assessmentId: string;
  duration: string;
}

export const BehaviourSetComplete: React.FC<IProps> = ({
  open,
  onClose,
  id,
  assessmentId,
  duration,
}) => {
  const navigate = useNavigate();

  const timerId = useRef<NodeJS.Timer | undefined>(undefined);
  const remainingTimeReference = useRef<number>(5);

  const [remainingTime, setRemainingTime] = useState<number>(5);

  useEffect(() => {
    timerId.current = setInterval(() => {
      if (remainingTimeReference.current === 1) {
        redirect();
        clearInterval(timerId.current);
        return;
      }
      remainingTimeReference.current -= 1;
      setRemainingTime((prev) => prev - 1);
    }, 1000);
    return () => {
      clearInterval(timerId.current);
    };
  }, []);

  function redirect() {
    navigate(
      `/${RouteLinks.Interview}/${id}/${assessmentId}/introduction?duration=${duration}`
    );
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      showCloseIcon={false}
      styles={{modal: halfModalContainer}}
    >
      <Typography variant="h4" fontWeight={"bold"}>
        {strings.wait_up}
      </Typography>
      <Typography sx={{marginTop: 2}} fontWeight={"600"}>
        <span>{strings.behaviour_set_complete}</span>
        <span>
          <a
            className="cursor-pointer underline text-primary font-bold"
            onClick={redirect}
          >{`here`}</a>
        </span>
        <span>{` to proceed or wait `}</span>
        <span className="text-primary font-bold">{` ${remainingTime} ${
          remainingTime > 1 ? "seconds" : "second"
        }`}</span>
        <span>{` before you are automatically redirected.`}</span>
      </Typography>
    </Modal>
  );
};
