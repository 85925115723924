import React from "react";
import Modal from "react-responsive-modal";
import {Typography} from "@mui/material";

import ViewButton from "components/Containers/Button/Button/ViewButton";
import {AppColors} from "globals/appcolors";
import {CustomModalBaseProps} from "globals/types/globalTypes";
import strings from "globals/constants/strings.json";

interface IProps extends CustomModalBaseProps {
  changeCount: number;
  enterFullScreen: () => void;
  finishTest: () => void;
}

export const EnterFullScreenAlert: React.FC<IProps> = ({
  open,
  onClose,
  enterFullScreen,
  finishTest,
  changeCount,
}) => {
  return (
    <Modal
      center
      onClose={onClose}
      open={open}
      showCloseIcon={false}
      styles={{
        modal: {
          background: AppColors.WHITE,
          borderRadius: 15,
          padding: "2rem",
          maxWidth: "50vw",
        },
      }}
    >
      <Typography variant="h4" sx={{fontWeight: 700}}>
        {changeCount < 3
          ? strings.interview.full_screnn_title
          : strings.wait_up}
      </Typography>
      <Typography variant="subtitle1" sx={{marginTop: 1}}>
        To prevent cheating of any kind, we would be needing you to enter full
        screen mode. You are welcome to end the test in case you wanna.
      </Typography>

      <div className="flex items-center justify-end mt-4">
        <ViewButton
          onClick={enterFullScreen}
          name={strings.got_it}
          style={{paddingInline: "2.5rem"}}
        />

        <ViewButton
          invert
          name={strings.finish}
          onClick={finishTest}
          style={{marginLeft: 16, paddingInline: "2.5rem"}}
        />
      </div>
    </Modal>
  );
};
