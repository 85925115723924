import React from "react";

const NoDetailsComp = () => {
  return (
    <div className="flex bg-blue-100 rounded-lg p-3 justify-center items-center">
      No Details Available
    </div>
  );
};

export default NoDetailsComp;
