export type LanguageTypes =
  | "javascript"
  | "typescript"
  | "python"
  | "java"
  | "csharp"
  | "php";

export const LANGUAGE_VERSIONS = {
  javascript: "18.15.0",
  typescript: "5.0.3",
  python: "3.10.0",
  java: "15.0.2",
  csharp: "6.12.0",
  php: "8.2.3",
};

export const CODE_SNIPPETS = {
  javascript: `
function greet(name) {
    console.log("Hello, " + name + "!");
}

greet("Alex");
`,
  typescript: `
type Params = {
    name: string;
}

function greet(data: Params) {
    console.log("Hello, " + data.name + "!");
}

greet({ name: "Alex" });
`,
  python: `
def greet(name):
    print("Hello, " + name + "!")

greet("Alex")
`,
  java: `
public class HelloWorld {
    public static void main(String[] args) {
        String name = "Alex";
        System.out.println("Hello, " + name + "!");
    }
}
`,
  csharp: `
using System;

namespace HelloWorld
{
    class Hello {
        static void Main(string[] args) {
            string name = "Alex";
            Console.WriteLine("Hello, " + name + "!");
        }
    }
}
`,
  php: `
<?php

$name = 'Alex';
echo "Hello, " . $name . "!";
`,
  assembly: `
section .data
    name db "Alex", 0
    greeting db "Hello, ", 0
    newline db 0xA

section .text
    global _start

_start:
    ; Print "Hello, Alex!"
    mov rax, 1              ; syscall: write
    mov rdi, 1              ; file descriptor: stdout
    mov rsi, greeting       ; message to print
    mov rdx, 7              ; length of message
    syscall

    mov rsi, name           ; name to print
    mov rdx, 5              ; length of name
    syscall

    mov rsi, newline        ; newline character
    mov rdx, 1              ; length of newline
    syscall

    ; Exit
    mov rax, 60             ; syscall: exit
    xor rdi, rdi            ; status 0
    syscall
`,
  bash: `
#!/bin/bash

name="Alex"
echo "Hello, $name!"
`,
  basic: `
Dim name As String
name = "Alex"
Print "Hello, " & name & "!"
`,
  c: `
#include <stdio.h>

int main() {
    char name[] = "Alex";
    printf("Hello, %s!\\n", name);
    return 0;
}
`,
  cpp: `
#include <iostream>

int main() {
    std::string name = "Alex";
    std::cout << "Hello, " << name << "!" << std::endl;
    return 0;
}
`,
  clojure: `
(def name "Alex")
(println (str "Hello, " name "!"))
`,
  cobol: `
       IDENTIFICATION DIVISION.
       PROGRAM-ID. HelloWorld.
       DATA DIVISION.
       WORKING-STORAGE SECTION.
       01 NAME PIC A(10) VALUE 'Alex'.
       PROCEDURE DIVISION.
           DISPLAY "Hello, " NAME "!".
           STOP RUN.
`,
  commonLisp: `
(let ((name "Alex"))
  (format t "Hello, A!%" name))
`,
  dart: `
void main() {
  String name = "Alex";
  print("Hello, $name!");
}
`,
  d: `
import std.stdio;

void main() {
    string name = "Alex";
    writeln("Hello, ", name, "!");
}
`,
  elixir: `
name = "Alex"
IO.puts("Hello, #{name}!")
`,
  erlang: `
-module(hello).
-export([greet/0]).

greet() ->
    Name = "Alex",
    io:format("Hello, ~s!~n", [Name]).
`,
  fsharp: `
let name = "Alex"
printfn "Hello, %s!" name
`,
  fortran: `
program hello
    implicit none
    character(len=10) :: name
    name = "Alex"
    print *, "Hello, ", trim(name), "!"
end program hello
`,
  go: `
package main

import "fmt"

func main() {
    name := "Alex"
    fmt.Printf("Hello, %s!\\n", name)
}
`,
  groovy: `
def name = "Alex"
println "Hello, ${name}!"
`,
  haskell: `
main = do
    let name = "Alex"
    putStrLn ("Hello, " ++ name ++ "!")
`,
  kotlin: `
fun main() {
    val name = "Alex"
    println("Hello, $name!")
}
`,
  lua: `
name = "Alex"
print("Hello, " .. name .. "!")
`,
  objc: `
#import <Foundation/Foundation.h>

int main(int argc, const char * argv[]) {
    @autoreleasepool {
        NSString *name = @"Alex";
        NSLog(@"Hello, %@!", name);
    }
    return 0;
}
`,
  ocaml: `
let name = "Alex"
let () = Printf.printf "Hello, %s!\\n" name
`,
  octave: `
name = "Alex";
printf("Hello, %s!\\n", name);
`,
  pascal: `
program HelloWorld;
var
    name: string;
begin
    name := 'Alex';
    writeln('Hello, ', name, '!');
end.
`,
  perl: `
my $name = "Alex";
print "Hello, $name!\\n";
`,
  prolog: `
greet :-
    Name = 'Alex',
    format('Hello, ~w!~n', [Name]).
`,
  r: `
name <- "Alex"
cat("Hello, ", name, "!\\n", sep = "")
`,
  ruby: `
name = "Alex"
puts "Hello, #{name}!"
`,
  rust: `
fn main() {
    let name = "Alex";
    println!("Hello, {}!", name);
}
`,
  scala: `
object HelloWorld {
    def main(args: Array[String]): Unit = {
        val name = "Alex"
        println(s"Hello, $name!")
    }
}
`,
  sql: `
SELECT 'Hello, ' || 'Alex' || '!' AS greeting;
`,
  swift: `
let name = "Alex"
print("Hello, \\(name)!")
`,
  vbnet: `
Module HelloWorld
    Sub Main()
        Dim name As String = "Alex"
        Console.WriteLine("Hello, " & name & "!")
    End Sub
End Module
`,
};
