import {memo} from "react";
import {Bounce} from "react-activity";

interface ActionButtonProps {
  isDarkMode: boolean;
  label: string;
  handleClick: () => void;
  disabled?: boolean;
  otherStyles?: string;
  isLoading?: boolean;
}

const InterviewActionButton = ({
  isDarkMode,
  label,
  handleClick,
  disabled = false,
  otherStyles,
  isLoading = false,
}: ActionButtonProps) => {
  return (
    <button
      disabled={disabled}
      onClick={handleClick}
      className={`${disabled && "opacity-50 cursor-not-allowed"} ${
        isDarkMode ? "bg-[#000] text-[#fff]" : "bg-[#fff] text-[#000]"
      } px-4 lg:px-6 py-2 lg:py-3 leading-[21.6px] text-center text-16 lg:text-18 font-semibold rounded-[6px] h-10 lg:h-[47px] min-w-[60px] lg:min-w-[87px] ${otherStyles}`}
    >
      {label} {isLoading ? <Bounce style={{marginLeft: 4}} /> : null}
    </button>
  );
};

export default memo(InterviewActionButton);
