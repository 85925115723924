import {useQuery, useQueryClient, UseQueryResult} from "@tanstack/react-query";
import {candidateInstance} from "globals/axiosConstants";
import {APIEndPoints} from "globals/constants/endPoints";
import {decodeEncodedAPIResponse} from "globals/helpers";
import {BehaviorQuestions} from "globals/types/APIResponses";

export function useBehaviorQuestions(
  assessmentId: string
): UseQueryResult<BehaviorQuestions, unknown> {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ["behavior"],
    queryFn: async () => {
      return candidateInstance
        .get(APIEndPoints.GET_BEHAVIOR_QUESTIONS(assessmentId))
        .then((res) => {
          if (res.data.success === true && res.data.code === 200) {
            const decodedData: BehaviorQuestions = decodeEncodedAPIResponse(
              res.data.data,
              res.data
            );
            queryClient.invalidateQueries(["behavior"]);
            return decodedData;
          }
        });
    },
    onError: (e) => e,
    enabled: false,
  });
}
