import CodeEditor from "components/CodeEditor/CodeEditor";
import InterviewHeader from "components/InterviewHeader";
import {Webcam} from "components/Webcam";
import {memo} from "react";
import styles from "./CodingRound.module.css";

interface Props {
  handleQuestionChange: () => void;
  question: string;
  currentQuestionNumber: number;
}

const CodingRound = ({
  handleQuestionChange,
  question,
  currentQuestionNumber,
}: Props) => {
  return (
    <div className={styles.main_wrapper}>
      <InterviewHeader
        isDarkMode
        title="Coding Round"
        showTimer
        initialTime={1800}
      />

      <div className="flex px-[60px] bg-navyBlue-700 h-[87vh]">
        {/* Left Side */}
        <div className="w-2/5 h-full p-[20px]">
          <div className="p-6 h-[57vh] rounded-xl bg-[#130E1F] overflow-y-scroll">
            <h2 className="text-white-900 text-14">{`Question (${currentQuestionNumber}/3)`}</h2>
            <p className="text-white-900 text-18 font-medium mt-[6px]">
              {question}
            </p>
          </div>
          <div className="flex w-full justify-between gap-x-5 mt-5">
            <div className="rounded-xl w-full overflow-hidden">
              <Webcam />
            </div>
            <div className="rounded-xl w-full bg-[#130E1F] flex justify-center items-center">
              <img
                className="w-[104px] h-[104px]"
                src="https://images.ctfassets.net/7oc31naqqojs/4xddgiW20opOeM9J9KVdJF/69f159e1a13bbbdf48dd35534382bc2b/receffect.png"
              />
            </div>
          </div>
        </div>

        {/* Right Side */}
        <div className="w-3/5 pt-[20px] flex h-full ">
          <CodeEditor
            handleQuestionChange={handleQuestionChange}
            currentQuestionNumber={currentQuestionNumber}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(CodingRound);
