import React, {CSSProperties} from "react";
import {Typography} from "@mui/material";
import {toast} from "react-toastify";

import {Row} from "components/Row";
import CustomSelect from "components/Inputs/Select/CustomSelect";
import SizedBox from "components/SizedBox/SizedBox";

import strings from "globals/constants/strings.json";
import {formatDDMMMYYYY} from "globals/helpers";
import {CandidateDetails} from "globals/types/APIResponses";
import {CandidateDetailsData} from "globals/types/globalTypes";
import {
  interviewStatusOptions,
  allInterviewStatusesOptions,
} from "globals/constants/dropdownConstants";
import {AppColors} from "globals/appcolors";
import {useUpdateAssessment} from "hooks/interview/useUpdateAssessment";

interface IProps {
  assessmentDetails: CandidateDetails;
  candidateDetails: CandidateDetailsData | undefined;
  fetchAssessmentDetails: (id: string) => void;
}

export const AssessmentDatesAndStatus: React.FC<IProps> = ({
  assessmentDetails,
  candidateDetails,
  fetchAssessmentDetails,
}) => {
  const selectStyles = {
    control: (base: CSSProperties) => ({
      ...base,
      background: AppColors.WHITE,
      boxSizing: "border-box",
      fontSize: "16px",
      borderRadius: "6px",
      boxShadow: "none",
      borderColor: AppColors.WHITE,
      "&:hover": {
        border: `1px solid ${AppColors.WHITE}`,
      },
    }),
    container: (provided: CSSProperties) => ({
      ...provided,
      outline: "none",
      fontSize: "16px",
      borderRadius: "6px",
      background: AppColors.WHITE,
    }),
    option: (provided: CSSProperties) => ({
      ...provided,
    }),
  };

  const {created_at, scheduled_date, status} =
    assessmentDetails.data.assessment;
  const {source} = candidateDetails || {};

  const {mutateAsync: updateAssessment} = useUpdateAssessment(
    assessmentDetails.data.assessment.id.toString()
  );

  function update(data: {label: string; value: string}) {
    updateAssessment({status: +data.value}).then((res) => {
      toast.success("Assessment updated");
      fetchAssessmentDetails(assessmentDetails.data.assessment.id.toString());
    });
  }

  return (
    <div className="grid grid-cols-4 border-y-2 border-monoGrey-400 px-2 py-5">
      <Row classNames="flex-col">
        <Typography>{strings.candidateDetails.quickInfo.one}</Typography>
        <SizedBox height="10px" />
        <Typography>{formatDDMMMYYYY(created_at)}</Typography>
      </Row>
      <Row classNames="flex-col">
        <Typography>{strings.candidateDetails.quickInfo.two}</Typography>
        <SizedBox height="10px" />
        <Typography>{formatDDMMMYYYY(scheduled_date)}</Typography>
      </Row>
      <Row classNames="flex-col">
        <Typography>{strings.candidateDetails.quickInfo.three}</Typography>
        <SizedBox height="10px" />
        <Typography>{source || ""}</Typography>
      </Row>
      <Row classNames="flex-col">
        <Typography>{strings.candidateDetails.quickInfo.four}</Typography>
        <SizedBox height="10px" />
        <CustomSelect
          options={interviewStatusOptions}
          customStyles={selectStyles}
          value={assessmentDetails && allInterviewStatusesOptions[status - 1]}
          onChange={(val: {label: string; value: string}) => update(val)}
        />
      </Row>
    </div>
  );
};
