import React, {useState, useEffect} from "react";
import moment, {Moment} from "moment";
import FilterAllInterviews from "./FilterAllInterviews";
import {Spinner} from "react-activity";

import ViewButton from "components/Containers/Button/Button/ViewButton";
import SizedBox from "components/SizedBox/SizedBox";
import Loader from "components/Loader";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";

import strings from "globals/constants/strings.json";
import {APIEndPoints} from "globals/constants/endPoints";
import {AssessmentResponse} from "globals/types/APIResponses";
import {formatDDMMMYYYY, postGoogleAnalyticsEvent} from "globals/helpers";

import styles from "routes/dashboard/DashboardHome/Dashboard.module.scss";
import {
  AssessTypes,
  TotalTableRowProps,
  ListTypes,
} from "routes/dashboard/DashboardHome/Types";
import DashboardTable from "routes/dashboard/DashboardHome/DashboardTable/ReactTable";

import axiosGetModule from "network/Get/AxiosGetModule";

interface MetaData {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number;
}

const AllInterviews: React.FC = () => {
  const allInterviewColumn = [
    {Header: "Name", accessor: "name"},
    {Header: "Assessments Date", accessor: "created_at"},
    {Header: "Assessment Name", accessor: "assessment_name"},
    {Header: "Scored", accessor: "assessment_points_scored"},
    {Header: "Source Name", accessor: "agency_name"},
  ];

  const {loading, setLoading} = useAppCommonDataProvider();
  const eventTracker = postGoogleAnalyticsEvent("Dashboard");

  const [selectedTab, setselectedTab] = useState<number>(0);

  const [flag, setFlag] = useState<boolean>(true);
  const [isFetching, setisFetching] = useState<boolean>(true);
  const [showLoadMore, setshowLoadMore] = useState<boolean>(false);

  const [totalAssessments, setTotalAssessments] = useState<
    TotalTableRowProps[]
  >([]);
  const [todaysAssess, settodaysAssess] = useState<TotalTableRowProps[]>([]);
  const [temp, setTemp] = useState<TotalTableRowProps[]>([]);
  const [list, setList] = useState<{
    search_value: string;
    score?: number | string;
  }>();
  const [filterdate, setfilterDate] = useState<Moment | string | null>();
  const [current_page, setcurrent_page] = useState<number>(1);
  const [metaData, setMetaData] = useState<MetaData>();

  const filterdata = (contact: ListTypes) => {
    setList(contact);
  };

  const dateHandler = (date: Moment | null) => {
    let livedate = date?.format("YYYY-MM-DD");
    setfilterDate(livedate);
    {
      date ? settodaysAssess([]) : "";
    }
  };

  const clearFilter = () => {
    setcurrent_page(1);
    setTemp([]);
    setFlag(true);
    setList({search_value: "", score: ""});
    setfilterDate(null);
  };

  const fetchAllinterviews = async () => {
    let params: {page?: number} = {};

    if (current_page) {
      setshowLoadMore(true);
      setLoading?.(true);
      params.page = current_page;
    }
    return await axiosGetModule(
      APIEndPoints.ALL_INTERVIEWS_NODE +
        `?key=${list?.search_value ? list?.search_value : ""}&score=${
          list?.score ? list?.score : ""
        }&scheduled_date=${filterdate ? filterdate : ""}&page=${current_page}`
    )
      .then((res: AssessmentResponse) => res.data)
      .catch((err) => err)
      .finally(() => {
        setisFetching(false), setLoading?.(false);
      });
  };

  const formatAssessments = (data: AssessmentResponse) => {
    setMetaData(data.meta);
    let formattedAssess: TotalTableRowProps[] = [];
    data?.data.forEach((element: any) => {
      console.log("ele", element);
      formattedAssess.push({
        name: `${element?.candidate?.user?.first_name} ${element?.candidate?.user?.last_name}`,
        assessment_name: element.name,
        created_at: formatDDMMMYYYY(element.scheduled_date),
        assessment_points_scored: `${
          element.assessment_points_scored == null
            ? 0
            : element.assessment_points_scored
        }/${element.assessment_total_points}`,
        agency_name: element?.candidate?.source,
        candidate_id: element.candidate_id,
      });
    });

    if (list?.search_value || list?.score || filterdate) {
      settodaysAssess([...formattedAssess]);
      setTemp([...formattedAssess]);
    } else {
      settodaysAssess([...todaysAssess, ...formattedAssess]);
      setTemp([...temp, ...formattedAssess]);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    eventTracker("Dashboard viewed", "User landed on dashboard");
  }, []);

  useEffect(() => {
    fetchAllinterviews()
      .then((res) => formatAssessments(res))
      .catch(console.log)
      .finally(() =>
        setTimeout(() => {
          setisFetching(false);
          setLoading?.(false);
        }, 300)
      );
  }, [list, filterdate, current_page, flag]);

  //Resetting the page whenever the values in filter box changes
  useEffect(() => {
    setcurrent_page(1);
  }, [list, filterdate]);

  const loadMore = () => {
    let temp = current_page + 1;
    setcurrent_page(temp);
  };

  if (isFetching) return <Loader />;

  return (
    <>
      <div className="flex">
        <div className={styles.interviews__wrapper}>
          <div
            className={styles.interviews__innerWrapper}
            style={{width: "100%"}}
          >
            <p className={styles.interviews__title}>All Interview's</p>
            <div className="px-5">
              <FilterAllInterviews
                filterdata={filterdata}
                dateHandler={dateHandler}
                clearFilter={clearFilter}
              />
            </div>

            <SizedBox height="32px" />
            <DashboardTable
              assessType={AssessTypes.ALL}
              column={allInterviewColumn}
              row={flag ? temp : todaysAssess}
            />

            {showLoadMore &&
              !(metaData?.current_page === metaData?.last_page) && (
                <div className="flex flex-col items-center">
                  <ViewButton
                    invert
                    name={loading ? <Spinner size={15} /> : strings.more}
                    isDisabled={loading ? true : false}
                    onClick={loadMore}
                    style={{paddingInline: "50px"}}
                  />
                  <SizedBox height="20px" />
                </div>
              )}
          </div>
          {!!!totalAssessments.length && selectedTab === 2 && (
            <div>
              <p className={styles.interviews__noData}>
                {strings.dashboard.noData}
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AllInterviews;
