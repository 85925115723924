import {Images} from "../../assets/Image";
import React from "react";
import styles from "./style.module.css";

const cards = [
  {
    head: "70%",
    subhead: "Reduce expenses",
    desc: "Sourcebae automates initial interviews, minimizing manual screening and streamlining coordination.",
  },
  {
    head: "3x",
    subhead: "Faster than the traditional",
    desc: "Immediate feedback and detailed profiles speed up hiring decisions, helping you fill positions faster.",
  },
  {
    head: "3.5x",
    subhead: "Better-suited candidates",
    desc: "Contextual interviews at Sourcebae ensure accurate candidate assessments based on job-specific needs.",
  },
];

const Hiring: React.FC = () => {
  return (
    <div className={styles.main_container}>
      <h2 className={styles.heading}>
        We take care of hiring.{" "}
        <span className={styles.heading_span}>
          You concentrate on what’s important
        </span>
      </h2>
      <div className="flex  justify-center">
        <div className={styles.cards_wrapper}>
          {cards.map((cardData, index) => {
            return (
              <div className={styles.card} key={index.toString()}>
                <p className={styles.card_head}>{cardData.head}</p>
                <p className={styles.subhead}>{cardData.subhead}</p>
                <p className={styles.desc}>{cardData.desc}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex justify-center">
        <button
          className={`${styles.btn} flex`}
          onClick={() => {
            document
              .getElementById("contact-form")
              ?.scrollIntoView({behavior: "smooth"});
          }}
        >
          Join the waitlist
          <img
            src={Images.ctaArrow}
            alt="arrow"
            className=" w-[22px] h-[22px] 1750:w-[26px] 1750:h-[26px] mr-2.5 mt-[-1px]"
          />
        </button>
      </div>
    </div>
  );
};

export default Hiring;
