import {COOKIESKEYS} from "globals/constants/cookiesKeys";
import strings from "globals/constants/strings.json";
import cookie from "react-cookies";
import {toast} from "react-toastify";

export const errorHandler = (data: any) => {
  let error = data.response;

  if (
    error?.status === 401 &&
    !window.location.pathname.includes("/create/one-up-interview")
  ) {
    localStorage.removeItem("AUTH_TOKEN");
    localStorage.removeItem("USER_DETAILS");
    cookie.remove(COOKIESKEYS.AUTH_TOKEN);
    cookie.remove(COOKIESKEYS.USER_DETAILS);
    toast.info(strings.tokenExpireMsg);
    setTimeout(() => {
      window.location.href = `/`;
    }, 700);
  }
  throw error;
};
