import React from "react";
import axios, {AxiosResponse} from "axios";
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import {APIEndPoints} from "globals/constants/endPoints";

export function useUploadSessionRecording(
  assessmentId: string
): UseMutationResult<
  AxiosResponse<any, any> | undefined,
  unknown,
  {url: string},
  unknown
> {
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("candidateToken")}`,
  };

  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["uploadSessionRecording"],
    mutationFn: async (data: {url: string}) => {
      return axios
        .post(APIEndPoints.UPLOAD_SESSION_RECORDING_NODE(assessmentId), data, {
          headers,
        })
        .then((res) => {
          if (res.data.success === true && res.data.code === 200) {
            queryClient.invalidateQueries(["uploadSessionRecording"]);
            return res;
          }
        })
        .catch((e) => {
          console.log("error in axios", e);
          return e;
        });
    },
    onError: (e) => {
      console.log("eeeeeeeee", e);
    },
  });
}
