export const jds = {
  react: `
    Job Title:  React JS Developer
    Location: [Insert Location or "Remote"]
    Company: SourceBae

    About SourceBae
    SourceBae is a fast-growing tech company focused on building cutting-edge software solutions. We specialize in delivering exceptional development services and are looking for passionate developers to join our team. If you’re eager to work on innovative projects in a collaborative environment, we’d love to hear from you!

    Position Overview
    We are seeking an Intermediate React Developer with 1-5 years of experience who excels in creating scalable and efficient front-end solutions. The ideal candidate will have a solid foundation in React.js, along with the ability to work collaboratively and deliver high-quality code in a fast-paced environment.

    Key Responsibilities
    Develop dynamic, responsive user interfaces using React.js
    Build reusable components and front-end libraries
    Convert designs and wireframes into high-quality code
    Optimize components for cross-browser compatibility and performance
    Collaborate with back-end developers and designers to enhance overall user experience
    Integrate front-end with RESTful APIs and GraphQL
    Participate in code reviews, ensuring adherence to best practices
    Debug and resolve issues, ensuring efficient performance and user satisfaction
    Required Skills & Experience
    1-5 years of experience working with React.js and its core principles
    Strong proficiency in JavaScript (ES6+), HTML, and CSS
    Experience with state management libraries (e.g., Redux, MobX)
    Understanding of RESTful APIs and modern authorization mechanisms (e.g., JWT)
    Familiarity with front-end build tools like Webpack, Babel, NPM, etc.
    Experience with version control systems (e.g., Git)
    Good understanding of cross-browser compatibility challenges and solutions
    Excellent problem-solving skills and attention to detail
    Preferred Skills
    Experience with TypeScript
    Familiarity with GraphQL
    Knowledge of server-side rendering (e.g., Next.js)
    Experience with CI/CD pipelines
    Understanding of testing frameworks like Jest or React Testing Library
`,

  node: `Node.js Developer with 1-5 years of experience:

    Job Title:Node.js Developer
    Company: SourceBae

    About SourceBae
    SourceBae is a fast-growing tech company focused on building cutting-edge software solutions. We specialize in delivering exceptional development services and are looking for passionate developers to join our team. If you’re eager to work on innovative projects in a collaborative environment, we’d love to hear from you!

    Position Overview
    We are seeking an Intermediate Node.js Developer with 1-5 years of experience who excels in creating scalable and efficient back-end solutions. The ideal candidate will have a solid foundation in Node.js, along with the ability to work collaboratively and deliver high-quality code in a fast-paced environment.

    Key Responsibilities
    Design and develop efficient, reusable, and scalable back-end solutions using Node.js
    Build and maintain RESTful APIs and GraphQL endpoints
    Optimize application performance, ensuring high availability and responsiveness
    Collaborate with front-end developers to integrate user-facing elements with server-side logic
    Work with databases such as MongoDB, MySQL, or PostgreSQL to store and manage data
    Implement security protocols and data protection measures
    Troubleshoot and debug applications to resolve issues quickly
    Participate in code reviews to ensure quality and maintain best practices
    Required Skills & Experience
    1-5 years of experience working with Node.js
    Strong proficiency in JavaScript (ES6+), including asynchronous programming
    Hands-on experience with building RESTful APIs and/or GraphQL
    Familiarity with database technologies such as MongoDB, MySQL, PostgreSQL, etc.
    Understanding of security best practices, including JWT, OAuth, and data encryption
    Experience with version control systems (e.g., Git)
    Familiarity with Docker and containerization is a plus
    Excellent problem-solving skills and attention to detail
    Preferred Skills
    Experience with cloud platforms (AWS, Google Cloud, Azure)
    Familiarity with TypeScript
    Understanding of microservices architecture
    Experience with CI/CD pipelines
    Knowledge of testing frameworks (e.g., Mocha, Chai, Jest)`,
};
